import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useInfoStyles = makeStyles((theme: Theme) =>
  createStyles({
    infoPaper: {
      display: 'flex',
      flexFlow: 'row-reverse',
      borderRadius: 0,
      padding: '16px',
      backgroundColor: 'rgba(28, 86, 46, 0.05)',
    },
    infoText: {
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.25px',
      fontWeight: 400,
      color: 'rgba(0, 0, 0, 0.6)',
    },
    infoIcon: {
      marginRight: '16px',
      width: '40px',
      height: '40px',
      fill: theme.palette.primary.main,
    },
  }),
);

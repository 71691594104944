import React from 'react';

import { AuctionFeatures } from './auctionFeatures/AuctionFeatures';
import { Copyright } from './copyright/Copyright';

export const Footer = () => {
  return (
    <>
      <AuctionFeatures />
      <Copyright />
    </>
  );
};

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  label: {
    color: theme.palette.grey[600],
    fontSize: theme.typography.body1.fontSize,
    marginBottom: theme.spacing(0.8),
  },
  value: {
    fontSize: theme.extras.typography.body0.fontSize,
  },
}));

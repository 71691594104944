import { useContext } from 'react';

import { UserDataRefreshContextType } from '../../context/userData/userDataContext/UserDataContext.types';
import { UserDataRefreshContext } from '../../context/userData/userDataContext/UserDataContext';

export const useUserDataRefresh = (): UserDataRefreshContextType => {
  const context = useContext(UserDataRefreshContext);

  if (context === undefined) {
    throw new Error('useUserDataRefresh must be used within an UserDataContextController');
  }

  return context;
};

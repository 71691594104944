import React, { useCallback, useEffect } from 'react';
import { useParameterizedQuery } from 'react-fetching-library';

import { getMyCurrentBids } from 'api/actions/user/userActions';
import { scrollTop } from 'helpers/scrollTop';

import { CurrentBidsList } from './CurrentBidsList';

export const CurrentBidsListContainer = () => {
  const { loading, query, error, payload, abort } = useParameterizedQuery(getMyCurrentBids);

  useEffect(() => {
    query(1);

    return () => abort();
  }, [abort, query]);

  const handlePageChange = useCallback(
    (page: number) => {
      if (!loading) {
        query(page);
        scrollTop();
      }
    },
    [loading, query],
  );

  return <CurrentBidsList error={error} auctions={payload} onPageChange={handlePageChange} />;
};

/* eslint-disable */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-getcanonicallocales/polyfill';
import 'custom-event-polyfill';

if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/dist/locale-data/en');
  require('@formatjs/intl-pluralrules/dist/locale-data/ar');
}

if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/en');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/ar');
}

if (typeof Intl.NumberFormat.__addLocaleData === 'function') {
  require('@formatjs/intl-numberformat/locale-data/en.js');
  require('@formatjs/intl-numberformat/locale-data/ar.js');
}

import { makeStyles } from '@material-ui/core';

export const useLocationStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    minHeight: 140,
    backgroundColor: '#FCFCFC',
    transition: 'background-color 0.25s ease-out',
  },
  paperActive: {
    backgroundColor: 'rgba(217, 226, 220, 0.25)',
  },
  label: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  labelActive: {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  inputInactive: {
    opacity: 0.6,
  },
  text: {
    color: theme.palette.text.disabled,
    fontSize: 16,
  },
  textActive: {
    color: theme.palette.text.primary,
  },
  autocomplete: {
    borderRadius: 0,
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.87) !important',
  },
  selectItem: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

import { makeStyles } from '@material-ui/core';

const outlineColor = 'rgba(0, 0, 0, 0.38)';
const outlineFocusedSpacing = 0.5;

export const useStyles = makeStyles(theme => ({
  root: {
    userSelect: 'none',
    display: 'grid',
    gridRowGap: theme.spacing(2),
    gridColumnGap: theme.spacing(2),
    gridTemplateColumns: `repeat(2, minmax(${theme.spacing(16)}px, 1fr))`,
    minHeight: theme.spacing(16),
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: `repeat(auto-fill, minmax(${theme.spacing(26)}px, 1fr))`,
      minHeight: theme.spacing(26),
      gridColumnGap: theme.spacing(3),
    },
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      '-ms-grid-rows': 'calc(136px + 146 * ((100vw - 320px) / 1600))',
      '-ms-grid-columns': '1fr 1fr',
      'grid-template-columns': '1fr 1fr',
      'grid-template-rows': '1fr 1fr',
    },
    [`${theme.breakpoints.up('xl')} and (-ms-high-contrast: none), (-ms-high-contrast: active)`]: {
      '-ms-grid-columns': '1fr 1fr 1fr',
      'grid-template-columns': '1fr 1fr 1fr',
    },
  },
  square: {
    position: 'relative',
    paddingBottom: '100%',
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      margin: theme.spacing(2),
      padding: theme.spacing(2),
    },
  },
  squareContent: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  squareCaption: {
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      width: '100%',
    },
  },
  dropzone: {
    cursor: 'pointer',
    outline: `1px solid ${outlineColor}`,
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.87)',
    opacity: 0.6,
    '&:focus': {
      outline: `${theme.spacing(outlineFocusedSpacing)}px solid ${outlineColor}`,
    },
  },
  dropzoneActive: {
    outline: `2px solid ${theme.palette.success.main}`,
    backgroundColor: theme.palette.success.light,
  },
  image: {
    outline: `1px solid ${outlineColor}`,
    backgroundColor: theme.palette.common.white,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  imageMain: {
    outline: `${theme.spacing(0.5)}px solid ${theme.palette.success.main}`,
  },
  removeFab: {
    position: 'absolute',
    bottom: theme.spacing(0.5),
    left: theme.spacing(0.5),
    minHeight: 0,
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.common.white,
  },
  selectButton: {
    width: '100%',
    height: '100%',
    '&:focus': {
      outline: `${theme.spacing(outlineFocusedSpacing)}px solid ${outlineColor}`,
    },
  },
  mainInfo: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  loadingOverlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    color: theme.palette.success.main,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  divider: {
    margin: `${theme.spacing(4)}px 0`,
    backgroundColor: '#B5B5B5',
  },
  logo: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  title: {
    textAlign: 'center',
    marginBottom: theme.spacing(0.5),
  },
  link: {
    color: theme.palette.primary.main,
  },
}));

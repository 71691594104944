import React from 'react';
import { Box, Divider } from '@material-ui/core';

import { FormDividerProps } from './FormDivider.types';
import { useDividerStyles } from './FormDivider.styles';

export const FormDivider = ({ text, className = '' }: FormDividerProps) => {
  const classes = useDividerStyles();

  return (
    <Box mt={4} mb={3} className={className}>
      <Divider />
      <p className={classes.text}>{text}</p>
    </Box>
  );
};

// see https://create-react-app.dev/docs/adding-custom-environment-variables
import { DebugOption } from '../../constants';

import { AppLocale } from './AppLocale.enum';

export const defaultLocale: AppLocale = (() => {
  const localStorageLocale = localStorage.getItem(DebugOption.locale);
  const supportedLocales: AppLocale[] = [AppLocale.en, AppLocale.ar];

  if (localStorageLocale && supportedLocales.some(locale => locale === localStorageLocale)) {
    return localStorageLocale as AppLocale;
  }

  return process.env.REACT_APP_DEFAULT_LOCALE as AppLocale;
})();

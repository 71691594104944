import { Action } from 'api/types';

import { LoginPayload, LoginResponse } from './authActions.types';

export function loginAction(values: LoginPayload): Action<LoginResponse> {
  return {
    method: 'POST',
    endpoint: '/login',
    body: values,
    config: {
      skipAuthorization: true,
      skipErrorHandler: [400, 401],
    },
  };
}

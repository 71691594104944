import qs from 'qs';

export enum AppRoute {
  home = '/',
  register = '/register',
  login = '/login',
  loginHelp = '/login-help/:type(vendor|buyer)',
  logout = '/logout',
  auctionDetails = '/auctions/:uuid',
  auctionsList = '/auctions',
  vendorProfile = '/profile/vendor/:uuid',
  buyerProfile = '/profile/buyer/:uuid',
  faq = '/faq',
  terms = '/terms-and-conditions',
  privacy = '/privacy-policy',
  shortLink = '/s/:hash',
}

export const urlBuilders = {
  [AppRoute.loginHelp]: (type: 'vendor' | 'buyer') => `/login-help/${type}`,
  [AppRoute.auctionDetails]: (uuid: string) => `/auctions/${uuid}`,
  [AppRoute.home]: (anchor: 'contact-us' | null) => (anchor ? `/#${anchor}` : '/'),
  [AppRoute.vendorProfile]: (uuid: string) => `/profile/vendor/${uuid}`,
  [AppRoute.buyerProfile]: (uuid: string) => `/profile/buyer/${uuid}`,
  [AppRoute.login]: (route?: string) => `/login${route && `?${qs.stringify({ route })}`}`,
};

import React, { useCallback, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';

import { Button } from '../../../ui/button/Button';
import { useLocale } from '../../../hooks/useLocale/useLocale';
import { useApiForm } from '../../../hooks/useApiForm/useApiForm';
import { Input } from '../../../ui/input/Input';
import { Dialog, DialogActions, DialogTitle } from '../../../ui/dialog/Dialog';

import { AdminTopBarFields, AdminTopBarProps } from './AdminTopBar.types';

export const AdminTopBar = ({ disabled, onAccept, onReject }: AdminTopBarProps) => {
  const { formatMsg } = useLocale();
  const [open, setOpen] = useState(false);
  const form = useApiForm<AdminTopBarFields>(async data => {
    const errors = onReject(data);
    if (!errors) handleOnClose();
    return errors;
  });
  const isBusy = disabled || form.formState.isSubmitting;
  const handleOnClose = useCallback(() => !isBusy && setOpen(false), [isBusy]);

  return (
    <>
      <Box my={3}>
        <Grid container spacing={3}>
          <Grid item>
            <Button
              icon={<CheckOutlinedIcon />}
              disabled={disabled}
              onClick={onAccept}
              text={formatMsg('auction.details.admin.approve')}
            />
          </Grid>
          <Grid item>
            <Button
              disabled={disabled}
              onClick={() => setOpen(true)}
              variant="text"
              text={formatMsg('auction.details.admin.reject')}
            />
          </Grid>
        </Grid>
      </Box>
      <Dialog open={open} onClose={handleOnClose}>
        <form onSubmit={form.onSubmit}>
          <DialogTitle text={formatMsg('auction.reject_auction.title')} />

          <Box mt={4}>{formatMsg('auction.reject_auction.description')}</Box>

          <Box mt={4}>
            <Input
              disabled={isBusy}
              name="reason"
              ref={form.register({ required: formatMsg('validation.common.required') })}
              type="text"
              autoFocus
              label={formatMsg('auction.reject_auction.label')}
              error={!!form.errors.reason?.message}
              helperText={form.errors.reason?.message}
            />
          </Box>

          <DialogActions>
            <Button disabled={isBusy} type="submit" text={formatMsg('auction.reject_auction.confirm')} size="small" />
            <Button
              disabled={isBusy}
              text={formatMsg('auction.reject_auction.cancel')}
              variant="text"
              onClick={handleOnClose}
              size="small"
            />
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

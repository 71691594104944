import { makeStyles } from '@material-ui/core';

export const useHomeStyles = makeStyles(theme => ({
  root: {
    overflowX: 'hidden',
  },
  grid: {
    padding: '0 16px',
    [theme.breakpoints.up('md')]: {
      padding: '0 60px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '0 75px',
    },
    [theme.breakpoints.up('xl')]: {
      padding: '0 122px',
    },
  },
  lightBackground: {
    position: 'relative',
    backgroundColor: '#fff',
    '& .MuiBox-root': {
      backgroundColor: '#fff',

      '& > [class*="makeStyles-root"]:first-of-type': {
        paddingTop: '35px',
        [theme.breakpoints.up('sm')]: {
          paddingTop: '45px',
        },
        [theme.breakpoints.up('md')]: {
          paddingTop: '60px',
        },
        [theme.breakpoints.up('lg')]: {
          paddingTop: '80px',
        },
        [theme.breakpoints.up('xl')]: {
          paddingTop: '115px',
        },
      },
    },
  },
  wave: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    transform: 'translateY(-98%)',
    width: '100%',
    height: 'auto',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      height: 'calc(12px + 76 * ((100vw - 320px) / 1600))',
    },
  },
  waveMobile: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

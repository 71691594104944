import React from 'react';
import { FormHelperText } from '@material-ui/core';

import { useLocale } from 'hooks/useLocale/useLocale';
import { Checkbox } from 'ui/checkbox/Checkbox';
import { Button } from 'ui/button/Button';
import { Link } from 'ui/link/Link';
import { getAppMessage } from 'i18n/messages';
import { AppRoute } from 'routing/AppRoute.enum';
import { useApiForm } from '../../hooks/useApiForm/useApiForm';
import { getPhoneValidationOptions } from '../../validation/phone';
import { getTermsValidationOptions } from '../../validation/terms';
import { Phone } from '../../ui/fields/phone/Phone';
import { phonePrefix } from '../../constants';
import { SdmNumber } from '../../ui/fields/sdmNumber/SdmNumber';
import { useScreenWidth } from 'hooks/useScreenWidth/useScreenWidth';

import { useStyles } from './Register.styles';
import { RegisterVendorFormFields, RegisterVendorProps } from './Register.types';

export const RegisterVendor = ({ setFirstFieldRef, onVendorSubmit }: RegisterVendorProps) => {
  const classes = useStyles();
  const { isTabletDesktop } = useScreenWidth();
  const { formatMessage } = useLocale();
  const form = useApiForm<RegisterVendorFormFields>(onVendorSubmit, { defaultValues: { phone: phonePrefix } });

  return (
    <form className={classes.form} onSubmit={form.onSubmit}>
      {form.formError && <FormHelperText error>{form.formError}</FormHelperText>}
      <Phone
        name="phone"
        autoFocus={isTabletDesktop}
        error={form.errors.phone?.message}
        ref={element => {
          form.register(element, getPhoneValidationOptions({ formatMessage }));
          if (setFirstFieldRef) {
            setFirstFieldRef(element);
          }
        }}
        margin="normal"
      />
      <SdmNumber
        name="saudiDatesMark"
        error={form.errors.saudiDatesMark?.message}
        ref={form.register({ required: false })}
        margin="normal"
      />
      <Checkbox
        className={classes.formCheckbox}
        label={formatMessage(
          { id: getAppMessage('login_and_registration.terms_and_conditions_text') },
          {
            termsAndConditionsLink: (
              <Link
                href={AppRoute.terms}
                text={formatMessage({ id: getAppMessage('login_and_registration.terms_and_conditions_link') })}
                variant="inherit"
              />
            ),
            privacyPolicyLink: (
              <Link
                href={AppRoute.privacy}
                text={formatMessage({ id: getAppMessage('login_and_registration.privacy_policy_link') })}
                variant="inherit"
              />
            ),
          },
        )}
        name="terms"
        error={!!form.errors.terms}
        helperText={formatMessage({ id: getAppMessage('validation.common.required') })}
        ref={form.register(getTermsValidationOptions({ formatMessage }))}
      />
      <Button
        text={formatMessage({ id: 'login_and_registration.sign_up' })}
        type="submit"
        disabled={form.formState.isSubmitting}
        color="primary"
        className={classes.button}
      />
    </form>
  );
};

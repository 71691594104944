import React from 'react';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';

import { AppRoute } from 'routing/AppRoute.enum';
import { getAppMessage } from 'i18n/messages';

import { NavigationLinksProps } from './Navigation.types';

export const LoggedOutLinks = ({ onClick }: NavigationLinksProps) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <NavLink exact to={AppRoute.login} onClick={onClick}>
        {formatMessage({ id: getAppMessage('common.navigation.login') })}
      </NavLink>
      <NavLink exact to={AppRoute.register} onClick={onClick}>
        {formatMessage({ id: getAppMessage('common.navigation.register') })}
      </NavLink>
    </>
  );
};

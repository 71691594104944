/* eslint-disable react/jsx-props-no-spreading */
import React, { ChangeEvent } from 'react';
import { Grid, Box } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { getAppMessage } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useApiForm } from 'hooks/useApiForm/useApiForm';
import { Button } from 'ui/button/Button';
import { Input } from 'ui/input/Input';
import { useStyles } from '../BuyerProfileDetails.styles';
import { BuyerProfileDetailsFormProps, BuyerProfileDetailsFormFields } from '../BuyerProfileDetails.types';
import { BuyerField } from '../BuyerProfileDetails.enum';
import { getIdFromCityName } from 'helpers/extractCity';
import { FullName } from 'ui/fields/fullName/FullName';
import { getFullNameValidationOptions } from 'validation/fullName';

export const BuyerProfileDetailsForm = ({ userData, onSubmit, onClose, cities }: BuyerProfileDetailsFormProps) => {
  const { formatMessage, locale } = useLocale();
  const classes = useStyles();

  const { register, onSubmit: handleSubmitCallback, errors, setValue, clearError } = useApiForm<
    BuyerProfileDetailsFormFields
  >(onSubmit, {
    defaultValues: {
      [BuyerField.city]: userData[BuyerField.city],
      [BuyerField.cityId]: userData[BuyerField.cityId],
      [BuyerField.fullName]: userData[BuyerField.fullName],
    },
  });

  register({ name: BuyerField.city }, { required: formatMessage({ id: getAppMessage('validation.common.required') }) });
  register({ name: BuyerField.cityId });

  const handleCityChange = (e: ChangeEvent<{}>, value: string | null) => {
    if (!value) {
      setValue(BuyerField.city, '');
      setValue(BuyerField.cityId, 0);
    } else if (value) {
      setValue(BuyerField.city, value);
      setValue(BuyerField.cityId, getIdFromCityName(value, cities));
      clearError();
    }
  };

  return (
    <form onSubmit={handleSubmitCallback}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <p className={classes.label}>{formatMessage({ id: getAppMessage('common.labels.national_id') })}</p>
          <p className={classes.field}>{userData[BuyerField.nationalId]}</p>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Autocomplete
            options={cities.map(({ nameEn, nameAr }) => (locale === 'en' ? nameEn : nameAr))}
            classes={{
              paper: classes.autocomplete,
              option: classes.selectItem,
            }}
            onChange={handleCityChange}
            defaultValue={userData[BuyerField.city]}
            renderInput={params => (
              <Input
                {...params}
                label={formatMessage({ id: getAppMessage('common.labels.city') })}
                error={Boolean(errors.city)}
                helperText={errors.city?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FullName
            name={BuyerField.fullName}
            error={errors[BuyerField.fullName]?.message}
            ref={element => {
              register(element, getFullNameValidationOptions({ formatMessage }));
            }}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Input
            type="text"
            label={formatMessage({ id: getAppMessage('common.labels.phone_number') })}
            value={userData[BuyerField.phone]}
            disabled
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Input
            type="text"
            label={formatMessage({ id: getAppMessage('common.labels.whats_app_link') })}
            value={`wa.me/${userData[BuyerField.phone]}`}
            disabled
          />
        </Grid>
        <Box mt={4} mb={2} className={classes.buttons} p={1}>
          <Button
            type="submit"
            text={formatMessage({ id: getAppMessage('common.save') })}
            size="medium"
            className={classes.button}
          />
          <Button
            onClick={onClose}
            text={formatMessage({ id: getAppMessage('common.cancel') })}
            size="medium"
            variant="text"
          />
        </Box>
      </Grid>
    </form>
  );
};

import React from 'react';
import { Box, Typography, useTheme, useMediaQuery } from '@material-ui/core';

import { useLocale } from 'hooks/useLocale/useLocale';
import { getAppMessage } from 'i18n/messages';
import { ReactComponent as PlaceholderImage } from 'assets/images/finger-pointing-up.svg';
import { Placeholder } from 'app/auctionDetails/placeholder/Placeholder';
import { Pagination } from 'ui/pagination/Pagination';

import { WonAuctionsListProps } from './WonAuctionsList.types';
import { useStyles } from './WonAuctionsList.styles';
import { DesktopTable } from './desktopTable/DesktopTable';
import { MobileTable } from './mobileTable/MobileTable';

export const WonAuctionsList = ({
  loading,
  error,
  auctions,
  onPageChange,
  handleDeliveryConfirmation,
}: WonAuctionsListProps) => {
  const { formatMessage } = useLocale();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const classes = useStyles();
  const isRtl = theme.direction === 'rtl';

  return (
    <>
      {!loading && error && (
        <Box px={[2, 3]} flex="1" display="flex" alignItems="center" justifyContent="center">
          <Typography color="error">
            {formatMessage({ id: getAppMessage('exception.unknown.primary_text') })}
          </Typography>
        </Box>
      )}
      {!error && !!auctions && (
        <>
          {!!auctions.data.length ? (
            <>
              {matches ? (
                <DesktopTable
                  disabled={loading}
                  isRtl={isRtl}
                  activeRowClass={classes.activeRow}
                  data={auctions.data}
                  className={classes.table}
                  handleDeliveryConfirmation={handleDeliveryConfirmation}
                />
              ) : (
                <MobileTable
                  disabled={loading}
                  isRtl={isRtl}
                  activeRowClass={classes.activeRow}
                  data={auctions.data}
                  className={classes.table}
                  handleDeliveryConfirmation={handleDeliveryConfirmation}
                />
              )}
              <Box px={[2, 3]} mt={4}>
                <Pagination
                  currentPage={auctions.pagination.current}
                  pagesCount={auctions.pagination.pages}
                  onChange={onPageChange}
                />
              </Box>
            </>
          ) : (
            <Placeholder>
              <PlaceholderImage
                role="img"
                title={formatMessage({ id: getAppMessage('user_profile.buyer.no_auctions_info') })}
              />
              <Typography color="textPrimary">
                {formatMessage({
                  id: getAppMessage('user_profile.buyer.no_auctions_info'),
                })}
              </Typography>
            </Placeholder>
          )}
        </>
      )}
    </>
  );
};

import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import { LocaleContextController } from 'context/locale/localeContextController/LocaleContextController';
import { ClientContextController } from 'context/client/clientContextController/ClientContextController';
import { AuthContextController } from 'context/auth/authContextController/AuthContextController';
import { ErrorBoundary } from 'app/errorBoundary/ErrorBoundary';
import { CommonDataContextController } from 'context/commonData/commonDataContextController/CommonDataContextController';
import { UserDataContextController } from '../context/userData/userDataContextController/UserDataContextController';
import { ScrollToTop } from 'app/scrollToTop/ScrollToTop';
import { QueryParamsContextController } from '../context/queryParams/queryParamsContext/QueryParamsContextController';
import { TitleContextController } from '../context/title/titleContextController/TitleContextController';

import { AppProvidersProps } from './AppProviders.types';
import { AppThemeProvider } from './appThemeProvider/AppThemeProvider';
import { AppStylesProvider } from './appStylesProvider/AppStylesProvider';
import { MuiPickersUtilsProviderWithLocale } from './muiPickersUtilsProviderWithLocale/MuiPickersUtilsProviderWithLocale';

export const AppProviders = ({ children }: AppProvidersProps) => (
  <LocaleContextController>
    <AppThemeProvider>
      <AppStylesProvider>
        <Router>
          <ScrollToTop>
            <TitleContextController>
              <QueryParamsContextController>
                <SnackbarProvider dense>
                  <MuiPickersUtilsProviderWithLocale>
                    <ErrorBoundary>
                      <AuthContextController>
                        <ClientContextController>
                          <CommonDataContextController>
                            <UserDataContextController>{children}</UserDataContextController>
                          </CommonDataContextController>
                        </ClientContextController>
                      </AuthContextController>
                    </ErrorBoundary>
                  </MuiPickersUtilsProviderWithLocale>
                </SnackbarProvider>
              </QueryParamsContextController>
            </TitleContextController>
          </ScrollToTop>
        </Router>
      </AppStylesProvider>
    </AppThemeProvider>
  </LocaleContextController>
);

import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  help: {
    textAlign: 'center',
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formInfo: {
    marginTop: '20px',
    marginBottom: '35px',
  },
  formCheckbox: {
    marginBottom: '10px !important',
    '& label span': {
      fontSize: '0.6875rem',
    },
  },
}));

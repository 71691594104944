import qs from 'qs';

import {
  QueryFilterParam,
  QueryParamsStateContextValueType,
} from 'context/queryParams/queryParamsContext/QueryParamsContext.types';

export const createQueryString = (queryParams: QueryParamsStateContextValueType) => {
  const queries = [];

  type queryObject = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };

  const removeEmpty = (obj: queryObject) => {
    Object.entries(obj).forEach(([key, val]) => {
      if (val && typeof val === 'object') {
        if (!Object.values(obj[key]).length) {
          delete obj[key];
        }
        removeEmpty(val);
      } else if (!val) delete obj[key];
    });
  };

  for (const [key, value] of Object.entries(queryParams)) {
    let query;
    if (value) {
      if (key === 'filter') {
        removeEmpty(value as QueryFilterParam);
        query = qs.stringify({ filter: value }, { encode: false, indices: false });
      } else if (key === 'sort') {
        removeEmpty(value as QueryFilterParam);
        query = qs.stringify({ sort: value }, { encode: false, indices: false });
      } else {
        query = `${key}=${value}`;
      }
      queries.push(query);
    }
  }

  const queryString = queries
    .join('&')
    .replace(/(page=1&)/g, '')
    .replace(/(\[type\])/g, '[type][]')
    .replace(/(\[location\])/g, '[location][]');

  queryString.endsWith('&') && queryString.slice(0, -1);

  return queryString;
};

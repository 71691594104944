import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';

import { useLocale } from '../../../hooks/useLocale/useLocale';
import { getAppMessage } from '../../../i18n/messages';
import { Section } from '../section/Section';
import { Placeholder } from '../placeholder/Placeholder';
import { ReactComponent as TaxImage } from '../../../assets/images/tax.svg';
import { formatAmount } from '../../../helpers/formatAmount';
import { FileInput } from '../../../ui/fileInput/FileInput';
import { documentMimeTypes, isTouchDevice } from '../../../constants';
import { FileDescriptor } from '../../../ui/fileInput/FileInput.types';
import { AuctionPaymentFlow, AuctionPaymentStatus } from '../../../api/actions/auction/auctionActions.types';
import { Alert } from '../../../ui/alert/Alert';
import { File } from '../../../ui/file/File';
import { Button } from '../../../ui/button/Button';
import { Table } from '../table/Table';

import { useStyles } from './Fee.styles';
import { FeeProps } from './Fee.types';

export const Fee = ({ disabled, auction, onConfirmationUpload, onConfirmationRemove }: FeeProps) => {
  const { formatMessage, locale } = useLocale();
  const classes = useStyles();
  const [fileDescriptors, setFileDescriptors] = useState<FileDescriptor[]>([]);
  const title = formatMessage({ id: getAppMessage('auction.details.fee.title') });

  const rows = [
    {
      key: formatMessage({ id: getAppMessage('auction.details.fee.bank_label') }),
      value: formatMessage({ id: getAppMessage('auction.details.fee.bank_value') }),
    },
    {
      key: formatMessage({ id: getAppMessage('auction.details.fee.iban_label') }),
      value: formatMessage({ id: getAppMessage('auction.details.fee.iban_value') }),
    },
  ];

  switch (auction.paymentFlow) {
    case AuctionPaymentFlow.none:
    case AuctionPaymentFlow.waitingForBuyerPaymentConfirmation:
    case AuctionPaymentFlow.waitingForVendorPaymentConfirmation:
      return (
        <Section disabled title={title}>
          <Placeholder>
            <TaxImage role="img" title={formatMessage({ id: getAppMessage('auction.details.fee.tax') })} />
            <Typography>{formatMessage({ id: getAppMessage('auction.details.fee.placeholder') })}</Typography>
          </Placeholder>
        </Section>
      );
    case AuctionPaymentFlow.waitingForVendorFeePaymentConfirmation:
    case AuctionPaymentFlow.waitingForAdminFeePaymentConfirmation:
      return (
        <Section title={title}>
          <Box px={[2, 3]}>
            <Box>
              <Typography variant="body1" className={classes.amountLabel}>
                {formatMessage({ id: getAppMessage('auction.details.fee.amount_label') })}
              </Typography>
            </Box>
            <Box mt={0.5}>
              <Typography variant="h4">{formatAmount(Number(auction.feeValue ?? 0) / 100, locale, 0)}</Typography>
            </Box>
            <Box mt={3}>
              <Table rows={rows} />
            </Box>
            {[AuctionPaymentStatus.pending, AuctionPaymentStatus.rejected].includes(auction.feeStatus) ? (
              <Box mt={4}>
                <FileInput
                  accept={documentMimeTypes}
                  value={fileDescriptors}
                  onChange={setFileDescriptors}
                  disabled={disabled}
                  uploader={onConfirmationUpload}
                  text={formatMessage({
                    id: isTouchDevice
                      ? getAppMessage('auction.details.fee.dropzone_mobile_text')
                      : getAppMessage('auction.details.fee.dropzone_text'),
                  })}
                />
              </Box>
            ) : auction.vendorFee ? (
              <Box mt={3}>
                <File
                  name={auction.vendorFee}
                  action={
                    <Button
                      disabled={disabled}
                      variant="text"
                      size="small"
                      onClick={onConfirmationRemove}
                      text={formatMessage({ id: getAppMessage('common.remove') })}
                    />
                  }
                />
              </Box>
            ) : null}
          </Box>
        </Section>
      );
    default:
      return (
        <Section title={title}>
          <Box px={[2, 3]}>
            <Alert severity="success">
              {formatMessage({ id: getAppMessage('auction.details.fee.alert_received') })}
            </Alert>
          </Box>
        </Section>
      );
  }
};

import { makeStyles } from '@material-ui/core';

export const useFAQStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: '180px 16px 40px',
    backgroundColor: '#fff',
    [theme.breakpoints.up('sm')]: {
      padding: '200px 16px 60px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '215px 60px 60px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '225px 75px 60px',
    },
    [theme.breakpoints.up('xl')]: {
      padding: '240px 122px 80px',
    },
  },
  title: {
    marginBottom: '30px',
    fontSize: '42px',
    lineHeight: '120%',
    fontWeight: 800,
    color: theme.palette.primary.main,
    [theme.breakpoints.up('sm')]: {
      marginBottom: '35px',
      fontSize: '48px',
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: '45px',
      fontSize: '54px',
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: '55px',
      fontSize: '70px',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '80px',
    },
  },
  flex: {
    justifyContent: 'flex-start',
    flexFlow: 'column',
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      flexFlow: 'row',
    },
  },
  col: {
    maxWidth: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: 'calc(300px + 220 * ((100vw - 320px) / 1600))',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: 'calc(300px + 320 * ((100vw - 320px) / 1600))',
    },
    '&:first-of-type': {
      margin: 0,
      [theme.breakpoints.up('md')]: {
        marginRight: 'calc(0px + 200 * ((100vw - 320px) / 1600))',
      },
      [theme.breakpoints.up('md')]: {
        marginRight: 'calc(0px + 280 * ((100vw - 320px) / 1600))',
      },
    },
  },
}));

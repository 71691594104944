import React from 'react';
import { Drawer, useTheme } from '@material-ui/core';

import { DrawerProps } from './Drawer.types';

export const DrawerComponent = ({ children, variant = 'persistent', open, className, anchor }: DrawerProps) => {
  const theme = useTheme();

  if (!anchor) {
    anchor = theme.direction === 'rtl' ? 'right' : 'left';
  }

  return (
    <Drawer variant={variant} anchor={anchor} open={open} PaperProps={{ className: className }}>
      {children}
    </Drawer>
  );
};

/* eslint-disable prefer-spread */
import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';

import { Title } from 'ui/title/Title';
import { Navigation } from 'ui/navigation/Navigation';
import { useLocale } from 'hooks/useLocale/useLocale';
import { getAppMessage } from 'i18n/messages';
import { Footer } from 'ui/footer/Footer';
import { useTitleDispatch } from '../../hooks/useTitleDispatch/useTitleDispatch';

import { useStyles } from './PrivacyPolicy.styles';

export const PrivacyPolicy = () => {
  const setTitle = useTitleDispatch();
  const { formatMessage, formatMsg } = useLocale();
  const classes = useStyles();

  useEffect(() => {
    setTitle([formatMsg('privacy_policy.title')]);
  }, [formatMsg, setTitle]);

  const items = {
    // [sectionNumber, numberOfElements]
    sectionTwo: [2, 2],
    sectionThree: [3, 4],
    sectionFour: [4, 16],
    sectionFive: [5, 16],
    sectionSix: [6, 9],
  };

  const getListItems = ([section, items]: number[]) => {
    const listItems = [];
    for (let i = 0; i < items; i++) {
      listItems.push(
        <li className={classes.listItem} key={i}>
          <p>{formatMessage({ id: getAppMessage(`privacy_policy.section${section}.list.${i}`) })}</p>
        </li>,
      );
    }
    return listItems;
  };

  const section2List = getListItems(items.sectionTwo);
  const section3List = getListItems(items.sectionThree);
  const section4List = getListItems(items.sectionFour);
  const section5List = getListItems(items.sectionFive);
  const section6List = getListItems(items.sectionSix);

  return (
    <>
      <Navigation variant="overlay" />
      <Box className={classes.root}>
        <Box>
          <Title
            text={formatMessage({ id: getAppMessage('privacy_policy.title') })}
            component="h1"
            variant="h2"
            className={classes.header1}
          />

          <p>{formatMessage({ id: getAppMessage('privacy_policy.section1.p1') })}</p>
          <p>{formatMessage({ id: getAppMessage('privacy_policy.section1.p2') })}</p>
          <p>{formatMessage({ id: getAppMessage('privacy_policy.section1.p3') })}</p>
        </Box>
        <Box>
          <Title
            component="h2"
            className={classes.header2}
            variant="h3"
            text={formatMessage({ id: getAppMessage('privacy_policy.section2.t1') })}
          />
          <Title
            component="h3"
            className={classes.header3}
            variant="h5"
            text={formatMessage({ id: getAppMessage('privacy_policy.section2.t2') })}
          />
          <p>{formatMessage({ id: getAppMessage('privacy_policy.section2.list.title') })}</p>
          <ul>{section2List}</ul>
        </Box>
        <Box>
          <Title
            component="h3"
            className={classes.header3}
            variant="h5"
            text={formatMessage({ id: getAppMessage('privacy_policy.section3.t1') })}
          />
          <p>{formatMessage({ id: getAppMessage('privacy_policy.section3.p1') })}</p>
          <Title
            component="h3"
            className={classes.header3}
            variant="h5"
            text={formatMessage({ id: getAppMessage('privacy_policy.section3.t2') })}
          />
          <p>{formatMessage({ id: getAppMessage('privacy_policy.section3.p2') })}</p>
          <p>{formatMessage({ id: getAppMessage('privacy_policy.section3.list.title') })}</p>
          <ul>{section3List}</ul>
          <p>{formatMessage({ id: getAppMessage('privacy_policy.section3.p3') })}</p>
          <p>{formatMessage({ id: getAppMessage('privacy_policy.section3.p4') })}</p>
        </Box>
        <Box>
          <Title
            component="h3"
            className={classes.header3}
            variant="h5"
            text={formatMessage({ id: getAppMessage('privacy_policy.section4.t1') })}
          />
          <p>{formatMessage({ id: getAppMessage('privacy_policy.section4.list.title') })}</p>
          <ul>{section4List}</ul>
          <p>{formatMessage({ id: getAppMessage('privacy_policy.section4.p1') })}</p>
        </Box>
        <Box>
          <Title
            component="h3"
            className={classes.header3}
            variant="h5"
            text={formatMessage({ id: getAppMessage('privacy_policy.section5.t1') })}
          />
          <p>{formatMessage({ id: getAppMessage('privacy_policy.section5.list.title') })}</p>
          <ul>{section5List}</ul>
          <p>{formatMessage({ id: getAppMessage('privacy_policy.section5.p1') })}</p>
        </Box>
        <Box>
          <Title
            component="h3"
            className={classes.header3}
            variant="h5"
            text={formatMessage({ id: getAppMessage('privacy_policy.section6.t1') })}
          />
          <p>{formatMessage({ id: getAppMessage('privacy_policy.section6.list.title') })}</p>
          <ul>{section6List}</ul>
          <p>{formatMessage({ id: getAppMessage('privacy_policy.section6.p1') })}</p>
          <p>{formatMessage({ id: getAppMessage('privacy_policy.section6.p2') })}</p>
          <p>{formatMessage({ id: getAppMessage('privacy_policy.section6.p3') })}</p>
        </Box>
        <Box>
          <Title
            component="h3"
            className={classes.header3}
            variant="h5"
            text={formatMessage({ id: getAppMessage('privacy_policy.section7.t1') })}
          />
          <p>{formatMessage({ id: getAppMessage('privacy_policy.section7.p1') })}</p>
          <Title
            component="h3"
            className={classes.header3}
            variant="h5"
            text={formatMessage({ id: getAppMessage('privacy_policy.section7.t2') })}
          />
          <p>{formatMessage({ id: getAppMessage('privacy_policy.section7.p2') })}</p>
          <Title
            component="h3"
            className={classes.header3}
            variant="h5"
            text={formatMessage({ id: getAppMessage('privacy_policy.section7.t3') })}
          />
          <p>{formatMessage({ id: getAppMessage('privacy_policy.section7.p3') })}</p>
          <Title
            component="h3"
            className={classes.header3}
            variant="h5"
            text={formatMessage({ id: getAppMessage('privacy_policy.section7.t4') })}
          />
          <p>{formatMessage({ id: getAppMessage('privacy_policy.section7.p4') })}</p>
        </Box>
      </Box>
      <Box>
        <Footer />
      </Box>
    </>
  );
};

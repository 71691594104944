import React from 'react';
import clsx from 'clsx';
import { Box } from '@material-ui/core';

import { PlaceholderProps } from './Placeholder.types';
import { useStyles } from './Placeholder.styles';

export const Placeholder = ({ children, className }: PlaceholderProps) => {
  const classes = useStyles();

  return (
    <Box
      px={[2, 3]}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      flex="1"
      className={classes.box}
    >
      <div className={clsx(classes.root, className)}>{children}</div>
    </Box>
  );
};

import React, { useState } from 'react';
import { Box, Paper } from '@material-ui/core';

import { useLocale } from '../../../hooks/useLocale/useLocale';
import { AppMessages } from '../../../i18n/messages';
import { Section } from '../section/Section';
import { Checkbox } from '../../../ui/checkbox/Checkbox';
import { Button } from '../../../ui/button/Button';

import { DeliveryConfirmationProps } from './DeliveryConfirmation.types';
import { useStyles } from './DeliveryConfirmation.styles';

export const DeliveryConfirmation = ({ disabled, onConfirmDelivery }: DeliveryConfirmationProps) => {
  const { formatMessage } = useLocale();
  const classes = useStyles();
  const [checked, setChecked] = useState(false);

  return (
    <Section title={formatMessage({ id: AppMessages['auction.details.delivery_confirmation.title'] })}>
      <Box px={[2, 3]}>
        <Paper elevation={0} className={checked ? classes.paperSuccess : classes.paperGrey}>
          <Box px={2} py={0.5}>
            <Checkbox
              disabled={disabled}
              checked={checked}
              onChange={(event, checked) => setChecked(checked)}
              name="confirmInformation"
              label={formatMessage({ id: AppMessages['auction.details.delivery_confirmation.checkbox_label'] })}
              color="primary"
            />
          </Box>
        </Paper>
        <Box mt={3}>
          <Button
            size="small"
            disabled={!checked || disabled}
            onClick={onConfirmDelivery}
            text={formatMessage({ id: AppMessages['auction.details.delivery_confirmation.confirm'] })}
          />
        </Box>
      </Box>
    </Section>
  );
};

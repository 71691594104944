import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  amountLabel: {
    color: theme.palette.grey[600],
  },
  fileLabel: {
    fontSize: 18,
    lineHeight: 1.6,
    fontWeight: 'bold',
  },
}));

import { SyntheticEvent, useCallback, useRef } from 'react';

export const useInputConstraints = (predicate: (value: string) => boolean) => {
  const rollbackData = useRef<{ selectionStart: number | null; selectionEnd: number | null; value: string } | null>(
    null,
  );

  const handleKeydownEvent = useCallback(
    (event: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement, KeyboardEvent>) => {
      const { selectionStart, selectionEnd, value } = event.currentTarget;
      rollbackData.current = { selectionStart, selectionEnd, value };
    },
    [],
  );

  const handleInputEvent = useCallback(
    (event: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement, KeyboardEvent>) => {
      if (!predicate(event.currentTarget.value)) {
        if (rollbackData.current !== null) {
          event.currentTarget.value = rollbackData.current.value;
          event.currentTarget.selectionStart = rollbackData.current.selectionStart;
          event.currentTarget.selectionEnd = rollbackData.current.selectionEnd;
        }
      }
    },
    [predicate],
  );

  return { onKeyDown: handleKeydownEvent, onInput: handleInputEvent };
};

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { TextField } from '@material-ui/core';

import { ConstrainedInputProps } from './ConstrainedInput.types';

declare global {
  interface Window {
    clipboardData: DataTransfer; // needed by IE
  }
}

export const ConstrainedInput = React.forwardRef<HTMLInputElement, ConstrainedInputProps>(
  ({ constraints, ...rest }, ref) => {
    return (
      <TextField
        {...rest}
        type="text"
        inputRef={ref}
        fullWidth
        variant="outlined"
        color="secondary"
        inputProps={{
          onKeyDown: event => {
            if (!event.metaKey && event.key.length === 1) {
              const predictedValue =
                event.currentTarget.selectionStart && event.currentTarget.selectionEnd
                  ? [
                      event.currentTarget.value.slice(0, event.currentTarget.selectionStart),
                      event.key,
                      event.currentTarget.value.slice(event.currentTarget.selectionEnd),
                    ].join('')
                  : null;
              if (
                !constraints.test(event.key) ||
                (predictedValue !== null &&
                  typeof constraints.maxLength === 'number' &&
                  predictedValue.length > constraints.maxLength)
              ) {
                event.preventDefault();
                event.stopPropagation();
              }
            }
          },
          onPaste: event => {
            const pastedText = (event.clipboardData || window.clipboardData).getData('text/plain');
            const predictedValue =
              event.currentTarget.selectionStart && event.currentTarget.selectionEnd
                ? [
                    event.currentTarget.value.slice(0, event.currentTarget.selectionStart),
                    pastedText,
                    event.currentTarget.value.slice(event.currentTarget.selectionEnd),
                  ].join('')
                : null;
            if (
              !constraints.test(pastedText) ||
              (predictedValue !== null &&
                typeof constraints.maxLength === 'number' &&
                predictedValue.length > constraints.maxLength)
            ) {
              event.preventDefault();
              event.stopPropagation();
            }
          },
        }}
      />
    );
  },
);

import React, { PropsWithChildren, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { TitleStateContext, TitleDispatchContext } from '../titleContext/TitleContext';
import { useLocale } from '../../../hooks/useLocale/useLocale';
import { appTitle } from '../../../constants';

export const TitleContextController = ({ children }: PropsWithChildren<{}>) => {
  const [segments, setSegments] = useState<string[]>([]);
  const location = useLocation();
  const { formatMsg } = useLocale();

  useLayoutEffect(() => {
    setSegments([]);
  }, [formatMsg, location]);

  useEffect(() => {
    document.title = [appTitle, ...segments].join(' • ');
  }, [formatMsg, segments]);

  const handleSetSegments = useCallback(segments => {
    setSegments(segments.filter(Boolean));
  }, []);

  return (
    <TitleStateContext.Provider value={segments}>
      <TitleDispatchContext.Provider value={handleSetSegments}>{children}</TitleDispatchContext.Provider>
    </TitleStateContext.Provider>
  );
};

import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useAuctionsListStyles = makeStyles((theme: Theme) =>
  createStyles({
    auctionsNav: {
      padding: '0 16px 0',
      [theme.breakpoints.up('md')]: {
        padding: '0 60px 0',
      },
      [theme.breakpoints.up('lg')]: {
        padding: '0 75px 0',
      },
      [theme.breakpoints.up('xl')]: {
        padding: '0 122px 0',
      },
    },
    filtersTitle: {
      color: theme.palette.primary.main,
    },
    actionButtons: {
      flexFlow: theme.direction === 'rtl' ? 'row-reverse' : 'row',
      display: 'flex',
      transform: 'translateY(50%)',
    },
    filtersButton: {
      width: '48px',
      height: '48px',
      backgroundColor: '#fff',
      '&:hover': {
        backgroundColor: '#fff',
      },
      boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)',
    },
    createAuctionButton: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'inline-flex',
        marginLeft: theme.direction === 'ltr' ? '30px' : 0,
        marginRight: theme.direction === 'rtl' ? '30px' : 0,
        fontWeight: 'bold',
        boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)',
      },
    },
    createAuctionButtonMobile: {
      position: 'fixed',
      bottom: '25px',
      left: theme.direction === 'rtl' ? '5px' : 'auto',
      right: theme.direction === 'ltr' ? '5px' : 'auto',
      display: 'inline-flex',
      transition: 'bottom 0.5s',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    createAuctionButtonMobileScrolled: {
      bottom: '120px',
    },
    auctionsSelect: {
      color: theme.palette.primary.main,
      '&::before, &::after': {
        content: 'none',
      },
    },
    auctionsSimpleFilters: {
      justifyContent: 'flex-start',
      alignItems: theme.direction === 'rtl' ? 'flex-start' : 'flex-end',
      flexFlow: 'column',
      display: 'flex',
      transform: 'translateY(25%)',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-start',
        flexFlow: theme.direction === 'rtl' ? 'row' : 'row-reverse',
        transform: 'translateY(50%)',
      },
      '& .MuiInputBase-root': {
        paddingRight: 0,
      },
    },
    auctionsTypeSelect: {
      marginRight: theme.direction === 'rtl' ? '30px' : 0,
      marginLeft: theme.direction === 'ltr' ? '30px' : 0,
      flexFlow: theme.direction === 'rtl' ? 'row-reverse' : 'row',
      '& .MuiSelect-root': {
        padding: theme.direction === 'rtl' ? '6px 0 7px 15px' : '6px 15px 7px 0',
      },
    },
    auctionsSortingSelect: {
      flexFlow: theme.direction === 'rtl' ? 'row' : 'row-reverse',
      '& svg': {
        transform: 'scale(-1, 1)',
      },
      '& .MuiSelect-root': {
        padding: theme.direction === 'rtl' ? '6px 6px 7px 0' : '6px 0 7px 6px',
      },
    },
    listWrapper: {
      padding: '45px 16px 35px',
      [theme.breakpoints.up('sm')]: {
        padding: '45px 16px 40px',
      },
      [theme.breakpoints.up('md')]: {
        padding: '65px 60px 55px',
      },
      [theme.breakpoints.up('lg')]: {
        padding: '65px 75px 70px',
      },
      [theme.breakpoints.up('xl')]: {
        padding: '70px 122px 85px',
      },
    },
  }),
);

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#FFFFFF',
  },
  topBorder: {
    borderTop: '1px solid rgba(28, 86, 42, 0.3)',
  },
  logo: {
    margin: 'auto',
    display: 'block',
    width: 139,
    height: 'auto',
  },
  logoWide: {
    width: 180,
  },
  text: {
    padding: `0 ${theme.spacing(1.5)}px`,
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    flexDirection: 'column',
    [theme.breakpoints.only('sm')]: {
      fontSize: '0.75rem',
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0.5),
    },
  },
  takamol: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing(2)}px 0`,
  },
  managed: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '0.75rem',
    opacity: 0.5,
  },
  altCopyright: {
    padding: `${theme.spacing(2)}px 0`,
  },
}));

import React from 'react';
import clsx from 'clsx';

import { Title } from 'ui/title/Title';

import { TextWithNumberProps } from './TextWithNumber.types';
import { useStyles } from './TextWithNumber.styles';

export const TextWithNumber = ({
  number,
  title,
  description,
  fullWidth,
  smallNumber,
  fixedTitleHeight,
}: TextWithNumberProps) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.container, {
        [classes.containerFullWidth]: fullWidth,
        [classes.containerSmallNumber]: smallNumber,
      })}
    >
      <p
        className={clsx(classes.number, {
          [classes.smallNumber]: smallNumber,
        })}
      >
        {number}
      </p>
      {title && (
        <Title
          component="h3"
          variant="h5"
          text={title}
          color="primary"
          className={clsx(classes.title, { [classes.titleFixedHeight]: fixedTitleHeight })}
        />
      )}
      {description && <p className={classes.description}>{description}</p>}
    </div>
  );
};

/* eslint-disable @typescript-eslint/camelcase */
import { QueryResponse, ResponseInterceptor } from 'react-fetching-library';
import { Dispatch } from 'react';

import { Action } from 'api/types';
import { AuthAction } from '../../../context/auth/auth.types';
import { setTokens, clearTokens } from '../../../context/auth/authActionCreators/authActionCreators';
import { RefreshTokenResponse } from '../../actions/auth/authActions.types';

let refreshPromise: null | Promise<RefreshTokenResponse> = null;

type RefreshTokenInterceptorFactory = (refreshToken: string, dispatch: Dispatch<AuthAction>) => ResponseInterceptor;

export const responseRefreshTokenInterceptor: RefreshTokenInterceptorFactory = (
  refreshToken,
  dispatch,
) => client => async (action: Action, response: QueryResponse<object>) => {
  if (action.config?.skipAuthorization || action.config?.skipRefreshToken) return response;

  if (response.status === 401) {
    if (!refreshPromise && refreshToken) {
      refreshPromise = fetch(`${String(process.env.REACT_APP_API_URL)}/token/refresh`, {
        method: 'POST',
        body: JSON.stringify({
          // eslint-disable-next-line @typescript-eslint/camelcase
          refresh_token: refreshToken,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then<RefreshTokenResponse>(response => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('Bad response');
        })
        .then<RefreshTokenResponse>(payload => {
          const { token, refresh_token } = payload;
          dispatch(setTokens(token, refresh_token));
          refreshPromise = null;
          return payload;
        })
        .catch(error => {
          dispatch(clearTokens());

          throw error;
        });
    }

    if (refreshPromise) {
      const { token } = await refreshPromise;
      const config = action.config || {};
      config.skipAuthorization = true; // to prevent potential infinite 401 loop
      return client.query({
        ...action,
        headers: {
          ...action.headers,
          Authorization: `Bearer ${token}`,
        },
        config,
      });
    }
  }

  return response;
};

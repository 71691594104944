import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  name: {
    wordBreak: 'break-word',
    padding: `${theme.spacing(1.5)}px 0`,
    marginRight: theme.spacing(1),
  },
  action: {
    padding: `${theme.spacing(1.5)}px 0`,
    marginLeft: theme.spacing(-1),
  },
}));

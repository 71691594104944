import { Action } from 'react-fetching-library';

import { CreateAuctionPayload, AuctionResponse } from './auctionActions.types';

export function createAuctionAction(values: CreateAuctionPayload): Action {
  return {
    method: 'POST',
    endpoint: '/v1/auctions',
    body: values,
  };
}

export function getAuction(uuid: string): Action<AuctionResponse> {
  return {
    method: 'GET',
    endpoint: `/v1/auctions/${uuid}`,
  };
}

export function addImageAction(file: File): Action {
  const formData = new FormData();
  formData.append('image', file);

  return {
    method: 'POST',
    endpoint: '/v1/auctions/images',
    body: formData,
  };
}

export function acceptAuction(uuid: string): Action {
  return {
    method: 'PUT',
    endpoint: `/v1/auctions/${uuid}/accept`,
  };
}

export function rejectAuction(uuid: string, reason: string): Action {
  return {
    method: 'PUT',
    endpoint: `/v1/auctions/${uuid}/decline`,
    body: { reason },
    config: {
      skipErrorHandler: false,
      errorHandlerKey: 'error',
    },
  };
}

export function closeAuction(uuid: string): Action {
  return {
    method: 'PUT',
    endpoint: `/v1/auctions/${uuid}/close`,
  };
}

export function confirmPayment(uuid: string): Action {
  return {
    method: 'POST',
    endpoint: `/v1/auctions/${uuid}/payment/confirm-payment`,
  };
}

export function confirmFee(uuid: string): Action {
  return {
    method: 'POST',
    endpoint: `/v1/auctions/${uuid}/payment/confirm-fee`,
  };
}

export function confirmDelivery(uuid: string): Action {
  return {
    method: 'POST',
    endpoint: `/v1/auctions/${uuid}/payment/received-dates`,
  };
}

export function uploadAuctionBuyerPayment(file: File): Action<{ uuid: string }> {
  const formData = new FormData();
  formData.append('file', file);
  return {
    method: 'POST',
    endpoint: '/v1/auctions/files/buyer_payment',
    body: formData,
  };
}

export function uploadAuctionPaymentFee(file: File): Action<{ uuid: string }> {
  const formData = new FormData();
  formData.append('file', file);
  return {
    method: 'POST',
    endpoint: '/v1/auctions/files/vendor_payment_fee',
    body: formData,
  };
}

export function uploadFeeConfirmation(body: { auctionUuid: string; fileUuid: string }): Action {
  return {
    method: 'POST',
    endpoint: `/v1/auctions/${body.auctionUuid}/payment/upload-payment-fee`,
    body: {
      auctionId: body.auctionUuid,
      fileId: body.fileUuid,
    },
  };
}

export function uploadPaymentConfirmation(body: { auctionUuid: string; fileUuid: string }): Action {
  return {
    method: 'POST',
    endpoint: `/v1/auctions/${body.auctionUuid}/payment/upload-payment-confirmation`,
    body: {
      auctionId: body.auctionUuid,
      fileId: body.fileUuid,
    },
  };
}

export function rejectFeeConfirmation(body: { auctionUuid: string }): Action {
  return {
    method: 'POST',
    endpoint: `/v1/auctions/${body.auctionUuid}/payment/reject-fee`,
  };
}

export function rejectPaymentConfirmation(body: { auctionUuid: string }): Action {
  return {
    method: 'POST',
    endpoint: `/v1/auctions/${body.auctionUuid}/payment/reject-payment`,
  };
}

export function removeFeeConfirmation(body: { auctionUuid: string }): Action {
  return {
    method: 'POST',
    endpoint: `/v1/auctions/${body.auctionUuid}/payment/remove-fee`,
  };
}

export function removePaymentConfirmation(body: { auctionUuid: string }): Action {
  return {
    method: 'POST',
    endpoint: `/v1/auctions/${body.auctionUuid}/payment/remove-payment`,
  };
}

export function getPaymentConfirmationUrl(body: { auctionUuid: string }): Action<{ url: string }> {
  return {
    method: 'GET',
    endpoint: `/v1/auctions/${body.auctionUuid}/download/buyer_payment`,
  };
}

export function getFeeConfirmationUrl(body: { auctionUuid: string }): Action<{ url: string }> {
  return {
    method: 'GET',
    endpoint: `/v1/auctions/${body.auctionUuid}/download/vendor_payment_fee`,
  };
}

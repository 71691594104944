const maxPreviewWidth = 512;
const maxPreviewHeight = 512;

const readFileAsDataURL = async (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();

      reader.addEventListener(
        'load',
        () => {
          resolve(reader.result as string);
        },
        false,
      );

      reader.readAsDataURL(file);
    } catch (error) {
      reject(error);
    }
  });

const fileToHTMLImageElement = async (file: File): Promise<HTMLImageElement> => {
  let src: string;
  try {
    src = URL.createObjectURL(file);
  } catch {
    src = await readFileAsDataURL(file);
  }

  return new Promise((resolve, reject) => {
    try {
      const img = new Image();
      img.onload = () => resolve(img);
      img.src = src;
    } catch (error) {
      reject(error);
    }
  });
};

export const getImageFilePreviewURL = async (file: File): Promise<string> => {
  const image = await fileToHTMLImageElement(file);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    throw new Error('Could not create 2d context.');
  }
  let width = image.naturalWidth;
  let height = image.naturalHeight;
  if (width > height) {
    if (width > maxPreviewWidth) {
      height *= maxPreviewWidth / width;
      width = maxPreviewWidth;
    }
  } else {
    if (height > maxPreviewHeight) {
      width *= maxPreviewHeight / height;
      height = maxPreviewHeight;
    }
  }
  canvas.width = width;
  canvas.height = height;

  ctx.drawImage(image, 0, 0, width, height);

  return canvas.toDataURL('image/png');
};

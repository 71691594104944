import { Action } from 'api/types';

import { AuctionsPayload, AuctionsFiltersPayload } from './auctionsActions.types';

export function getAuctionsAction(query: string): Action<AuctionsPayload> {
  return {
    method: 'GET',
    endpoint: `/v1/auctions${query}`,
  };
}

export function getAuctionsFiltersAction(): Action<AuctionsFiltersPayload> {
  return {
    method: 'GET',
    endpoint: '/v1/auctions/filters',
  };
}

import { makeStyles, Theme } from '@material-ui/core';

import { auctionStatusTextColors } from '../../../constants';

import { AuctionTileStatusProps } from './AuctionTileStatus.types';

export const useStyles = makeStyles<Theme, Pick<AuctionTileStatusProps, 'color'>>(theme => ({
  root: {
    position: 'absolute',
    top: '8px',
    right: theme.direction === 'rtl' ? '8px' : 'auto',
    left: theme.direction === 'ltr' ? '8px' : 'auto',
    padding: '2px 8px',
    fontSize: '14px',
    lineHeight: '24px',
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)',
    color: ({ color }) => auctionStatusTextColors[color],
    backgroundColor: ({ color }) => color,
    borderRadius: '4px',
  },
}));

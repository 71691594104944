import React, { forwardRef } from 'react';
import { Checkbox as MuiCheckbox, FormControlLabel, Typography, FormControl, FormHelperText } from '@material-ui/core';

import { CheckboxProps } from './Checkbox.types';
import { useCheckboxStyles } from './Checkbox.styles';

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    { label, name, error, helperText, className, color = 'default', variant = 'caption', checked, onChange, disabled },
    ref,
  ) => {
    const classes = useCheckboxStyles();

    return (
      <FormControl error={error} className={className}>
        <FormControlLabel
          disabled={disabled}
          control={
            <MuiCheckbox
              color={color}
              inputRef={ref}
              name={name}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...(checked !== undefined && onChange ? { checked, onChange } : {})}
            />
          }
          label={
            <Typography align="left" className={classes.primaryCheckboxLabel} variant={variant}>
              {label}
            </Typography>
          }
        />
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  },
);

import React, { useCallback, useMemo, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useClient } from 'react-fetching-library';
import { saveAs } from 'file-saver';

import { useLocale } from '../../../hooks/useLocale/useLocale';
import { AppMessages } from '../../../i18n/messages';
import { Section } from '../section/Section';
import { AuctionPaymentFlow, AuctionStatus } from '../../../api/actions/auction/auctionActions.types';
import { ReactComponent as TaxImage } from '../../../assets/images/tax.svg';
import { Placeholder } from '../placeholder/Placeholder';
import { formatAmount } from '../../../helpers/formatAmount';
import { File } from '../../../ui/file/File';
import { Button } from '../../../ui/button/Button';
import { Alert } from '../../../ui/alert/Alert';
import { getFeeConfirmationUrl } from '../../../api/actions/auction/auctionActions';

import { AdminProps } from './Admin.types';
import { useStyles } from './Admin.styles';

export const Admin = ({ auction, onConfirmFee, onRejectFee, disabled }: AdminProps) => {
  const client = useClient();
  const { formatMessage, locale } = useLocale();
  const classes = useStyles();
  const title = formatMessage({ id: AppMessages['auction.details.admin.title'] });
  const { vendorFee } = auction;
  const [isBusy, setIsBusy] = useState();

  const handleDownload = useCallback(
    async (filename: string) => {
      setIsBusy(true);
      const urlRes = await client.query(getFeeConfirmationUrl({ auctionUuid: auction.uuid }));
      if (!urlRes.error && urlRes.payload) {
        window.open(urlRes.payload.url, '_blank');
        const blob = await fetch(urlRes.payload.url).then(response => response.blob());
        saveAs(blob, filename);
      }
      setIsBusy(false);
    },
    [auction.uuid, client],
  );

  const file = useMemo(
    () =>
      vendorFee ? (
        <Box mt={5}>
          <div className={classes.fileLabel}>
            {formatMessage({ id: AppMessages['auction.details.bids.transfer_receipt'] })}
          </div>
          <File
            name={vendorFee}
            action={
              <Button
                disabled={disabled || isBusy}
                variant="text"
                size="small"
                onClick={() => handleDownload(vendorFee)}
                text={formatMessage({ id: AppMessages['common.download'] })}
              />
            }
          />
        </Box>
      ) : null,
    [vendorFee, classes.fileLabel, formatMessage, disabled, isBusy, handleDownload],
  );

  switch (auction.status) {
    case AuctionStatus.new:
    case AuctionStatus.accepted:
      return (
        <Section disabled title={title}>
          <Placeholder>
            <TaxImage role="img" title={formatMessage({ id: AppMessages['auction.details.fee.tax'] })} />
            <Typography>{formatMessage({ id: AppMessages['auction.details.admin.placeholder'] })}</Typography>
          </Placeholder>
        </Section>
      );
    case AuctionStatus.declined:
    case AuctionStatus.closed:
      return null;
    case AuctionStatus.ended:
      return null;
    case AuctionStatus.awarded:
      switch (auction.paymentFlow) {
        case AuctionPaymentFlow.none:
        case AuctionPaymentFlow.waitingForBuyerPaymentConfirmation:
        case AuctionPaymentFlow.waitingForVendorPaymentConfirmation:
        case AuctionPaymentFlow.waitingForVendorFeePaymentConfirmation:
          return (
            <Section title={title}>
              <Box px={[2, 3]}>
                <Box>
                  <Typography variant="body1" className={classes.amountLabel}>
                    {formatMessage({ id: AppMessages['auction.details.admin.amount_label'] })}
                  </Typography>
                </Box>
                <Box mt={0.5}>
                  <Typography variant="h4">{formatAmount(Number(auction.feeValue ?? 0) / 100, locale, 0)}</Typography>
                </Box>
              </Box>
            </Section>
          );
        case AuctionPaymentFlow.waitingForAdminFeePaymentConfirmation:
          return (
            <Section title={title}>
              <Box px={[2, 3]}>
                <Box>
                  <Typography variant="body1" className={classes.amountLabel}>
                    {formatMessage({ id: AppMessages['auction.details.admin.amount_label'] })}
                  </Typography>
                </Box>
                <Box mt={0.5}>
                  <Typography variant="h4">{formatAmount(Number(auction.feeValue ?? 0) / 100, locale, 0)}</Typography>
                </Box>
                {file}
                <Box mt={3}>
                  <Alert severity="success">
                    {formatMessage({ id: AppMessages['auction.details.admin.alert_pending'] })}
                  </Alert>
                </Box>
                <Box mt={4}>
                  <Grid container spacing={3}>
                    <Grid item>
                      <Button
                        disabled={disabled}
                        size="small"
                        onClick={onConfirmFee}
                        text={formatMessage({ id: AppMessages['auction.details.payments.confirm'] })}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        disabled={disabled}
                        variant="text"
                        size="small"
                        onClick={onRejectFee}
                        text={formatMessage({ id: AppMessages['auction.details.payments.reject'] })}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Section>
          );
        case AuctionPaymentFlow.paymentCompleted:
        case AuctionPaymentFlow.buyerGetShipment:
        default:
          return (
            <Section title={title}>
              <Box px={[2, 3]}>
                {file}
                <Box mt={3}>
                  <Alert severity="success">
                    {formatMessage({ id: AppMessages['auction.details.admin.alert_confirmed'] })}
                  </Alert>
                </Box>
              </Box>
            </Section>
          );
      }
  }
};

import { makeStyles, createStyles, Theme } from '@material-ui/core';

const underlineThickness = 2;
const navBgColor = '#FDE6BF';

export const useNavigationStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexFlow: theme.direction === 'rtl' ? 'row-reverse' : 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      display: 'flex',
      backgroundColor: 'transparent',
      boxShadow: 'none',
      transition: theme.transitions.create(['background', 'box-shadow'], {
        duration: theme.transitions.duration.enteringScreen,
        easing: theme.transitions.easing.easeOut,
      }),
      minHeight: 84,
      padding: '0 16px',
      [theme.breakpoints.up('md')]: {
        minHeight: 140,
        padding: '0 60px',
      },
      [theme.breakpoints.up('lg')]: {
        minHeight: 150,
        padding: '0 75px',
      },
      [theme.breakpoints.up('xl')]: {
        padding: '0 122px',
        minHeight: 160,
      },
    },
    rootAdmin: {
      padding: '20px 16px 35px',
      [theme.breakpoints.up('md')]: {
        padding: '20px 60px 40px',
      },
      [theme.breakpoints.up('lg')]: {
        padding: '20px 75px',
      },
      [theme.breakpoints.up('xl')]: {
        padding: '20px 122px',
      },
    },
    padding: {
      minHeight: 84,
      [theme.breakpoints.up('md')]: {
        minHeight: 140,
      },
      [theme.breakpoints.up('lg')]: {
        minHeight: 150,
      },
      [theme.breakpoints.up('xl')]: {
        minHeight: 160,
      },
    },
    paddingAdmin: {
      [theme.breakpoints.between('xs', 'sm')]: {
        minHeight: 102,
      },
    },
    appBar: {
      backgroundColor: 'inherit',
    },
    solid: {
      backgroundColor: navBgColor,
      boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)',
    },
    overlay: {
      '& $padding': {
        minHeight: 0,
      },
    },
    rootScrolled: {
      backgroundColor: '#fff',
      boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)',
    },
    navButton: {
      display: 'inline-block',
      marginBottom: '7px',
      padding: 0,
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
      '& svg': {
        width: '40px',
        height: '40px',
      },
    },
    navButtonClose: {
      position: 'absolute',
      top: 24,
      right: theme.direction === 'rtl' ? '16px' : 'auto',
      left: theme.direction === 'ltr' ? '16px' : 'auto',
      [theme.breakpoints.up('md')]: {
        right: theme.direction === 'rtl' ? '60px' : 'auto',
        left: theme.direction === 'ltr' ? '60px' : 'auto',
      },
    },
    logo: {
      width: '170px',
      [theme.breakpoints.up('md')]: {
        width: '200px',
      },
      [theme.breakpoints.up('lg')]: {
        width: '230px',
      },
      '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
        height: 38,
      },
      [`${theme.breakpoints.up('md')} and (-ms-high-contrast: none), (-ms-high-contrast: active)`]: {
        height: 45,
      },
      [`${theme.breakpoints.up('lg')} and (-ms-high-contrast: none), (-ms-high-contrast: active)`]: {
        height: 52,
      },
    },
    navLinks: {
      '& a': {
        display: 'inline-block',
        fontSize: '18px',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        textDecoration: 'none',
        textTransform: 'uppercase',
        borderBottom: `${underlineThickness}px solid transparent`,
        [theme.breakpoints.up('lg')]: {
          marginRight: theme.direction === 'ltr' ? '40px' : 0,
          marginLeft: theme.direction === 'rtl' ? '40px' : 0,
          alignItems: 'flex-start',
        },
        [theme.breakpoints.up('xl')]: {
          marginRight: theme.direction === 'ltr' ? '55px' : 0,
          marginLeft: theme.direction === 'rtl' ? '55px' : 0,
          fontSize: '20px',
        },

        '&.active': {
          borderBottom: `${underlineThickness}px solid ${theme.palette.primary.main}`,
        },
      },
    },
    navLinksDesktop: {
      direction: 'initial',
      display: 'none',
      [theme.breakpoints.up('lg')]: {
        display: 'block',
      },
      [`${theme.breakpoints.up('lg')} and (-ms-high-contrast: none), (-ms-high-contrast: active)`]: {
        display: 'flex',
        flexFlow: theme.direction === 'rtl' ? 'row-reverse' : 'row',
      },
    },
    navLinksMobile: {
      flexFlow: 'column-reverse',
      alignItems: 'center',
      display: 'flex',
      '& a': {
        marginBottom: '35px',
        fontSize: '22px',
        [theme.breakpoints.up('sm')]: {
          marginBottom: '45px',
          fontSize: '26px',
        },
        [theme.breakpoints.up('md')]: {
          marginBottom: '50px',
          fontSize: '30px',
        },
      },
      '& a:first-of-type': {
        marginBottom: 0,
      },
    },
    mobileNav: {
      flexFlow: 'column',
      justifyContent: 'center',
      height: '100vh',
      backgroundColor: '#E5E5E5',
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
    adminLabel: {
      position: 'absolute',
      bottom: 6,
      right: theme.direction === 'ltr' ? 16 : 'auto',
      left: theme.direction === 'rtl' ? 16 : 'auto',
      [theme.breakpoints.up('md')]: {
        right: theme.direction === 'ltr' ? 60 : 'auto',
        left: theme.direction === 'rtl' ? 60 : 'auto',
        bottom: 14,
      },
      [theme.breakpoints.up('lg')]: {
        right: theme.direction === 'ltr' ? 75 : 'auto',
        left: theme.direction === 'rtl' ? 75 : 'auto',
      },
      [theme.breakpoints.up('xl')]: {
        right: theme.direction === 'ltr' ? 122 : 'auto',
        left: theme.direction === 'rtl' ? 122 : 'auto',
      },
    },
  }),
);

import { makeStyles } from '@material-ui/core';

import placeholderImage from 'assets/images/placeholder.svg';

const imageHeight = 360;
const thumbnailHeight = 80;

export const useStyles = makeStyles(theme => {
  return {
    counter: {
      position: 'absolute',
      top: theme.spacing(2),
      left: 'auto',
      right: theme.spacing(2),
      background: 'rgba(255, 255, 255, 0.5)',
      borderRadius: theme.spacing(0.5),
      padding: `${theme.spacing(0.25)}px ${theme.spacing(1)}px`,
      lineHeight: 1.75,
    },
    itemWrapper: {
      verticalAlign: 'top',
    },
    images: {
      overflow: 'hidden',
      borderRadius: theme.spacing(2),
    },
    imagesWrapper: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
    },
    imagesItem: {
      height: imageHeight,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },
    imagesArrow: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(2),
    },
    imagesArrowPrev: theme.direction === 'rtl' ? { right: 0 } : { left: 0 },
    imagesArrowNext: theme.direction === 'rtl' ? { left: 0 } : { right: 0 },
    thumbnails: {
      flex: '1 1 auto',
      overflow: 'hidden',
      '& .slick-slide': {
        outline: 'none',
      },
    },
    thumbnailsWrapper: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      margin: `0 ${-theme.spacing(1)}px`,
      display: 'flex',
      alignItems: 'center',
    },
    thumbnailsArrow: {
      flex: '0 0 auto',
    },
    thumbnailsArrowPrev:
      theme.direction === 'rtl' ? { marginRight: -theme.spacing(0.75) } : { marginLeft: -theme.spacing(0.75) },
    thumbnailsArrowNext:
      theme.direction === 'rtl' ? { marginLeft: -theme.spacing(0.75) } : { marginRight: -theme.spacing(0.75) },
    thumbnailsItem: {
      cursor: 'pointer',
      borderRadius: theme.spacing(1),
      margin: `0 ${theme.spacing(1)}px`,
      height: thumbnailHeight,
      width: 'auto',
    },
    root: {
      width: '100%',
      height: imageHeight + theme.spacing(2) + thumbnailHeight,
      position: 'relative',
    },
    rootWithoutThumbnails: {
      height: imageHeight,
    },
    placeholder: {
      height: imageHeight,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover, 50%',
      backgroundImage: `linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%), url("${placeholderImage}")`,
      borderRadius: theme.spacing(2),
    },
  };
});

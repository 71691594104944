/* eslint-disable react/jsx-props-no-spreading,prefer-const */
import React from 'react';
import { useDropzone } from 'react-dropzone';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { Typography, ButtonBase, CircularProgress, useTheme, Fab, useMediaQuery } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import { isTouchDevice } from '../../constants';
import { createUniqueIdGenerator } from '../../helpers/createUniqueIdGenerator';
import { getImageFilePreviewURL } from '../../helpers/getImageFilePreviewURL';
import { getAppMessage } from '../../i18n/messages';
import { Status } from '../status/Status';

import { useStyles } from './ImageInput.styles';
import { FileDescriptor, ImageInputProps } from './ImageInput.types';

const idGenerator = createUniqueIdGenerator();

export const ImageInput = ({ value: [descriptors, mainId], onChange, uploader }: ImageInputProps) => {
  const addDescriptor = React.useRef<(fileDescriptor: FileDescriptor) => void>();
  const removeDescriptor = React.useRef<(fileDescriptorId: string | number) => void>();
  const replaceDescriptor = React.useRef<(fileDescriptorId: number, fileDescriptor: FileDescriptor) => void>();

  addDescriptor.current = fileDescriptor => onChange([[...descriptors, fileDescriptor], mainId]);
  removeDescriptor.current = id => onChange([descriptors.filter(d => d.id !== id), mainId]);
  replaceDescriptor.current = (id, fileDescriptor) => {
    onChange([descriptors.map(d => (d.id === id ? fileDescriptor : d)), mainId]);
  };

  const handleDrop = React.useCallback(
    (acceptedFiles: File[]) => {
      const processFile = async (file: File) => {
        const id = idGenerator.next().value;
        addDescriptor.current?.({ type: 'pending', id });
        try {
          const previewUrl = await getImageFilePreviewURL(file);
          replaceDescriptor.current?.(id, { type: 'pending', id, previewUrl });
          const realId = await uploader(file);
          replaceDescriptor.current?.(id, { type: 'uploaded', id: realId, previewUrl });
        } catch {
          removeDescriptor.current?.(id);
        }
      };
      for (const acceptedFile of acceptedFiles) {
        processFile(acceptedFile);
      }
    },
    [uploader],
  );
  const handleRemove = React.useCallback((id: string) => removeDescriptor.current?.(id), []);
  const handleMainIdChange = React.useCallback((id: string) => onChange([descriptors, id]), [descriptors, onChange]);

  const { formatMessage } = useIntl();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/jpeg, image/png',
    onDrop: handleDrop,
  });
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('xl'));

  const countGridPosition = (itemNumber: number) => {
    let rowCounter = 1;
    let columnCounter = 1;

    if (matches) {
      const col = Number((itemNumber / 3).toFixed(1));
      const integer = Math.floor(col);
      const decimal = Number((col - integer).toFixed(1));

      rowCounter = decimal === 0 ? integer : integer + 1;

      if (decimal === 0.3) {
        columnCounter = 1;
      } else if (decimal === 0.7) {
        columnCounter = 2;
      } else {
        columnCounter = 3;
      }
    } else {
      const col = Number((itemNumber / 2).toFixed(1));
      const integer = Math.floor(col);
      const decimal = Number((col - integer).toFixed(1));

      rowCounter = decimal === 0 ? integer : integer + 1;

      if (decimal === 0.5) {
        columnCounter = 1;
      } else {
        columnCounter = 2;
      }
    }

    return {
      row: rowCounter,
      column: columnCounter,
    };
  };

  const calcGridRows = (itemsCount: number) => {
    let rows = 0;
    if (matches) {
      rows = Math.floor(Number((itemsCount / 3).toFixed(1))) + 1;
    } else {
      rows = Math.floor(Number((itemsCount / 2).toFixed(1))) + 1;
    }

    return 'calc(136px + 146 * ((100vw - 320px) / 1600)) '.repeat(rows);
  };

  return (
    <div className={classes.root} style={{ msGridRows: calcGridRows(descriptors.length) } as React.CSSProperties}>
      <div className={classes.square}>
        <div
          {...getRootProps({
            className: clsx(classes.squareContent, classes.dropzone, isDragActive && classes.dropzoneActive),
          })}
        >
          <input {...getInputProps()} />
          <AddIcon />
          <Typography variant="caption" className={classes.squareCaption}>
            {formatMessage({
              id: isTouchDevice
                ? getAppMessage('common.image_input.dropzone_mobile_text')
                : getAppMessage('common.image_input.dropzone_text'),
            })}
          </Typography>
        </div>
      </div>
      {descriptors.map((descriptor, key) => {
        const gridPos = countGridPosition(key + 2);
        const style = {
          backgroundImage: descriptor.previewUrl ? `url("${descriptor.previewUrl}")` : 'none',
          msGridRow: `${gridPos.row}`,
          msGridColumn: `${gridPos.column}`,
        };
        return (
          <div
            id={`${key}`}
            key={descriptor.id}
            className={clsx(classes.square, classes.image, mainId === descriptor.id && classes.imageMain)}
            style={style as React.CSSProperties}
          >
            <div className={classes.squareContent}>
              {descriptor.type === 'uploaded' && (
                <ButtonBase
                  className={classes.selectButton}
                  aria-label={formatMessage({ id: getAppMessage('common.image_input.select_main') })}
                  onClick={() => handleMainIdChange(descriptor.id)}
                />
              )}
              {mainId === descriptor.id && (
                <Status className={classes.mainInfo} elevation={1} color={theme.palette.success.main}>
                  {formatMessage({ id: getAppMessage('common.image_input.main_text') })}
                </Status>
              )}
              {descriptor.type === 'uploaded' && (
                <Fab
                  className={classes.removeFab}
                  aria-label={formatMessage({ id: getAppMessage('common.image_input.remove') })}
                  onClick={() => handleRemove(descriptor.id)}
                >
                  <ClearIcon />
                </Fab>
              )}
              {descriptor.type === 'pending' && (
                <div className={classes.loadingOverlay}>
                  <CircularProgress color="inherit" />
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

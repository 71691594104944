import React from 'react';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Fab, IconButton, useTheme, useMediaQuery } from '@material-ui/core';
import { useIntl } from 'react-intl';
import $ from 'jquery';
import clsx from 'clsx';

import { getAppMessage } from '../../i18n/messages';

import { useStyles } from './Gallery.styles';
import { ArrowProps, GalleryProps } from './Gallery.types';

import 'slick-carousel';
import 'slick-carousel/slick/slick.css';

const ImagesArrow = ({ left, onClick, disabled }: ArrowProps) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.imagesArrow, left ? classes.imagesArrowPrev : classes.imagesArrowNext)}>
      <Fab color="primary" size="small" onClick={onClick} disabled={disabled}>
        {left ? <NavigateBeforeIcon fontSize="large" /> : <NavigateNextIcon fontSize="large" />}
      </Fab>
    </div>
  );
};

const ThumbnailsArrow = ({ left, onClick, disabled }: ArrowProps) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.thumbnailsArrow, left ? classes.thumbnailsArrowPrev : classes.thumbnailsArrowNext)}>
      <IconButton size="small" disabled={disabled} onClick={onClick}>
        {left ? <NavigateBeforeIcon fontSize="large" /> : <NavigateNextIcon fontSize="large" />}
      </IconButton>
    </div>
  );
};

export const Gallery = ({ images = [] }: GalleryProps) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const imagesRef = React.useRef<HTMLDivElement | null>(null);
  const thumbnailsRef = React.useRef<HTMLDivElement | null>(null);
  const [currentImage, setCurrentImage] = React.useState(0);
  const [currentThumbnail, setCurrentThumbnail] = React.useState(0);
  const [hasHiddenThumbnails, setHasHiddenThumbnails] = React.useState<boolean>(false);
  const [showThumbnailsNav, setShowThumbnailsNav] = React.useState<boolean>(false);
  const isRtl = theme.direction === 'rtl';
  const imagesElements = React.useMemo(
    () =>
      images.map((url, i) => (
        <div key={i} className={classes.itemWrapper}>
          <div className={classes.imagesItem} style={{ backgroundImage: `url("${url}")` }} />
        </div>
      )),
    [classes.imagesItem, classes.itemWrapper, images],
  );
  const thumbnailsElements = React.useMemo(
    () =>
      images.map((url, i) => (
        <div key={i} className={classes.itemWrapper}>
          <img
            alt={formatMessage({ id: getAppMessage('common.gallery.thumbnail') })}
            className={classes.thumbnailsItem}
            src={url}
            onClick={() => imagesRef.current && $(imagesRef.current).slick('slickGoTo', i)}
          />
        </div>
      )),
    [classes.itemWrapper, classes.thumbnailsItem, formatMessage, images],
  );

  React.useEffect(() => {
    if (!imagesRef.current) return;
    const instance = $(imagesRef.current).slick({
      rtl: isRtl,
      arrows: false,
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    });
    instance.on('afterChange', function(event, slick, currentSlide) {
      setCurrentImage(currentSlide);
    });

    return () => {
      instance.slick('unslick');
    };
  }, [isRtl, imagesElements]);

  React.useEffect(() => {
    if (!thumbnailsRef.current) return;
    const instance = $(thumbnailsRef.current).slick({
      rtl: isRtl,
      arrows: false,
      swipe: false,
      dots: false,
      infinite: false,
      speed: 500,
      variableWidth: true,
    });
    instance.on('afterChange', function(event, slick, currentSlide) {
      setCurrentThumbnail(currentSlide);
    });
    instance.on('setPosition', function() {
      if (!thumbnailsRef.current) return;
      const list = thumbnailsRef.current.querySelector('.slick-list');
      if (!list) return;
      const listBox = list.getBoundingClientRect();
      const itemsBoxes = Array.from(list.querySelectorAll('.slick-slide')).map(element =>
        element.getBoundingClientRect(),
      );
      const totalItemsWidth = itemsBoxes.reduce((acc, box) => acc + box.width, 0);
      setShowThumbnailsNav(listBox.width < totalItemsWidth);
      if (isRtl) {
        setHasHiddenThumbnails(listBox.left < itemsBoxes[itemsBoxes.length - 1]?.left ?? 0);
      } else {
        setHasHiddenThumbnails(listBox.right > itemsBoxes[itemsBoxes.length - 1]?.right ?? 0);
      }
    });

    return () => {
      instance.slick('unslick');
    };
  }, [isRtl, thumbnailsElements]);

  React.useEffect(() => {
    !showThumbnailsNav && thumbnailsRef.current && $(thumbnailsRef.current).slick('slickGoTo', 0);
  }, [showThumbnailsNav]);

  const hideThumbnails = !matches || images.length <= 1;

  return (
    <div className={clsx(classes.root, hideThumbnails && classes.rootWithoutThumbnails)}>
      {!!images.length ? (
        <>
          <div className={classes.imagesWrapper}>
            <div dir={theme.direction} ref={imagesRef} className={classes.images}>
              {imagesElements}
            </div>
            {matches && images.length > 1 ? (
              <>
                <ImagesArrow
                  left={!isRtl}
                  onClick={() => imagesRef.current && $(imagesRef.current).slick('slickPrev')}
                  disabled={currentImage <= 0}
                />
                <ImagesArrow
                  left={isRtl}
                  onClick={() => imagesRef.current && $(imagesRef.current).slick('slickNext')}
                  disabled={currentImage + 1 >= images.length}
                />
              </>
            ) : (
              <div className={classes.counter}>
                {formatMessage({ id: 'common.gallery.i_out_of_n' }, { i: currentImage + 1, n: images.length })}
              </div>
            )}
          </div>
          <div className={classes.thumbnailsWrapper} style={{ display: hideThumbnails ? 'none' : undefined }}>
            {showThumbnailsNav && (
              <ThumbnailsArrow
                left={!isRtl}
                disabled={currentThumbnail <= 0}
                onClick={() => thumbnailsRef.current && $(thumbnailsRef.current).slick('slickPrev')}
              />
            )}
            <div className={classes.thumbnails}>
              <div dir={theme.direction} ref={thumbnailsRef}>
                {thumbnailsElements}
              </div>
            </div>
            {showThumbnailsNav && (
              <ThumbnailsArrow
                left={isRtl}
                disabled={hasHiddenThumbnails}
                onClick={() => thumbnailsRef.current && $(thumbnailsRef.current).slick('slickNext')}
              />
            )}
          </div>
        </>
      ) : (
        <div className={classes.placeholder} />
      )}
    </div>
  );
};

import React, { useCallback } from 'react';
import { useMutation } from 'react-fetching-library';
import { Redirect } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { AppRoute } from 'routing/AppRoute.enum';
import * as actions from '../../api/actions/registration/registrationActions';
import { ApiFormError } from '../../hooks/useApiForm/useApiForm';
import { noSmsChecking } from '../../constants';
import { useAuthorize } from '../../hooks/useAuthorize/useAuthorize';
import { useLocale } from 'hooks/useLocale/useLocale';

import { OneTimePassword } from './oneTimePassword/OneTimePassword';
import { SetPassword } from './setPassword/SetPassword';
import { OneTimePasswordFields } from './oneTimePassword/OneTimePassword.types';
import { SetPasswordFormFields } from './setPassword/SetPassword.types';
import { Register } from './Register';
import {
  RegisterVendorFormFields,
  RegisterBuyerFormFields,
  RegistrationSteps,
  ReducerActionType,
} from './Register.types';
import { reducer, reducerInitialState } from './Register.reducer';

export const RegisterContainer = () => {
  const [state, dispatch] = React.useReducer(reducer, reducerInitialState);
  const { mutate: generateBuyerCodeMutate, loading: generatingBuyerCode } = useMutation(actions.generateBuyerCode);
  const { mutate: validateBuyerCodeMutate } = useMutation(actions.validateBuyerCode);
  const { mutate: createBuyerMutate } = useMutation(actions.createBuyer);
  const { mutate: generateVendorCodeMutate, loading: generatingVendorCode } = useMutation(actions.generateVendorCode);
  const { mutate: validateVendorCodeMutate } = useMutation(actions.validateVendorCode);
  const { mutate: createVendorMutate } = useMutation(actions.createVendor);
  const { mutate: createNonSdmVendorMutate } = useMutation(actions.createNonSdmVendor);
  const { isAuthorized, authorize } = useAuthorize();
  const { enqueueSnackbar } = useSnackbar();
  const { formatMsg } = useLocale();

  const onVendorAccountInfoSubmit = useCallback(
    async ({ phone, saudiDatesMark }: RegisterVendorFormFields) => {
      if (state.step !== RegistrationSteps.AccountInfo) return;
      let errors: Record<string, string> | undefined;

      const nonSdm = saudiDatesMark.length === 0;

      const { payload, error } = await generateVendorCodeMutate({
        phone,
        saudiDatesMark: nonSdm ? 'NO-SMS-CHECKING' : saudiDatesMark,
        validation: nonSdm || noSmsChecking ? 'NO-SMS-CHECKING' : undefined,
      });
      if (!error && payload) {
        nonSdm
          ? dispatch({
              accountType: 'vendor',
              type: ReducerActionType.SkipStep2,
              uuid: payload.uuid,
              accountInfo: { phone, saudiDatesMark: nonSdm ? 'NO-SMS-CHECKING' : saudiDatesMark },
            })
          : dispatch({
              accountType: 'vendor',
              type: ReducerActionType.GoToStep2,
              uuid: payload.uuid,
              accountInfo: { phone, saudiDatesMark },
            });
      } else {
        errors = payload;
      }

      return errors;
    },
    [generateVendorCodeMutate, state.step],
  );

  const onBuyerAccountInfoSubmit = useCallback(
    async ({ phone, fullName, nationalId }: RegisterBuyerFormFields) => {
      if (state.step !== RegistrationSteps.AccountInfo) return;
      let errors: Record<string, string> | undefined;

      const { payload, error } = await generateBuyerCodeMutate({
        phone,
        fullName,
        nationalId,
        validation: noSmsChecking ? 'NO-SMS-CHECKING' : undefined,
      });
      if (!error && payload) {
        dispatch({
          accountType: 'buyer',
          type: ReducerActionType.GoToStep2,
          uuid: payload.uuid,
          accountInfo: { fullName, phone, nationalId },
        });
      } else {
        errors = payload;
      }

      return errors;
    },
    [generateBuyerCodeMutate, state.step],
  );

  const onResendSecurityCode = useCallback(async () => {
    let errors: Record<string, string> | undefined;

    if (state.step === RegistrationSteps.OneTimePassword) {
      if (state.accountType === 'buyer') {
        const {
          accountInfo: { fullName, phone, nationalId },
        } = state;

        const { payload, error } = await generateBuyerCodeMutate({
          phone,
          fullName,
          nationalId,
          validation: noSmsChecking ? 'NO-SMS-CHECKING' : undefined,
        });

        if (!error && payload) {
          dispatch({
            accountType: 'buyer',
            type: ReducerActionType.ResendSecurityCode,
            uuid: payload.uuid,
            accountInfo: { fullName, phone, nationalId },
          });
          enqueueSnackbar(formatMsg('login_and_registration.otp_pass.code_resent'), { variant: 'success' });
        } else {
          errors = { error: formatMsg('exception.unknown.primary_text') };
          enqueueSnackbar(
            formatMsg('exception.unknown.secondary_text_part_1', {
              part2: formatMsg('exception.unknown.secondary_text_part_2'),
            }),
            { variant: 'error' },
          );
        }
      }

      if (state.accountType === 'vendor') {
        const {
          accountInfo: { phone, saudiDatesMark },
        } = state;

        const { payload, error } = await generateVendorCodeMutate({
          phone,
          saudiDatesMark,
          validation: noSmsChecking ? 'NO-SMS-CHECKING' : undefined,
        });
        if (!error && payload) {
          dispatch({
            accountType: 'vendor',
            type: ReducerActionType.ResendSecurityCode,
            uuid: payload.uuid,
            accountInfo: { phone, saudiDatesMark },
          });
          enqueueSnackbar(formatMsg('login_and_registration.otp_pass.code_resent'), { variant: 'success' });
        } else {
          errors = { error: formatMsg('exception.unknown.primary_text') };
          enqueueSnackbar(
            formatMsg('exception.unknown.secondary_text_part_1', {
              part2: formatMsg('exception.unknown.secondary_text_part_2'),
            }),
            { variant: 'error' },
          );
        }
      }
    }

    return errors;
  }, [enqueueSnackbar, formatMsg, generateBuyerCodeMutate, generateVendorCodeMutate, state]);

  const onOneTimePasswordSubmit = useCallback(
    async ({ smsPassword }: OneTimePasswordFields) => {
      if (state.step !== RegistrationSteps.OneTimePassword) return;
      let errors: ApiFormError<OneTimePasswordFields> | undefined;

      const action = state.accountType === 'buyer' ? validateBuyerCodeMutate : validateVendorCodeMutate;
      const { payload, error } = await action({ uuid: state.uuid, smsPassword });
      if (!error) {
        if (state.accountType === 'buyer') {
          dispatch({
            accountType: state.accountType,
            type: ReducerActionType.GoToStep3,
            accountInfo: state.accountInfo,
            uuid: state.uuid,
            smsPassword,
          });
        } else {
          dispatch({
            accountType: state.accountType,
            type: ReducerActionType.GoToStep3,
            accountInfo: state.accountInfo,
            uuid: state.uuid,
            smsPassword,
          });
        }
      } else {
        errors = payload as ApiFormError<OneTimePasswordFields> | undefined;
      }

      return errors;
    },
    [state.step, state.accountType, state.uuid, state.accountInfo, validateBuyerCodeMutate, validateVendorCodeMutate],
  );

  const onSetPasswordSubmit = useCallback(
    async ({ plainPassword }: SetPasswordFormFields) => {
      if (state.step !== RegistrationSteps.SetPassword) return;
      let errors: ApiFormError<SetPasswordFormFields> | undefined;

      const { payload, error } =
        state.accountType === 'buyer'
          ? await createBuyerMutate({
              fullName: state.accountInfo.fullName,
              phone: state.accountInfo.phone,
              plainPassword,
              nationalId: state.accountInfo.nationalId,
              smsUuid: state.uuid,
              smsPassword: state.smsPassword,
            })
          : state.accountInfo.saudiDatesMark === 'NO-SMS-CHECKING'
          ? await createNonSdmVendorMutate({
              phone: state.accountInfo.phone,
              plainPassword,
              saudiDatesMark: state.accountInfo.saudiDatesMark,
              smsUuid: state.uuid,
              smsPassword: '666666',
              validation: 'NO-SMS-CHECKING',
            })
          : await createVendorMutate({
              phone: state.accountInfo.phone,
              plainPassword,
              saudiDatesMark: state.accountInfo.saudiDatesMark,
              smsUuid: state.uuid,
              smsPassword: state.smsPassword ? state.smsPassword : '',
              validation: noSmsChecking ? 'NO-SMS-CHECKING' : undefined,
            });
      if (!error) {
        errors = await authorize(state.accountInfo.phone, plainPassword, state.accountType);
      } else {
        errors = payload as ApiFormError<SetPasswordFormFields> | undefined;
      }

      return errors;
    },
    [
      state.step,
      state.accountType,
      state.accountInfo,
      state.uuid,
      state.smsPassword,
      createBuyerMutate,
      createVendorMutate,
      createNonSdmVendorMutate,
      authorize,
    ],
  );

  if (isAuthorized) {
    return <Redirect to={AppRoute.auctionsList} />;
  }

  switch (state.step) {
    case RegistrationSteps.AccountInfo:
      return <Register onVendorSubmit={onVendorAccountInfoSubmit} onBuyerSubmit={onBuyerAccountInfoSubmit} />;
    case RegistrationSteps.OneTimePassword:
      return (
        <OneTimePassword
          onSubmit={onOneTimePasswordSubmit}
          onResend={onResendSecurityCode}
          resendingSecurityCode={generatingBuyerCode || generatingVendorCode}
        />
      );
    case RegistrationSteps.SetPassword:
      return <SetPassword onSubmit={onSetPasswordSubmit} />;
  }
};

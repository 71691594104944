import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';

import { getAppMessage } from '../../i18n/messages';
import { TextWithNumber } from 'ui/textWithNumber/TextWithNumber';
import { Title } from 'ui/title/Title';
import { Navigation } from 'ui/navigation/Navigation';
import { Footer } from 'ui/footer/Footer';
import { externalLinks } from '../../constants';
import { useTitleDispatch } from '../../hooks/useTitleDispatch/useTitleDispatch';
import { useLocale } from '../../hooks/useLocale/useLocale';

import { useFAQStyles } from './FAQ.styles';

export const FAQ = () => {
  const setTitle = useTitleDispatch();
  const { formatMessage, formatMsg } = useLocale();
  const classes = useFAQStyles();
  const saudiDatesMarkLink = {
    link: externalLinks.sdm,
    text: 'www.saudidatesmark.com',
  };

  useEffect(() => {
    setTitle([formatMsg('faq.title')]);
  }, [formatMsg, setTitle]);

  return (
    <>
      <Navigation variant="overlay" />
      <Box className={classes.root}>
        <Title
          text={formatMessage({ id: getAppMessage('faq.title') })}
          component="h1"
          variant="h2"
          className={classes.title}
        />
        <Box className={classes.flex}>
          <Box className={classes.col}>
            <TextWithNumber
              number="01"
              title={formatMessage({ id: getAppMessage('faq.question_1.question') })}
              description={formatMessage({ id: getAppMessage('faq.question_1.answer') })}
              fullWidth
              smallNumber
            />
            <TextWithNumber
              number="02"
              title={formatMessage({ id: getAppMessage('faq.question_2.question') })}
              description={formatMessage(
                { id: getAppMessage('faq.question_2.answer') },
                {
                  saudiDatesMarkLink: (
                    <a href={saudiDatesMarkLink.link} target="_blank" rel="noopener noreferrer">
                      {saudiDatesMarkLink.text}
                    </a>
                  ),
                },
              )}
              fullWidth
              smallNumber
            />
            <TextWithNumber
              number="03"
              title={formatMessage({ id: getAppMessage('faq.question_3.question') })}
              description={formatMessage({ id: getAppMessage('faq.question_3.answer') })}
              fullWidth
              smallNumber
            />
            <TextWithNumber
              number="04"
              title={formatMessage({ id: getAppMessage('faq.question_4.question') })}
              description={formatMessage(
                { id: getAppMessage('faq.question_4.answer') },
                {
                  saudiDatesMarkLink: (
                    <a href={saudiDatesMarkLink.link} target="_blank" rel="noopener noreferrer">
                      {saudiDatesMarkLink.text}
                    </a>
                  ),
                },
              )}
              fullWidth
              smallNumber
            />
            <TextWithNumber
              number="05"
              title={formatMessage({ id: getAppMessage('faq.question_5.question') })}
              description={formatMessage({ id: getAppMessage('faq.question_5.answer') })}
              fullWidth
              smallNumber
            />
            <TextWithNumber
              number="06"
              title={formatMessage({ id: getAppMessage('faq.question_6.question') })}
              description={formatMessage({ id: getAppMessage('faq.question_6.answer') })}
              fullWidth
              smallNumber
            />
            <TextWithNumber
              number="07"
              title={formatMessage({ id: getAppMessage('faq.question_7.question') })}
              description={formatMessage({ id: getAppMessage('faq.question_7.answer') })}
              fullWidth
              smallNumber
            />
          </Box>
          <Box className={classes.col}>
            <TextWithNumber
              number="08"
              title={formatMessage({ id: getAppMessage('faq.question_8.question') })}
              description={formatMessage({ id: getAppMessage('faq.question_8.answer') })}
              fullWidth
              smallNumber
            />
            <TextWithNumber
              number="09"
              title={formatMessage({ id: getAppMessage('faq.question_9.question') })}
              description={formatMessage({ id: getAppMessage('faq.question_9.answer') })}
              fullWidth
              smallNumber
            />
            <TextWithNumber
              number="10"
              title={formatMessage({ id: getAppMessage('faq.question_10.question') })}
              description={formatMessage({ id: getAppMessage('faq.question_10.answer') })}
              fullWidth
              smallNumber
            />
            <TextWithNumber
              number="11"
              title={formatMessage({ id: getAppMessage('faq.question_11.question') })}
              description={formatMessage({ id: getAppMessage('faq.question_11.answer') })}
              fullWidth
              smallNumber
            />
            <TextWithNumber
              number="12"
              title={formatMessage({ id: getAppMessage('faq.question_12.question') })}
              description={formatMessage({ id: getAppMessage('faq.question_12.answer') })}
              fullWidth
              smallNumber
            />
            <TextWithNumber
              number="13"
              title={formatMessage({ id: getAppMessage('faq.question_13.question') })}
              description={formatMessage({ id: getAppMessage('faq.question_13.answer') })}
              fullWidth
              smallNumber
            />
            <TextWithNumber
              number="14"
              title={formatMessage({ id: getAppMessage('faq.question_14.question') })}
              description={formatMessage({ id: getAppMessage('faq.question_14.answer') })}
              fullWidth
              smallNumber
            />
            <TextWithNumber
              number="15"
              title={formatMessage({ id: getAppMessage('faq.question_15.question') })}
              description={formatMessage({ id: getAppMessage('faq.question_15.answer') })}
              fullWidth
              smallNumber
            />
          </Box>
        </Box>
      </Box>
      <Box>
        <Footer />
      </Box>
    </>
  );
};

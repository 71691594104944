/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, ChangeEvent } from 'react';
import { Box, Divider, TextField, Checkbox as MuiCheckbox, CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Controller, useForm } from 'react-hook-form';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { DrawerComponent } from 'ui/drawer/Drawer';
import { Title } from 'ui/title/Title';
import { Input } from 'ui/input/Input';
import { getAppMessage } from 'i18n/messages';
import { SelectComponent } from 'ui/select/Select';
import { Checkbox } from 'ui/checkbox/Checkbox';
import { AuctionsFiltersFormFields } from '../Auctions.types';
import { IconButton } from 'ui/iconButton/IconButton';
import { SelectChangeEvent } from 'ui/select/Select.types';
import { QueryFilterParam } from 'context/queryParams/queryParamsContext/QueryParamsContext.types';
import { Button } from 'ui/button/Button';
import { useCommonData } from 'hooks/useCommonData/useCommonData';
import { useLocale } from 'hooks/useLocale/useLocale';

import { useAuctionsFiltersStyles } from './AuctionsFilters.styles';
import { AuctionsFiltersProps } from './AuctionsFilters.types';

export const AuctionsFilters = ({ value: filter, onChange, open, onClose }: AuctionsFiltersProps) => {
  const { register, setValue, handleSubmit, watch, reset, getValues, errors, control } = useForm<
    AuctionsFiltersFormFields
  >();
  const { formatMessage, locale } = useLocale();
  const classes = useAuctionsFiltersStyles();
  const { dateTypes, units, cities, citiesLoading, citiesError } = useCommonData();

  register({ name: 'unit' });
  register({ name: 'type' });
  register({ name: 'location' });

  useEffect(() => {
    const nextValues: Record<keyof AuctionsFiltersFormFields | string, unknown> = {};
    filter &&
      Object.keys(filter).forEach(key => {
        if (!key.includes('auction')) {
          if (key === 'weight') {
            const weight = filter[key];
            if (weight?.min) {
              nextValues.minWeight = weight.min;
            }
            if (weight?.max) {
              nextValues.maxWeight = weight.max;
            }
          } else if (key === 'price') {
            const price = filter[key];
            if (price?.min) {
              nextValues.minPrice = price.min / 100;
            }
            if (price?.max) {
              nextValues.maxPrice = price.max / 100;
            }
          } else if (key === 'delivery') {
            nextValues.delivery = filter[key] === '1';
          } else if (key === 'pickup') {
            nextValues.pickup = filter[key] === '1';
          } else {
            nextValues[key] = filter[key];
          }
        }
      });
    reset(nextValues);
  }, [filter, reset]);

  const handleUnitChange = (event: SelectChangeEvent) => {
    const value = event.target.value as string;
    setValue('unit', value);
  };

  const handleTypeChange = (value: string[]) => {
    setValue('type', value);
  };

  const handleLocationChange = (value: string[]) => {
    setValue('location', value);
  };

  const handleFormSubmit = ({
    type,
    minWeight,
    maxWeight,
    minPrice,
    maxPrice,
    delivery,
    pickup,
    ...body
  }: AuctionsFiltersFormFields) => {
    let sortingFilter = {};

    if (filter) {
      sortingFilter = Object.keys(filter)
        .filter(key => key.includes('auction'))
        .reduce((obj: QueryFilterParam, key) => {
          obj[key] = filter[key];
          return obj;
        }, {});
    }

    const newFilter: QueryFilterParam = {
      ...sortingFilter,
      ...body,
      type: type || [],
      delivery: delivery ? '1' : undefined,
      pickup: pickup ? '1' : undefined,
      weight: {
        min: minWeight,
        max: maxWeight,
      },
      price: {
        min: minPrice * 100,
        max: maxPrice * 100,
      },
    };

    onChange(newFilter);
    onClose();
  };

  const clearFilters = () => {
    reset({
      delivery: false,
      pickup: false,
    });
    handleFormSubmit(getValues());
  };

  return (
    <DrawerComponent open={open} className={classes.root}>
      <form onSubmit={handleSubmit(body => handleFormSubmit(body))}>
        <Box className={classes.filtersContent}>
          <Box className={classes.header}>
            <Title
              text={formatMessage({ id: getAppMessage('auctions.filters.title') })}
              variant="h5"
              component="h3"
              className={classes.title}
            />
            <IconButton className={classes.closeButton} type="button" onClick={onClose}>
              <CloseIcon color="primary" />
            </IconButton>
          </Box>
          <Divider />
          <Box>
            <Title
              text={formatMessage({ id: getAppMessage('auctions.filters.category.dates') })}
              variant="h6"
              component="h4"
              className={classes.categoryTitle}
            />
            <Autocomplete
              multiple
              id="type"
              options={Object.keys(dateTypes)}
              getOptionLabel={option => (dateTypes[option] ? formatMessage({ id: dateTypes[option] }) : option)}
              disableCloseOnSelect
              value={watch('type') ? watch('type') : []}
              onChange={(e: ChangeEvent<{}>, newValue: string[]) => {
                handleTypeChange(newValue);
              }}
              classes={{
                popper: classes.selectPopper,
              }}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <MuiCheckbox checked={selected} color="primary" />
                  {dateTypes[option] ? formatMessage({ id: dateTypes[option] }) : option}
                </React.Fragment>
              )}
              renderInput={params => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  className={classes.select}
                  variant="outlined"
                  label={formatMessage({ id: getAppMessage('common.labels.type') })}
                />
              )}
            />
            <Box className={classes.smallFilters}>
              <SelectComponent
                id="unit"
                label={formatMessage({ id: getAppMessage('common.labels.unit') })}
                value={watch('unit') ? watch('unit') : ''}
                optionsWithValues
                options={Object.entries(units).map(([value, messageId]) => ({
                  value: value,
                  name: formatMessage({ id: getAppMessage(messageId) }),
                }))}
                handleChange={handleUnitChange}
              />
              <Input
                name="maxWeight"
                type="number"
                label={formatMessage({ id: getAppMessage('common.labels.max_weight') })}
                helperText={errors.maxWeight?.message}
                ref={register({
                  required: false,
                  validate: value => {
                    if (!!value && value < Number(watch('minWeight')) + 1) {
                      return formatMessage({ id: getAppMessage('validation.common.greater_than_min_weight') });
                    } else {
                      return true;
                    }
                  },
                })}
                margin="normal"
              />
              <Input
                name="minWeight"
                type="number"
                label={formatMessage({ id: getAppMessage('common.labels.min_weight') })}
                helperText={errors.minWeight?.message}
                ref={register({
                  required: false,
                  min: {
                    value: 0,
                    message: formatMessage({ id: getAppMessage('validation.common.greater_than_zero') }),
                  },
                })}
                margin="normal"
              />
            </Box>
          </Box>
          <Divider />
          <Box>
            <Title
              text={formatMessage({ id: getAppMessage('auctions.filters.category.price') })}
              variant="h6"
              component="h4"
              className={classes.categoryTitle}
            />
            <Box className={classes.mediumFilters}>
              <Input
                name="maxPrice"
                type="number"
                label={formatMessage({ id: getAppMessage('common.labels.max_price') })}
                helperText={errors.maxPrice?.message}
                ref={register({
                  required: false,
                  validate: value => {
                    if (!!value && value < Number(watch('minPrice')) + 1) {
                      return formatMessage({ id: getAppMessage('validation.common.greater_than_min') });
                    } else {
                      return true;
                    }
                  },
                })}
                margin="normal"
              />
              <Input
                name="minPrice"
                type="number"
                label={formatMessage({ id: getAppMessage('common.labels.min_price') })}
                helperText={errors.minPrice?.message}
                ref={register({
                  required: false,
                  min: {
                    value: 0,
                    message: formatMessage({ id: getAppMessage('validation.common.greater_than_zero') }),
                  },
                })}
                margin="normal"
              />
            </Box>
          </Box>
          <Divider />
          <Box>
            <Title
              text={formatMessage({ id: getAppMessage('auctions.filters.category.location') })}
              variant="h6"
              component="h4"
              className={classes.categoryTitle}
            />
            <Autocomplete
              color="primary"
              multiple
              id="location"
              options={
                cities && !citiesError ? cities.map(({ nameEn, nameAr }) => (locale === 'en' ? nameEn : nameAr)) : []
              }
              disableCloseOnSelect
              value={watch('location') ? watch('location') : []}
              onChange={(e: ChangeEvent<{}>, newValue: string[]) => {
                handleLocationChange(newValue);
              }}
              classes={{
                popper: classes.selectPopper,
              }}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <MuiCheckbox checked={selected} color="primary" />
                  {option}
                </React.Fragment>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  className={classes.select}
                  variant="outlined"
                  label={formatMessage({ id: getAppMessage('common.labels.location') })}
                  {...(citiesLoading
                    ? {
                        InputProps: {
                          endAdornment: <CircularProgress size={24} classes={{ root: classes.inputLoader }} />,
                        },
                      }
                    : {})}
                />
              )}
            />
          </Box>
          <Divider />
          <Box>
            <Title
              text={formatMessage({ id: getAppMessage('auctions.filters.category.delivery_method') })}
              variant="h6"
              component="h4"
              className={classes.categoryTitle}
            />
            <Controller
              name="delivery"
              as={Checkbox}
              control={control}
              label={formatMessage({ id: getAppMessage('common.labels.delivery') })}
              variant="inherit"
              className={classes.checkbox}
              color="primary"
              defaultValue={false}
              valueName="checked"
            />
            <Controller
              name="pickup"
              as={Checkbox}
              control={control}
              label={formatMessage({ id: getAppMessage('common.labels.pickup') })}
              variant="inherit"
              className={classes.checkbox}
              color="primary"
              defaultValue={false}
              valueName="checked"
            />
          </Box>
        </Box>
        <Box className={classes.filterButtons}>
          <Button
            text={formatMessage({ id: getAppMessage('auctions.filters.clear_filters') })}
            variant="outlined"
            type="button"
            onClick={clearFilters}
          />
          <Button text={formatMessage({ id: getAppMessage('auctions.filters.apply_filters') })} type="submit" />
        </Box>
      </form>
    </DrawerComponent>
  );
};

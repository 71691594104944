import { Dispatch } from 'react';

import { QueryAction } from '../queryParamsReducer/queryParamsReducer.types';

export type QueryParamsStateContextValueType = {
  page: string;
  sort?: SortFilterParam;
  auctionStatus?: string;
  filter?: QueryFilterParam;
};

export type QueryFilterParam = {
  type?: string[];
  unit?: string;
  weight?: QueryFilterRange;
  price?: QueryFilterRange;
  location?: string[];
  delivery?: string;
  pickup?: string;
  auction_active?: string;
  auction_closed?: string;
  auction_pending_approval?: string;
  auction_pending_fee?: string;
  auction_pending_payment?: string;
  auction_pending_shipment?: string;
  auction_my_bids?: string;
  auction_product_received?: string;
  auction_received_payment?: string;
  auction_requires_payment?: string;
  auction_confirmed_payment?: string;
  vendor?: string;
  [key: string]: string | string[] | boolean | QueryFilterRange | undefined | object | number;
};

export enum SortingProperty {
  createdAt = 'createdAt',
  bidPrice = 'bidPrice',
}

export enum SortingDirection {
  desc = 'desc',
  asc = 'asc',
}

export type SortFilterParam = {
  [key: string]: string | undefined;
  [SortingProperty.createdAt]?: SortingDirection;
  [SortingProperty.bidPrice]?: SortingDirection;
};

export type QueryFilterRange = {
  min?: number;
  max?: number;
};

export type QueryParamsDispatchContextType = Dispatch<QueryAction>;

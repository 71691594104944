import React from 'react';
import { Grid, Typography, Paper, Box } from '@material-ui/core';
import clsx from 'clsx';

import { auctionStatusColors } from '../../constants';
import { auctionStatusColorDefault } from '../../constants';
import { ReactComponent as DatesIcon } from 'assets/images/placeholder.svg';
import { getAppMessage } from 'i18n/messages';
import { Link } from '../link/Link';
import { AppRoute, urlBuilders } from '../../routing/AppRoute.enum';
import { useCommonData } from 'hooks/useCommonData/useCommonData';
import { formatAmount } from '../../helpers/formatAmount';
import { useLocale } from '../../hooks/useLocale/useLocale';
import { formatWeight } from '../../helpers/formatWeight';
import { useUserData } from '../../hooks/useUserData/useUserData';
import { canSeeAuctionDetails } from '../../helpers/canSeeAuctionDetails';

import { AuctionTileProps } from './AuctionTile.types';
import { useAuctionTileStyles } from './AuctionTile.styles';
import { AuctionTileStatus } from './auctionTileStatus/AuctionTileStatus';

export const AuctionTile = ({
  mainImage,
  name,
  type,
  location,
  delivery,
  pickup,
  weight,
  bidPrice,
  status,
  unit,
  uuid,
  vendorId,
  buyerProfileVersion,
}: AuctionTileProps) => {
  const classes = useAuctionTileStyles();
  const { formatMessage, locale } = useLocale();
  const { dateTypes, units } = useCommonData();
  const auctionUnit = units[unit];
  const auctionType = dateTypes[type];
  const userData = useUserData();

  let paper = (
    <Paper className={clsx(classes.paper, { [classes.peperBuyerVersion]: buyerProfileVersion })}>
      <Box
        className={clsx(classes.imageBox, { [classes.imageBoxBuyerVersion]: buyerProfileVersion })}
        style={{ backgroundImage: mainImage ? `url(${mainImage})` : 'none' }}
      >
        {!mainImage && <DatesIcon className={classes.imagePlaceholder} />}
        {status &&
          (Array.isArray(status) ? (
            status.map(s => (
              <AuctionTileStatus
                key={s}
                status={formatMessage({ id: getAppMessage(s) })}
                color={auctionStatusColors[s] ?? auctionStatusColorDefault}
              />
            ))
          ) : (
            <AuctionTileStatus
              status={
                typeof status === 'number'
                  ? `${status} ${formatMessage({ id: getAppMessage('auction.status.bids') })}`
                  : formatMessage({ id: getAppMessage(status) })
              }
              color={auctionStatusColors[status] ?? auctionStatusColorDefault}
            />
          ))}
      </Box>
      <Box className={clsx(classes.content, { [classes.contentBuyerVersion]: buyerProfileVersion })}>
        <Typography className={classes.name} variant="h3">
          {name}
        </Typography>
        <Typography variant="body1" className={classes.details}>
          {auctionType && formatMessage({ id: getAppMessage(auctionType) })}
          <span className={classes.separator}>•</span>
          {location}
        </Typography>
        <Typography variant="body1" className={classes.details}>
          {delivery &&
            pickup &&
            formatMessage({ id: getAppMessage('common.labels.delivery_methods.delivery_and_pickup') })}
          {delivery && !pickup && formatMessage({ id: getAppMessage('common.labels.delivery_methods.delivery') })}
          {!delivery && pickup && formatMessage({ id: getAppMessage('common.labels.delivery_methods.pickup') })}
        </Typography>
        <Typography variant="body1" className={classes.detailsBold}>
          <span className={classes.inlineBlock}>
            {auctionUnit && formatWeight(weight, locale, formatMessage({ id: auctionUnit }))}
          </span>
          {typeof bidPrice === 'number' && (
            <>
              <span className={clsx(classes.inlineBlock, classes.separator)}>•</span>
              <span className={classes.inlineBlock}>{formatAmount(Number(bidPrice) / 100, locale)}</span>
            </>
          )}
        </Typography>
      </Box>
    </Paper>
  );

  if (canSeeAuctionDetails({ vendorId }, userData))
    paper = (
      <Link href={urlBuilders[AppRoute.auctionDetails](uuid)} color="inherit" variant="inherit" underline="none">
        {paper}
      </Link>
    );

  return (
    <Grid item className={clsx(classes.root, { [classes.rootBuyerVersion]: buyerProfileVersion })}>
      {paper}
    </Grid>
  );
};

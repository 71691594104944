import { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { FormatXMLElementFn, PrimitiveType } from 'intl-messageformat';
import * as React from 'react';

import { LocaleContext } from 'context/locale/localeContext/LocaleContext';
import { MessageKeys } from '../../i18n/messages';

import { UseLocale } from './useLocale.types';

export const useLocale = (): UseLocale => {
  const intl = useIntl();
  const localeContext = useContext(LocaleContext);

  if (localeContext === undefined) {
    throw new Error('LocaleContext is unavailable, make sure you are using LocaleContextController');
  }

  return useMemo<UseLocale>(() => {
    function formatMsg(id: MessageKeys, values?: Record<string, PrimitiveType>): string;
    function formatMsg(
      id: MessageKeys,
      values?: Record<string, PrimitiveType | React.ReactElement | FormatXMLElementFn>,
    ): string | React.ReactNodeArray;
    function formatMsg(
      id: MessageKeys,
      values?: Record<string, PrimitiveType> | Record<string, PrimitiveType | React.ReactElement | FormatXMLElementFn>,
    ): string | (string | React.ReactNodeArray) {
      return intl.formatMessage({ id }, values);
    }

    return {
      ...intl,
      ...localeContext,
      formatMsg,
    };
  }, [intl, localeContext]);
};

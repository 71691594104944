import React from 'react';
import { Paper } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from './Status.styles';
import { StatusProps } from './Status.types';

export const Status = ({ children, color = '#4E8B43', elevation = 0, className }: StatusProps) => {
  const classes = useStyles({ color });

  return (
    <Paper elevation={elevation} className={clsx(classes.root, className)}>
      {children}
    </Paper>
  );
};

import { MenuProps } from '@material-ui/core';

export const getSelectMenuProps = (alignment: 'left' | 'right' = 'left'): Partial<MenuProps> => {
  return {
    PaperProps: {
      square: true,
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: alignment,
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: alignment,
    },
    getContentAnchorEl: null,
  };
};

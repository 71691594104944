import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(4),
    padding: theme.spacing(3),
    transition: 'background-color 0.25s ease-out',
  },
  paperGrey: {
    backgroundColor: theme.extras.palette.grey.paper,
  },
  paperSuccess: {
    backgroundColor: theme.extras.palette.success.paper,
  },
}));

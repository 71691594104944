import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useParameterizedQuery } from 'react-fetching-library';
import { useParams } from 'react-router-dom';
import { Box, Container, Divider, Hidden, Paper, Grid, Typography, useTheme } from '@material-ui/core';
import SortIcon from '@material-ui/icons/Sort';
import clsx from 'clsx';
import TuneIcon from '@material-ui/icons/Tune';

import { getVendor } from '../../api/actions/vendor/vendorActions';
import { LoaderOverlay } from '../../ui/loaderOverlay/LoaderOverlay';
import { Navigation } from '../../ui/navigation/Navigation';
import { Alert } from '../../ui/alert/Alert';
import { useLocale } from '../../hooks/useLocale/useLocale';
import { useCommonData } from '../../hooks/useCommonData/useCommonData';
import { MessageKeys } from '../../i18n/messages';
import { AuctionsList } from '../../ui/auctionsList/AuctionsList';
import { useAuthState } from '../../hooks/useAuthState/useAuthState';
import { getUserRole } from '../../helpers/getUserRole';
import { getAuctionsAction } from '../../api/actions/auctions/auctionsActions';
import { createQueryString } from '../../helpers/createQueryString';
import { SelectComponent } from '../../ui/select/Select';
import { getSortingFilters } from '../../helpers/getSortingFilters';
import { SelectChangeEvent } from '../../ui/select/Select.types';
import { useQueryParamsDispatch } from '../../hooks/useQueryParamsDispatch/useQueryParamsDispatch';
import { useQueryParamsState } from '../../hooks/useQueryParamsState/useQueryParamsState';
import {
  setFiltersParam,
  setOrderParam,
  setPageParam,
} from '../../context/queryParams/queryActionCreators/queryActionCreators';
import { IconButton } from '../../ui/iconButton/IconButton';
import { AuctionsFilters } from '../auctionsList/auctionsFilters/AuctionsFilters';
import { useTitleDispatch } from '../../hooks/useTitleDispatch/useTitleDispatch';

import { useStyles } from './VendorProfile.styles';
import { BasicInfo } from './basicInfo/BasicInfo';

export const VendorProfile = () => {
  const classes = useStyles();
  const dispatch = useQueryParamsDispatch();
  const query = useQueryParamsState();
  const theme = useTheme();
  const { formatMsg } = useLocale();
  const { user } = useAuthState();
  const { uuid: vendorUuid } = useParams<{ uuid: string }>();
  const { dateTypesError, dateTypesLoading, dateTypes } = useCommonData();
  const [filtersVisibility, setFiltersVisibility] = useState<boolean>(false);
  const {
    loading: vendorLoading,
    error: vendorError,
    payload: vendor,
    query: vendorQuery,
    abort: vendorAbort,
  } = useParameterizedQuery(getVendor);
  const {
    query: listQuery,
    loading: listLoading,
    payload: listPayload,
    error: listError,
    abort: listAbort,
  } = useParameterizedQuery(getAuctionsAction);
  const setTitle = useTitleDispatch();

  useEffect(() => {
    setTitle([formatMsg('page_title.vendor_profile'), vendor?.fullName]);
  }, [formatMsg, setTitle, vendor]);

  const loading = dateTypesLoading || vendorLoading;
  const error = dateTypesError || vendorError;

  useLayoutEffect(() => {
    vendorQuery(vendorUuid);

    return () => vendorAbort();
  }, [vendorAbort, vendorQuery, vendorUuid]);

  useLayoutEffect(() => {
    const queryString = `?${createQueryString({
      ...query,
      filter: {
        ...query.filter,
        vendor: vendorUuid,
      },
    })}`;
    listQuery(queryString);

    return () => listAbort();
  }, [listAbort, listQuery, query, vendorUuid]);

  const handlePageChange = useCallback((page: number) => dispatch(setPageParam(String(page))), [dispatch]);

  const handleSortingChange = useCallback(
    (event: SelectChangeEvent) => {
      const [name, value] = (event.target.value as string).split('=');
      dispatch(setOrderParam({ [name]: value }));
    },
    [dispatch],
  );

  const list = (
    <>
      <Grid container alignItems="center" justify="space-between" spacing={3}>
        <Grid item xs={12} md="auto">
          <Typography variant="h5" component="h2">
            <strong>{formatMsg('user_profile.vendor.auctions.title')}</strong>
          </Typography>
        </Grid>
        <Grid item xs={12} md="auto">
          <Grid container wrap="nowrap" alignItems="center" justify="space-between" spacing={4}>
            <Grid item>
              <SelectComponent
                id="sort"
                label=""
                optionsWithValues
                options={getSortingFilters(formatMsg)}
                value={
                  query.sort?.bidPrice
                    ? `bidPrice=${query.sort.bidPrice}`
                    : query.sort?.createdAt
                    ? `createdAt=${query.sort.createdAt}`
                    : ''
                }
                variant="standard"
                icon={<SortIcon />}
                className={clsx(classes.auctionsSelect, classes.auctionsSortingSelect)}
                handleChange={handleSortingChange}
              />
            </Grid>
            <Grid item>
              <IconButton
                color="primary"
                className={classes.filtersButton}
                onClick={() => setFiltersVisibility(!filtersVisibility)}
              >
                <TuneIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box mt={[3, 3, 6]}>
        <AuctionsList
          loading={listLoading}
          error={listError}
          page={Number(query.page)}
          onPageChange={handlePageChange}
          payload={listPayload}
          userRole={user ? getUserRole(user) : null}
        />
      </Box>
      <AuctionsFilters
        value={query.filter}
        onChange={filter => dispatch(setFiltersParam(filter))}
        open={filtersVisibility}
        onClose={() => setFiltersVisibility(false)}
      />
    </>
  );

  return (
    <>
      <Navigation />

      <>
        <Box py={[5, 10]}>
          <Container maxWidth="xl">
            <Box mx={[-2, -3, 0]}>
              {!error && vendor && dateTypes && (
                <BasicInfo vendor={vendor} dateTypes={dateTypes as Record<string, MessageKeys>} />
              )}
              {error && <Alert severity="error">{formatMsg('common.errors.fetching_data_error')}</Alert>}
            </Box>
          </Container>
          <Hidden smDown>
            <Box my={6}>
              <Divider />
            </Box>
          </Hidden>
          <Container maxWidth="xl">
            <Box mt={3}>
              <Hidden smDown>{list}</Hidden>
              <Hidden mdUp>
                <Box mx={[-2, -3, 0]}>
                  <Paper elevation={0}>
                    <Box px={[2, 3]} py={4}>
                      {list}
                    </Box>
                  </Paper>
                </Box>
              </Hidden>
            </Box>
          </Container>
        </Box>
      </>
      <LoaderOverlay open={loading || listLoading} zIndex={theme.zIndex.modal} />
    </>
  );
};

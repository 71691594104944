import React from 'react';
import clsx from 'clsx';

import { trimPhone } from 'helpers/trimPhone';
import { saudiArabiaCountryCode } from '../../constants';

import { WhatsappLinkProps } from './WhatsappLink.types';
import { useStyles } from './WhatsappLink.styles';

export const WhatsappLink = ({ phone, className }: WhatsappLinkProps) => {
  const classes = useStyles();

  const trimmedPhone = trimPhone(phone);
  const waNumber = `${saudiArabiaCountryCode}${trimmedPhone}`;

  return (
    <a
      href={`https://wa.me/${waNumber}`}
      className={clsx(classes.link, className)}
      target="_blank"
      rel="noopener noreferrer"
    >
      wa.me/{waNumber}
    </a>
  );
};

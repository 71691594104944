import 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/ar';
import './polyfills';
import 'assets/styles/main.css';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import relativeTime from 'dayjs/plugin/relativeTime';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { install } from 'ga-gtag';

import { AppProviders } from 'providers/AppProviders';
import { mockServer } from 'api/mocks/mock-server';

import { App } from './app/App';
import * as serviceWorker from './serviceWorker';

dayjs.extend(localeData);
dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);

if (process.env.REACT_APP_GTAG) {
  install(process.env.REACT_APP_GTAG);
}

if (process.env.REACT_APP_API_URL === 'http://mock') {
  mockServer();
}

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);
}

if (process.env.REACT_APP_SENTRY_DSN) {
  const semver = [process.env.REACT_APP_VERSION, process.env.REACT_APP_BUILD_META].filter(Boolean).join('+');
  try {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      release: semver ? `sdm-react@${semver}` : undefined,
      environment: process.env.REACT_APP_SENTRY_ENVIRONMENT ?? undefined,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

ReactDOM.render(
  <AppProviders>
    <App />
  </AppProviders>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import { currencyCode } from '../constants';
import { AppLocale } from '../context/locale/AppLocale.enum';

export const formatAmount = (amount: number, locale: AppLocale, minimumFractionDigits?: number) => {
  const fixedLocale = `${locale}-u-nu-latn`;
  const formattedCurrencyCode = new Intl.NumberFormat(fixedLocale, { style: 'currency', currency: currencyCode })
    .formatToParts(0)
    .find(part => part.type === 'currency')?.value;
  const formattedAmount = new Intl.NumberFormat(fixedLocale, {
    style: 'decimal',
    minimumFractionDigits,
    maximumFractionDigits: 2,
  }).format(Number(amount));

  return formattedCurrencyCode ? `${formattedAmount} ${formattedCurrencyCode}` : formattedAmount;
};

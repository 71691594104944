import React from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';

import { dayJsLocales } from '../../constants';
import { useLocale } from 'hooks/useLocale/useLocale';

import { MuiPickersUtilsProviderWithLocaleProps } from './MuiPickersUtilsProviderWithLocale.types';

export const MuiPickersUtilsProviderWithLocale = ({ children }: MuiPickersUtilsProviderWithLocaleProps) => {
  const { locale } = useLocale();

  return (
    <MuiPickersUtilsProvider utils={DayjsUtils} locale={dayJsLocales[locale]}>
      {children}
    </MuiPickersUtilsProvider>
  );
};

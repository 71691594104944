/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, ChangeEvent, useEffect } from 'react';
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { formRequirements } from '../../../constants';
import { AuctionPartExtendedProps } from '../CreateAuction.types';
import { Input } from 'ui/input/Input';
import { getAppMessage } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { SelectChangeEvent } from 'ui/select/Select.types';
import { useStyles } from '../CreateAuction.styles';
import { useCommonData } from 'hooks/useCommonData/useCommonData';
import { extractSingleTransCode } from 'helpers/extractSingleTransCode';
import { getSelectMenuProps } from '../../../helpers/getSelectMenuProps';

export const BasicInformation = ({ register, errors, setValue }: AuctionPartExtendedProps) => {
  const { formatMessage } = useLocale();
  const classes = useStyles();
  const {
    dateTypes,
    dateTypesLoading,
    dateTypesError,
    dateGrades,
    dateGradesLoading,
    dateGradesError,
    units,
    unitsLoading,
    unitsError,
  } = useCommonData();

  register({ name: 'unit' });
  register({ name: 'type' }, { required: formatMessage({ id: getAppMessage('validation.common.required') }) });
  register({ name: 'quality' }, { required: formatMessage({ id: getAppMessage('validation.common.required') }) });

  const [unitValue, setUnitValue] = useState('');

  const handleUnitChange = (event: SelectChangeEvent) => {
    const value = event.target.value as string;
    setValue('unit', value);
    setUnitValue(value);
  };

  const handleTypeChange = (_: ChangeEvent<{}>, value: string | null) => {
    setValue('type', extractSingleTransCode(value));
  };

  const handleQualityChange = (_: ChangeEvent<{}>, value: string | null) => {
    setValue('quality', extractSingleTransCode(value));
  };

  useEffect(() => {
    const values = Object.entries(units ?? {});
    if (values.length) {
      setUnitValue(values[0][0]);
    }
  }, [units]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <Input
          type="text"
          name="name"
          label={formatMessage({ id: getAppMessage('common.labels.auction_name') })}
          error={Boolean(errors.name)}
          helperText={errors.name?.message}
          InputProps={{
            inputRef: register({
              required: formatMessage({ id: getAppMessage('validation.common.required') }),
              maxLength: {
                value: formRequirements.nameMaxLength,
                message: formatMessage(
                  { id: getAppMessage('validation.common.length_max') },
                  { limit: formRequirements.nameMaxLength },
                ),
              },
            }),
          }}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <Autocomplete
          id="type"
          options={Object.values(dateTypes)}
          getOptionLabel={option => formatMessage({ id: getAppMessage(option) })}
          classes={{
            paper: classes.autocomplete,
            option: classes.selectItem,
          }}
          onChange={handleTypeChange}
          renderOption={option => formatMessage({ id: getAppMessage(option) })}
          renderInput={params => (
            <TextField
              {...params}
              type="text"
              name="type"
              label={formatMessage({ id: getAppMessage('common.labels.type') })}
              error={Boolean(errors.type) || dateTypesError}
              helperText={errors.type?.message}
              fullWidth
              variant="outlined"
              color="secondary"
              InputProps={{
                ...params.InputProps,
                classes: {
                  root: classes.autocomplete,
                  notchedOutline: classes.primarySelectStyles,
                },
                ...(dateTypesLoading
                  ? { endAdornment: <CircularProgress size={24} classes={{ root: classes.inputLoader }} /> }
                  : {}),
              }}
              InputLabelProps={{
                classes: {
                  root: classes.autocomplete,
                },
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <Autocomplete
          id="quality"
          options={Object.values(dateGrades)}
          getOptionLabel={option => formatMessage({ id: getAppMessage(option) })}
          classes={{
            paper: classes.autocomplete,
            option: classes.selectItem,
          }}
          onChange={handleQualityChange}
          renderOption={option => formatMessage({ id: getAppMessage(option) })}
          renderInput={params => (
            <TextField
              {...params}
              type="text"
              name="quality"
              label={formatMessage({ id: getAppMessage('common.labels.grade') })}
              error={Boolean(errors.quality) || dateGradesError}
              helperText={errors.quality?.message}
              fullWidth
              variant="outlined"
              color="secondary"
              InputProps={{
                ...params.InputProps,
                classes: {
                  root: classes.autocomplete,
                  notchedOutline: classes.primarySelectStyles,
                },
                ...(dateGradesLoading
                  ? { endAdornment: <CircularProgress size={24} classes={{ root: classes.inputLoader }} /> }
                  : {}),
              }}
              InputLabelProps={{
                classes: {
                  root: classes.autocomplete,
                },
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={7} lg={4}>
        <Input
          type="number"
          name="weight"
          label={formatMessage({ id: getAppMessage('common.labels.weight') })}
          error={Boolean(errors.weight)}
          helperText={errors.weight?.message}
          InputProps={{
            inputRef: register({
              required: formatMessage({ id: getAppMessage('validation.common.required') }),
              min: {
                value: formRequirements.weightMin,
                message: formatMessage({ id: getAppMessage('validation.common.greater_than_zero') }),
              },
              pattern: {
                value: formRequirements.onlyNumbers,
                message: formatMessage({ id: getAppMessage('validation.common.only_numbers') }),
              },
            }),
          }}
        />
      </Grid>
      <Grid item xs={5} lg={2}>
        <FormControl
          variant="outlined"
          classes={{
            root: classes.formControl,
          }}
        >
          <InputLabel
            classes={{
              root: classes.selectLabel,
            }}
            id="label-unit"
          >
            {formatMessage({ id: getAppMessage('common.labels.unit') })}
          </InputLabel>
          <Select
            labelId="label-unit"
            id="unit"
            value={unitValue}
            onChange={handleUnitChange}
            label={formatMessage({ id: getAppMessage('common.labels.unit') })}
            error={unitsError}
            fullWidth
            className={classes.primarySelectStyles}
            {...(unitsLoading
              ? {
                  IconComponent: () => <CircularProgress size={24} classes={{ root: classes.inputLoader }} />,
                }
              : {})}
            MenuProps={getSelectMenuProps()}
          >
            {units &&
              Object.entries(units).map(([unitSingleCode, unit]) => {
                return (
                  <MenuItem key={unitSingleCode} value={unitSingleCode} className={classes.selectItem}>
                    {formatMessage({ id: getAppMessage(unit) })}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Input
          type="text"
          name="source"
          label={formatMessage({ id: getAppMessage('common.labels.source') })}
          error={Boolean(errors.source)}
          helperText={errors.source?.message}
          InputProps={{
            inputRef: register({ required: formatMessage({ id: getAppMessage('validation.common.required') }) }),
          }}
        />
      </Grid>
      <Grid item xs={12} lg={10}>
        <Input
          type="text"
          name="description"
          label={formatMessage({ id: getAppMessage('common.labels.description') })}
          error={Boolean(errors.description)}
          helperText={errors.description?.message}
          InputProps={{
            inputRef: register({ required: formatMessage({ id: getAppMessage('validation.common.required') }) }),
          }}
        />
      </Grid>
    </Grid>
  );
};

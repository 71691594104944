import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useAuctionTileStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '0',
      width: '100%',
      backgroundColor: 'transparent',
      [theme.breakpoints.up('sm')]: {
        width: '50%',
      },
      [theme.breakpoints.up('md')]: {
        width: '33.3%',
      },
      [theme.breakpoints.up('lg')]: {
        width: '25%',
      },
      [theme.breakpoints.up('xl')]: {
        width: '20%',
      },
    },
    rootBuyerVersion: {
      width: '100%',
      marginBottom: '10px',
      [theme.breakpoints.up('md')]: {
        marginBottom: '16px',
      },
    },
    paper: {
      overflow: 'hidden',
      flexFlow: 'column',
      display: 'flex',
      height: '100%',
      borderRadius: '8px',
      backgroundColor: theme.palette.grey[50],
      boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14);',
    },
    peperBuyerVersion: {
      [theme.breakpoints.up('md')]: {
        flexFlow: theme.direction === 'rtl' ? 'row-reverse' : 'row',
      },
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    imagePlaceholder: {
      width: '60px',
      [theme.breakpoints.up('md')]: {
        width: '70px',
      },
      [theme.breakpoints.up('lg')]: {
        width: '80px',
      },
    },
    imageBox: {
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      width: '100%',
      height: 160,
      backgroundColor: theme.palette.background.default,
      backgroundSize: 'cover',
      backgroundPosition: '50% 50%',
      backgroundRepeat: 'no-repeat',
      '&::before': {
        content: "''",
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'block',
        width: '100%',
        height: '100%',
        background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%);',
      },
      // Position for the second Auction status if presented
      '& p:nth-of-type(2)': {
        top: 42,
      },
    },
    imageBoxBuyerVersion: {
      [theme.breakpoints.up('md')]: {
        width: '40%',
        height: 'auto',
      },
      [theme.breakpoints.up('md')]: {
        width: '30%',
        height: 'auto',
      },
    },
    content: {
      padding: '16px',
    },
    contentBuyerVersion: {
      [theme.breakpoints.up('md')]: {
        width: '60%',
      },
      [theme.breakpoints.up('md')]: {
        width: '70%',
      },
    },
    name: {
      fontSize: '24px',
      fontWeight: 500,
    },
    details: {
      color: 'rgba(0, 0, 0, 0.6)',
    },
    detailsBold: {
      margin: '8px 0 2px',
      fontWeight: 'bold',
    },
    separator: {
      margin: '0 6px',
    },
    inlineBlock: {
      display: 'inline-block',
    },
  }),
);

import React from 'react';
import { Tabs as MuiTabs, Tab as MuiTab } from '@material-ui/core';

import { TabsProps } from './Tabs.types';

export function Tabs<T extends string = string>({
  labels,
  value,
  onChange,
  variant = 'fullWidth',
  className,
}: TabsProps<T>) {
  return (
    <MuiTabs
      value={value}
      onChange={onChange}
      indicatorColor="primary"
      textColor="primary"
      variant={variant}
      className={className}
    >
      {(Object.keys(labels) as T[]).map(value => {
        return <MuiTab value={value} label={labels[value]} key={value} />;
      })}
    </MuiTabs>
  );
}

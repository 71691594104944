import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Box, useTheme, useMediaQuery } from '@material-ui/core';
import 'swiper/swiper.scss';

import { StepsSliderProps } from './StepsSlider.types';
import { useStepsSliderStyles } from './StepsSlider.styles';

export const StepsSlider = ({ slides }: StepsSliderProps) => {
  const classes = useStepsSliderStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  const xl = useMediaQuery(theme.breakpoints.up('xl'));

  return (
    <Box className={classes.root}>
      <Swiper
        dir={theme.direction}
        spaceBetween={xl ? 80 : lg ? 65 : md ? 55 : sm ? 40 : 30}
        slidesPerView={xl ? 4 : lg ? 3.5 : md ? 2.5 : sm ? 1.5 : 1.3}
      >
        {slides.map((slide, key) => (
          <SwiperSlide key={key}>
            <Box>{slide}</Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

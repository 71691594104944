import { makeStyles, createStyles } from '@material-ui/core';

export const useDividerStyles = makeStyles(theme =>
  createStyles({
    text: {
      color: 'rgba(0, 0, 0, 0.6)',
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '24px',
      marginTop: theme.spacing(1.5),
    },
  }),
);

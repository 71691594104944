import React from 'react';
import { Link as MuiLink } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

import { LinkProps } from './Link.types';

export const Link = ({
  children,
  text,
  href,
  underline = 'always',
  variant = 'body1',
  color,
  className,
}: LinkProps) => {
  return (
    <MuiLink
      color={color}
      component={RouterLink}
      to={href}
      underline={underline}
      variant={variant}
      className={className}
    >
      {text || children}
    </MuiLink>
  );
};

/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, ChangeEvent } from 'react';
import { Grid, Paper, Box, CircularProgress } from '@material-ui/core';
import { Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import clsx from 'clsx';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useLocale } from 'hooks/useLocale/useLocale';
import { getAppMessage } from 'i18n/messages';
import { Address, LocationProps } from '../CreateAuction.types';
import { Input } from 'ui/input/Input';
import { useStyles } from '../CreateAuction.styles';

import { useLocationStyles } from './Location.styles';

export const Location = ({
  register,
  errors,
  setValue,
  cities: { cities, citiesLoading, citiesError },
  vendorData,
}: LocationProps) => {
  const { formatMessage, locale } = useLocale();
  const classes = useLocationStyles();
  const createAuctionClasses = useStyles();

  const [locationType, setLocationType] = useState<Address>('main');
  const [mainActive, setMainActive] = useState(true);
  const [city, setCity] = useState('');
  const [mainAddress, setMainAddress] = useState('');

  register({ name: 'location' }, { required: formatMessage({ id: getAppMessage('validation.common.required') }) });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newLocation = event.target.value as Address;
    setLocationType(newLocation);
  };

  const handleCityChange = (e: ChangeEvent<{}>, value: string | null) => {
    if (value) {
      setCity(value);
    }
  };

  useEffect(() => {
    setMainActive(locationType === 'main');
    if (locationType === 'main') {
      setValue('location', 'default');
    } else {
      setValue('location', city);
    }
  }, [locationType, setValue, city]);

  useEffect(() => {
    if (vendorData?.city) {
      setMainAddress(vendorData.city);
    }
  }, [vendorData]);

  return (
    <RadioGroup name="location" value={locationType} onChange={handleChange}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Paper className={clsx(classes.paper, { [classes.paperActive]: mainActive })} elevation={0}>
            <FormControlLabel
              value="main"
              control={<Radio color="primary" />}
              label={
                <span className={clsx(classes.label, { [classes.labelActive]: mainActive })}>
                  {formatMessage({ id: getAppMessage('auction.create_auction.main_address') })}
                </span>
              }
            />
            <Box pt={2} pb={1}>
              <p className={clsx(classes.text, { [classes.textActive]: mainActive })}>{mainAddress}</p>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper className={clsx(classes.paper, { [classes.paperActive]: !mainActive })} elevation={0}>
            <FormControlLabel
              value="different"
              control={<Radio color="primary" />}
              label={
                <span className={clsx(classes.label, { [classes.labelActive]: !mainActive })}>
                  {formatMessage({ id: getAppMessage('auction.create_auction.different_location') })}
                </span>
              }
            />
            <Box pt={2} pb={1}>
              <Autocomplete
                options={
                  cities && !citiesError ? cities.map(({ nameEn, nameAr }) => (locale === 'en' ? nameEn : nameAr)) : []
                }
                classes={{
                  paper: classes.autocomplete,
                  option: classes.selectItem,
                }}
                onChange={handleCityChange}
                renderInput={params => (
                  <Input
                    {...params}
                    label={formatMessage({ id: getAppMessage('common.labels.city') })}
                    className={clsx({ [classes.inputInactive]: mainActive })}
                    onClick={() => setLocationType('different')}
                    value={city}
                    error={Boolean(errors.location) || citiesError}
                    helperText={errors.location?.message}
                    {...(citiesLoading
                      ? {
                          InputProps: {
                            endAdornment: (
                              <CircularProgress size={24} classes={{ root: createAuctionClasses.inputLoader }} />
                            ),
                          },
                        }
                      : {})}
                  />
                )}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </RadioGroup>
  );
};

import React from 'react';
import { IconButton as MuiIconButton } from '@material-ui/core';

import { IconButtonProps } from './IconButton.types';

export const IconButton = ({
  children,
  onClick,
  color = 'default',
  className = '',
  disabled = false,
  type = 'button',
}: IconButtonProps) => {
  return (
    <MuiIconButton type={type} className={className} onClick={onClick} color={color} disabled={disabled}>
      {children}
    </MuiIconButton>
  );
};

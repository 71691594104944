/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useIntl } from 'react-intl';
import { IconButton, InputAdornment } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { getAppMessage } from '../../../i18n/messages';
import { Input } from '../../input/Input';

import { PasswordProps } from './Password.types';

export const Password = React.forwardRef<HTMLInputElement, PasswordProps>(({ name, label, error, margin }, ref) => {
  const { formatMessage } = useIntl();
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Input
      type={showPassword ? 'text' : 'password'}
      ref={ref}
      name={name}
      label={label || formatMessage({ id: getAppMessage('common.labels.password') })}
      error={!!error}
      helperText={error}
      margin={margin}
      InputProps={{
        inputRef: ref,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
});

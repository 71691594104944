import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useButtonStyles = makeStyles((theme: Theme) =>
  createStyles({
    primaryButton: {
      borderRadius: 200,
      borderWidth: '2px',
      borderColor: theme.palette.primary.main,
      padding: '15px 16px',
      fontSize: '14px',
      fontWeight: 'bold',
      lineHeight: '16px',
      letterSpacing: '0.75px',
      '&:hover': {
        borderWidth: '2px',
      },
      '& [class*="label"]': {
        flexFlow: theme.direction === 'rtl' ? 'row-reverse' : 'row',
        justifyContent: 'center',
        display: 'inline-flex',
      },
      '&.MuiButton-sizeSmall': {
        padding: '10px 16px',
      },
    },
    buttonIcon: {
      marginTop: -12,
      marginBottom: -12,
      marginLeft: theme.direction === 'rtl' ? '10px' : 0,
      marginRight: theme.direction === 'ltr' ? '10px' : 0,
      width: '24px',
      height: '24px',
      '& svg': {
        width: 'auto',
        height: '24px',
      },
    },
  }),
);

import { ValidationOptions } from 'react-hook-form';
import { IntlFormatters } from 'react-intl';
import { electronicFormatIBAN, isValidIBAN } from 'ibantools';

import { getAppMessage } from '../i18n/messages';

export function getIbanValidationOptions({
  formatMessage,
}: {
  formatMessage: IntlFormatters['formatMessage'];
}): ValidationOptions {
  return {
    required: formatMessage({ id: getAppMessage('validation.common.required') }),
    validate: {
      format: (value: string) => {
        return isValidIBAN(electronicFormatIBAN(value)) || formatMessage({ id: getAppMessage('validation.bank.iban') });
      },
    },
  };
}

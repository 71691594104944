import React from 'react';
import clsx from 'clsx';
import { Box, useTheme } from '@material-ui/core';

import { useLocale } from 'hooks/useLocale/useLocale';
import { getAppMessage } from 'i18n/messages';
import { useStyles } from '../AuctionFeatures.styles';
import { Title } from 'ui/title/Title';
import { sdmPhoneNumber } from '../../../../constants';

export const GetToKnow = () => {
  const classes = useStyles();
  const { formatMessage } = useLocale();
  const theme = useTheme();

  const isRtl = theme.direction === 'rtl';

  return (
    <Box>
      <Title
        text={formatMessage({ id: getAppMessage('footer.get_to_know.title') })}
        component="h3"
        variant="h5"
        color="primary"
        className={classes.title}
      />
      <p className={classes.description}>{formatMessage({ id: getAppMessage('footer.get_to_know.description') })}</p>
      <div className={classes.connect}>
        <Title
          text={formatMessage({ id: getAppMessage('footer.connect') })}
          variant="h6"
          component="h4"
          className={clsx(classes.connectText, classes.subtitle)}
        />
        <a className={clsx(classes.connectText, classes.phone)} href={`tel:+${sdmPhoneNumber}`}>
          {!isRtl ? '+' : ''}
          {sdmPhoneNumber}
          {isRtl ? '+' : ''}
        </a>
      </div>
    </Box>
  );
};

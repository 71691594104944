import React from 'react';
import { Grid, Box } from '@material-ui/core';

import { CreateAuctionLayoutProps } from './CreateAuctionLayout.types';
import { useStyles } from './CreateAuctionLayout.styles';

export const CreateAuctionLayout = React.forwardRef<HTMLElement, CreateAuctionLayoutProps>(({ children }, ref) => {
  const classes = useStyles();

  return (
    <Grid container item xs={12} md={6} className={classes.side} innerRef={ref} tabIndex={-1}>
      <Box className={classes.content} p={[2, 3]}>
        {children}
      </Box>
    </Grid>
  );
});

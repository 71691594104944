import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  topText: {
    fontSize: theme.extras.typography.body0.fontSize,
    color: theme.palette.text.secondary,
  },
  inlineBlock: {
    display: 'inline-block',
  },
  separator: {
    margin: `0 ${theme.spacing(1)}px`,
  },
  name: {
    marginRight: theme.spacing(3),
  },
  type: {
    verticalAlign: 'middle',
  },
}));

import { SelectOption } from '../ui/select/Select.types';
import { UseLocaleExtras } from '../hooks/useLocale/useLocale.types';
import { SortingDirection, SortingProperty } from '../context/queryParams/queryParamsContext/QueryParamsContext.types';

export const getSortingFilters = (formatMsg: UseLocaleExtras['formatMsg']): SelectOption[] => {
  return [
    {
      value: `${SortingProperty.createdAt}=${SortingDirection.desc}`,
      name: formatMsg('common.filters.date.newest_first'),
    },
    {
      value: `${SortingProperty.createdAt}=${SortingDirection.asc}`,
      name: formatMsg('common.filters.date.oldest_first'),
    },
    {
      value: `${SortingProperty.bidPrice}=${SortingDirection.asc}`,
      name: formatMsg('common.filters.price.low_to_high'),
    },
    {
      value: `${SortingProperty.bidPrice}=${SortingDirection.desc}`,
      name: formatMsg('common.filters.price.high_to_low'),
    },
  ];
};

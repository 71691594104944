import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from 'react-fetching-library';
import { Grid, Box } from '@material-ui/core';
import clsx from 'clsx';

import { getBuyerProfileAction, updateBuyerProfileAction } from 'api/actions/buyers/buyerActions';
import { BuyerBody } from 'api/actions/buyers/buyerActions.types';
import { useUserData } from 'hooks/useUserData/useUserData';

import { ShoppingDetails } from './shoppingDetails/ShoppingDetails';
import { BuyerProfileDetails } from './BuyerProfileDetails';
import { BuyerProfileDetailsFormFields, BuyerProfileDetailsContainerProps } from './BuyerProfileDetails.types';
import { useStyles } from './BuyerProfileDetails.styles';

export const BuyerProfileDetailsContainer = ({ uuid }: BuyerProfileDetailsContainerProps) => {
  const { mutate } = useMutation(updateBuyerProfileAction);
  const { error, loading, payload, query } = useQuery(getBuyerProfileAction(uuid));
  const classes = useStyles();
  const userData = useUserData();

  const [edit, setEdit] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!userData?.buyerUuid) {
      return;
    }
    setIsOwner(userData.buyerUuid === uuid);
  }, [userData, uuid]);

  useEffect(() => {
    // if backend return phone number, it means that extra fields should be visible
    if (Boolean(payload?.phone.length)) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [payload]);

  const handleSubmit = async (body: BuyerProfileDetailsFormFields) => {
    const buyerBody: BuyerBody = {
      cityId: body.cityId,
      fullName: body.fullName,
    };

    const { error, status, payload } = await mutate({ uuid: uuid, body: buyerBody });

    if (!error && status === 200) {
      query();
      setEdit(false);
      return;
    }
    if (error && payload) {
      return payload;
    }

    return { error: 'exception.unknown.primary_text' };
  };

  const toggleEdit = () => {
    setEdit(prevEdit => !prevEdit);
  };

  return (
    <Box className={clsx(classes.box, { [classes.boxBefore]: !isOwner })}>
      <Grid container spacing={3} className={classes.root}>
        {isOwner && (
          <Grid item xs={12} lg={6} className={classes.contentBox}>
            <ShoppingDetails />
          </Grid>
        )}
        <Grid item xs={12} lg={6} className={classes.contentBox}>
          <BuyerProfileDetails
            onSubmit={handleSubmit}
            toggleEdit={toggleEdit}
            edit={edit}
            editable={isOwner}
            visible={isVisible}
            loading={loading}
            error={error}
            payload={payload}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

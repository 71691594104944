import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: 0,
    listStylePosition: 'inside',
  },
  normal: {},
  inline: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    '& $item': {
      marginRight: theme.spacing(4),
    },
  },
  item: {
    marginBottom: theme.spacing(0.5),
  },
  itemContent: {
    marginLeft: theme.spacing(-1),
  },
}));

import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useClient } from 'react-fetching-library';
import { useSnackbar } from 'notistack';

import { exchangeShortLinkHash } from '../../api/actions/shortLink/shortLinkActions';
import { AppRoute, urlBuilders } from '../../routing/AppRoute.enum';
import { useLocale } from '../../hooks/useLocale/useLocale';

export const ShortLink = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { hash } = useParams();
  const { formatMsg } = useLocale();
  const [route, setRoute] = useState<string | undefined>();
  const client = useClient();

  useEffect(() => {
    const cancellation = { cancel: false };

    const f = async () => {
      const res = await client.query(exchangeShortLinkHash(hash));

      if (cancellation.cancel) return;

      if (!res.error && res.payload) {
        let auctionId: string | null;

        try {
          const values = typeof res.payload.values === 'string' ? JSON.parse(res.payload.values) : res.payload.values;
          auctionId = typeof values === 'object' && typeof values.auctionId === 'string' ? values.auctionId : null;
        } catch (e) {
          auctionId = null;
        }

        if (auctionId) {
          setRoute(urlBuilders[AppRoute.auctionDetails](auctionId));
          return;
        }
      }

      enqueueSnackbar(formatMsg('exception.short_link.invalid'), { variant: 'error' });
      setRoute(AppRoute.home);
    };

    f();

    return () => {
      cancellation.cancel = true;
    };
  }, [client, enqueueSnackbar, formatMsg, hash]);

  if (route) {
    return <Redirect to={route} />;
  }

  return null;
};

/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { useMutation, useClient } from 'react-fetching-library';

import { updateBuyerProfileAction, getBuyerProfileAction } from 'api/actions/buyers/buyerActions';
import { BuyerBody, BuyerResponse } from 'api/actions/buyers/buyerActions.types';
import { BuyerProfileDetailsFormFields, UserData } from '../BuyerProfileDetails.types';
import { BuyerField } from '../BuyerProfileDetails.enum';
import { useUserData } from 'hooks/useUserData/useUserData';
import { getNameFromCityId } from 'helpers/extractCity';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useCommonData } from 'hooks/useCommonData/useCommonData';

import { CompleteProfileData } from './CompleteProfileData';

export const CompleteProfileDataContainer = () => {
  const { mutate } = useMutation(updateBuyerProfileAction);
  const currentUser = useUserData();
  const { locale } = useLocale();
  const [uuid, setUuid] = useState('');
  const [payload, setPayload] = useState<BuyerResponse | undefined>(undefined);
  const [userHasCity, setUserHasCity] = useState(true);
  const { cities } = useCommonData();
  const { query } = useClient();

  useEffect(() => {
    if (currentUser?.buyerUuid) {
      setUuid(currentUser?.buyerUuid);
    }
  }, [uuid, currentUser]);

  useEffect(() => {
    if (!uuid) {
      return;
    }
    query(getBuyerProfileAction(uuid)).then(data => {
      if (!data.error && data.status === 200) {
        setPayload(data.payload);
      }
    });
  }, [uuid, query]);

  useEffect(() => {
    if (!payload) {
      return;
    }
    setUserHasCity(Boolean(payload.cityId));
  }, [payload]);

  const handleSubmit = async (body: BuyerProfileDetailsFormFields) => {
    const buyerBody: BuyerBody = {
      cityId: body.cityId,
      fullName: body.fullName,
    };

    const { error, status, payload } = await mutate({ uuid: uuid, body: buyerBody });

    if (!error && status === 200) {
      setUserHasCity(true);
      return;
    }
    if (error && payload) {
      return payload;
    }

    return { error: 'exception.unknown.primary_text' };
  };

  if (!uuid || !payload || !cities.length || userHasCity) {
    return null;
  }

  const userData: UserData = {
    [BuyerField.nationalId]: payload.nationalId,
    [BuyerField.fullName]: payload.fullName,
    [BuyerField.phone]: payload.phone,
    [BuyerField.city]: getNameFromCityId(payload.cityId, cities, locale),
    [BuyerField.cityId]: payload.cityId ? payload.cityId : 0,
  };

  return <CompleteProfileData onSubmit={handleSubmit} open={!userHasCity} userData={userData} cities={cities} />;
};

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  tabs: {
    marginLeft: -theme.spacing(3),
    marginRight: -theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  help: {
    textAlign: 'center',
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

export const useLoginStepStyles = makeStyles(theme => {
  return {
    description: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
    },
    button: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(1.5),
    },
    textButton: {
      verticalAlign: 'baseline',
    },
    help: {
      textAlign: 'center',
    },
  };
});

import React, { useCallback, useLayoutEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { Client, QueryResponse, useClient } from 'react-fetching-library';
import { Box, Container, Divider } from '@material-ui/core';

import {
  acceptAuction,
  closeAuction,
  confirmDelivery,
  confirmFee,
  confirmPayment,
  getAuction,
  rejectAuction,
  rejectFeeConfirmation,
  rejectPaymentConfirmation,
  removeFeeConfirmation,
  removePaymentConfirmation,
  uploadAuctionBuyerPayment,
  uploadAuctionPaymentFee,
  uploadFeeConfirmation,
  uploadPaymentConfirmation,
} from '../../api/actions/auction/auctionActions';
import { AppRoute } from '../../routing/AppRoute.enum';
import { useAuthState } from '../../hooks/useAuthState/useAuthState';
import { UserRole } from '../../constants';
import {
  acceptBid,
  editBid,
  getBidsList,
  getCurrentUserBidInfo,
  makeBid,
  removeBid,
} from '../../api/actions/bids/bidsActions';
import { AuctionPaymentFlow, AuctionResponse, AuctionStatus } from '../../api/actions/auction/auctionActions.types';
import { LoaderOverlay } from '../../ui/loaderOverlay/LoaderOverlay';
import { BidInfoResponse, BidsResponse } from '../../api/actions/bids/bidsActions.types';
import { getVendor } from '../../api/actions/vendor/vendorActions';
import { User } from '../../context/auth/auth.types';
import { Navigation } from '../../ui/navigation/Navigation';
import { useUserData } from '../../hooks/useUserData/useUserData';
import { canSeeAuctionDetails } from '../../helpers/canSeeAuctionDetails';
import { getUserRole } from '../../helpers/getUserRole';
import { Dictionaries } from '../../api/actions/dictionaries/dictionariesActions.types';
import { getDictionaries } from '../../api/actions/dictionaries/dictionariesActions';

import { AuctionDetails } from './AuctionDetails';
import { AuctionAction, AuctionDetailsAllData, AuctionDetailsUserData } from './AuctionDetails.types';
import { AdminTopBar } from './adminTopBar/AdminTopBar';

const getAllData = async (client: Client, auctionUuid: string, user?: User): Promise<AuctionDetailsAllData | null> => {
  if (!user) return null;

  const [auctionRes, bidInfoRes, bidsListRes, dictionariesRes] = await Promise.all<
    QueryResponse<AuctionResponse>,
    QueryResponse<BidInfoResponse> | null,
    QueryResponse<BidsResponse>,
    QueryResponse<Dictionaries>
  >([
    client.query(getAuction(auctionUuid)),
    user.roles.includes(UserRole.ROLE_BUYER) ? client.query(getCurrentUserBidInfo(auctionUuid)) : Promise.resolve(null),
    client.query(getBidsList({ auctionUuid, page: 1 })),
    client.query(getDictionaries()),
  ]);

  if (auctionRes.error || !auctionRes.payload) return null;
  if (dictionariesRes.error || !dictionariesRes.payload) return null;
  const auction = auctionRes.payload;

  const bidInfo = bidInfoRes && !(bidInfoRes.error || !bidInfoRes.payload) ? bidInfoRes.payload : null;
  const maxBidAmount =
    (!bidsListRes.error ? bidsListRes.payload?.data?.[0]?.value : null) ?? bidInfo?.maxBidPrice ?? null;

  const [vendorRes] = await Promise.all([client.query(getVendor(auction.vendorId))]);

  const vendor = !(vendorRes.error || !vendorRes.payload) ? vendorRes.payload : null;

  let userData: AuctionDetailsUserData | null;
  switch (getUserRole(user)) {
    case UserRole.ROLE_ADMIN:
      userData = {
        type: UserRole.ROLE_ADMIN,
      };
      break;
    case UserRole.ROLE_BUYER:
      userData = {
        type: UserRole.ROLE_BUYER,
        vendor,
        bidInfo,
        banks: dictionariesRes.payload.banks,
        minBidAmount: maxBidAmount !== null ? maxBidAmount + 100 : auction.priceMin,
      };
      break;
    case UserRole.ROLE_VENDOR:
      userData = {
        type: UserRole.ROLE_VENDOR,
      };
      break;
    default:
      userData = null;
  }

  if (!userData) return null;

  return {
    auction,
    userData,
    dateTypes: dictionariesRes.payload.dateTypes,
    dateGrades: dictionariesRes.payload.dateGrades,
    units: dictionariesRes.payload.units,
  };
};

export const AuctionDetailsContainer = () => {
  const client = useClient();
  const { user } = useAuthState();
  const { uuid } = useParams<{ uuid: string }>();
  const [isBusy, setIsBusy] = useState(false);
  const [data, setData] = useState<AuctionDetailsAllData | null | undefined>();
  const [refreshKey, setRefreshKey] = useState(Date.now());
  const [bidsListRefreshKey, setBidsListRefreshKey] = useState(Date.now());
  const userData = useUserData();

  useLayoutEffect(() => {
    const cancellation = { cancel: false };

    setIsBusy(true);
    getAllData(client, uuid, user)
      .then(data => {
        if (cancellation.cancel) return;
        setData(data);
      })
      .finally(() => {
        if (cancellation.cancel) return;
        setIsBusy(false);
      });

    return () => {
      setIsBusy(false);
      cancellation.cancel = true;
    };
  }, [refreshKey, client, uuid, user]);

  const handleAuctionAction = useCallback<(action: AuctionAction) => Promise<QueryResponse | undefined>>(
    async (action: AuctionAction) => {
      if (!data?.auction || isBusy) return;
      setIsBusy(true);
      const { auction } = data;
      let promise: Promise<QueryResponse>;
      switch (action.type) {
        case 'accept':
          promise = client.query(acceptAuction(auction.uuid));
          break;
        case 'close':
          promise = client.query(closeAuction(auction.uuid));
          break;
        case 'reject':
          promise = client.query(rejectAuction(auction.uuid, action.data.reason));
          break;
        case 'confirm-fee':
          promise = client.query(confirmFee(auction.uuid));
          break;
        case 'confirm-payment':
          promise = client.query(confirmPayment(auction.uuid));
          break;
        case 'confirm-delivery':
          promise = client.query(confirmDelivery(auction.uuid));
          break;
        case 'make-bid': {
          promise = client.query(makeBid({ auctionId: auction.uuid, value: action.value }));
          const res = await promise;
          if (res.error) {
            // refresh data to update min bid amount info
            setRefreshKey(Date.now());
            setBidsListRefreshKey(Date.now());
          }
          break;
        }
        case 'edit-bid': {
          promise = client.query(editBid({ bidUuid: action.bidUuid, value: action.value }));
          const res = await promise;
          if (res.error) {
            // refresh data to update min bid amount info
            setRefreshKey(Date.now());
            setBidsListRefreshKey(Date.now());
          }
          break;
        }
        case 'leave-bidding':
          promise = client.query(removeBid(action.bidUuid));
          break;
        case 'accept-bid':
          promise = client.query(acceptBid(action.bidUuid));
          break;
        case 'payment-confirmation-upload': {
          const fileRes = await client.query(uploadAuctionBuyerPayment(action.file));
          const { error: uploadError, payload: uploadPayload } = fileRes;
          if (uploadError || !uploadPayload) promise = Promise.resolve(fileRes);
          else
            promise = client.query(
              uploadPaymentConfirmation({ auctionUuid: auction.uuid, fileUuid: uploadPayload.uuid }),
            );
          break;
        }
        case 'fee-confirmation-upload': {
          const fileRes = await client.query(uploadAuctionPaymentFee(action.file));
          const { error: uploadError, payload: uploadPayload } = fileRes;
          if (uploadError || !uploadPayload) promise = Promise.resolve(fileRes);
          else
            promise = client.query(uploadFeeConfirmation({ auctionUuid: auction.uuid, fileUuid: uploadPayload.uuid }));
          break;
        }
        case 'payment-confirmation-remove':
          promise = client.query(removePaymentConfirmation({ auctionUuid: auction.uuid }));
          break;
        case 'fee-confirmation-remove':
          promise = client.query(removeFeeConfirmation({ auctionUuid: auction.uuid }));
          break;
        case 'payment-confirmation-reject':
          promise = client.query(rejectPaymentConfirmation({ auctionUuid: auction.uuid }));
          break;
        case 'fee-confirmation-reject':
          promise = client.query(rejectFeeConfirmation({ auctionUuid: auction.uuid }));
          break;
      }
      const res = await promise;
      if (!res.error) {
        setRefreshKey(Date.now());
        setBidsListRefreshKey(Date.now());
      } else {
        setIsBusy(false);
      }
      return res;
    },
    [client, data, isBusy],
  );

  if (data === null || canSeeAuctionDetails(data?.auction, userData) === false) {
    return <Redirect to={AppRoute.auctionsList} />;
  }

  return (
    <>
      <Navigation />
      {data && userData && (
        <Container maxWidth="xl" style={{ overflowX: 'hidden' }}>
          <Box display="flex" flexDirection="column" mx={[-2, 0]}>
            {data.userData.type === UserRole.ROLE_ADMIN && data.auction.status === AuctionStatus.new && (
              <Box mb={[-2, -7]}>
                <Box mx={[2, 0]}>
                  <AdminTopBar
                    disabled={isBusy}
                    onAccept={() => handleAuctionAction({ type: 'accept' })}
                    onReject={async data => {
                      const res = await handleAuctionAction({ type: 'reject', data });
                      if (!res || res.error) return {}; // this will prevent dialog from closing, real error will be handled by interceptor
                    }}
                  />
                </Box>
                <Box mx={-3}>
                  <Divider />
                </Box>
              </Box>
            )}
            <Box py={[5, 10]}>
              <AuctionDetails
                bidsListRefreshKey={bidsListRefreshKey}
                disabled={isBusy}
                auction={data.auction}
                userData={data.userData}
                dateTypes={data.dateTypes}
                dateGrades={data.dateGrades}
                units={data.units}
                onCloseAuction={
                  [UserRole.ROLE_VENDOR, UserRole.ROLE_ADMIN].includes(data.userData.type) &&
                  [AuctionStatus.accepted].includes(data.auction.status)
                    ? () => handleAuctionAction({ type: 'close' })
                    : undefined
                }
                onLeaveBidding={bidUuid => handleAuctionAction({ type: 'leave-bidding', bidUuid })}
                onBid={
                  [UserRole.ROLE_BUYER].includes(data.userData.type) &&
                  [AuctionStatus.accepted].includes(data.auction.status)
                    ? (value, bidUuid) => {
                        if (bidUuid) return handleAuctionAction({ type: 'edit-bid', bidUuid, value });
                        return handleAuctionAction({ type: 'make-bid', value });
                      }
                    : undefined
                }
                onAcceptBid={
                  data.userData.type === UserRole.ROLE_VENDOR && data.auction.paymentFlow === AuctionPaymentFlow.none
                    ? bidUuid => handleAuctionAction({ type: 'accept-bid', bidUuid })
                    : undefined
                }
                onConfirmDelivery={() => handleAuctionAction({ type: 'confirm-delivery' })}
                onPaymentConfirmationUpload={async file => {
                  await handleAuctionAction({ type: 'payment-confirmation-upload', file });
                  return undefined;
                }}
                onFeeConfirmationUpload={async file => {
                  await handleAuctionAction({ type: 'fee-confirmation-upload', file });
                  return undefined;
                }}
                onFeeConfirm={() => handleAuctionAction({ type: 'confirm-fee' })}
                onPaymentConfirm={() => handleAuctionAction({ type: 'confirm-payment' })}
                onPaymentRemove={() => handleAuctionAction({ type: 'payment-confirmation-remove' })}
                onFeeRemove={() => handleAuctionAction({ type: 'fee-confirmation-remove' })}
                onPaymentReject={() => handleAuctionAction({ type: 'payment-confirmation-reject' })}
                onFeeReject={() => handleAuctionAction({ type: 'fee-confirmation-reject' })}
              />
            </Box>
          </Box>
        </Container>
      )}
      <LoaderOverlay open={!data || !userData} />
    </>
  );
};

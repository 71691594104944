import React, { useState } from 'react';
import { useQuery } from 'react-fetching-library';
import SortIcon from '@material-ui/icons/Sort';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import clsx from 'clsx';

import { SelectComponent } from 'ui/select/Select';
import { getAuctionsFiltersAction } from 'api/actions/auctions/auctionsActions';
import { useQueryParamsDispatch } from 'hooks/useQueryParamsDispatch/useQueryParamsDispatch';
import { useQueryParamsState } from 'hooks/useQueryParamsState/useQueryParamsState';
import { setFiltersParam, setOrderParam } from 'context/queryParams/queryActionCreators/queryActionCreators';
import { getAppMessage } from 'i18n/messages';
import { SelectChangeEvent, SelectOption } from 'ui/select/Select.types';
import {
  SortFilterParam,
  SortingDirection,
  SortingProperty,
} from 'context/queryParams/queryParamsContext/QueryParamsContext.types';
import { useAuctionsListStyles } from '../AuctionsList.styles';
import { StatusName } from 'api/actions/auctions/auctionsActions.types';
import { getSortingFilters } from '../../../helpers/getSortingFilters';
import { useLocale } from '../../../hooks/useLocale/useLocale';

export const AuctionsSortingFilters = () => {
  const dispatch = useQueryParamsDispatch();
  const classes = useAuctionsListStyles();
  const { formatMessage, formatMsg } = useLocale();
  const { sort, filter } = useQueryParamsState();

  const { payload, error } = useQuery(getAuctionsFiltersAction());

  const statusFilters: SelectOption[] = [
    { value: '', name: formatMessage({ id: getAppMessage('auctions.filters.auction_types.all') }) },
  ];
  const sortingFilters = getSortingFilters(formatMsg);

  const [sortValue, setSortValue] = useState(`${SortingProperty.createdAt}=${SortingDirection.asc}`);
  const [statusValue, setStatusValue] = useState('');

  const setInitialValues = () => {
    if (sort) {
      const sortBy = `${Object.keys(sort)[0]}=${Object.values(sort)[0]}`;
      sortValue !== sortBy && setSortValue(sortBy);
    }

    if (filter) {
      let status = '';
      Object.keys(filter).forEach(key => {
        if (key.includes('auction')) {
          status = key;
        }
      });
      statusValue !== status && setStatusValue(status);
    }
  };

  if (payload && !error) {
    if (payload.statuses) {
      const statuses = Object.keys(payload.statuses).map(key => {
        const statusName = payload.statuses[key].display.replace('auction.', 'auctions.');
        return {
          value: key,
          name: formatMessage({ id: getAppMessage(statusName as StatusName) }),
        };
      });
      statusFilters.push(...statuses);
    }
    setInitialValues();
  }

  const handleSortingChange = (event: SelectChangeEvent) => {
    const sort: SortFilterParam = {};
    const name = (event.target.value as string).split('=')[0];
    sort[name] = (event.target.value as string).split('=')[1];
    setSortValue(event.target.value as string);
    dispatch(setOrderParam(sort));
  };

  const handleStatusFilterChange = (event: SelectChangeEvent) => {
    if (filter) {
      const newFilter = filter;
      if (event.target.value) {
        newFilter[event.target.value as string] = 1;
      }
      Object.keys(newFilter).forEach(key => {
        if (key.includes('auction')) {
          if (key !== event.target.value) {
            delete newFilter[key];
          }
        }
      });
      dispatch(setFiltersParam(newFilter));
    } else {
      dispatch(setFiltersParam({ [event.target.value as string]: 1 }));
    }
    setStatusValue(event.target.value as string);
  };

  return (
    <>
      <SelectComponent
        id="status"
        label=""
        optionsWithValues
        options={statusFilters}
        value={statusValue}
        variant="standard"
        icon={<KeyboardArrowDownIcon />}
        className={clsx(classes.auctionsSelect, classes.auctionsTypeSelect)}
        handleChange={handleStatusFilterChange}
      />
      <SelectComponent
        id="sort"
        label=""
        optionsWithValues
        options={sortingFilters}
        value={sortValue}
        variant="standard"
        icon={<SortIcon />}
        className={clsx(classes.auctionsSelect, classes.auctionsSortingSelect)}
        handleChange={handleSortingChange}
      />
    </>
  );
};

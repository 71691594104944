import React, { useEffect, useState } from 'react';
import { useClient, useParameterizedQuery } from 'react-fetching-library';
import { Box, useTheme } from '@material-ui/core';
import TuneIcon from '@material-ui/icons/Tune';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';

import { IconButton } from 'ui/iconButton/IconButton';
import { getAuctionsAction } from 'api/actions/auctions/auctionsActions';
import { Button } from 'ui/button/Button';
import { useQueryParamsDispatch } from 'hooks/useQueryParamsDispatch/useQueryParamsDispatch';
import { setFiltersParam, setPageParam } from 'context/queryParams/queryActionCreators/queryActionCreators';
import { useQueryParamsState } from 'hooks/useQueryParamsState/useQueryParamsState';
import { getAppMessage } from 'i18n/messages';
import { CreateAuctionContainer } from 'app/createAuction/CreateAuctionContainer';
import { Navigation } from 'ui/navigation/Navigation';
import { useAuthState } from '../../hooks/useAuthState/useAuthState';
import { deliveryReminderDelay, UserRole } from '../../constants';
import { LoaderOverlay } from '../../ui/loaderOverlay/LoaderOverlay';
import { getUserRole } from '../../helpers/getUserRole';
import { DeliveryReminderDialog } from '../deliveryReminderDialog/DeliveryReminderDialog';
import { AuctionsList } from '../../ui/auctionsList/AuctionsList';
import { useUserData } from '../../hooks/useUserData/useUserData';
import { getMyDeliveryReminders } from '../../api/actions/user/userActions';
import { MyDeliveryReminder } from '../../api/actions/user/userActions.types';
import { useTitleDispatch } from '../../hooks/useTitleDispatch/useTitleDispatch';
import { useLocale } from '../../hooks/useLocale/useLocale';
import { createQueryString } from '../../helpers/createQueryString';
import { CompleteProfileDataContainer } from 'app/buyerProfileDetails/completeProfileData/CompleteProfileDataContainer';

import { AuctionsFilters } from './auctionsFilters/AuctionsFilters';
import { useAuctionsListStyles } from './AuctionsList.styles';
import { AuctionsSortingFilters } from './auctionsFilters/AuctionsSortingFilters';

let reminderShown = false;

export const AuctionsContainer = () => {
  const SCROLL_BOTTOM_MARGIN = 15;
  const { user } = useAuthState();
  const dispatch = useQueryParamsDispatch();
  const queryParams = useQueryParamsState();
  const { page, filter } = queryParams;
  const classes = useAuctionsListStyles();
  const { formatMessage, formatMsg } = useLocale();
  const theme = useTheme();
  const client = useClient();
  const buyerUuid = useUserData()?.buyerUuid;
  const setTitle = useTitleDispatch();
  const canCreateAuction = user?.roles?.includes?.(UserRole.ROLE_VENDOR);
  const isBuyer = user?.roles?.includes?.(UserRole.ROLE_BUYER);

  useEffect(() => {
    setTitle([formatMsg('page_title.auctions_list')]);
  }, [formatMsg, setTitle]);

  const [showReminderDialog, setShowReminderDialog] = useState(false);
  const [reminderItems, setReminderItems] = useState<MyDeliveryReminder[] | null>(null);
  const [filtersVisibility, setFiltersVisibility] = useState<boolean>(false);
  const [auctionFormVisibility, setAuctionFormVisibility] = useState<boolean>(false);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState<boolean>(false);

  const { query, loading, payload, error, abort } = useParameterizedQuery(getAuctionsAction);

  const handleScroll = () => {
    if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight - SCROLL_BOTTOM_MARGIN) {
      setIsScrolledToBottom(true);
    } else {
      setIsScrolledToBottom(false);
    }
  };

  useEffect(() => {
    query(`?${createQueryString(queryParams)}`);

    return () => {
      abort();
    };
  }, [queryParams, query, abort]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!isBuyer || !buyerUuid || reminderShown) return;
    const cancellation = { cancel: false };

    new Promise(resolve => setTimeout(resolve, deliveryReminderDelay)).then(async () => {
      if (cancellation.cancel) return;

      const { error, payload } = await client.query(getMyDeliveryReminders());

      if (cancellation.cancel || error || !payload) return;

      setReminderItems(payload);

      if (payload.length) {
        setShowReminderDialog(true);
        reminderShown = true;
      }
    });

    return () => {
      cancellation.cancel = true;
    };
  }, [isBuyer, buyerUuid, client]);

  const handlePageChange = (page: number) => {
    dispatch(setPageParam(page.toString()));
  };

  return (
    <>
      <Navigation>
        <Box className={classes.auctionsNav}>
          <Box className={classes.auctionsSimpleFilters}>
            <AuctionsSortingFilters />
          </Box>
          <Box className={classes.actionButtons}>
            <IconButton
              color="primary"
              className={classes.filtersButton}
              onClick={() => setFiltersVisibility(!filtersVisibility)}
            >
              <TuneIcon />
            </IconButton>
            {canCreateAuction && (
              <Button
                color="primary"
                className={classes.createAuctionButton}
                onClick={() => setAuctionFormVisibility(!auctionFormVisibility)}
                text={formatMessage({ id: getAppMessage('auctions.create_new_auction') })}
                icon={<AddIcon />}
              />
            )}
          </Box>
        </Box>
      </Navigation>
      <Box>
        <AuctionsFilters
          value={filter}
          onChange={filter => dispatch(setFiltersParam(filter))}
          open={filtersVisibility}
          onClose={() => setFiltersVisibility(false)}
        />
        <LoaderOverlay open={loading} zIndex={theme.zIndex.modal} />
        <Box className={classes.listWrapper}>
          <AuctionsList
            userRole={user ? getUserRole(user) : null}
            payload={payload}
            loading={loading}
            error={error}
            page={parseInt(page)}
            onPageChange={handlePageChange}
          />
        </Box>
        {canCreateAuction && (
          <>
            <Button
              color="primary"
              className={clsx(
                classes.createAuctionButton,
                classes.createAuctionButtonMobile,
                isScrolledToBottom && classes.createAuctionButtonMobileScrolled,
              )}
              onClick={() => setAuctionFormVisibility(!auctionFormVisibility)}
              text={formatMessage({ id: getAppMessage('auctions.new_auction') })}
              icon={<AddIcon />}
            />
            <CreateAuctionContainer
              open={auctionFormVisibility}
              onClose={(reload: boolean) => {
                setAuctionFormVisibility(!auctionFormVisibility);
                if (reload) query(`?${createQueryString(queryParams)}`);
              }}
            />
          </>
        )}
      </Box>
      {isBuyer && <CompleteProfileDataContainer />}
      {isBuyer && buyerUuid && (
        <DeliveryReminderDialog
          buyerUuid={buyerUuid}
          open={showReminderDialog}
          onClose={() => setShowReminderDialog(false)}
          items={reminderItems ?? []}
        />
      )}
    </>
  );
};

import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const usePaginationStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
      display: 'flex',
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
    paginationItem: {
      position: 'relative',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'inline-flex',
      boxSizing: 'border-box',
      outline: 0,
      borderRadius: '16px',
      border: 'none',
      margin: '0 3px',
      height: '30px',
      minWidth: '30px',
      padding: '0 4px',
      color: 'rgba(0, 0, 0, 0.38)',
      fontSize: '16px',
      fontFamily: theme.typography.fontFamily,
      lineHeight: '28px',
      textAlign: 'center',
      letterSpacing: '0.5px',
      cursor: 'pointer',
      backgroundColor: 'transparent',
      transition:
        'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      [theme.breakpoints.up('md')]: {
        height: '32px',
        minWidth: '32px',
        padding: '0 6px',
        fontSize: '18px',
      },
    },
    paginationItemSelected: {
      color: '#fff',
      backgroundColor: theme.palette.primary.main,
    },
    paginationTextItem: {
      color: theme.palette.primary.main,
      '&:disabled': {
        opacity: 0.5,
      },
    },
  }),
);

import { Reducer } from 'react';

import { ReducerAction, ReducerActionType, ReducerState, RegistrationSteps } from './Register.types';

export const reducerInitialState: ReducerState = {
  accountType: null,
  step: RegistrationSteps.AccountInfo,
  accountInfo: null,
  uuid: null,
  smsPassword: null,
};

export const reducer: Reducer<ReducerState, ReducerAction> = (prevState, action) => {
  switch (action.accountType) {
    case 'buyer':
      switch (action.type) {
        case ReducerActionType.GoToStep2:
          return {
            accountType: action.accountType,
            step: RegistrationSteps.OneTimePassword,
            accountInfo: action.accountInfo,
            uuid: action.uuid,
            smsPassword: null,
          };
        case ReducerActionType.ResendSecurityCode:
          return {
            accountType: action.accountType,
            step: RegistrationSteps.OneTimePassword,
            accountInfo: action.accountInfo,
            uuid: action.uuid,
            smsPassword: null,
          };
        case ReducerActionType.GoToStep3:
          return {
            accountType: action.accountType,
            step: RegistrationSteps.SetPassword,
            accountInfo: action.accountInfo,
            uuid: action.uuid,
            smsPassword: action.smsPassword,
          };
        default:
          return prevState;
      }
    case 'vendor':
      switch (action.type) {
        case ReducerActionType.GoToStep2:
          return {
            accountType: action.accountType,
            step: RegistrationSteps.OneTimePassword,
            accountInfo: action.accountInfo,
            uuid: action.uuid,
            smsPassword: null,
          };
        case ReducerActionType.ResendSecurityCode:
          return {
            accountType: action.accountType,
            step: RegistrationSteps.OneTimePassword,
            accountInfo: action.accountInfo,
            uuid: action.uuid,
            smsPassword: null,
          };
        case ReducerActionType.GoToStep3:
          return {
            accountType: action.accountType,
            step: RegistrationSteps.SetPassword,
            accountInfo: action.accountInfo,
            uuid: action.uuid,
            smsPassword: action.smsPassword,
          };
        case ReducerActionType.SkipStep2:
          return {
            accountType: action.accountType,
            step: RegistrationSteps.SetPassword,
            accountInfo: action.accountInfo,
            uuid: action.uuid,
            smsPassword: '666666',
          };
        default:
          return prevState;
      }
  }
};

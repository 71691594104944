/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';

import { Status } from 'ui/status/Status';
import { Button } from 'ui/button/Button';
import { useLocale } from 'hooks/useLocale/useLocale';
import { formatAmount } from 'helpers/formatAmount';
import { Confirmation } from 'app/auctionDetails/confirmation/Confirmation';
import { Link } from 'ui/link/Link';
import { urlBuilders, AppRoute } from 'routing/AppRoute.enum';
import { AuctionShippingStatus, AuctionPaymentFlow } from 'api/actions/auction/auctionActions.types';

import { useStyles } from './MobileTable.styles';
import { MobileTableProps } from './MobileTable.types';

export const MobileTable = ({
  isRtl,
  activeRowClass,
  data,
  className,
  handleDeliveryConfirmation,
}: MobileTableProps) => {
  const { formatMessage, formatMsg, locale } = useLocale();
  const classes = useStyles();

  return (
    <Table className={className}>
      <TableBody>
        {data.map(auction => (
          <TableRow key={auction.uuid}>
            <TableCell padding="none">
              <Table className={classes.itemTable}>
                <TableBody>
                  <TableRow>
                    <TableCell align={isRtl ? 'left' : 'right'}>
                      <Box mb={0.5} className={classes.label}>
                        {formatMessage({ id: 'user_profile.buyer.auction_name' })}
                      </Box>
                      <Link
                        href={urlBuilders[AppRoute.auctionDetails](auction.uuid)}
                        variant="inherit"
                        text={auction.name}
                      />
                    </TableCell>
                    <TableCell align={isRtl ? 'left' : 'right'}>
                      <Box mb={0.5} className={classes.label}>
                        {formatMessage({ id: 'user_profile.buyer.bid_amount' })}
                      </Box>
                      <Typography variant="body1">
                        <strong>{formatAmount(Number(auction.bidPrice) / 100, locale)}</strong>
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align={isRtl ? 'left' : 'right'}>
                      <Box mb={0.5} className={classes.label}>
                        {formatMessage({ id: 'user_profile.buyer.vendor' })}
                      </Box>
                      <Typography variant="body2">{auction.vendorName}</Typography>
                    </TableCell>
                    <TableCell align={isRtl ? 'left' : 'right'}>
                      {auction.shippingStatus === AuctionShippingStatus.received ? (
                        <Status>{formatMsg('user_profile.buyer.delivered')}</Status>
                      ) : (
                        <Confirmation
                          popupId={auction.uuid}
                          titleMsg={'user_profile.buyer.confirm_delivery_popup_title'}
                          bodyMsg={'user_profile.buyer.confirm_delivery_popup_description'}
                          confirmationMsg={'user_profile.buyer.confirm'}
                          onConfirm={() => handleDeliveryConfirmation(auction.uuid)}
                        >
                          {confirmationProps => (
                            <Button
                              disabled={auction.paymentFlow !== AuctionPaymentFlow.paymentCompleted}
                              variant="text"
                              text={formatMsg('user_profile.buyer.confirm_delivery')}
                              {...confirmationProps}
                            />
                          )}
                        </Confirmation>
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

import React, { useLayoutEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import dayjs from 'dayjs';
import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';

import { AppLocale } from '../AppLocale.enum';
import { defaultLocale } from '../defaultLocale';
import { translations } from '../../../i18n/messages';
import { LocaleContext } from '../localeContext/LocaleContext';

import { LocaleContextControllerProps } from './LocaleContextController.types';

function onError(err: string): void {
  if (process.env.NODE_ENV === 'production') {
    if (process.env.REACT_APP_SENTRY_DSN) {
      Sentry.captureMessage(err, Severity.Error);
    }
  } else {
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

export const LocaleContextController = ({ children }: LocaleContextControllerProps) => {
  const [locale, setLocale] = useState<AppLocale>(defaultLocale);

  useLayoutEffect(() => {
    dayjs.locale(locale);
  }, [locale]);

  return (
    <IntlProvider onError={onError} defaultLocale={defaultLocale} locale={locale} messages={translations[locale]}>
      <LocaleContext.Provider value={{ defaultLocale, locale, setLocale }}>{children}</LocaleContext.Provider>
    </IntlProvider>
  );
};

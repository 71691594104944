import React from 'react';
import { Grid, useTheme, useMediaQuery } from '@material-ui/core';

import { TextWithIcon } from 'ui/textWithIcon/TextWithIcon';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useStyles } from '../AuctionFeatures.styles';
import globe from 'assets/images/footer/icons/globe.svg';
import sandglass from 'assets/images/footer/icons/sandglass.svg';
import coins from 'assets/images/footer/icons/coins.svg';
import people from 'assets/images/footer/icons/people.svg';
import { getAppMessage } from 'i18n/messages';
import { Title } from 'ui/title/Title';

export const Features = () => {
  const classes = useStyles();
  const { formatMessage } = useLocale();

  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchesLg = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Grid container item>
      <Grid item xs={12}>
        <Title
          text={formatMessage({ id: getAppMessage('footer.features.title') })}
          component="h3"
          variant="h4"
          color="primary"
          className={classes.title}
        />
      </Grid>
      <Grid container item spacing={matchesLg ? 8 : matchesMd ? 4 : 1}>
        <Grid item xs={12} md={3}>
          <TextWithIcon
            imgUrl={globe}
            alt={formatMessage({ id: getAppMessage('footer.features.globe') })}
            text={formatMessage({ id: getAppMessage('footer.features.easy_participation') })}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextWithIcon
            imgUrl={coins}
            alt={formatMessage({ id: getAppMessage('footer.features.coins') })}
            text={formatMessage({ id: getAppMessage('footer.features.equal_opportunities') })}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextWithIcon
            imgUrl={people}
            alt={formatMessage({ id: getAppMessage('footer.features.people') })}
            text={formatMessage({ id: getAppMessage('footer.features.buying_more') })}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextWithIcon
            imgUrl={sandglass}
            alt={formatMessage({ id: getAppMessage('footer.features.sandglass') })}
            text={formatMessage({ id: getAppMessage('footer.features.save_time') })}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export type LoginHelpFormFields = {
  phone: string;
};

export enum LoginHelpSteps {
  Phone,
  SecurityCode,
  NewPassword,
}

export enum ReducerActionType {
  GoToStep2,
  ResendSecurityCode,
  GoToStep3,
}

export type ReducerAction =
  | {
      type: ReducerActionType.GoToStep2;
      username: string;
      uuid: string;
    }
  | {
      type: ReducerActionType.ResendSecurityCode;
      username: string;
      uuid: string;
    }
  | {
      type: ReducerActionType.GoToStep3;
      username: string;
      uuid: string;
      smsPassword: string;
    };

export type ReducerState =
  | {
      step: LoginHelpSteps.Phone;
      username: null;
      uuid: null;
      smsPassword: null;
    }
  | {
      step: LoginHelpSteps.SecurityCode;
      username: string;
      uuid: string;
      smsPassword: null;
    }
  | {
      step: LoginHelpSteps.NewPassword;
      username: string;
      uuid: string;
      smsPassword: string;
    };

export type LoginHelpProps = {
  onSubmit: (payload: LoginHelpFormFields) => Promise<Record<string, string> | undefined>;
};

import { ValidationOptions } from 'react-hook-form';
import { IntlFormatters } from 'react-intl';

import { getAppMessage } from '../i18n/messages';
import { maxFullNameLength } from '../constants';

export function getFullNameValidationOptions({
  formatMessage,
}: {
  formatMessage: IntlFormatters['formatMessage'];
}): ValidationOptions {
  return {
    required: formatMessage({ id: getAppMessage('validation.common.required') }),
    maxLength: {
      value: maxFullNameLength,
      message: formatMessage({ id: getAppMessage('validation.common.length_max') }, { limit: maxFullNameLength }),
    },
  };
}

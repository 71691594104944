/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useState } from 'react';
import { Box, Typography } from '@material-ui/core';

import { useLocale } from '../../../hooks/useLocale/useLocale';
import { AppMessages } from '../../../i18n/messages';
import { ReactComponent as BidImage } from '../../../assets/images/bid.svg';
import { Placeholder } from '../placeholder/Placeholder';
import { Section } from '../section/Section';
import { formatAmount } from '../../../helpers/formatAmount';
import { Button } from '../../../ui/button/Button';
import { Alert } from '../../../ui/alert/Alert';
import { Table } from '../table/Table';
import { File } from '../../../ui/file/File';
import { FileInput } from '../../../ui/fileInput/FileInput';
import { documentMimeTypes, isTouchDevice } from '../../../constants';
import { FileDescriptor } from '../../../ui/fileInput/FileInput.types';
import {
  AuctionPaymentFlow,
  AuctionPaymentStatus,
  AuctionStatus,
} from '../../../api/actions/auction/auctionActions.types';
import { Confirmation } from '../confirmation/Confirmation';
import { formatIban } from '../../../helpers/formatIban';

import { useStyles } from './MyBids.styles';
import { MyBidsProps } from './MyBids.types';

const getBidDifferenceData = (
  bidAmount?: number,
  highestBidAmount?: number,
): { amountDifference: number; highestBidAmount: number } | undefined => {
  if (typeof bidAmount !== 'number' || typeof highestBidAmount !== 'number' || highestBidAmount === bidAmount) return;
  return { amountDifference: highestBidAmount - bidAmount, highestBidAmount };
};

export const MyBids = ({
  disabled,
  auction,
  buyerData,
  onLeaveBidding,
  onPaymentConfirmationUpload,
  onPaymentConfirmationRemove,
}: MyBidsProps) => {
  const { bidInfo } = buyerData;
  const { formatMessage, locale } = useLocale();
  const classes = useStyles();
  const [fileDescriptors, setFileDescriptors] = useState<FileDescriptor[]>([]);
  const bidDifferenceData = useMemo(() => getBidDifferenceData(bidInfo?.myBidPrice, bidInfo?.maxBidPrice), [bidInfo]);
  const buyerCanInteract = [
    AuctionPaymentFlow.waitingForBuyerPaymentConfirmation,
    AuctionPaymentFlow.waitingForVendorPaymentConfirmation,
  ].includes(auction.paymentFlow);

  return (
    <Section
      disabled={!bidInfo}
      title={formatMessage({ id: AppMessages['auction.details.bids.title'] })}
      action={
        auction.status === AuctionStatus.accepted &&
        bidInfo?.uuid && (
          <Confirmation
            popupId="leave-bidding"
            titleMsg={'auction.details.bids.leave_bidding_confirmation_title'}
            bodyMsg={'auction.details.bids.leave_bidding_confirmation_body'}
            confirmationMsg={'auction.details.bids.leave_bidding'}
            onConfirm={() => bidInfo && onLeaveBidding(bidInfo.uuid)}
          >
            {confirmationProps => (
              <Button
                disabled={disabled}
                variant="text"
                size="small"
                text={formatMessage({ id: AppMessages['auction.details.bids.leave_bidding'] })}
                {...confirmationProps}
              />
            )}
          </Confirmation>
        )
      }
    >
      {!bidInfo ? (
        <Placeholder>
          <BidImage role="img" title={formatMessage({ id: AppMessages['auction.details.bids.bid'] })} />
          <Typography>{formatMessage({ id: AppMessages['auction.details.bids.placeholder'] })}</Typography>
        </Placeholder>
      ) : (
        <Box px={[2, 3]}>
          <Box>
            <Typography variant="body1" className={classes.amountLabel}>
              {auction.status === AuctionStatus.accepted &&
                formatMessage({ id: AppMessages['auction.details.bids.amount_label'] })}
              {auction.status === AuctionStatus.awarded &&
                formatMessage({ id: AppMessages['auction.details.bids.final_amount_label'] })}
            </Typography>
          </Box>
          <Box mt={0.5}>
            <Typography variant="h4">{formatAmount(Number(bidInfo.myBidPrice) / 100, locale, 0)}</Typography>
          </Box>
          {auction.status === AuctionStatus.accepted && bidDifferenceData && (
            <Box mt={3}>
              <Alert severity="warning">
                {formatMessage(
                  { id: AppMessages['auction.details.bids.alert_outbid'] },
                  {
                    amountDifference: formatAmount(Number(bidDifferenceData.amountDifference) / 100, locale, 0),
                    highestBidAmount: formatAmount(Number(bidDifferenceData.highestBidAmount) / 100, locale, 0),
                  },
                )}
              </Alert>
            </Box>
          )}
          {auction.status === AuctionStatus.awarded && bidInfo.accepted && (
            <>
              <Box mt={3}>
                <Alert severity="success">
                  <span>
                    {formatMessage(
                      { id: AppMessages['auction.details.bids.alert_accepted_1'] },
                      {
                        part2: buyerCanInteract ? (
                          <strong>{formatMessage({ id: AppMessages['auction.details.bids.alert_accepted_2'] })}</strong>
                        ) : (
                          ''
                        ),
                      },
                    )}
                  </span>
                </Alert>
              </Box>
              {buyerCanInteract && (
                <>
                  {buyerData.vendor &&
                    buyerData.vendor.bankBeneficientName &&
                    buyerData.vendor.bankId &&
                    buyerData.vendor.bankIban && (
                      <Box mt={3}>
                        <Table
                          rows={[
                            {
                              key: formatMessage({ id: AppMessages['auction.details.bids.beneficient_name_label'] }),
                              value: buyerData.vendor.bankBeneficientName,
                            },
                            {
                              key: formatMessage({ id: AppMessages['auction.details.fee.bank_label'] }),
                              value: buyerData.banks[buyerData.vendor.bankId],
                            },
                            {
                              key: formatMessage({ id: AppMessages['auction.details.fee.iban_label'] }),
                              value: formatIban(buyerData.vendor.bankIban),
                            },
                          ]}
                        />
                      </Box>
                    )}
                  {[AuctionPaymentStatus.pending, AuctionPaymentStatus.rejected].includes(auction.paymentStatus) ? (
                    <Box mt={4}>
                      <FileInput
                        accept={documentMimeTypes}
                        value={fileDescriptors}
                        onChange={setFileDescriptors}
                        uploader={onPaymentConfirmationUpload}
                        text={formatMessage({
                          id: isTouchDevice
                            ? AppMessages['auction.details.fee.dropzone_mobile_text']
                            : AppMessages['auction.details.fee.dropzone_text'],
                        })}
                      />
                    </Box>
                  ) : auction.buyerFee ? (
                    <Box mt={3}>
                      <div className={classes.fileLabel}>
                        {formatMessage({ id: AppMessages['auction.details.bids.transfer_receipt'] })}
                      </div>
                      <File
                        name={auction.buyerFee}
                        action={
                          <Button
                            disabled={disabled}
                            variant="text"
                            size="small"
                            onClick={onPaymentConfirmationRemove}
                            text={formatMessage({ id: AppMessages['common.remove'] })}
                          />
                        }
                      />
                    </Box>
                  ) : null}
                </>
              )}
            </>
          )}
          {auction.status === AuctionStatus.awarded && !bidInfo.accepted && (
            <Box mt={3}>
              <Alert severity="error">
                {formatMessage({ id: AppMessages['auction.details.bids.alert_rejected'] })}
              </Alert>
            </Box>
          )}
        </Box>
      )}
    </Section>
  );
};

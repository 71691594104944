import React, { useCallback } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useMutation } from 'react-fetching-library';
import { useSnackbar } from 'notistack';

import { AppRoute } from 'routing/AppRoute.enum';
import { SetPasswordFormFields } from '../registration/setPassword/SetPassword.types';
import {
  loginHelpPhoneAction,
  loginHelpSecurityCodeAction,
  newPasswordAction,
} from '../../api/actions/password/passwordActions';
import { HelpPhonePayload, SecurityCodePayload, PasswordPayload } from 'api/actions/password/passwordActions.types';
import { noSmsChecking } from '../../constants';
import { useAuthorize } from '../../hooks/useAuthorize/useAuthorize';
import { useLocale } from 'hooks/useLocale/useLocale';

import { LoginHelpFormFields, LoginHelpSteps, ReducerActionType } from './LoginHelp.types';
import { LoginHelp } from './LoginHelp';
import { reducer, reducerInitialState } from './LoginHelp.reducer';
import { SecurityCode } from './securityCode/SecurityCode';
import { NewPassword } from './newPassword/NewPassword';
import { SecurityCodeFields } from './securityCode/SecurityCode.types';

export const LoginHelpContainer = () => {
  const [state, dispatch] = React.useReducer(reducer, reducerInitialState);
  const { mutate: loginHelpPhoneMutate, loading: helpPhoneLoading } = useMutation(loginHelpPhoneAction);
  const { mutate: loginHelpSecurityCodeMutate } = useMutation(loginHelpSecurityCodeAction);
  const { mutate: newPasswordMutate } = useMutation(newPasswordAction);
  const { isAuthorized, authorize } = useAuthorize();
  const { enqueueSnackbar } = useSnackbar();
  const { formatMsg } = useLocale();
  const { type } = useParams() as { type: 'buyer' | 'vendor' };

  const onPhoneSubmit = useCallback(
    async ({ phone }: LoginHelpFormFields) => {
      if (state.step !== LoginHelpSteps.Phone) return;
      let errors: Record<string, string> | undefined;

      const requestBody: HelpPhonePayload = {
        phone,
        type,
        validation: noSmsChecking ? 'NO-SMS-CHECKING' : undefined,
      };

      const { payload, error } = await loginHelpPhoneMutate(requestBody);
      if (!error) {
        dispatch({
          type: ReducerActionType.GoToStep2,
          username: phone,
          uuid: payload.uuid,
        });
      } else {
        errors = payload;
      }

      return errors;
    },
    [loginHelpPhoneMutate, state.step, type],
  );

  const onResendSecurityCode = useCallback(async () => {
    let errors: Record<string, string> | undefined;

    if (state.step === LoginHelpSteps.SecurityCode) {
      const requestBody: HelpPhonePayload = {
        phone: state.username,
        type,
        validation: noSmsChecking ? 'NO-SMS-CHECKING' : undefined,
      };

      const { payload, error, status } = await loginHelpPhoneMutate(requestBody);
      if (!error) {
        dispatch({
          type: ReducerActionType.ResendSecurityCode,
          username: state.username,
          uuid: payload.uuid,
        });
        enqueueSnackbar(formatMsg('login_and_registration.security_code.code_resent'), { variant: 'success' });
      } else {
        if (status && String(status).startsWith('4')) {
          errors = { error: formatMsg('exception.unknown.primary_text') };
          enqueueSnackbar(
            formatMsg('exception.unknown.secondary_text_part_1', {
              part2: formatMsg('exception.unknown.secondary_text_part_2'),
            }),
            { variant: 'error' },
          );
        }
      }
    }

    return errors;
  }, [enqueueSnackbar, formatMsg, loginHelpPhoneMutate, state.step, state.username, type]);

  const onSecurityCodeSubmit = useCallback(
    async ({ smsPassword }: SecurityCodeFields) => {
      if (state.step !== LoginHelpSteps.SecurityCode) return;
      let errors: Record<string, string> | undefined;

      const requestBody: SecurityCodePayload = {
        smsPassword,
        uuid: state.uuid,
      };

      const { payload, error } = await loginHelpSecurityCodeMutate(requestBody);

      if (!error) {
        dispatch({
          type: ReducerActionType.GoToStep3,
          username: state.username,
          uuid: state.uuid,
          smsPassword,
        });
      } else {
        errors = payload;
      }

      return errors;
    },
    [loginHelpSecurityCodeMutate, state.step, state.username, state.uuid],
  );

  const onNewPasswordSubmit = useCallback(
    async ({ plainPassword }: SetPasswordFormFields) => {
      if (state.step !== LoginHelpSteps.NewPassword) return;
      let errors: Record<string, string> | undefined;

      const requestBody: PasswordPayload = {
        phone: state.username,
        type,
        plainPassword: plainPassword,
        smsPassword: state.smsPassword,
        smsUuid: state.uuid,
      };

      const { payload, error } = await newPasswordMutate(requestBody);
      if (!error) {
        errors = await authorize(state.username, plainPassword, type as 'vendor' | 'buyer');
      } else {
        errors = payload;
      }

      return errors;
    },
    [authorize, newPasswordMutate, state.smsPassword, state.step, state.username, state.uuid, type],
  );

  if (isAuthorized) {
    return <Redirect to={AppRoute.home} />;
  }

  switch (state.step) {
    case LoginHelpSteps.Phone:
      return <LoginHelp onSubmit={onPhoneSubmit} />;
    case LoginHelpSteps.SecurityCode:
      return (
        <SecurityCode
          onSubmit={onSecurityCodeSubmit}
          onResend={onResendSecurityCode}
          resendingSecurityCode={helpPhoneLoading}
        />
      );
    case LoginHelpSteps.NewPassword:
      return <NewPassword onSubmit={onNewPasswordSubmit} />;
  }
};

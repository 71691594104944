import React from 'react';
import { Box, CircularProgress, Paper } from '@material-ui/core';
import clsx from 'clsx';

import { Title } from '../../../ui/title/Title';

import { SectionProps } from './Section.types';
import { useStyles } from './Section.styles';

export const Section = ({ title, loading, action, disabled, children, minHeight }: SectionProps) => {
  const classes = useStyles();

  return (
    <Box py={4} minHeight={minHeight} display="flex" flexDirection="column">
      {({ className }: { className: string }) => (
        <Paper elevation={0} className={className}>
          {title && (
            <Box px={[2, 3]} mb={4} display="flex" justifyContent="space-between">
              <Title
                className={clsx(disabled && classes.disabledTitle)}
                text={<strong>{title}</strong>}
                component="h3"
                variant="h5"
              />
              {loading ? <CircularProgress size={24} /> : action}
            </Box>
          )}
          {children}
        </Paper>
      )}
    </Box>
  );
};

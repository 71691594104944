import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParameterizedQuery, useClient } from 'react-fetching-library';

import { getMyWonAuctions } from 'api/actions/user/userActions';
import { confirmDelivery } from 'api/actions/auction/auctionActions';
import { scrollTop } from 'helpers/scrollTop';
import { UserWonAuctionsResponse, WonAuction } from 'api/actions/user/userActions.types';
import { AuctionShippingStatus } from 'api/actions/auction/auctionActions.types';

import { WonAuctionsList } from './WonAuctionsList';

export const WonAuctionsContainer = () => {
  const client = useClient();
  const { loading, query, error, payload, abort } = useParameterizedQuery(getMyWonAuctions);
  const [payloadData, setPayloadData] = useState<undefined | UserWonAuctionsResponse>(undefined);

  useEffect(() => {
    query(1);

    return () => abort();
  }, [abort, query]);

  const handlePageChange = useCallback(
    (page: number) => {
      if (!loading) {
        query(page);
        scrollTop();
      }
    },
    [loading, query],
  );

  useEffect(() => {
    setPayloadData(payload);
  }, [payload]);

  const handleDeliveryConfirmation = useMemo(() => {
    return async (auctionUuid: string) => {
      const res = await client.query(confirmDelivery(auctionUuid));
      if (!res.error && payloadData) {
        const newAuctions: WonAuction[] = payloadData?.data.map(auction => {
          if (auction.uuid === auctionUuid) {
            auction.shippingStatus = AuctionShippingStatus.received;
          }
          return auction;
        });

        setPayloadData({ data: newAuctions, pagination: payloadData.pagination });
      }
    };
  }, [client, payloadData]);

  return (
    <WonAuctionsList
      loading={loading}
      error={error}
      auctions={payloadData}
      onPageChange={handlePageChange}
      handleDeliveryConfirmation={handleDeliveryConfirmation}
    />
  );
};

import { ValidationOptions } from 'react-hook-form';
import { IntlFormatters } from 'react-intl';

import { getAppMessage } from '../i18n/messages';

export function getNationalIdValidationOptions({
  formatMessage,
}: {
  formatMessage: IntlFormatters['formatMessage'];
}): ValidationOptions {
  return {
    required: formatMessage({ id: getAppMessage('validation.common.required') }),
    validate: {
      pattern: (value: string) =>
        !!value.match(/^[0-9]*$/) || formatMessage({ id: getAppMessage('validation.common.invalid_format') }),
    },
  };
}

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: '180px 16px 40px',
    backgroundColor: '#fff',
    [theme.breakpoints.up('sm')]: {
      padding: '200px 16px 100px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '215px 60px 80px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '225px 75px 100px',
    },
    [theme.breakpoints.up('xl')]: {
      padding: '240px 122px 120px',
    },
    '& p': {
      fontSize: '1.125rem',
      lineHeight: 1.56,
      letterSpacing: '0.5px',
    },
    '& ul': {
      listStyle: 'none',
    },
  },
  header1: {
    fontSize: '3.25rem',
    color: theme.palette.primary.main,
    lineHeight: 1.2,
    fontWeight: 800,
    marginBottom: theme.spacing(8),
    [theme.breakpoints.up('sm')]: {
      fontSize: '3.75rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '4.25rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '5rem',
    },
  },
  header3: {
    lineHeight: 1.33,
    fontWeight: 800,
    color: theme.palette.primary.main,
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
  },
  listItem: {
    '& p::before': {
      content: "'\u2022'",
      color: theme.palette.primary.main,
      display: 'inline-block',
      width: '1.25em',
      marginLeft: '-1.25em',
    },
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  noBottomMargin: {
    marginBottom: 0,
  },
  noTopMargin: {
    marginTop: 0,
  },
}));

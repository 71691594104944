/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { Status } from '../../../../ui/status/Status';
import { Button } from '../../../../ui/button/Button';
import { useLocale } from '../../../../hooks/useLocale/useLocale';
import { formatAmount } from '../../../../helpers/formatAmount';
import { Confirmation } from '../../confirmation/Confirmation';
import { getNameFromCityId } from '../../../../helpers/extractCity';
import { useCommonData } from '../../../../hooks/useCommonData/useCommonData';
import { Link } from 'ui/link/Link';
import { AppRoute, urlBuilders } from 'routing/AppRoute.enum';

import { DesktopTableProps } from './DesktopTable.types';

export const DesktopTable = ({
  disabled,
  activeRowClass,
  onAcceptBid,
  data,
  isRtl,
  className,
  isVendor,
}: DesktopTableProps) => {
  const { formatMsg, locale } = useLocale();
  const { cities } = useCommonData();

  return (
    <Table className={className}>
      <TableHead>
        <TableRow>
          <TableCell align={isRtl ? 'left' : 'right'}>
            {formatMsg('auction.details.buyer_bid_list.bid_amount')}
          </TableCell>
          <TableCell align={isRtl ? 'left' : 'right'}>
            {formatMsg('auction.details.buyer_bid_list.user_name')}
          </TableCell>
          <TableCell align={isRtl ? 'left' : 'right'}>{formatMsg('auction.details.buyer_bid_list.city')}</TableCell>
          <TableCell align={isRtl ? 'right' : 'left'} />
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map(bid => (
          <TableRow key={bid.bidId} className={clsx(bid.accepted && activeRowClass)}>
            <TableCell align={isRtl ? 'left' : 'right'}>
              <Typography variant="body1">
                <strong>{formatAmount(Number(bid.value) / 100, locale)}</strong>
              </Typography>
            </TableCell>
            <TableCell align={isRtl ? 'left' : 'right'}>
              {isVendor ? (
                <Link
                  text={bid.fullName}
                  href={urlBuilders[AppRoute.buyerProfile](bid.buyerId)}
                  underline="hover"
                  variant="inherit"
                />
              ) : (
                <Typography color="primary" variant="body2">
                  {bid.fullName}
                </Typography>
              )}
            </TableCell>
            <TableCell align={isRtl ? 'left' : 'right'}>
              <Typography variant="body2">
                {(bid.cityId && getNameFromCityId(bid.cityId, cities, locale)) || '-'}
              </Typography>
            </TableCell>
            <TableCell align={isRtl ? 'right' : 'left'}>
              {bid.accepted ? (
                <Status>{formatMsg('auction.details.buyer_bid_list.accepted')}</Status>
              ) : (
                onAcceptBid && (
                  <Confirmation
                    popupId={bid.bidId}
                    titleMsg={'auction.details.buyer_bid_list.accept_bid_confirmation_title'}
                    bodyMsg={'auction.details.buyer_bid_list.accept_bid_confirmation_body'}
                    confirmationMsg={'auction.details.buyer_bid_list.accept_bid'}
                    onConfirm={() => onAcceptBid(bid.bidId)}
                  >
                    {confirmationProps => (
                      <Button
                        disabled={disabled}
                        variant="text"
                        text={formatMsg('auction.details.buyer_bid_list.accept_bid')}
                        {...confirmationProps}
                      />
                    )}
                  </Confirmation>
                )
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

import React, { useCallback, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import dayjs, { ConfigType } from 'dayjs';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import {
  formRequirements,
  auctionDurationDaysLimit,
  auctionStartDateOffsetDays,
  datePickerDateFormat,
} from '../../../constants';
import { AuctionPartExtendedProps } from '../CreateAuction.types';
import { useLocale } from 'hooks/useLocale/useLocale';
import { getAppMessage } from 'i18n/messages';
import { Input } from 'ui/input/Input';
import { DatePicker } from '../../../ui/datepicker/DatePicker';

const transformValue = (value: ConfigType) => `${dayjs(value).format(datePickerDateFormat)} 12:00:00`;

export const AuctionSetup = ({ register, setValue, errors, triggerValidation, watch }: AuctionPartExtendedProps) => {
  const { formatMessage, formatMsg } = useLocale();

  register('startDate', { required: formatMsg('validation.common.required') });
  register('endDate', { required: formatMsg('validation.common.required') });

  const startDate = watch('startDate');
  const endDate = watch('endDate');

  const minDate: string = transformValue(dayjs().add(auctionStartDateOffsetDays, 'day'));
  const maxDate: string = startDate ? transformValue(dayjs(startDate).add(auctionDurationDaysLimit, 'day')) : '';

  const handleDateChange = useCallback(
    (name: 'startDate' | 'endDate', value: MaterialUiPickersDate) => {
      setValue(name, value ? transformValue(value) : '');
      triggerValidation(name);
    },
    [setValue, triggerValidation],
  );

  useEffect(() => {
    if (!startDate || !endDate) return;
    if (dayjs(endDate).isBefore(startDate)) setValue('endDate', startDate);
    if (maxDate && dayjs(endDate).isAfter(maxDate)) setValue('endDate', maxDate);
  }, [startDate, endDate, maxDate, setValue]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={8}>
        <Input
          type="number"
          name="priceMin"
          label={formatMessage({ id: getAppMessage('common.labels.min_price') })}
          error={Boolean(errors.priceMin)}
          helperText={errors.priceMin?.message}
          InputProps={{
            inputRef: register({
              required: formatMessage({ id: getAppMessage('validation.common.required') }),
              min: {
                value: formRequirements.priceMin,
                message: formatMessage({ id: getAppMessage('validation.common.greater_than_zero') }),
              },
              pattern: {
                value: formRequirements.onlyNumbers,
                message: formatMessage({ id: getAppMessage('validation.common.only_numbers') }),
              },
            }),
          }}
        />
      </Grid>
      <Grid item xs={12} lg={8}>
        <DatePicker
          fullWidth
          label={formatMsg('common.labels.start_date')}
          value={startDate || null}
          initialFocusedDate={dayjs()}
          onChange={value => handleDateChange('startDate', value)}
          minDate={minDate}
          error={!!errors.startDate?.message}
          helperText={errors.startDate?.message}
        />
      </Grid>
      <Grid item xs={12} lg={8}>
        <DatePicker
          fullWidth
          label={formatMsg('common.labels.end_date')}
          value={endDate || null}
          initialFocusedDate={startDate || dayjs()}
          onChange={value => handleDateChange('endDate', value)}
          minDate={startDate ?? minDate}
          maxDate={maxDate}
          error={!!errors.endDate?.message}
          helperText={errors.endDate?.message}
        />
      </Grid>
    </Grid>
  );
};

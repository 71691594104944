import { Action } from 'react-fetching-library';

import { BuyerResponse, UpdateBuyerProfilePayload } from './buyerActions.types';

export function getBuyerProfileAction(uuid: string): Action<BuyerResponse> {
  return {
    method: 'GET',
    endpoint: `/v1/buyers/${uuid}`,
  };
}

export function updateBuyerProfileAction({ uuid, body }: UpdateBuyerProfilePayload): Action {
  return {
    method: 'PUT',
    endpoint: `/v1/buyers/${uuid}`,
    body: body,
  };
}

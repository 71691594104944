import React from 'react';
import { Box, Typography } from '@material-ui/core';

import heroImage from 'assets/images/hero.png';
import { Title } from 'ui/title/Title';
import { Link } from 'ui/link/Link';
import { Button } from 'ui/button/Button';
import { useLocale } from 'hooks/useLocale/useLocale';
import { getAppMessage } from 'i18n/messages';
import { AppRoute } from 'routing/AppRoute.enum';

import { useHeroStyles } from './Hero.styles';

export const Hero = () => {
  const { formatMessage } = useLocale();
  const classes = useHeroStyles();

  return (
    <Box className={classes.root}>
      <img src={heroImage} alt={formatMessage({ id: getAppMessage('hero.img_alt') })} className={classes.img} />
      <Box className={classes.text}>
        <Title
          className={classes.title}
          text={formatMessage({ id: getAppMessage('hero.title') })}
          component="h1"
          variant="h2"
        />
        <Typography className={classes.desc}>{formatMessage({ id: getAppMessage('hero.description') })}</Typography>
        <Link href={AppRoute.auctionsList} className={classes.link}>
          <Button className={classes.btn} text={formatMessage({ id: getAppMessage('hero.auctions_link') })} />
        </Link>
        <Link href={AppRoute.login} className={classes.link}>
          <Button
            className={classes.btn}
            text={formatMessage({ id: getAppMessage('hero.start_selling_link') })}
            variant="outlined"
          />
        </Link>
      </Box>
    </Box>
  );
};

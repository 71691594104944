/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';

import { AppRoute, urlBuilders } from 'routing/AppRoute.enum';
import { getAppMessage } from 'i18n/messages';
import { useUserData } from '../../hooks/useUserData/useUserData';

import { NavigationLinksProps } from './Navigation.types';

export const LoggedInLinks = ({ onClick, isAdmin }: NavigationLinksProps) => {
  const { formatMessage } = useIntl();
  const userData = useUserData();
  const profileUrl = useMemo(() => {
    if (userData?.vendorUuid) return urlBuilders[AppRoute.vendorProfile](userData.vendorUuid);
    if (userData?.buyerUuid) return urlBuilders[AppRoute.buyerProfile](userData.buyerUuid);
    return null;
  }, [userData]);

  return (
    <>
      <NavLink exact to={AppRoute.logout} onClick={onClick}>
        {formatMessage({ id: getAppMessage('common.navigation.logout') })}
      </NavLink>
      {profileUrl && !isAdmin && (
        <NavLink exact to={profileUrl} onClick={onClick}>
          {formatMessage({ id: getAppMessage('common.navigation.my_profile') })}
        </NavLink>
      )}
      <NavLink exact to={AppRoute.auctionsList} onClick={onClick}>
        {formatMessage({ id: getAppMessage('common.navigation.auctions') })}
      </NavLink>
    </>
  );
};

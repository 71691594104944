/* eslint-disable react/jsx-props-no-spreading,prefer-const */
import React from 'react';
import { useDropzone } from 'react-dropzone';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';
import { Typography, CircularProgress, Box } from '@material-ui/core';

import { createUniqueIdGenerator } from '../../helpers/createUniqueIdGenerator';
import { File } from '../file/File';

import { useStyles } from './FileInput.styles';
import { FileDescriptor, FileInputProps } from './FileInput.types';

const idGenerator = createUniqueIdGenerator();

export const FileInput = ({
  value: descriptors,
  onChange,
  uploader,
  text,
  multiple = false,
  accept,
  disabled,
}: FileInputProps) => {
  const addDescriptor = React.useRef<(fileDescriptor: FileDescriptor) => void>();
  const removeDescriptor = React.useRef<(fileDescriptorId: string | number) => void>();
  const replaceDescriptor = React.useRef<(fileDescriptorId: number, fileDescriptor: FileDescriptor) => void>();

  addDescriptor.current = fileDescriptor => onChange([...descriptors, fileDescriptor]);
  removeDescriptor.current = id => onChange(descriptors.filter(d => d.id !== id));
  replaceDescriptor.current = (id, fileDescriptor) => {
    onChange(descriptors.map(d => (d.id === id ? fileDescriptor : d)));
  };

  const handleDrop = React.useCallback(
    (acceptedFiles: File[]) => {
      if ((!multiple && !!descriptors.length) || disabled) return;
      const processFile = async (file: File) => {
        const id = idGenerator.next().value;
        addDescriptor.current?.({ type: 'pending', id });
        try {
          const realId = await uploader(file);
          if (!realId) {
            removeDescriptor.current?.(id);
          } else {
            replaceDescriptor.current?.(id, { type: 'uploaded', id: realId });
          }
        } catch {
          removeDescriptor.current?.(id);
        }
      };
      for (const acceptedFile of acceptedFiles) {
        processFile(acceptedFile);
      }
    },
    [descriptors.length, disabled, multiple, uploader],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: handleDrop, multiple: multiple, accept });
  const classes = useStyles();
  const isBusy = descriptors.some(descriptor => descriptor.type === 'pending');

  return (
    <div
      {...getRootProps({
        className: clsx(classes.dropzone, isDragActive && classes.dropzoneActive, isBusy && classes.dropzoneBusy),
      })}
    >
      <div className={classes.content}>
        <input {...getInputProps()} />
        <Box mb={1}>
          <AddIcon />
        </Box>
        <Typography variant="caption">{text}</Typography>
      </div>
      <div className={classes.loadingOverlay}>
        <CircularProgress color="inherit" />
      </div>
    </div>
  );
};

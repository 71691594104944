import React, { forwardRef } from 'react';
import { InputLabel, FormControl, Select, MenuItem } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import clsx from 'clsx';

import { getSelectMenuProps } from '../../helpers/getSelectMenuProps';

import { SelectProps, SelectOption } from './Select.types';
import { useSelectStyles } from './Select.styles';

export const SelectComponent = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      id,
      label,
      options,
      optionsWithValues,
      value,
      variant = 'outlined',
      handleChange,
      multiple = false,
      icon,
      className,
    },
    ref,
  ) => {
    const classes = useSelectStyles();

    return (
      <FormControl variant={variant}>
        {label && (
          <InputLabel
            id={id}
            classes={{
              root: classes.primarySelectLabel,
              filled: classes.primarySelectLabelFilled,
            }}
          >
            {label}
          </InputLabel>
        )}
        <Select
          IconComponent={() => {
            return icon ? icon : <ArrowDropDownIcon />;
          }}
          inputProps={{ name: id }}
          className={clsx(classes.primarySelectStyles, className)}
          labelId={id}
          value={value}
          onChange={e => handleChange(e)}
          label={label}
          multiple={multiple}
          inputRef={ref}
          color="secondary"
          displayEmpty
          MenuProps={getSelectMenuProps()}
        >
          {optionsWithValues &&
            (options as SelectOption[]).map(option => {
              return (
                <MenuItem
                  key={option.name}
                  value={option.value}
                  classes={{
                    selected: classes.primarySelectOptionSelected,
                  }}
                >
                  {option.name}
                </MenuItem>
              );
            })}
          {!optionsWithValues &&
            (options as string[]).map(option => (
              <MenuItem
                key={option}
                value={option}
                classes={{
                  selected: classes.primarySelectOptionSelected,
                }}
              >
                {option}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  },
);

import React from 'react';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';

import { TextWithIconProps } from './TextWithIcon.types';
import { useStyles } from './TextWithIcon.styles';

export const TextWithIcon = ({ text, imgUrl, alt }: TextWithIconProps) => {
  const classes = useStyles();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Grid container spacing={matches ? 1 : 4} style={{ maxWidth: matches ? 240 : '100%' }}>
      <Grid xs={3} md={12} item container className={classes.imageWrapper}>
        <img className={classes.image} src={imgUrl} alt={alt} />
      </Grid>
      <Grid xs={9} md={12} item container>
        <p className={classes.text}>{text}</p>
      </Grid>
    </Grid>
  );
};

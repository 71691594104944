import { useContext } from 'react';

import { UserDataContext } from '../../context/userData/userDataContext/UserDataContext';
import { UserDataContextType } from '../../context/userData/userDataContext/UserDataContext.types';

export const useUserData = (): UserDataContextType | null => {
  const context = useContext(UserDataContext);

  if (context === undefined) {
    throw new Error('useUserData must be used within an UserDataContextController');
  }

  return context;
};

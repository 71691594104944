import { Action } from '../../types';

import {
  GenerateBuyerCodePayload,
  ValidateBuyerCodePayload,
  CreateBuyerPayload,
  GenerateBuyerCodeResponse,
  GenerateVendorCodePayload,
  GenerateVendorCodeResponse,
  ValidateVendorCodePayload,
  CreateVendorPayload,
  CreateNonSdmVendorPayload,
} from './registrationActions.types';

export function generateBuyerCode(values: GenerateBuyerCodePayload): Action<GenerateBuyerCodeResponse> {
  return {
    method: 'POST',
    endpoint: '/v1/register/buyer/verify',
    body: values,
  };
}

export function validateBuyerCode(values: ValidateBuyerCodePayload): Action {
  return {
    method: 'PUT',
    endpoint: '/v1/register/sms-code',
    body: values,
  };
}

export function createBuyer(values: CreateBuyerPayload): Action {
  return {
    method: 'POST',
    endpoint: '/v1/register/buyer',
    body: values,
  };
}

export function generateVendorCode(values: GenerateVendorCodePayload): Action<GenerateVendorCodeResponse> {
  return {
    method: 'POST',
    endpoint: '/v1/register/vendor/verify',
    body: values,
  };
}

export function validateVendorCode(values: ValidateVendorCodePayload): Action {
  return {
    method: 'PUT',
    endpoint: '/v1/register/sms-code',
    body: values,
  };
}

export function createVendor(values: CreateVendorPayload): Action {
  return {
    method: 'POST',
    endpoint: '/v1/register/vendor',
    body: values,
  };
}

export function createNonSdmVendor(values: CreateNonSdmVendorPayload): Action {
  return {
    method: 'POST',
    endpoint: '/v1/register/vendor',
    body: values,
  };
}

import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import desktopLogoImage from 'assets/images/logo-desktop.svg';
import { Card } from 'ui/card/Card';
import { AppRoute } from 'routing/AppRoute.enum';
import { getAppMessage } from '../../i18n/messages';
import { VersionInfo } from '../versionInfo/VersionInfo';

import { LoginLayoutProps } from './LoginLayout.types';
import { useStyles } from './LoginLayout.styles';

export const LoginLayout = ({ children }: LoginLayoutProps) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const logoAlt = formatMessage({ id: getAppMessage('common.logo') });

  return (
    <Grid container className={classes.root}>
      <Grid item container xs={12} md={6} alignItems="center" justify="center" className={classes.side}>
        <Box px={2} py={1} className={clsx(classes.content, classes.mobileLogoWrapper, classes.hiddenUpMd)}>
          <Link to={AppRoute.home}>
            <img src={desktopLogoImage} className={classes.mobileLogo} alt={logoAlt} />
          </Link>
        </Box>
        <Box p={2} className={classes.content}>
          <Card>
            <Box px={3} py={6}>
              {children}
            </Box>
          </Card>
        </Box>
      </Grid>
      <Grid
        item
        container
        xs={12}
        md={6}
        alignItems="flex-end"
        justify="flex-end"
        className={clsx(classes.side, classes.hiddenDownSm, classes.background)}
      >
        <Link to={AppRoute.home}>
          <img src={desktopLogoImage} className={classes.desktopLogo} alt={logoAlt} />
        </Link>
        <div className={classes.versionInfoWrapper}>
          <VersionInfo />
        </div>
      </Grid>
    </Grid>
  );
};

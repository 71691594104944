import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useAuctionsFiltersStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: '100%',
      padding: '10px 16px 20px',
      backgroundColor: theme.palette.background.default,
      boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)',
      [theme.breakpoints.up('md')]: {
        maxWidth: '500px',
        padding: '10px 32px 20px',
      },
      '& .MuiFormControl-root': {
        margin: '18px 0',
        width: '100%',
      },
      '& .MuiFormControl-root[class*="checkbox"]': {
        margin: '10px 0 0',
        width: '100%',
      },
      '& .MuiFormHelperText-root': {
        margin: '5px 0 0',
        color: theme.palette.error.main,
      },
    },
    header: {
      alignItems: 'center',
      flexFlow: theme.direction === 'rtl' ? 'row-reverse' : 'row',
      justifyContent: theme.direction === 'ltr' ? 'flex-start' : 'flex-end',
      display: 'flex',
      paddingBottom: '10px',
    },
    closeButton: {
      marginTop: '6px',
      padding: '6px',
    },
    title: {
      paddingRight: theme.direction === 'ltr' ? '10px' : 0,
      paddingLeft: theme.direction === 'rtl' ? '10px' : 0,
      color: theme.palette.primary.main,
    },
    categoryTitle: {
      margin: '10px 0 0',
      fontSize: '16px',
      fontWeight: 500,
      color: 'rgba(0, 0, 0, 0.6)',
    },
    smallFilters: {
      justifyContent: 'space-between',
      flexFlow: 'column-reverse',
      flexWrap: 'wrap',
      display: 'flex',
      [theme.breakpoints.up('sm')]: {
        flexFlow: 'row-reverse',
      },
      '& .MuiFormControl-root': {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          width: '30%',
        },
      },
    },
    mediumFilters: {
      justifyContent: 'space-between',
      flexFlow: theme.direction === 'rtl' ? 'row' : 'row-reverse',
      display: 'flex',
      '& .MuiFormControl-root': {
        width: '47.5%',
      },
    },
    checkbox: {
      fontSize: '16px',
      fontWeight: 500,
      '&:last-of-type': {
        marginTop: '5px !important',
      },
    },
    select: {
      '& .MuiInputBase-root': {
        borderRadius: 0,
      },
    },
    selectPopper: {
      '& .MuiPaper-root': {
        backgroundColor: theme.palette.grey[50],
        borderRadius: 0,
        boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)',
      },
      '& .MuiAutocomplete-option': {
        color: theme.palette.primary.main,
      },
    },
    filtersContent: {
      paddingBottom: '20px',
    },
    filterButtons: {
      zIndex: 2,
      position: 'sticky',
      justifyContent: 'flex-end',
      flexFlow: theme.direction === 'rtl' ? 'row-reverse' : 'row',
      display: 'flex',
      bottom: 0,
      '& .MuiButton-outlined': {
        backgroundColor: theme.palette.background.default,
        margin: theme.direction === 'rtl' ? '0 0 0 16px' : '0 16px 0 0',
      },
    },
    inputLoader: {
      position: 'absolute',
      right: 14,
    },
  }),
);

import React from 'react';
import { ProviderContext } from 'notistack';
import { QueryResponse, ResponseInterceptor } from 'react-fetching-library';
import { IntlFormatters } from 'react-intl';
import { Typography } from '@material-ui/core';

import { Action } from '../../types';
import { Link } from 'ui/link/Link';
import { AppRoute, urlBuilders } from '../../../routing/AppRoute.enum';

type ErrorInterceptorFactory = (
  enqueueSnackbar: ProviderContext['enqueueSnackbar'],
  formatMessage: IntlFormatters['formatMessage'],
) => ResponseInterceptor;

export const responseErrorInterceptor: ErrorInterceptorFactory = (enqueueSnackbar, formatMessage) => () => async (
  { signal, config: { skipErrorHandler = [400], errorHandlerKey = 'message' } = {} }: Action,
  response: QueryResponse<object>,
) => {
  if (!response.error || skipErrorHandler === true || signal?.aborted) {
    return response;
  }

  if (typeof skipErrorHandler === 'object' && response.status && skipErrorHandler.includes(response.status)) {
    return response;
  }

  const extractMessage = (response: QueryResponse): string | null => {
    if (typeof response.payload === 'object') {
      const payload = response.payload as Record<string, unknown>;
      const message = errorHandlerKey && payload[errorHandlerKey];

      if (typeof message === 'string') {
        return message;
      }

      if (typeof payload.error === 'string') {
        return payload.error;
      }
    }

    return null;
  };

  const isCriticalError = (response: QueryResponse): boolean => {
    if (response.status && response.status.toString().startsWith('5')) {
      return true;
    }

    if (typeof response.payload === 'object') {
      const payload = response.payload as Record<string, unknown>;
      return !!payload.error && !!payload.file;
    }

    return false;
  };

  const getGenericError = () => (
    <div>
      <Typography>{formatMessage({ id: 'exception.unknown.primary_text' })}</Typography>
      <Typography variant="caption">
        {formatMessage(
          { id: 'exception.unknown.secondary_text_part_1' },
          {
            part2: (
              <Link
                color="inherit"
                href={urlBuilders[AppRoute.home]('contact-us')}
                text={formatMessage({ id: 'exception.unknown.secondary_text_part_2' })}
                variant="inherit"
              />
            ),
          },
        )}
      </Typography>
    </div>
  );

  const showErrorSnackbar = (message: string | React.ReactNode) => {
    enqueueSnackbar(message, { variant: 'error' });
  };

  if (response.status === undefined || isCriticalError(response)) {
    showErrorSnackbar(getGenericError());
  } else {
    const message = extractMessage(response);
    showErrorSnackbar(message ? formatMessage({ id: message }) : getGenericError());
  }

  return response;
};

import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      position: 'relative',
      width: 240,
      display: 'inline-block',
      paddingTop: 66,
      paddingLeft: 10,
    },
    containerFullWidth: {
      width: '100%',
    },
    containerSmallNumber: {
      paddingTop: 48,
    },
    title: {
      position: 'relative',
      margin: '0 0 18px',
      fontWeight: 800,
      lineHeight: 1.33,
    },
    titleFixedHeight: {
      alignItems: 'flex-end',
      display: 'flex',
      minHeight: 62,
    },
    number: {
      zIndex: 0,
      position: 'absolute',
      top: 0,
      left: 0,
      fontSize: 138,
      lineHeight: 1,
      margin: 0,
      color: '#FAC571',
      opacity: 0.75,
      fontWeight: 'bold',
    },
    smallNumber: {
      fontSize: 82,
    },
    description: {
      lineHeight: 1.56,
      fontSize: 18,
      marginTop: 0,
      marginBottom: '15px',
      color: '#0C2714',
      letterSpacing: '0.5px',
      '& a': {
        color: theme.palette.primary.main,
        textDecoration: 'none',
      },
    },
  }),
);

import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FieldValues, UseFormOptions } from 'react-hook-form/dist/types';

import { useIsMounted } from '../useIsMounted/useIsMounted';
import { useLocale } from '../useLocale/useLocale';

type SimpleError = { code: number; message: string };
type FormError<FormValues> = Partial<Record<keyof FormValues | 'error', string>>;

export type ApiFormError<FormValues> = SimpleError | FormError<FormValues>;

function isSimpleError<FormValues>(error: ApiFormError<FormValues>): error is SimpleError {
  return typeof (error as Record<string, unknown>).code === 'number';
}

export function useApiForm<FormValues extends FieldValues = FieldValues>(
  callback: (payload: FormValues) => Promise<ApiFormError<FormValues> | undefined>,
  options?: UseFormOptions<FormValues>,
) {
  const { formatMessage } = useLocale();
  const isMounted = useIsMounted();
  const {
    register,
    handleSubmit,
    errors,
    setError,
    formState,
    watch,
    setValue,
    clearError,
    triggerValidation,
    unregister,
    reset,
    getValues,
    control,
  } = useForm<FormValues>(options);
  const [formError, setFormError] = useState<string | null>(null);
  const submitCallback = useCallback(
    async (body: FormValues) => {
      const errors = await callback(body);
      if (!isMounted()) return;
      let formError = null;

      if (errors) {
        if (isSimpleError(errors)) {
          formError = errors.message || formatMessage({ id: 'exception.unknown.primary_text' });
        } else {
          if (errors.error) {
            formError = formatMessage({ id: errors.error });
            delete errors.error;
          }
          Object.entries(errors).forEach(([key, value]) => {
            setError(key, 'server', formatMessage({ id: value }));
          });
        }
      }

      setFormError(formError);
    },
    [callback, formatMessage, isMounted, setError],
  );

  return {
    register,
    onSubmit: handleSubmit(submitCallback),
    errors,
    formError,
    formState,
    watch,
    setValue,
    clearError,
    triggerValidation,
    unregister,
    reset,
    getValues,
    control,
  };
}

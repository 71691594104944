import { createMuiTheme } from '@material-ui/core';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    extras: {
      palette: {
        grey: {
          paper: string;
        };
        success: {
          paper: string;
        };
      };
      typography: {
        body0: {
          fontSize: string;
        };
      };
    };
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    extras: {
      palette: {
        grey: {
          paper: string;
        };
        success: {
          paper: string;
        };
      };
      typography: {
        body0: {
          fontSize: string;
        };
      };
    };
  }
}

export const createAppTheme = (direction: 'ltr' | 'rtl') =>
  createMuiTheme({
    palette: {
      primary: {
        main: '#1C562E',
      },
      secondary: {
        main: '#19191952',
        light: '#FAF5EE',
        dark: '#FAC571',
      },
      background: {
        default: '#FAF5EE',
      },
      error: {
        main: '#FF0C3E',
      },
      success: {
        main: '#1C562E',
        light: '#D9E2DC',
      },
    },
    direction: direction,
    shape: {
      borderRadius: 16,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 425,
        md: 768,
        lg: 1024,
        xl: 1440,
      },
    },
    typography: {
      fontFamily: '"Frutiger LT Arabic", "Helvetica", "Arial", sans-serif',
      h5: {
        fontWeight: 500,
        lineHeight: 1.166,
      },
    },
    extras: {
      palette: {
        grey: {
          paper: '#FCFCFC',
        },
        success: {
          paper: '#EBF0ED',
        },
      },
      typography: {
        body0: {
          fontSize: '1.125rem',
        },
      },
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          body: {
            '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
              overflowX: 'hidden',
            },
          },
        },
      },
      MuiInputLabel: {
        root: {
          color: 'rgba(0, 0, 0, 0.87)',
          '&$focused': {
            color: 'rgba(0, 0, 0, 0.87)',
          },
        },
      },
      MuiSelect: {
        root: {
          fontWeight: 500,
        },
      },
      MuiOutlinedInput: {
        root: {
          borderRadius: 0,
        },
        notchedOutline: {
          borderWidth: '1px !important',
        },
      },
      MuiListItem: {
        button: {
          '&.Mui-selected': {
            color: '#1C562E',
            backgroundColor: 'rgba(28, 86, 46, 0.15)',
            '&:hover': {
              color: '#1C562E',
              backgroundColor: 'rgba(28, 86, 46, 0.15)',
            },
          },
        },
      },
      MuiDialog: {
        paper: {
          margin: 24,
        },
      },
      MuiBackdrop: {
        root: {
          backgroundColor: 'rgba(28, 86, 46, 0.9)',
        },
      },
      MuiTableCell: {
        root: {
          padding: `22px 16px`,
          height: 73,
        },
        sizeSmall: {
          padding: `8px 16px`,
          height: 48,
        },
        head: {
          fontSize: 10,
          textTransform: 'uppercase',
          color: '#747474',
          fontWeight: 'bold',
          padding: `4px 16px`,
          height: 'auto',
        },
      },
    },
  });

import React from 'react';
import { Box, Grid } from '@material-ui/core';

import { useLocale } from '../../../hooks/useLocale/useLocale';
import { AppMessages } from '../../../i18n/messages';
import { Section } from '../section/Section';
import { Attribute } from '../attribute/Attribute';
import { Link } from '../../../ui/link/Link';
import { AppRoute, urlBuilders } from '../../../routing/AppRoute.enum';

import { VendorInfoProps } from './VendorInfo.types';

export const VendorInfo = ({ vendor, vendorId }: VendorInfoProps) => {
  const { formatMessage } = useLocale();

  return (
    <Section title={formatMessage({ id: AppMessages['auction.details.vendor.title'] })}>
      <Box px={[2, 3]}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Attribute
              label={formatMessage({ id: AppMessages['auction.details.vendor.full_name_label'] })}
              value={
                vendorId ? (
                  <Link href={urlBuilders[AppRoute.vendorProfile](vendorId)} text={vendor.fullName} />
                ) : (
                  vendor.fullName
                )
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Attribute
              label={formatMessage({ id: AppMessages['auction.details.vendor.city_label'] })}
              value={vendor.city ?? '-'}
            />
          </Grid>
        </Grid>
      </Box>
    </Section>
  );
};

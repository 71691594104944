import React from 'react';
import { Grid } from '@material-ui/core';

import { AuctionPartProps, DeliveryMethodProps } from '../CreateAuction.types';
import { useLocale } from 'hooks/useLocale/useLocale';
import { getAppMessage } from 'i18n/messages';
import { Checkbox } from 'ui/checkbox/Checkbox';
import { useStyles } from '../CreateAuction.styles';

export const DeliveryMethod = ({ register, errors, validate }: AuctionPartProps & DeliveryMethodProps) => {
  const { formatMessage } = useLocale();
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Checkbox
          name="pickUp"
          label={
            <span className={classes.checkboxLabel}>
              {formatMessage({ id: getAppMessage('common.labels.pickup') })}
            </span>
          }
          error={Boolean(errors.pickUp)}
          helperText={errors.pickUp?.message}
          ref={register({ validate })}
          color="primary"
          variant="body1"
        />
      </Grid>
      <Grid item xs={12}>
        <Checkbox
          name="delivery"
          label={
            <span className={classes.checkboxLabel}>
              {formatMessage({ id: getAppMessage('common.labels.delivery') })}
            </span>
          }
          error={Boolean(errors.delivery)}
          helperText={errors.delivery?.message}
          ref={register({ validate })}
          color="primary"
          variant="body1"
        />
      </Grid>
    </Grid>
  );
};

import { UserRole } from '../constants';
import { UserDataResponse } from '../api/actions/user/userActions.types';

export function canSeeAuctionDetails(auctionData?: { vendorId: string }, userData?: UserDataResponse | null) {
  if (!userData) return undefined; // returns undefined if not enough data is available to make a decision

  const isNotAVendor = !userData.userRoles.includes(UserRole.ROLE_VENDOR);

  if (isNotAVendor) return true;

  if (!auctionData) return undefined;

  return auctionData.vendorId === userData.vendorUuid;
}

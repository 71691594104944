/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';

import { Status } from 'ui/status/Status';
import { useLocale } from 'hooks/useLocale/useLocale';
import { formatAmount } from 'helpers/formatAmount';
import { Link } from 'ui/link/Link';
import { AppRoute, urlBuilders } from 'routing/AppRoute.enum';
import { Confirmation } from 'app/auctionDetails/confirmation/Confirmation';
import { Button } from 'ui/button/Button';
import { AuctionShippingStatus, AuctionPaymentFlow } from 'api/actions/auction/auctionActions.types';

import { DesktopTableProps } from './DesktopTable.types';

export const DesktopTable = ({
  activeRowClass,
  data,
  isRtl,
  className,
  handleDeliveryConfirmation,
}: DesktopTableProps) => {
  const { formatMsg, locale } = useLocale();

  return (
    <Table className={className}>
      <TableHead>
        <TableRow>
          <TableCell align={isRtl ? 'right' : 'left'}>{formatMsg('user_profile.buyer.auction_name')}</TableCell>
          <TableCell align={isRtl ? 'right' : 'left'}>{formatMsg('user_profile.buyer.bid_amount')}</TableCell>
          <TableCell align={isRtl ? 'right' : 'left'}>{formatMsg('user_profile.buyer.vendor')}</TableCell>
          <TableCell align={isRtl ? 'right' : 'left'} />
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map(auction => (
          <TableRow key={auction.uuid}>
            <TableCell align={isRtl ? 'right' : 'left'}>
              <Link href={urlBuilders[AppRoute.auctionDetails](auction.uuid)} variant="inherit" text={auction.name} />
            </TableCell>
            <TableCell align={isRtl ? 'right' : 'left'}>
              <Typography variant="body1">
                <strong>{formatAmount(Number(auction.bidPrice) / 100, locale)}</strong>
              </Typography>
            </TableCell>
            <TableCell align={isRtl ? 'right' : 'left'}>
              <Typography variant="body2">{auction.vendorName}</Typography>
            </TableCell>
            <TableCell align={isRtl ? 'right' : 'left'}>
              {auction.shippingStatus === AuctionShippingStatus.received ? (
                <Status>{formatMsg('user_profile.buyer.delivered')}</Status>
              ) : (
                <Confirmation
                  popupId={auction.uuid}
                  titleMsg={'user_profile.buyer.confirm_delivery_popup_title'}
                  bodyMsg={'user_profile.buyer.confirm_delivery_popup_description'}
                  confirmationMsg={'user_profile.buyer.confirm'}
                  onConfirm={() => handleDeliveryConfirmation(auction.uuid)}
                >
                  {confirmationProps => (
                    <Button
                      disabled={auction.paymentFlow !== AuctionPaymentFlow.paymentCompleted}
                      variant="text"
                      text={formatMsg('user_profile.buyer.confirm_delivery')}
                      {...confirmationProps}
                    />
                  )}
                </Confirmation>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

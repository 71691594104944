import { useContext } from 'react';

import { TitleDispatchContext } from '../../context/title/titleContext/TitleContext';

export const useTitleDispatch = () => {
  const context = useContext(TitleDispatchContext);

  if (context === undefined) {
    throw new Error('useTitleDispatch must be used within an TitleContextController');
  }

  return context;
};

import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';

import { useAuthState } from 'hooks/useAuthState/useAuthState';

import { AppRoute, urlBuilders } from './AppRoute.enum';
import { AuthorizedRouteProps } from './AuthorizedRoute.types';

export const AuthorizedRoute = ({ noReturn, ...rest }: AuthorizedRouteProps) => {
  const { isAuthorized } = useAuthState();
  const { pathname, search, hash } = useLocation();

  if (isAuthorized) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Route {...rest} />;
  }

  return <Redirect to={urlBuilders[AppRoute.login](!noReturn ? [pathname, search, hash].join('') : undefined)} />;
};

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box, ClickAwayListener, Fade, Grid, Paper, Popper, Typography } from '@material-ui/core';
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state';

import { Button } from '../../../ui/button/Button';
import { useLocale } from '../../../hooks/useLocale/useLocale';

import { ConfirmationProps } from './Confirmation.types';
import { useStyles } from './Confirmation.styles';

export const Confirmation = ({
  popupId,
  titleMsg,
  bodyMsg,
  confirmationMsg,
  children,
  onConfirm,
}: ConfirmationProps) => {
  const { formatMsg } = useLocale();
  const classes = useStyles();

  return (
    <PopupState variant="popper" popupId={popupId}>
      {popupState => (
        <>
          {children(bindToggle(popupState))}
          <Popper {...bindPopper(popupState)} placement="bottom-end" transition>
            {({ TransitionProps }) => (
              <ClickAwayListener onClickAway={popupState.close}>
                <Fade {...TransitionProps} timeout={350}>
                  <Paper square className={classes.paper}>
                    <Box p={2}>
                      <Box>
                        <Typography variant="body1">
                          <strong>{formatMsg(titleMsg)}</strong>
                        </Typography>
                      </Box>
                      <Box mt={2}>
                        <Typography variant="body1" color="textSecondary">
                          {formatMsg(bodyMsg)}
                        </Typography>
                      </Box>
                      <Box mt={3}>
                        <Grid container spacing={2}>
                          <Grid item>
                            <Button
                              size="small"
                              variant="text"
                              color="secondary"
                              text={formatMsg('common.cancel')}
                              onClick={popupState.close}
                            />
                          </Grid>
                          <Grid item>
                            <Button
                              size="small"
                              variant="text"
                              text={formatMsg(confirmationMsg)}
                              onClick={() => {
                                popupState.close();
                                onConfirm();
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Paper>
                </Fade>
              </ClickAwayListener>
            )}
          </Popper>
        </>
      )}
    </PopupState>
  );
};

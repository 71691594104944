import React from 'react';
import { CssBaseline } from '@material-ui/core';

import { AppRoutes } from 'routing/AppRoutes';

export const App = () => {
  return (
    <>
      <CssBaseline />
      <AppRoutes />
    </>
  );
};

import React from 'react';
import { FormHelperText } from '@material-ui/core';

import { useLocale } from 'hooks/useLocale/useLocale';
import { Checkbox } from 'ui/checkbox/Checkbox';
import { Button } from 'ui/button/Button';
import { Link } from 'ui/link/Link';
import { getAppMessage } from 'i18n/messages';
import { AppRoute } from 'routing/AppRoute.enum';
import { useApiForm } from '../../hooks/useApiForm/useApiForm';
import { getNationalIdValidationOptions } from '../../validation/nationalId';
import { getPhoneValidationOptions } from '../../validation/phone';
import { getFullNameValidationOptions } from '../../validation/fullName';
import { getTermsValidationOptions } from '../../validation/terms';
import { NationalId } from '../../ui/fields/nationalId/NationalId';
import { Phone } from '../../ui/fields/phone/Phone';
import { Info } from 'ui/info/Info';
import { phonePrefix } from '../../constants';
import { FullName } from '../../ui/fields/fullName/FullName';
import { useScreenWidth } from 'hooks/useScreenWidth/useScreenWidth';

import { useStyles } from './Register.styles';
import { RegisterBuyerProps, RegisterBuyerFormFields } from './Register.types';

export const RegisterBuyer = ({ setFirstFieldRef, onBuyerSubmit }: RegisterBuyerProps) => {
  const classes = useStyles();
  const { isTabletDesktop } = useScreenWidth();
  const form = useApiForm<RegisterBuyerFormFields>(onBuyerSubmit, { defaultValues: { phone: phonePrefix } });
  const { formatMessage } = useLocale();

  return (
    <form className={classes.form} onSubmit={form.onSubmit}>
      {form.formError && <FormHelperText error>{form.formError}</FormHelperText>}
      <FullName
        name="fullName"
        autoFocus={isTabletDesktop}
        error={form.errors.fullName?.message}
        ref={element => {
          form.register(element, getFullNameValidationOptions({ formatMessage }));
          if (setFirstFieldRef) {
            setFirstFieldRef(element);
          }
        }}
        margin="normal"
      />
      <NationalId
        name="nationalId"
        error={form.errors.nationalId?.message}
        ref={form.register(getNationalIdValidationOptions({ formatMessage }))}
        margin="normal"
      />
      <Phone
        name="phone"
        error={form.errors.phone?.message}
        ref={form.register(getPhoneValidationOptions({ formatMessage }))}
        margin="normal"
      />
      <Info
        className={classes.formInfo}
        text={formatMessage({ id: getAppMessage('login_and_registration.buyer_registration_info') })}
      />
      <Checkbox
        className={classes.formCheckbox}
        label={formatMessage(
          { id: getAppMessage('login_and_registration.terms_and_conditions_text') },
          {
            termsAndConditionsLink: (
              <Link
                href={AppRoute.terms}
                text={formatMessage({ id: getAppMessage('login_and_registration.terms_and_conditions_link') })}
                variant="inherit"
              />
            ),
            privacyPolicyLink: (
              <Link
                href={AppRoute.privacy}
                text={formatMessage({ id: getAppMessage('login_and_registration.privacy_policy_link') })}
                variant="inherit"
              />
            ),
          },
        )}
        name="terms"
        error={!!form.errors.terms}
        helperText={formatMessage({ id: getAppMessage('validation.common.required') })}
        ref={form.register(getTermsValidationOptions({ formatMessage }))}
      />
      <Button
        text={formatMessage({ id: 'login_and_registration.continue' })}
        type="submit"
        disabled={form.formState.isSubmitting}
        color="primary"
        className={classes.button}
      />
    </form>
  );
};

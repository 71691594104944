import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  activeRow: {
    backgroundColor: 'rgba(78, 139, 67, 0.2) !important',
  },
  table: {
    '& > tbody > tr:nth-child(odd)': {
      backgroundColor: 'rgba(217, 226, 220, 0.25)',
    },
  },
}));

import { AppLocale } from '../context/locale/AppLocale.enum';

import enMessages from './data/en.json';
import saMessages from './data/sa.json';

type KeyAsValue<T> = { [P in keyof T]: P };

const keysToValues = <T extends Record<string, string | object>>(source: T): KeyAsValue<typeof source> => {
  return (Object.keys(source) as Array<keyof T>).reduce((accumulated, current) => {
    accumulated[current] = current;
    return accumulated;
  }, {} as KeyAsValue<typeof source>);
};

export const AppMessages = keysToValues(enMessages);
export const getAppMessage = (id: string) =>
  Object.keys(AppMessages).some(msgId => msgId === id)
    ? (AppMessages as {
        [key: string]: string;
      })[id]
    : id;

export type MessageKeys = keyof typeof AppMessages;

export const translations: Record<AppLocale, Record<MessageKeys, string>> = {
  [AppLocale.en]: enMessages,
  [AppLocale.ar]: (saMessages as unknown) as typeof enMessages,
};

import {
  SET_PAGE_PARAM,
  SET_FILTERS_PARAM,
  SET_ORDER_PARAM,
  SET_AUCTION_STATUS_PARAM,
  UPDATE_QUERY_PARAMS,
} from '../queryParamsReducer/queryParamsReducer';
import { QueryAction } from '../queryParamsReducer/queryParamsReducer.types';
import {
  QueryParamsStateContextValueType,
  QueryFilterParam,
  SortFilterParam,
} from '../queryParamsContext/QueryParamsContext.types';

export const setPageParam: (page: string) => QueryAction = page => {
  return {
    type: SET_PAGE_PARAM,
    page,
  };
};

export const setFiltersParam: (filter: QueryFilterParam) => QueryAction = filter => ({
  type: SET_FILTERS_PARAM,
  filter,
});

export const setOrderParam: (sort: SortFilterParam) => QueryAction = sort => ({
  type: SET_ORDER_PARAM,
  sort,
});

export const setAuctionStatusParam: (auctionStatus: string) => QueryAction = auctionStatus => ({
  type: SET_AUCTION_STATUS_PARAM,
  auctionStatus,
});

export const updateQueryParams: (params: QueryParamsStateContextValueType) => QueryAction = params => ({
  type: UPDATE_QUERY_PARAMS,
  ...params,
});

import React from 'react';
import { Box, Grid, Hidden, Paper, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';

import { ReactComponent as LogoImage } from 'assets/images/logo-saudi-date-mark.svg';
import { Attribute } from '../../auctionDetails/attribute/Attribute';
import { useLocale } from '../../../hooks/useLocale/useLocale';
import { TextList } from '../../../ui/textList/TextList';
import { Status } from '../../../ui/status/Status';
import { MessageKeys } from '../../../i18n/messages';
import { VendorType } from '../../../api/actions/vendor/vendorActions.types';

import { BasicInfoProps } from './BasicInfo.types';
import { useStyles } from './BasicInfo.styles';

const typesMap: Record<VendorType, MessageKeys> = {
  farm: 'user_profile.vendor.types.farm',
  factory: 'user_profile.vendor.types.factory',
};

const labelMap: Record<VendorType, MessageKeys> = {
  farm: 'user_profile.vendor.attributes.farm_number',
  factory: 'user_profile.vendor.attributes.factory_number',
};

export const BasicInfo = ({ vendor, dateTypes }: BasicInfoProps) => {
  const { formatMsg } = useLocale();
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const topTextItems = [vendor.city, vendor.region].filter((item): item is string => typeof item === 'string');

  return (
    <Paper elevation={0}>
      <Box px={[2, 3]} py={4}>
        <Grid container wrap="nowrap">
          <Grid item>
            <Hidden mdDown>
              <Box mr={6}>
                <LogoImage />
              </Box>
            </Hidden>
          </Grid>
          <Grid item container spacing={3}>
            <Grid item xs={12} md={8}>
              <Box className={classes.topText}>
                {topTextItems.map((item, i) => (
                  <React.Fragment key={i}>
                    {i !== 0 && <span className={clsx(classes.inlineBlock, classes.separator)}>•</span>}
                    <span className={classes.inlineBlock}>{item}</span>
                  </React.Fragment>
                ))}
              </Box>
              <Box mt={1}>
                <Typography variant="h4" component="h2">
                  <span className={classes.name}>{vendor.fullName}</span>
                  <Status className={classes.type} color={theme.palette.secondary.dark}>
                    <Typography color="textPrimary">
                      {typesMap[vendor.type] ? formatMsg(typesMap[vendor.type]) : vendor.type}
                    </Typography>
                  </Status>
                </Typography>
              </Box>
              <Box mt={4.5}>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <Attribute
                      label={formatMsg('user_profile.vendor.attributes.sdm_number')}
                      value={
                        vendor.saudiDatesMark === 'NO-SMS-CHECKING'
                          ? formatMsg('user_profile.vendor.attributes.mark_not_provided')
                          : vendor.saudiDatesMark
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Attribute
                      label={
                        matches
                          ? labelMap[vendor.type]
                            ? formatMsg(labelMap[vendor.type])
                            : vendor.type
                          : formatMsg('user_profile.vendor.attributes.register_number')
                      }
                      value={vendor.registrationNumber}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item container direction="column" wrap="nowrap" xs={12} md={4}>
              <Attribute
                label={formatMsg('user_profile.vendor.attributes.date_types')}
                value={
                  <TextList variant={matches ? 'normal' : 'inline'}>
                    {vendor.dateTypes.map(key => (
                      <React.Fragment key={key}>{dateTypes[key] ? formatMsg(dateTypes[key]) : key}</React.Fragment>
                    ))}
                  </TextList>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

import React from 'react';
import { Box } from '@material-ui/core';

import { ReactComponent as PackageImage } from 'assets/images/package.svg';
import { Dialog, DialogActions, DialogTitle } from '../../ui/dialog/Dialog';
import { Button } from '../../ui/button/Button';
import { useLocale } from '../../hooks/useLocale/useLocale';
import { AppRoute, urlBuilders } from '../../routing/AppRoute.enum';
import { formatAmount } from '../../helpers/formatAmount';

import { DeliveryReminderDialogProps } from './DeliveryReminderDialog.types';
import { useStyles } from './DeliveryReminderDialog.styles';

export const DeliveryReminderDialog = ({ buyerUuid, open, onClose, items }: DeliveryReminderDialogProps) => {
  const { formatMsg, locale } = useLocale();
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose}>
      <Box mb={4} textAlign="center">
        <PackageImage role="img" title={formatMsg('auctions.confirm_delivery.package')} />
      </Box>

      <DialogTitle text={formatMsg('auctions.confirm_delivery.confirm_purchases')} />

      <Box mt={4}>{formatMsg('auctions.confirm_delivery.auctions')}</Box>

      <Box mt={2.5}>
        <ul className={classes.list}>
          {items.map(item => (
            <li key={item.uuid}>
              {formatMsg('auctions.confirm_delivery.one_line_auction_description', {
                auctionName: <strong>{item.name}</strong>,
                bidAmount: formatAmount(Number(item.bidPrice ?? 0) / 100, locale),
                vendorName: <span>{item.vendorName}</span>,
              })}
            </li>
          ))}
        </ul>
      </Box>

      <Box mt={2.5}>
        <strong>{formatMsg('auctions.confirm_delivery.please_confirm')}</strong>
      </Box>

      <DialogActions>
        <Button
          text={formatMsg('auctions.confirm_delivery.confirm')}
          to={urlBuilders[AppRoute.buyerProfile](buyerUuid)}
          size="small"
        />
        <Button text={formatMsg('auctions.confirm_delivery.later')} variant="text" onClick={onClose} size="small" />
      </DialogActions>
    </Dialog>
  );
};

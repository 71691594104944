import React, { useRef, useEffect } from 'react';
import { Box } from '@material-ui/core';

import { LoginLayout } from '../../ui/loginLayout/LoginLayout';
import { getAppMessage } from 'i18n/messages';
import { Tabs } from 'ui/tabs/Tabs';
import { Title } from 'ui/title/Title';
import { useLocale } from 'hooks/useLocale/useLocale';
import { Link } from 'ui/link/Link';
import { AppRoute } from 'routing/AppRoute.enum';
import { useScreenWidth } from 'hooks/useScreenWidth/useScreenWidth';
import { useTitleDispatch } from '../../hooks/useTitleDispatch/useTitleDispatch';

import { RegisterProps } from './Register.types';
import { useStyles } from './Register.styles';
import { RegisterVendor } from './RegisterVendor';
import { RegisterBuyer } from './RegisterBuyer';
import { GetTheMark } from './getTheMark/GetTheMark';

export const Register = ({ onVendorSubmit, onBuyerSubmit }: RegisterProps) => {
  const classes = useStyles();
  const { isTabletDesktop } = useScreenWidth();
  const { formatMessage, formatMsg } = useLocale();
  const [type, setType] = React.useState<'vendor' | 'buyer'>('vendor');
  const setTitle = useTitleDispatch();

  useEffect(() => {
    setTitle([formatMsg('login_and_registration.create_account')]);
  }, [formatMsg, setTitle]);

  // First form field refs for focus management purpose
  const firstFieldRefs = {
    vendor: useRef<HTMLInputElement | null>(null),
    buyer: useRef<HTMLInputElement | null>(null),
  };

  const setFirstFieldRef = (value: 'vendor' | 'buyer') => (ref: HTMLInputElement | null) => {
    firstFieldRefs[value].current = ref;
  };

  // Focus on first field on tab change
  useEffect(() => {
    const firstField = firstFieldRefs[type].current;
    if (firstField && isTabletDesktop) {
      firstField.focus();
    }
  }, [firstFieldRefs, isTabletDesktop, type]);

  return (
    <LoginLayout>
      <Box mb={4}>
        <Title
          text={formatMessage({ id: getAppMessage('login_and_registration.create_account') })}
          component="h2"
          variant="h5"
        />
      </Box>
      <Box mb={2}>
        <Tabs
          labels={{
            vendor: formatMessage({ id: getAppMessage('login_and_registration.tabs.want_to_sell') }),
            buyer: formatMessage({ id: getAppMessage('login_and_registration.tabs.want_to_buy') }),
          }}
          value={type}
          onChange={(e, value) => setType(value)}
        />
      </Box>

      <Box display={type === 'vendor' ? 'block' : 'none'}>
        <RegisterVendor setFirstFieldRef={setFirstFieldRef('vendor')} onVendorSubmit={onVendorSubmit} />
      </Box>

      <Box display={type === 'buyer' ? 'block' : 'none'}>
        <RegisterBuyer setFirstFieldRef={setFirstFieldRef('buyer')} onBuyerSubmit={onBuyerSubmit} />
      </Box>

      <Box className={classes.help}>
        {formatMessage({ id: getAppMessage('login_and_registration.already_have_account') })}{' '}
        <Link
          text={formatMessage({ id: getAppMessage('login_and_registration.log_in') })}
          href={AppRoute.login}
          variant="inherit"
        />
      </Box>

      <Box display={type === 'vendor' ? 'block' : 'none'}>
        <GetTheMark />
      </Box>
    </LoginLayout>
  );
};

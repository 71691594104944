import { makeStyles, createStyles } from '@material-ui/core';

export const useCheckboxStyles = makeStyles(() =>
  createStyles({
    primaryCheckboxLabel: {
      marginRight: '6px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      textAlign: 'right',
    },
  }),
);

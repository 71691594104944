import React from 'react';
import { Typography } from '@material-ui/core';

import { auctionStatusColorDefault } from '../../../constants';

import { useStyles } from './AuctionTileStatus.styles';
import { AuctionTileStatusProps } from './AuctionTileStatus.types';

export const AuctionTileStatus = ({ color = auctionStatusColorDefault, status }: AuctionTileStatusProps) => {
  const classes = useStyles({ color });

  return <Typography classes={{ root: classes.root }}>{status}</Typography>;
};

import React, { useCallback, useEffect, useMemo } from 'react';
import { useParameterizedQuery } from 'react-fetching-library';

import { getBidsList } from '../../../api/actions/bids/bidsActions';

import { BidsList } from './BidsList';
import { BidsListContainerProps } from './BidsList.types';

export const BidsListContainer = ({
  auctionUuid,
  onAcceptBid,
  userData,
  disabled,
  refreshKey,
}: BidsListContainerProps) => {
  const { loading, query, error, payload, abort } = useParameterizedQuery(getBidsList);

  useEffect(() => {
    query({ auctionUuid, page: 1 });

    return () => abort();
  }, [refreshKey, auctionUuid, abort, query]);

  const handlePageChange = useCallback((page: number) => !loading && query({ auctionUuid, page }), [
    auctionUuid,
    loading,
    query,
  ]);

  const handleAcceptBid = useMemo(() => {
    if (!onAcceptBid) return undefined;
    return async (bidId: string) => {
      const res = await onAcceptBid(bidId);
      if (res && !res.error) query({ auctionUuid, page: 1 });
    };
  }, [auctionUuid, onAcceptBid, query]);

  return (
    <BidsList
      disabled={disabled}
      loading={loading}
      error={error}
      bids={payload}
      onPageChange={handlePageChange}
      onAcceptBid={handleAcceptBid}
      userData={userData}
    />
  );
};

import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  activeRow: {
    backgroundColor: 'rgba(78, 139, 67, 0.2) !important',
  },
  table: {
    '& > tbody > tr:nth-child(odd)': {
      backgroundColor: 'rgba(217, 226, 220, 0.25)',
    },
    '& tbody > tr > td': {
      width: 'auto',
      [theme.breakpoints.up('md')]: {
        width: '25%',
      },
      '&:last-of-type': {
        [theme.breakpoints.up('md')]: {
          textAlign: 'center',
        },
      },

      '& tbody >  tr > td': {
        width: '50%',
      },
    },
  },
  confirmBtn: {
    padding: '5px 8px',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    borderRadius: '50px',
    textAlign: theme.direction === 'rtl' ? 'right' : 'left',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
}));

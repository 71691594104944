import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    padding: '35px 0',
  },
  title: {
    padding: '0 24px 30px',
    fontSize: '24px',
    lineHeight: '48px',
    fontWeight: 'bold',
  },
  tabsNav: {
    padding: '0 24px 30px',
  },
}));

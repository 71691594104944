import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  firstDivider: {
    marginTop: theme.spacing(2),
  },
  icon: {
    padding: 0,
    marginRight: theme.spacing(1.5),
  },
  paper: {
    marginBottom: theme.spacing(4),
    padding: theme.spacing(3),
    transition: 'background-color 0.25s ease-out',
  },
  checkboxLabel: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  autocomplete: {
    borderRadius: 0,
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.87) !important',
  },
  primarySelectStyles: {
    borderRadius: 0,
    fontWeight: 500,
    borderWidth: '1px !important',
    '& fieldset': {
      borderWidth: '1px !important',
    },
  },
  formControl: {
    width: '100%',
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
  },
  selectLabel: {
    color: '#000 !important',
    borderWidth: '1px !important',
    borderColor: 'rgba(0, 0, 0, 0.23)',
  },
  selectItem: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  selectItemSelected: {
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
    },
  },
  inputLoader: {
    position: 'absolute',
    right: 14,
  },
}));

import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useAuctionsListStyles = makeStyles((theme: Theme) =>
  createStyles({
    pagination: {
      marginTop: 35,
      [theme.breakpoints.up('sm')]: {
        marginTop: 45,
      },
      [theme.breakpoints.up('md')]: {
        marginTop: 55,
      },
    },
  }),
);

import { Action } from '../../types';

import { ShortLinkResponse } from './shortLinkActions.types';

export function exchangeShortLinkHash(hash: string): Action<ShortLinkResponse> {
  return {
    method: 'GET',
    endpoint: `/v1/short-links/${hash}`,
    config: {
      skipErrorHandler: true,
    },
  };
}

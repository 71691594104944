import React from 'react';
import { Button as MuiButton } from '@material-ui/core';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { ButtonProps } from './Button.types';
import { useButtonStyles } from './Button.styles';

export const Button = ({
  type = 'button',
  text,
  icon,
  onClick,
  disabled,
  color = 'primary',
  className,
  variant = 'contained',
  size,
  to,
}: ButtonProps) => {
  const classes = useButtonStyles();
  const finalClassName = clsx(classes.primaryButton, className);
  const children = (
    <>
      {icon && <span className={classes.buttonIcon}>{icon}</span>}
      {text}
    </>
  );

  if (to)
    return (
      <MuiButton
        component={Link}
        to={to}
        variant={variant}
        color={color}
        size={size}
        disabled={disabled}
        className={finalClassName}
        children={children}
      />
    );

  return (
    <MuiButton
      type={type}
      onClick={onClick}
      variant={variant}
      color={color}
      size={size}
      disabled={!!disabled}
      className={finalClassName}
      children={children}
    />
  );
};

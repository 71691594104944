import { ValidationOptions } from 'react-hook-form';
import { IntlFormatters } from 'react-intl';

import { getAppMessage } from '../i18n/messages';

export function getTermsValidationOptions({
  formatMessage,
}: {
  formatMessage: IntlFormatters['formatMessage'];
}): ValidationOptions {
  return {
    required: formatMessage({ id: getAppMessage('validation.common.required') }),
  };
}

import React, { Component, ErrorInfo } from 'react';
import * as Sentry from '@sentry/browser';
import { Container, Typography, Box } from '@material-ui/core';

import { Alert } from '../../ui/alert/Alert';
import { useLocale } from '../../hooks/useLocale/useLocale';

import { ErrorBoundaryProps, ErrorBoundaryState } from './ErrorBoundary.types';

const ErrorBoundaryAlert = () => {
  const { formatMsg } = useLocale();

  return (
    <Alert severity="error">
      <div data-testid="error-boundary">
        <Typography>{formatMsg('exception.unknown.primary_text')}</Typography>
        <Typography variant="caption">
          {formatMsg('exception.unknown.secondary_text_part_1', {
            part2: formatMsg('exception.unknown.secondary_text_part_2'),
          })}
        </Typography>
      </div>
    </Alert>
  );
};

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (process.env.REACT_APP_SENTRY_DSN) {
      Sentry.withScope(scope => {
        scope.setExtras(errorInfo);
        Sentry.captureException(error);
      });
    }
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <Container maxWidth="md">
          <Box py={[5, 10]}>
            <ErrorBoundaryAlert />
          </Box>
        </Container>
      );
    }

    return children;
  }
}

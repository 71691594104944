import React from 'react';
import { useIntl } from 'react-intl';

import { getAppMessage } from '../../../i18n/messages';
import { Input } from '../../input/Input';
import { useInputConstraints } from '../../../hooks/useInputConstraints/useInputConstraints';

import { SdmNumberProps } from './SdmNumber.types';

/* Allow any number of digits, letters (english or arabic) and "-" characters. */
const predicate = (value: string) =>
  new RegExp(
    '^[-a-zA-Z0-9\u0600-\u06ff\u0750-\u077f\ufb50-\ufbc1\ufbd3-\ufd3f\ufd50-\ufd8f\ufd50-\ufd8f\ufe70-\ufefc\uFDF0-\uFDFD]*$',
  ).test(value);

export const SdmNumber = React.forwardRef<HTMLInputElement, SdmNumberProps>(
  ({ name, label, autoFocus, error, margin }, ref) => {
    const { formatMessage } = useIntl();
    const inputProps = useInputConstraints(predicate);

    return (
      <Input
        name={name}
        ref={ref}
        type="text"
        autoFocus={autoFocus}
        label={label || formatMessage({ id: getAppMessage('common.labels.sdm_number') })}
        error={!!error}
        helperText={error}
        margin={margin}
        inputProps={inputProps}
      />
    );
  },
);

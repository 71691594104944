import React from 'react';
import { Box, Grid, Hidden } from '@material-ui/core';
import clsx from 'clsx';

import takamol from 'assets/images/footer/takamol.png';
import ncpd from 'assets/images/footer/ncpd.png';
import { useLocale } from 'hooks/useLocale/useLocale';
import { getAppMessage } from 'i18n/messages';
import { externalLinks } from '../../../constants';
import { useHomeStyles } from '../../../app/home/Home.styles';

import { useStyles } from './Copyright.styles';

export const Copyright = () => {
  const classes = useStyles();
  const homeClasses = useHomeStyles();
  const { formatMessage } = useLocale();
  const currentYear = new Date().getFullYear();
  const text = (
    <p className={classes.text}>
      <span>
        {formatMessage({ id: getAppMessage('footer.all_rights_reserved') })}&nbsp;&copy;&nbsp;{currentYear}&nbsp;
        {formatMessage({ id: getAppMessage('footer.dates') })}
      </span>
    </p>
  );
  const takamolLogo2 = (
    <div className={classes.takamol}>
      <span className={classes.managed}>{formatMessage({ id: getAppMessage('footer.managed_by') })}</span>
      <a href={externalLinks.takamol} target="_blank" rel="noopener noreferrer">
        <img className={classes.logo} src={takamol} alt={formatMessage({ id: getAppMessage('footer.takamol') })} />
      </a>
    </div>
  );
  const ncpdLogo = (
    <a href={externalLinks.ncpd} target="_blank" rel="noopener noreferrer">
      <img
        className={clsx(classes.logo, classes.logoWide)}
        src={ncpd}
        alt={formatMessage({ id: getAppMessage('footer.dates') })}
      />
    </a>
  );

  return (
    <footer className={classes.root}>
      <Box className={homeClasses.grid}>
        <Grid container alignItems="center" justify="space-between">
          <Grid item xs={4} md="auto">
            {ncpdLogo}
          </Grid>
          <Hidden mdDown>
            <Grid item xs={4} md="auto">
              {text}
            </Grid>
          </Hidden>
          <Grid item xs={4} md="auto">
            {takamolLogo2}
          </Grid>
        </Grid>
      </Box>

      <Hidden lgUp>
        <Box className={clsx(homeClasses.grid, classes.topBorder, classes.altCopyright)}>{text}</Box>
      </Hidden>
    </footer>
  );
};

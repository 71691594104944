import React from 'react';
import { Box, Divider } from '@material-ui/core';

import { ReactComponent as Logo } from 'assets/images/logo-saudi-date-mark.svg';
import { externalLinks } from '../../../constants';
import { useLocale } from 'hooks/useLocale/useLocale';
import { getAppMessage } from 'i18n/messages';

import { useStyles } from './GetTheMark.styles';

export const GetTheMark = () => {
  const { formatMessage } = useLocale();
  const classes = useStyles();

  return (
    <>
      <Divider className={classes.divider} />
      <Box className={classes.container}>
        <Logo className={classes.logo} />
        <p className={classes.title}>
          {formatMessage({ id: getAppMessage('login_and_registration.get_the_mark.title') })}
        </p>
        <a className={classes.link} href={externalLinks.sdm} target="_blank" rel="noopener noreferrer">
          {formatMessage({ id: getAppMessage('login_and_registration.get_the_mark.link') })}
        </a>
      </Box>
    </>
  );
};

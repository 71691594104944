import { useContext } from 'react';

import { QueryParamsStateContextValueType } from 'context/queryParams/queryParamsContext/QueryParamsContext.types';
import { QueryParamsStateContext } from 'context/queryParams/queryParamsContext/QueryParamsContextController';

export const useQueryParamsState = (): QueryParamsStateContextValueType => {
  const context = useContext(QueryParamsStateContext);

  if (context === undefined) {
    throw new Error('useQueryParamsState is unavailable, make sure you are using QueryParamsContextController');
  }

  return context;
};

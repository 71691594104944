/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';
import { TextField } from '@material-ui/core';

import { InputProps } from './Input.types';
import { useInputStyles } from './Input.styles';

export const Input = forwardRef<HTMLInputElement, InputProps>(({ multiline = false, InputProps, ...rest }, ref) => {
  const classes = useInputStyles();

  return (
    <TextField
      {...rest}
      inputRef={ref}
      multiline={multiline}
      fullWidth
      variant="outlined"
      color="secondary"
      InputProps={{
        ...InputProps,
        classes: {
          ...InputProps?.classes,
          root: classes.root,
          notchedOutline: classes.primaryInput,
        },
      }}
      InputLabelProps={{
        classes: {
          root: classes.primaryInputLabel,
        },
      }}
    />
  );
});

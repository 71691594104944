import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import { AuctionTile } from 'ui/auctionTile/AuctionTile';
import { useLocale } from 'hooks/useLocale/useLocale';
import { getAuctionStatusMessage } from '../../helpers/getAuctionStatusMessage';
import { Pagination } from '../pagination/Pagination';
import { getAppMessage } from '../../i18n/messages';
import { AuctionResponse } from 'api/actions/auction/auctionActions.types';

import { useAuctionsListStyles } from './AuctionsList.styles';
import { AuctionsListProps } from './AuctionsList.types';

export const AuctionsList = ({ payload, userRole, error, loading, page, onPageChange }: AuctionsListProps) => {
  const { formatMessage } = useLocale();
  const classes = useAuctionsListStyles();

  return (
    <>
      {!error && payload && (
        <>
          {payload.data.length ? (
            <>
              <Box>
                <Grid container alignItems="stretch" spacing={2}>
                  {payload.data.map((auction: AuctionResponse) => (
                    <AuctionTile
                      key={auction.uuid}
                      uuid={auction.uuid}
                      name={auction.name}
                      mainImage={auction.mainImage ?? auction.images?.[0].main ?? null}
                      type={auction.type}
                      location={auction.location}
                      delivery={auction.delivery}
                      pickup={auction.pickup}
                      weight={auction.weight}
                      unit={auction.unit}
                      bidPrice={auction.bidPrice}
                      status={userRole ? getAuctionStatusMessage(auction, userRole) : undefined}
                      vendorId={auction.vendorId}
                    />
                  ))}
                </Grid>
              </Box>
              <Pagination
                currentPage={page}
                pagesCount={payload.pagination.pages}
                onChange={onPageChange}
                className={classes.pagination}
              />
            </>
          ) : (
            <Box>
              <Typography align="center">
                {formatMessage({ id: getAppMessage('auctions.no_auctions_found') })}
              </Typography>
            </Box>
          )}
        </>
      )}
      {!loading && error && !payload && (
        <Box>
          <Typography align="center">{formatMessage({ id: getAppMessage('auctions.request_error') })}</Typography>
        </Box>
      )}
    </>
  );
};

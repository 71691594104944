import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  box: {
    position: 'relative',
  },
  boxBefore: {
    '&::before': {
      content: "''",
      zIndex: -1,
      position: 'fixed',
      bottom: 0,
      left: theme.direction === 'ltr' ? '50%' : 'auto',
      right: theme.direction === 'rtl' ? '50%' : 'auto',
      transform: `translate(${theme.direction === 'rtl' ? '' : '-'}50%, 50%)`,
      width: 'calc(455px + 1165 * ((100vw - 320px) / 1600))',
      height: 'calc(455px + 1165 * ((100vw - 320px) / 1600))',
      backgroundColor: theme.palette.secondary.dark,
      borderRadius: '100%',
      [theme.breakpoints.up('md')]: {
        left: theme.direction === 'ltr' ? 0 : 'auto',
        right: theme.direction === 'rtl' ? 0 : 'auto',
        transform: `translate(${theme.direction === 'rtl' ? '' : '-'}25%, 58%)`,
      },
      [theme.breakpoints.up('lg')]: {
        transform: `translate(${theme.direction === 'rtl' ? '' : '-'}18%, 56%)`,
      },
    },
  },
  root: {
    margin: 0,
    padding: '0 0 45px',
    width: '100%',
    minHeight: 'calc(100vh - 130px)',
    boxSizing: 'border-box',
    overflowX: 'hidden',
    [theme.breakpoints.up('md')]: {
      padding: '40px 60px 75px',
      minHeight: 'calc(100vh - 140px)',
    },
    [theme.breakpoints.up('lg')]: {
      flexFlow: theme.direction === 'rtl' ? 'row-reverse' : 'row',
      justifyContent: 'flex-end',
      padding: '60px 75px 95px',
      minHeight: 'calc(100vh - 155px)',
    },
    [theme.breakpoints.up('xl')]: {
      padding: '80px 122px 110px',
      minHeight: 'calc(100vh - 160px)',
    },
  },
  contentBox: {
    marginTop: -12,
    marginLeft: -12,
    marginRight: -12,
    width: 'calc(100% + 24px)',
    maxWidth: 'none',
    flexBasis: 'auto',
    [theme.breakpoints.up('lg')]: {
      margin: 0,
      width: '50%',
      maxWidth: '100%',
      flexBasis: '50%',
    },

    '&:first-of-type > .MuiPaper-root': {
      [theme.breakpoints.down('sm')]: {
        borderRadius: 0,
      },
    },
  },
  title: {
    fontWeight: 'bold',
    padding: `${theme.spacing(2)}px 0`,
  },
  label: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    marginBottom: 0,
  },
  field: {
    marginTop: theme.spacing(1),
    fontSize: '1rem',
    display: 'block',
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
  buttons: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
  },
  button: {
    padding: `${theme.spacing(1.5)}px ${theme.spacing(10)}px`,
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(2),
    },
  },
  autocomplete: {
    borderRadius: 0,
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.87) !important',
  },
  selectItem: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

import React from 'react';
import { Modal as MuiModal } from '@material-ui/core';

import { ModalProps } from './Modal.types';
import { useModalStyles } from './Modal.styles';

export const Modal = ({ open, onClose, children }: ModalProps) => {
  const classes = useModalStyles();

  return (
    <MuiModal
      open={open}
      onClose={onClose}
      BackdropProps={{
        className: classes.backdrop,
      }}
    >
      {children}
    </MuiModal>
  );
};

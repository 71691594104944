import React from 'react';
import { Card as MuiCard } from '@material-ui/core';

import { CardProps } from './Card.types';
import { useCardStyles } from './Card.styles';

export const Card = ({ children }: CardProps) => {
  const classes = useCardStyles();

  return <MuiCard className={classes.card}>{children}</MuiCard>;
};

/* eslint-disable prefer-spread */
import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { Title } from 'ui/title/Title';
import { Navigation } from 'ui/navigation/Navigation';
import { useLocale } from 'hooks/useLocale/useLocale';
import { getAppMessage } from 'i18n/messages';
import { Footer } from 'ui/footer/Footer';
import { AppRoute } from 'routing/AppRoute.enum';
import { Link } from 'ui/link/Link';
import { useTitleDispatch } from '../../hooks/useTitleDispatch/useTitleDispatch';

import { useStyles } from './TermsAndConditions.styles';

export const TermsAndConditions = () => {
  const setTitle = useTitleDispatch();
  const { formatMessage, formatMsg } = useLocale();
  const classes = useStyles();

  useEffect(() => {
    setTitle([formatMsg('terms_and_conditions.title')]);
  }, [formatMsg, setTitle]);

  const items = {
    // [sectionNumber, numberOfElements]
    sectionTwo: [2, 1],
    sectionFour: [4, 15],
    sectionFive: [5, 3],
  };

  const getListItems = ([section, items]: number[]) => {
    const listItems = [];
    for (let i = 0; i < items; i++) {
      listItems.push(
        <li className={classes.listItem} key={i}>
          <p>
            <FormattedMessage
              id={getAppMessage(`terms_and_conditions.section${section}.list.${i}`)}
              values={{
                a: (chunks: string) => (
                  <Link color="inherit" underline="hover" href={AppRoute.home} className={classes.link}>
                    {chunks}
                  </Link>
                ),
                s: (chunks: string) => <strong>{chunks}</strong>,
              }}
            />
          </p>
        </li>,
      );
    }
    return listItems;
  };

  const section2List = getListItems(items.sectionTwo);
  const section4List = getListItems(items.sectionFour);
  const section5List = getListItems(items.sectionFive);

  return (
    <>
      <Navigation variant="overlay" />
      <Box className={classes.root}>
        <Box>
          <Title
            text={formatMessage({ id: getAppMessage('terms_and_conditions.title') })}
            component="h1"
            variant="h2"
            className={classes.header1}
          />
        </Box>
        <Box>
          <Title
            component="h3"
            className={classes.header3}
            variant="h5"
            text={formatMessage({ id: getAppMessage('terms_and_conditions.section1.t1') })}
          />
          <p>{formatMessage({ id: getAppMessage('terms_and_conditions.section1.p1') })}</p>
          <p>
            <FormattedMessage
              id={getAppMessage('terms_and_conditions.section1.p2')}
              values={{
                a: (chunks: string) => (
                  <Link color="inherit" underline="hover" href={AppRoute.home} className={classes.link}>
                    {chunks}
                  </Link>
                ),
              }}
            />
          </p>
          <p>{formatMessage({ id: getAppMessage('terms_and_conditions.section1.p3') })}</p>
          <p>{formatMessage({ id: getAppMessage('terms_and_conditions.section1.p4') })}</p>
        </Box>
        <Box>
          <Title
            component="h3"
            className={classes.header3}
            variant="h5"
            text={formatMessage({ id: getAppMessage('terms_and_conditions.section2.t1') })}
          />
          <p>{formatMessage({ id: getAppMessage('terms_and_conditions.section2.list.title') })}</p>
          <ul>{section2List}</ul>
        </Box>
        <Box>
          <Title
            component="h3"
            className={classes.header3}
            variant="h5"
            text={formatMessage({ id: getAppMessage('terms_and_conditions.section3.t1') })}
          />
          <p>
            <FormattedMessage
              id={getAppMessage('terms_and_conditions.section3.p1')}
              values={{
                a: (chunks: string) => (
                  <Link color="inherit" underline="hover" href={AppRoute.home} className={classes.link}>
                    {chunks}
                  </Link>
                ),
              }}
            />
          </p>
        </Box>
        <Box>
          <Title
            component="h3"
            className={classes.header3}
            variant="h5"
            text={formatMessage({ id: getAppMessage('terms_and_conditions.section4.t1') })}
          />
          <p>{formatMessage({ id: getAppMessage('terms_and_conditions.section4.list.title1') })}</p>
          <ul>{section4List}</ul>
          <p>{formatMessage({ id: getAppMessage('terms_and_conditions.section4.list.title2') })}</p>
        </Box>
        <Box>
          <Title
            component="h3"
            className={classes.header3}
            variant="h5"
            text={formatMessage({ id: getAppMessage('terms_and_conditions.section5.t1') })}
          />
          <p>{formatMessage({ id: getAppMessage('terms_and_conditions.section5.list.title') })}</p>
          <ul>{section5List}</ul>
        </Box>
        <Box>
          <Title
            component="h3"
            className={classes.header3}
            variant="h5"
            text={formatMessage({ id: getAppMessage('terms_and_conditions.section6.t1') })}
          />
          <p>{formatMessage({ id: getAppMessage('terms_and_conditions.section6.p1') })}</p>
          <p>{formatMessage({ id: getAppMessage('terms_and_conditions.section6.p2') })}</p>
          <p>{formatMessage({ id: getAppMessage('terms_and_conditions.section6.p3') })}</p>
          <p>{formatMessage({ id: getAppMessage('terms_and_conditions.section6.p4') })}</p>
        </Box>
        <Box>
          <Title
            component="h3"
            className={classes.header3}
            variant="h5"
            text={formatMessage({ id: getAppMessage('terms_and_conditions.section7.t1') })}
          />
          <p className={classes.noBottomMargin}>
            {formatMessage({ id: getAppMessage('terms_and_conditions.section7.p1') })}
          </p>
          <p className={classes.noTopMargin}>
            {formatMessage({ id: getAppMessage('terms_and_conditions.section7.p2') })}
          </p>
          <p>{formatMessage({ id: getAppMessage('terms_and_conditions.section7.p3') })}</p>
          <p>{formatMessage({ id: getAppMessage('terms_and_conditions.section7.p4') })}</p>
        </Box>
        <Box>
          <Title
            component="h3"
            className={classes.header3}
            variant="h5"
            text={formatMessage({ id: getAppMessage('terms_and_conditions.section8.t1') })}
          />
          <p>{formatMessage({ id: getAppMessage('terms_and_conditions.section8.p1') })}</p>
        </Box>
      </Box>
      <Box>
        <Footer />
      </Box>
    </>
  );
};

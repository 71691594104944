import { Action } from 'react-fetching-library';
import qs from 'qs';

import { qsApiOptions } from '../../../constants';

import { BidInfoResponse, BidsResponse } from './bidsActions.types';

export function getBidsList({ auctionUuid, page }: { auctionUuid: string; page?: number }): Action<BidsResponse> {
  return {
    method: 'GET',
    endpoint: `/v1/bids/auction/${auctionUuid}${qs.stringify({ page }, qsApiOptions)}`,
  };
}

export function makeBid(body: { auctionId: string; value: number }): Action {
  return {
    method: 'POST',
    endpoint: '/v1/bids/make',
    body: body,
  };
}

export function acceptBid(bidUuid: string): Action {
  return {
    method: 'PUT',
    endpoint: `/v1/bids/${bidUuid}/accept`,
  };
}

export function editBid({ bidUuid, value }: { bidUuid: string; value: number }): Action {
  return {
    method: 'PUT',
    endpoint: `/v1/bids/${bidUuid}/edit`,
    body: {
      value,
    },
  };
}

export function removeBid(bidUuid: string): Action {
  return {
    method: 'PUT',
    endpoint: `/v1/bids/${bidUuid}/remove`,
  };
}

export function getCurrentUserBidInfo(auctionUuid: string): Action<BidInfoResponse> {
  return {
    method: 'GET',
    endpoint: `/v1/bids/details/auction/${auctionUuid}`,
    config: {
      skipErrorHandler: true,
    },
  };
}

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useIntl } from 'react-intl';

import { getAppMessage } from '../../../i18n/messages';
import { Input } from '../../input/Input';
import { useInputConstraints } from '../../../hooks/useInputConstraints/useInputConstraints';

import { PhoneProps } from './Phone.types';

/* Allow from 0 to 10 digits. */
const predicate = (value: string) => new RegExp('^[0-9]{0,10}$').test(value);

export const Phone = React.forwardRef<HTMLInputElement, PhoneProps>(
  ({ name, label, autoFocus, error, margin }, ref) => {
    const { formatMessage } = useIntl();
    const inputProps = useInputConstraints(predicate);

    return (
      <Input
        type="tel"
        ref={ref}
        name={name}
        label={label || formatMessage({ id: getAppMessage('common.labels.phone_number') })}
        autoFocus={autoFocus}
        error={!!error}
        helperText={error}
        margin={margin}
        inputProps={inputProps}
      />
    );
  },
);

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    borderRadius: theme.spacing(1),
    fontSize: 16,
    lineHeight: 1.25,
    color: 'rgba(0,0,0,.6)',
  },
  alertIcon: {
    boxSizing: 'content-box',
    fontSize: theme.spacing(3),
    padding: theme.spacing(1),
    borderRadius: '50%',
  },
  alertWarningIcon: {
    backgroundColor: 'rgba(239, 197, 105, 0.2)',
  },
  alertSuccessIcon: {
    backgroundColor: 'rgba(28, 86, 46, 0.1)',
  },
  alertErrorIcon: {
    backgroundColor: 'rgba(255, 0, 0, 0.1)',
  },
}));

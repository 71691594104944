import React from 'react';
import { Box, Typography, Paper } from '@material-ui/core';

import { prodUrl } from '../../constants';

export const VersionInfo =
  process.env.REACT_APP_VERSION && !window.location.href.includes(prodUrl)
    ? () => {
        return (
          <Paper
            style={{ opacity: 0.75 }}
            data-semver={
              process.env.REACT_APP_BUILD_META
                ? `${process.env.REACT_APP_VERSION}+${process.env.REACT_APP_BUILD_META}`
                : process.env.REACT_APP_VERSION
            }
          >
            <Box px={1} py={0.5}>
              <Typography variant="caption">v{process.env.REACT_APP_VERSION}</Typography>
            </Box>
          </Paper>
        );
      }
    : () => null;

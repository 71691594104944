import { makeStyles, Theme } from '@material-ui/core';

import { StatusProps } from './Status.types';

export const useStyles = makeStyles<Theme, StatusProps>(theme => ({
  root: {
    display: 'inline-block',
    color: theme.palette.common.white,
    backgroundColor: props => props.color,
    padding: `${theme.spacing(0.25)}px ${theme.spacing(1)}px`,
    borderRadius: theme.spacing(0.5),
    lineHeight: 1.75,
  },
}));

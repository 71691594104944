import React, { useState } from 'react';
import { Box, AppBar, Slide, useScrollTrigger } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

import logoImage from 'assets/images/logo-desktop.svg';
import { IconButton } from 'ui/iconButton/IconButton';
import { AppRoute } from 'routing/AppRoute.enum';
import { getAppMessage } from 'i18n/messages';
import { DrawerComponent } from 'ui/drawer/Drawer';
import { Status } from 'ui/status/Status';
import { UserRole } from '../../constants';
import { useUserData } from 'hooks/useUserData/useUserData';

import { useNavigationStyles } from './Navigation.styles';
import { NavigationLinks } from './NavigationLinks';
import { NavigationProps } from './Navigation.types';

export const Navigation = ({ children, variant = 'solid' }: NavigationProps) => {
  const trigger = useScrollTrigger({ target: window });
  const isNavOnTop = useScrollTrigger({ target: window, disableHysteresis: true, threshold: 0 });
  const { formatMessage } = useIntl();
  const [mobileNavVisibility, setMobileNavVisibility] = useState<boolean>(false);
  const userData = useUserData();
  const isAdmin = userData?.userRoles.includes(UserRole.ROLE_ADMIN);

  const classes = useNavigationStyles();
  const logoAlt = formatMessage({ id: getAppMessage('common.logo') });

  return (
    <Box className={classes[variant]}>
      <Slide appear={false} direction="down" in={!trigger}>
        <AppBar elevation={0} className={classes.appBar}>
          <Box className={clsx(classes.root, { [classes.rootScrolled]: isNavOnTop, [classes.rootAdmin]: isAdmin })}>
            <IconButton className={classes.navButton} color="primary" onClick={() => setMobileNavVisibility(true)}>
              <MenuIcon />
            </IconButton>
            <Box className={clsx(classes.navLinks, classes.navLinksDesktop)}>
              <NavigationLinks isAdmin={isAdmin} />
            </Box>
            {isAdmin && (
              <Status className={classes.adminLabel}>
                {formatMessage({ id: getAppMessage('common.navigation.admin_label') })}
              </Status>
            )}
            <NavLink to={AppRoute.home} onClick={() => setMobileNavVisibility(false)}>
              <img className={classes.logo} src={logoImage} alt={logoAlt} />
            </NavLink>
          </Box>
        </AppBar>
      </Slide>
      <DrawerComponent open={mobileNavVisibility} className={clsx(classes.root, classes.mobileNav)} anchor="top">
        <IconButton
          className={clsx(classes.navButton, classes.navButtonClose)}
          type="button"
          onClick={() => setMobileNavVisibility(false)}
        >
          <CloseIcon color="primary" />
        </IconButton>
        <Box className={clsx(classes.navLinks, classes.navLinksMobile)}>
          <NavigationLinks isAdmin={isAdmin} onClick={() => setMobileNavVisibility(false)} />
        </Box>
      </DrawerComponent>
      <Box className={clsx(classes.padding, { [classes.paddingAdmin]: isAdmin })} />
      {children}
    </Box>
  );
};

import { Reducer } from 'react';

import { ReducerAction, ReducerActionType, ReducerState, LoginHelpSteps } from './LoginHelp.types';

export const reducerInitialState: ReducerState = {
  step: LoginHelpSteps.Phone,
  username: null,
  uuid: null,
  smsPassword: null,
};

export const reducer: Reducer<ReducerState, ReducerAction> = (prevState, action) => {
  switch (action.type) {
    case ReducerActionType.GoToStep2:
      return { step: LoginHelpSteps.SecurityCode, username: action.username, uuid: action.uuid, smsPassword: null };
    case ReducerActionType.ResendSecurityCode:
      return { step: LoginHelpSteps.SecurityCode, username: action.username, uuid: action.uuid, smsPassword: null };
    case ReducerActionType.GoToStep3:
      return {
        step: LoginHelpSteps.NewPassword,
        username: action.username,
        uuid: action.uuid,
        smsPassword: action.smsPassword,
      };
    default:
      return prevState;
  }
};

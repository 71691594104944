/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { usePagination } from '@material-ui/lab/Pagination';
import { useIntl } from 'react-intl';
import clsx from 'clsx';

import { getAppMessage } from 'i18n/messages';
import { useScreenWidth } from 'hooks/useScreenWidth/useScreenWidth';

import { usePaginationStyles } from './Pagination.styles';
import { PaginationProps } from './Pagination.types';

export const Pagination = ({ pagesCount, onChange, className, currentPage }: PaginationProps) => {
  const { formatMessage } = useIntl();
  const classes = usePaginationStyles();
  const { isTabletDesktop } = useScreenWidth();

  const handleChange = (page: number) => {
    onChange(page);
  };

  const { items } = usePagination({
    page: currentPage,
    count: pagesCount,
    onChange: (e: React.ChangeEvent<unknown>, page: number) => handleChange(page),
    boundaryCount: 1,
    siblingCount: isTabletDesktop ? 1 : 0,
  });

  return (
    <nav>
      <ul className={clsx(classes.root, className)}>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;

          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = <div className={classes.paginationItem}>...</div>;
          } else if (type === 'page') {
            children = (
              <button
                className={clsx(classes.paginationItem, selected ? classes.paginationItemSelected : '')}
                type="button"
                {...item}
              >
                {page}
              </button>
            );
          } else {
            children = (
              <button className={clsx(classes.paginationItem, classes.paginationTextItem)} type="button" {...item}>
                {type === 'next'
                  ? formatMessage({ id: getAppMessage('common.pagination.next') })
                  : formatMessage({ id: getAppMessage('common.pagination.previous') })}
              </button>
            );
          }

          return <li key={index}>{children}</li>;
        })}
      </ul>
    </nav>
  );
};

import { City } from 'api/actions/cities/citiesActions.types';
import { AppLocale } from '../context/locale/AppLocale.enum';

export const getIdFromCityName = (value: string, cities: City[]): number => {
  const city = cities.find(city => {
    return city.nameAr === value || city.nameEn === value;
  });

  return city?.id || 0;
};

export const getNameFromCityId = (id: number | null, cities: City[], locale: AppLocale): string => {
  if (id === null) {
    return '';
  }
  const city = cities.find(city => city.id === id);
  if (!city) {
    return '';
  }
  if (locale === AppLocale.en) {
    return city.nameEn;
  } else {
    return city.nameAr;
  }
};

import React from 'react';
import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { Button } from 'ui/button/Button';
import { Title } from 'ui/title/Title';
import { Link } from 'ui/link/Link';

import { useTextImageSectionStyles } from './TextImageSection.styles';
import { TextImageSectionProps } from './TextImageSection.types';

export const TextImageSection = ({
  smallTitle,
  title,
  description,
  image,
  imageAlt,
  btnText,
  link,
  reversed,
  additionalLink,
}: TextImageSectionProps) => {
  const classes = useTextImageSectionStyles();

  return (
    <Box className={clsx(classes.root, reversed ? classes.rootReversed : '')}>
      <Box className={clsx(classes.content, reversed ? classes.contentReversed : '')}>
        <Title text={title} component="h2" variant="h3" className={classes.title} />
        <Typography className={classes.smallTitle}>{smallTitle}</Typography>
        <img src={image} alt={imageAlt} className={classes.imgMobile} />
        <Typography className={classes.description}>{description}</Typography>
        <Box className={classes.links}>
          <Link href={link} className={clsx(classes.link, additionalLink ? classes.linkMargin : '')}>
            <Button text={btnText} className={classes.btn} />
          </Link>
          {additionalLink && (
            <a className={classes.additionalLink} href={additionalLink.link} target="_blank" rel="noopener noreferrer">
              {additionalLink.text}
            </a>
          )}
        </Box>
      </Box>
      <img
        src={image}
        alt={imageAlt}
        className={clsx(classes.imgDesktop, reversed ? classes.imgDesktopReversed : '')}
      />
    </Box>
  );
};

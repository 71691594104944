import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import clsx from 'clsx';

import { InfoProps } from './Info.types';
import { useInfoStyles } from './Info.styles';

export const Info = ({ elevation = 0, text, className }: InfoProps) => {
  const styles = useInfoStyles();

  return (
    <Paper elevation={elevation} className={clsx(styles.infoPaper, className)}>
      <Typography className={styles.infoText}>{text}</Typography>
      <InfoIcon className={styles.infoIcon} />
    </Paper>
  );
};

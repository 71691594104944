import React from 'react';
import clsx from 'clsx';

import { TextListProps } from './TextList.types';
import { useStyles } from './TextList.styles';

export const TextList = ({ children, className, variant = 'normal' }: TextListProps) => {
  const classes = useStyles();

  return (
    <ul className={clsx(classes.root, classes[variant], className)}>
      {React.Children.map(children, child => (
        <li className={classes.item}>
          <span className={classes.itemContent}>{child}</span>
        </li>
      ))}
    </ul>
  );
};

import React, { useCallback, useMemo, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useClient } from 'react-fetching-library';
import { saveAs } from 'file-saver';

import { useLocale } from '../../../hooks/useLocale/useLocale';
import { getAppMessage } from '../../../i18n/messages';
import { ReactComponent as InvoiceImage } from '../../../assets/images/invoice.svg';
import { Placeholder } from '../placeholder/Placeholder';
import { AuctionPaymentFlow } from '../../../api/actions/auction/auctionActions.types';
import { Section } from '../section/Section';
import { Alert } from '../../../ui/alert/Alert';
import { Button } from '../../../ui/button/Button';
import { File } from '../../../ui/file/File';
import { getPaymentConfirmationUrl } from '../../../api/actions/auction/auctionActions';

import { PaymentsProps } from './Payments.types';

export const Payments = ({ disabled, auction, onConfirmPayment, onRejectPayment }: PaymentsProps) => {
  const client = useClient();
  const { formatMessage } = useLocale();
  const title = formatMessage({ id: getAppMessage('auction.details.payments.title') });
  const { buyerFee } = auction;
  const [isBusy, setIsBusy] = useState(false);

  const handleDownload = useCallback(
    async (filename: string) => {
      setIsBusy(true);
      const urlRes = await client.query(getPaymentConfirmationUrl({ auctionUuid: auction.uuid }));
      if (!urlRes.error && urlRes.payload) {
        window.open(urlRes.payload.url, '_blank');
        const blob = await fetch(urlRes.payload.url).then(response => response.blob());
        saveAs(blob, filename);
      }
      setIsBusy(false);
    },
    [auction.uuid, client],
  );

  const file = useMemo(
    () =>
      buyerFee ? (
        <Box mb={3}>
          <File
            name={buyerFee}
            action={
              <Button
                disabled={disabled || isBusy}
                variant="text"
                size="small"
                onClick={() => handleDownload(buyerFee)}
                text={formatMessage({ id: getAppMessage('common.download') })}
              />
            }
          />
        </Box>
      ) : null,
    [buyerFee, disabled, formatMessage, handleDownload, isBusy],
  );

  switch (auction.paymentFlow) {
    case AuctionPaymentFlow.none:
    case AuctionPaymentFlow.waitingForBuyerPaymentConfirmation:
      return (
        <Section disabled title={title}>
          <Placeholder>
            <InvoiceImage role="img" title={formatMessage({ id: getAppMessage('auction.details.payments.invoice') })} />
            <Typography>{formatMessage({ id: getAppMessage('auction.details.payments.placeholder') })}</Typography>
          </Placeholder>
        </Section>
      );
    case AuctionPaymentFlow.waitingForVendorPaymentConfirmation:
      return (
        <Section title={title}>
          <Box px={[2, 3]}>
            {file}
            <Box mb={4}>
              <Alert severity="success">
                {formatMessage({ id: getAppMessage('auction.details.payments.alert_pending') })}
              </Alert>
            </Box>
            <Grid container spacing={3}>
              <Grid item>
                <Button
                  disabled={disabled}
                  size="small"
                  onClick={onConfirmPayment}
                  text={formatMessage({ id: getAppMessage('auction.details.payments.confirm') })}
                />
              </Grid>
              <Grid item>
                <Button
                  disabled={disabled}
                  variant="text"
                  size="small"
                  onClick={onRejectPayment}
                  text={formatMessage({ id: getAppMessage('auction.details.payments.reject') })}
                />
              </Grid>
            </Grid>
          </Box>
        </Section>
      );
    default:
      return (
        <Section title={title}>
          <Box px={[2, 3]}>
            {file}
            <Box mb={4}>
              <Alert severity="success">
                {formatMessage({ id: getAppMessage('auction.details.payments.alert_received') })}
              </Alert>
            </Box>
          </Box>
        </Section>
      );
  }
};

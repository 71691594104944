import { DebugOption } from '../../../constants';

const ACCESS_TOKEN_KEY = 'accessToken';
const REFRESH_TOKEN_KEY = 'refreshToken';

class AuthStorage {
  webStorage: Storage;

  constructor(useLocalStorage: boolean) {
    this.webStorage = useLocalStorage ? localStorage : sessionStorage;
  }

  get accessToken(): string | null {
    try {
      return this.webStorage.getItem(ACCESS_TOKEN_KEY);
    } catch {
      return null;
    }
  }

  set accessToken(value: string | null) {
    try {
      if (typeof value === 'string') {
        this.webStorage.setItem(ACCESS_TOKEN_KEY, value);
      } else {
        this.webStorage.removeItem(ACCESS_TOKEN_KEY);
      }
    } catch (error) {}
  }

  get refreshToken(): string | null {
    try {
      return this.webStorage.getItem(REFRESH_TOKEN_KEY);
    } catch {
      return null;
    }
  }

  set refreshToken(value: string | null) {
    try {
      if (typeof value === 'string') {
        this.webStorage.setItem(REFRESH_TOKEN_KEY, value);
      } else {
        this.webStorage.removeItem(REFRESH_TOKEN_KEY);
      }
    } catch (error) {}
  }
}

export const authStorage = new AuthStorage(localStorage.getItem(DebugOption.authStorage) !== 'session');

import { Action } from '../../types';

import { Dictionaries } from './dictionariesActions.types';

export const getDictionaries = (): Action<Dictionaries> => {
  return {
    method: 'GET',
    endpoint: '/v1/dictionaries',
    config: {
      cacheResponse: true,
      skipErrorHandler: false,
    },
  };
};

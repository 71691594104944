import { electronicFormatIBAN } from 'ibantools';

import { Action } from '../../types';
import { AuctionsPayload } from '../auctions/auctionsActions.types';

import {
  BankAccountInfoPayload,
  UserDataResponse,
  UserWonAuctionsResponse,
  MyDeliveryRemindersResponse,
} from './userActions.types';

export function getMe(): Action<UserDataResponse> {
  return {
    method: 'GET',
    endpoint: '/v1/me',
    config: {
      skipErrorHandler: false,
    },
  };
}

export function getMyWonAuctions(page: number): Action<UserWonAuctionsResponse> {
  return {
    method: 'GET',
    endpoint: `/v1/buyers/auction/list?page=${page}`,
  };
}

export function getMyDeliveryReminders(): Action<MyDeliveryRemindersResponse> {
  return {
    method: 'GET',
    endpoint: '/v1/buyers/auctions/paid',
  };
}

export function saveBankAccountInfo(vendorUuid: string, body: BankAccountInfoPayload): Action {
  return {
    method: 'PUT',
    endpoint: `/v1/vendors/${vendorUuid}/bank`,
    body: {
      bankBeneficientName: body.bankBeneficientName,
      bankIban: electronicFormatIBAN(body.bankIban),
      bankId: Number(body.bankId),
      bankAcknowledge: body.bankAcknowledge,
    },
  };
}

export function getMyCurrentBids(page: number): Action<AuctionsPayload> {
  return {
    method: 'GET',
    endpoint: `/v1/buyers/bid/list?page=${page}`,
  };
}

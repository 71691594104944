import React, { useEffect } from 'react';
import { FormHelperText } from '@material-ui/core';

import { sdmPhoneNumber } from '../../../constants';
import { useLocale } from 'hooks/useLocale/useLocale';
import { getAppMessage } from 'i18n/messages';
import { LoginLayout } from 'ui/loginLayout/LoginLayout';
import { Title } from 'ui/title/Title';
import { Button } from 'ui/button/Button';
import { useLoginStepStyles } from 'app/login/Login.styles';
import { useApiForm } from '../../../hooks/useApiForm/useApiForm';
import { getNewPasswordValidationOptions } from '../../../validation/newPassword';
import { Password } from '../../../ui/fields/password/Password';
import { useTitleDispatch } from '../../../hooks/useTitleDispatch/useTitleDispatch';
import { ExternalLink } from 'ui/link/externalLink/ExternalLink';

import { SetPasswordProps, SetPasswordFormFields } from './SetPassword.types';

export const SetPassword = ({ onSubmit }: SetPasswordProps) => {
  const classes = useLoginStepStyles();
  const { formatMessage, formatMsg } = useLocale();
  const form = useApiForm<SetPasswordFormFields>(onSubmit);
  const setTitle = useTitleDispatch();

  useEffect(() => {
    setTitle([formatMsg('login_and_registration.set_password.title')]);
  }, [formatMsg, setTitle]);

  return (
    <LoginLayout>
      <Title
        component="h1"
        variant="h5"
        text={formatMessage({ id: getAppMessage('login_and_registration.set_password.title') })}
      />
      <p className={classes.description}>
        {formatMessage({ id: getAppMessage('login_and_registration.set_password.description') })}
      </p>
      <form className={classes.form} onSubmit={form.onSubmit}>
        {form.formError && <FormHelperText error>{form.formError}</FormHelperText>}
        <Password
          name="plainPassword"
          error={form.errors.plainPassword?.message}
          ref={form.register(getNewPasswordValidationOptions({ formatMessage }))}
          margin="normal"
        />
        <Button
          className={classes.button}
          type="submit"
          disabled={form.formState.isSubmitting}
          text={formatMessage({ id: getAppMessage('login_and_registration.set_password.submit') })}
        />
      </form>
      <p className={classes.help}>
        {formatMessage({ id: getAppMessage('login_and_registration.set_password.help') })}{' '}
        <ExternalLink href={`tel:+${sdmPhoneNumber}`}>
          {formatMessage({ id: getAppMessage('login_and_registration.set_password.contact') })}
        </ExternalLink>
      </p>
    </LoginLayout>
  );
};

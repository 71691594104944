import { createStyles, makeStyles, Theme } from '@material-ui/core';

import backgroundImage from 'assets/images/background-login.jpg';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up('md')]: {
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    background: {
      backgroundImage: `url("${backgroundImage}")`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'bottom left',
    },
    desktopLogo: {
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      width: 275,
      marginTop: 56,
      marginInlineEnd: 'auto',
      marginLeft: 61,
      cursor: 'pointer',
    },
    mobileLogoWrapper: {
      display: 'flex',
      alignItems: 'center',
      minHeight: 80,
    },
    mobileLogo: {
      height: 40,
      cursor: 'pointer',
      width: 170,
    },
    side: {
      height: '100%',
      overflowY: 'auto',
      position: 'relative',
    },
    content: {
      width: 496,
    },
    hiddenDownSm: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    hiddenUpMd: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    versionInfoWrapper: {
      padding: theme.spacing(3),
      marginInlineStart: 'auto',
      marginInlineEnd: 0,
    },
  }),
);

import { User } from '../context/auth/auth.types';
import { UserRole } from '../constants';

export const getUserRole = (user: User) => {
  // we assume only one role per user is possible
  if (user.roles.includes(UserRole.ROLE_ADMIN)) {
    return UserRole.ROLE_ADMIN;
  } else if (user.roles.includes(UserRole.ROLE_BUYER)) {
    return UserRole.ROLE_BUYER;
  } else if (user.roles.includes(UserRole.ROLE_VENDOR)) {
    return UserRole.ROLE_VENDOR;
  }

  return null;
};

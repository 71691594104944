import React, { useCallback } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import qs from 'qs';

import { AppRoute } from 'routing/AppRoute.enum';
import { useAuthorize } from '../../hooks/useAuthorize/useAuthorize';

import { Login } from './Login';
import { LoginFormFields } from './Login.types';

export const LoginContainer = () => {
  const { isAuthorized, authorize } = useAuthorize();
  const { search } = useLocation();

  const onSubmit = useCallback((body: LoginFormFields) => authorize(body.phone, body.password, body.type), [authorize]);

  if (isAuthorized) {
    const { route } = qs.parse(search, { ignoreQueryPrefix: true });
    return <Redirect to={typeof route === 'string' ? route : AppRoute.auctionsList} />;
  }

  return <Login onSubmit={onSubmit} />;
};

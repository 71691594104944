import {
  GenerateBuyerCodePayload,
  GenerateVendorCodePayload,
} from '../../api/actions/registration/registrationActions.types';
import { ApiFormError } from '../../hooks/useApiForm/useApiForm';

export type RegisterVendorFormFields = {
  phone: string;
  saudiDatesMark: string;
  terms: boolean;
};

export type RegisterBuyerFormFields = {
  fullName: string;
  nationalId: string;
  phone: string;
  terms: boolean;
};

export enum RegistrationSteps {
  AccountInfo,
  OneTimePassword,
  SetPassword,
}

export enum ReducerActionType {
  GoToStep2,
  ResendSecurityCode,
  GoToStep3,
  SkipStep2,
}

export type ReducerAction =
  | {
      type: ReducerActionType.GoToStep2;
      accountType: 'buyer';
      accountInfo: GenerateBuyerCodePayload;
      uuid: string;
    }
  | {
      type: ReducerActionType.ResendSecurityCode;
      accountType: 'buyer';
      accountInfo: GenerateBuyerCodePayload;
      uuid: string;
    }
  | {
      type: ReducerActionType.GoToStep2;
      accountType: 'vendor';
      accountInfo: GenerateVendorCodePayload;
      uuid: string;
    }
  | {
      type: ReducerActionType.ResendSecurityCode;
      accountType: 'vendor';
      accountInfo: GenerateVendorCodePayload;
      uuid: string;
    }
  | {
      type: ReducerActionType.GoToStep3;
      accountType: 'buyer';
      accountInfo: GenerateBuyerCodePayload;
      uuid: string;
      smsPassword: string;
    }
  | {
      type: ReducerActionType.GoToStep3;
      accountType: 'vendor';
      accountInfo: GenerateVendorCodePayload;
      uuid: string;
      smsPassword: string;
    }
  | {
      type: ReducerActionType.SkipStep2;
      accountType: 'vendor';
      accountInfo: GenerateVendorCodePayload;
      uuid: string;
    };

export type ReducerState =
  | {
      accountType: null;
      step: RegistrationSteps.AccountInfo;
      accountInfo: null;
      uuid: null;
      smsPassword: null;
    }
  | {
      accountType: 'buyer';
      step: RegistrationSteps.OneTimePassword;
      accountInfo: GenerateBuyerCodePayload;
      uuid: string;
      smsPassword: null;
    }
  | {
      accountType: 'vendor';
      step: RegistrationSteps.OneTimePassword;
      accountInfo: GenerateVendorCodePayload;
      uuid: string;
      smsPassword: null;
    }
  | {
      accountType: 'buyer';
      step: RegistrationSteps.SetPassword;
      accountInfo: GenerateBuyerCodePayload;
      uuid: string;
      smsPassword: string;
    }
  | {
      accountType: 'vendor';
      step: RegistrationSteps.SetPassword;
      accountInfo: GenerateVendorCodePayload;
      uuid: string;
      smsPassword: string;
    }
  | {
      accountType: 'vendor';
      step: RegistrationSteps.SetPassword;
      accountInfo: GenerateVendorCodePayload;
      uuid: string;
      smsPassword: null;
    };

export type RegisterVendorProps = {
  setFirstFieldRef?: (ref: HTMLInputElement | null) => void;
  onVendorSubmit(fields: RegisterVendorFormFields): Promise<ApiFormError<RegisterVendorFormFields> | undefined>;
};

export type RegisterBuyerProps = {
  setFirstFieldRef?: (ref: HTMLInputElement | null) => void;
  onBuyerSubmit(fields: RegisterBuyerFormFields): Promise<ApiFormError<RegisterBuyerFormFields> | undefined>;
};

export type RegisterProps = RegisterBuyerProps & RegisterVendorProps;

import { useTheme, useMediaQuery } from '@material-ui/core';

export const useScreenWidth = () => {
  const theme = useTheme();

  const isTabletDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isMobile = !isTabletDesktop;

  return { isMobile, isTabletDesktop };
};

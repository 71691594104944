import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    side: {
      outlineWidth: 0,
      backgroundColor: 'white',
      height: '100%',
      overflowY: 'auto',
      position: 'relative',
    },
    content: {
      width: '100%',
    },
  }),
);

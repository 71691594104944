import React, { ReactNode } from 'react';

import { useLocale } from '../../../hooks/useLocale/useLocale';

import { AttributeProps } from './Attribute.types';
import { useStyles } from './Attribute.styles';

export const Attribute = ({ label, value, style, className }: AttributeProps) => {
  const { formatNumber } = useLocale();
  const classes = useStyles();
  let formattedValue: ReactNode;

  switch (typeof value) {
    case 'number':
      formattedValue = formatNumber(value);
      break;
    default:
      formattedValue = value;
  }

  return (
    <div style={style} className={className}>
      <div className={classes.label}>{label}</div>
      <div className={classes.value}>{formattedValue}</div>
    </div>
  );
};

import React, { useCallback, useEffect } from 'react';
import { useClient, useMutation, useParameterizedQuery } from 'react-fetching-library';
import { Box, CircularProgress } from '@material-ui/core';

import { Modal } from 'ui/modal/Modal';
import { createAuctionAction } from 'api/actions/auction/auctionActions';
import { CreateAuctionPayload } from 'api/actions/auction/auctionActions.types';
import { BankAccountInfoPayload } from '../../api/actions/user/userActions.types';
import { saveBankAccountInfo } from '../../api/actions/user/userActions';
import { ApiFormError } from '../../hooks/useApiForm/useApiForm';
import { useUserData } from '../../hooks/useUserData/useUserData';
import { CreateAuctionLayout } from '../../ui/createAuctionLayout/CreateAuctionLayout';
import { useCommonData } from '../../hooks/useCommonData/useCommonData';
import { Alert } from '../../ui/alert/Alert';
import { useLocale } from '../../hooks/useLocale/useLocale';
import { useUserDataRefresh } from '../../hooks/useUserDataRefresh/useUserDataRefresh';
import { scrollTop } from 'helpers/scrollTop';
import { getVendor } from '../../api/actions/vendor/vendorActions';

import { CreateAuctionContainerProps, CreateAuctionFormFields } from './CreateAuction.types';
import { CreateAuction } from './CreateAuction';
import { BankAccountInfo } from './bankAccountInfo/BankAccountInfo';

export const CreateAuctionContainer = ({ open, onClose }: CreateAuctionContainerProps) => {
  const { formatMsg } = useLocale();
  const { mutate } = useMutation(createAuctionAction);
  const userData = useUserData();
  const refreshUserData = useUserDataRefresh();
  const commonData = useCommonData();
  const { payload: vendorData, query: queryVendorData, abort: abortVendorData } = useParameterizedQuery(getVendor);
  const client = useClient();

  useEffect(() => {
    if (!userData?.vendorUuid) return;

    queryVendorData(userData.vendorUuid);

    return () => abortVendorData();
  }, [abortVendorData, queryVendorData, userData]);

  const hasBankAccountData = userData ? !!userData.vendorBankAccountInfo : undefined;
  const banks = commonData.banksLoading
    ? undefined
    : commonData.banksError || !commonData.banks
    ? null
    : commonData.banks;

  const handleNormalClose = useCallback(() => onClose(false), [onClose]);

  const onSubmit = useCallback(
    async ({ weight, priceMin, agreeToPay, confirmInformation, location, ...body }: CreateAuctionFormFields) => {
      const createAuctionPayload: CreateAuctionPayload = {
        ...body,
        location: (location === 'default' && vendorData?.city) || location,
        weight: Number(weight),
        priceMin: Number(priceMin) * 100, // backend requirements
        acknowledge: agreeToPay && confirmInformation,
      };

      const { error, status, payload } = await mutate(createAuctionPayload);

      if (!error && status === 201) {
        onClose(true);
        return;
      }
      if (error && payload) {
        return payload;
      }

      return { error: 'exception.unknown.primary_text' };
    },
    [mutate, onClose, vendorData],
  );

  const handleBankAccountInfoSubmit = useCallback(
    async (body: BankAccountInfoPayload) => {
      if (!userData?.vendorUuid) return;
      const res = await client.query(saveBankAccountInfo(userData?.vendorUuid, body));
      if (res.error)
        return (res.payload ?? { error: 'exception.unknown.primary_text' }) as ApiFormError<BankAccountInfoPayload>;
      refreshUserData();
      scrollTop();
    },
    [client, refreshUserData, userData],
  );

  return (
    <Modal open={open} onClose={handleNormalClose}>
      <CreateAuctionLayout>
        {hasBankAccountData === undefined || banks === undefined ? (
          <Box height="100%" display="flex" alignItems="center" justifyContent="center">
            <CircularProgress size={64} />
          </Box>
        ) : (
          <>
            {hasBankAccountData ? (
              <CreateAuction onClose={handleNormalClose} onSubmit={onSubmit} vendorData={vendorData} />
            ) : banks ? (
              <BankAccountInfo onClose={handleNormalClose} onSubmit={handleBankAccountInfoSubmit} banks={banks} />
            ) : (
              <Alert severity="error">{formatMsg('common.errors.fetching_data_error')}</Alert>
            )}
          </>
        )}
      </CreateAuctionLayout>
    </Modal>
  );
};

import React from 'react';
import { Direction, ThemeProvider } from '@material-ui/core';

import { useLocale } from '../../hooks/useLocale/useLocale';
import { AppLocale } from '../../context/locale/AppLocale.enum';
import { DebugOption } from '../../constants';

import { AppThemeProviderProps } from './AppThemeProvider.types';
import { createAppTheme } from './AppTheme.styles';

const directionOverride: Direction | null = (() => {
  const localStorageDirection = localStorage.getItem(DebugOption.direction);
  const supportedDirections: Direction[] = ['ltr', 'rtl'];
  return supportedDirections.some(direction => direction === localStorageDirection)
    ? (localStorageDirection as Direction)
    : null;
})();

export const AppThemeProvider = ({ children }: AppThemeProviderProps) => {
  const { locale } = useLocale();
  const direction = React.useMemo(() => directionOverride || (locale === AppLocale.ar ? 'rtl' : 'ltr'), [locale]);
  const appTheme = React.useMemo(() => createAppTheme(direction), [direction]);

  React.useLayoutEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  return <ThemeProvider theme={appTheme}>{children}</ThemeProvider>;
};

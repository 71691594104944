import React from 'react';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@material-ui/core';

import { AppStylesProviderProps } from './AppStylesProvider.types';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
export const AppStylesProvider = ({ children }: AppStylesProviderProps) => {
  return <StylesProvider jss={jss}>{children}</StylesProvider>;
};

import { AuctionPaymentFlow, AuctionStatus, AuctionResponse } from '../api/actions/auction/auctionActions.types';
import { MessageKeys } from '../i18n/messages';
import { UserRole } from '../constants';

export const getAuctionStatusMessage = (
  { status, paymentFlow, bidsCount }: AuctionResponse,
  userRole: UserRole,
): MessageKeys | string[] | number => {
  switch (status) {
    case AuctionStatus.new:
      return 'auction.status.inactive';

    case AuctionStatus.accepted: {
      if (bidsCount) {
        return bidsCount;
      }
      return 'auction.status.active';
    }

    case AuctionStatus.closed:
      return 'auction.status.ended';

    case AuctionStatus.declined:
      return 'auction.status.declined';

    case AuctionStatus.awarded: {
      if (userRole === UserRole.ROLE_ADMIN) {
        switch (paymentFlow) {
          case AuctionPaymentFlow.waitingForBuyerPaymentConfirmation:
            return 'auction.status.payment_pending';
          case AuctionPaymentFlow.waitingForVendorPaymentConfirmation:
            return 'auction.status.payment_received';
          case AuctionPaymentFlow.waitingForVendorFeePaymentConfirmation:
            return ['auction.status.fee_payment_pending', 'auction.status.shipment_confirmation_pending'];
          case AuctionPaymentFlow.waitingForAdminFeePaymentConfirmation:
            return 'auction.status.fee_payment_received';
        }
      }

      if (userRole === UserRole.ROLE_VENDOR) {
        switch (paymentFlow) {
          case AuctionPaymentFlow.waitingForBuyerPaymentConfirmation:
            return 'auction.status.payment_pending';
          case AuctionPaymentFlow.waitingForVendorPaymentConfirmation:
            return 'auction.status.payment_received';
          case AuctionPaymentFlow.waitingForVendorFeePaymentConfirmation:
            return ['auction.status.fee_payment_required', 'auction.status.shipment_confirmation_pending'];
          case AuctionPaymentFlow.waitingForAdminFeePaymentConfirmation:
            return 'auction.status.fee_payment_received';
          case AuctionPaymentFlow.paymentCompleted:
            return 'auction.status.fee_payment_confirmed';
        }
      }

      if (userRole === UserRole.ROLE_BUYER) {
        switch (paymentFlow) {
          case AuctionPaymentFlow.waitingForBuyerPaymentConfirmation:
            return 'auction.status.payment_pending';
          case AuctionPaymentFlow.waitingForVendorPaymentConfirmation:
            return 'auction.status.payment_received';
          case AuctionPaymentFlow.waitingForVendorFeePaymentConfirmation:
            return 'auction.status.shipment_confirmation_pending';
          case AuctionPaymentFlow.paymentCompleted:
            return 'auction.status.shipment_confirmation_pending';
        }
      }

      return 'auction.status.ended';
    }

    case AuctionStatus.ended:
      return 'auction.status.ended';

    default:
      return 'auction.status.active';
  }
};

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    color: '#979797',
    textAlign: 'center',
    padding: `${theme.spacing(2)}px 0`,
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(6),
    },
  },
  box: {
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      flex: '1 1 auto',
    },
  },
}));

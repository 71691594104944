import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-fetching-library';

import { CommonDataContextType } from '../commonDataContext/CommonDataContext.types';
import { CommonDataContext } from '../commonDataContext/CommonDataContext';
import { getDictionaries } from '../../../api/actions/dictionaries/dictionariesActions';

import { CommonDataContextControllerProps } from './CommonDataContextController.types';

export const commonDataContextInitialState = {
  dateTypes: {},
  dateTypesLoading: true,
  dateTypesError: false,
  dateGrades: {},
  dateGradesLoading: true,
  dateGradesError: false,
  units: {},
  unitsLoading: true,
  unitsError: false,
  banks: {},
  banksLoading: true,
  banksError: false,
  cities: [],
  citiesLoading: true,
  citiesError: false,
};

export const CommonDataContextController = ({ children }: CommonDataContextControllerProps) => {
  const [commonData, setCommonData] = useState<CommonDataContextType>(commonDataContextInitialState);
  const { payload, error } = useQuery(getDictionaries());

  useEffect(() => {
    if (payload && !error) {
      setCommonData(state => ({
        ...state,
        dateTypes: payload.dateTypes,
        dateTypesLoading: false,
        dateGrades: payload.dateGrades,
        dateGradesLoading: false,
        units: payload.units,
        unitsLoading: false,
        banks: payload.banks,
        banksLoading: false,
        cities: payload.cities,
        citiesLoading: false,
      }));
    }

    if (error) {
      setCommonData(state => ({
        ...state,
        dateTypesLoading: false,
        dateTypesError: error,
        unitsLoading: false,
        unitsError: error,
        banksLoading: false,
        banksError: error,
        citiesLoading: false,
        citiesError: error,
      }));
    }
  }, [error, payload]);

  return <CommonDataContext.Provider value={commonData}>{children}</CommonDataContext.Provider>;
};

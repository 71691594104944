import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Box } from '@material-ui/core';

import { IconButton } from 'ui/iconButton/IconButton';
import { Title } from 'ui/title/Title';
import { CreateAuctionHeaderProps } from '../CreateAuction.types';
import { useStyles } from '../CreateAuction.styles';

export const CreateAuctionHeader = ({ title, onClose }: CreateAuctionHeaderProps) => {
  const classes = useStyles();

  return (
    <Box mb={1} display="flex" alignItems="center">
      <IconButton className={classes.icon} onClick={onClose} color="primary">
        <CloseIcon />
      </IconButton>
      <Title text={title} variant="h5" component="h1" color="primary" />
    </Box>
  );
};

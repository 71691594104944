import { useClient } from 'react-fetching-library';
import { useCallback } from 'react';

import { setTokens, clearTokens } from '../../context/auth/authActionCreators/authActionCreators';
import { LoginResponse } from '../../api/actions/auth/authActions.types';
import { useAuthDispatch } from '../useAuthDispatch/useAuthDispatch';
import { loginAction } from '../../api/actions/auth/authActions';
import { useAuthState } from '../useAuthState/useAuthState';

export function useAuthorize() {
  const client = useClient();
  const authDispatch = useAuthDispatch();
  const { isAuthorized } = useAuthState();

  const authorize = useCallback(
    async (username: string, password: string, type: 'vendor' | 'buyer') => {
      const { error, payload } = await client.query(loginAction({ username, password, type }));
      if (!error && payload) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        const { token, refresh_token }: LoginResponse = payload;
        authDispatch(setTokens(token, refresh_token));
        return;
      }

      authDispatch(clearTokens());

      return { error: 'validation.user.credentials' };
    },
    [authDispatch, client],
  );

  return { isAuthorized, authorize };
}

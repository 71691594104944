import React from 'react';

import { useLocale } from 'hooks/useLocale/useLocale';
import { useStyles } from '../AuctionFeatures.styles';
import { getAppMessage } from 'i18n/messages';
import { Link } from 'ui/link/Link';
import { AppRoute } from 'routing/AppRoute.enum';
import { externalLinks } from '../../../../constants';

export const Pages = () => {
  const { formatMessage } = useLocale();
  const classes = useStyles();

  return (
    <nav className={classes.nav}>
      <Link
        color="inherit"
        underline="hover"
        href={AppRoute.faq}
        text={formatMessage({ id: getAppMessage('common.navigation.faq') })}
        className={classes.navLink}
      />
      <a href={externalLinks.sdm} className={classes.navLink} target="_blank" rel="noopener noreferrer">
        {formatMessage({ id: getAppMessage('common.navigation.sdm') })}
      </a>
      <Link
        color="inherit"
        underline="hover"
        href={AppRoute.terms}
        text={formatMessage({ id: getAppMessage('common.navigation.terms') })}
        className={classes.navLink}
      />
      <Link
        color="inherit"
        underline="hover"
        href={AppRoute.privacy}
        text={formatMessage({ id: getAppMessage('common.navigation.privacy') })}
        className={classes.navLink}
      />
    </nav>
  );
};

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Controller } from 'react-hook-form';

import { ConstrainedInput } from '../../constrainedInput/ConstrainedInput';

import { SecurityCodeProps } from './SecurityCode.types';

const testDigits = (value: string) => /^\d*$/.test(value);

export const SecurityCode = ({ name, error, ...rest }: SecurityCodeProps) => {
  return (
    <Controller
      {...rest}
      defaultValue=""
      name={name}
      error={!!error}
      helperText={error}
      as={ConstrainedInput}
      inputMode="numeric"
      constraints={{ test: testDigits }}
    />
  );
};

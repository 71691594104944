import { ValidationOptions } from 'react-hook-form';
import { IntlFormatters } from 'react-intl';

import { getAppMessage } from '../i18n/messages';

const length = 10;

export function getPhoneValidationOptions({
  formatMessage,
}: {
  formatMessage: IntlFormatters['formatMessage'];
}): ValidationOptions {
  return {
    required: formatMessage({ id: getAppMessage('validation.common.required') }),
    minLength: {
      value: length,
      message: formatMessage({ id: getAppMessage('validation.user.phone.length_min') }, { limit: length }),
    },
    maxLength: {
      value: length,
      message: formatMessage({ id: getAppMessage('validation.user.phone.length_max') }, { limit: length }),
    },
  };
}

import React from 'react';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';

import { AppRoute } from 'routing/AppRoute.enum';
import { getAppMessage } from 'i18n/messages';
import { useAuthState } from 'hooks/useAuthState/useAuthState';

import { LoggedInLinks } from './LoggedInLinks';
import { LoggedOutLinks } from './LoggedOutLinks';
import { NavigationLinksProps } from './Navigation.types';

export const NavigationLinks = ({ onClick, isAdmin }: NavigationLinksProps) => {
  const { formatMessage } = useIntl();
  const { isAuthorized } = useAuthState();

  return (
    <>
      {isAuthorized ? <LoggedInLinks isAdmin={isAdmin} onClick={onClick} /> : <LoggedOutLinks onClick={onClick} />}
      <NavLink exact to={AppRoute.faq} onClick={onClick}>
        {formatMessage({ id: getAppMessage('common.navigation.faq') })}
      </NavLink>
      <NavLink exact to={AppRoute.home} onClick={onClick}>
        {formatMessage({ id: getAppMessage('common.navigation.about_us') })}
      </NavLink>
    </>
  );
};

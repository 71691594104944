import React from 'react';
import { Box, Typography, useTheme, useMediaQuery } from '@material-ui/core';

import { useLocale } from '../../../hooks/useLocale/useLocale';
import { getAppMessage } from '../../../i18n/messages';
import { ReactComponent as PlaceholderImage } from 'assets/images/finger-pointing-up.svg';
import { Placeholder } from '../placeholder/Placeholder';
import { Pagination } from '../../../ui/pagination/Pagination';
import { Section } from '../section/Section';
import { UserRole } from '../../../constants';

import { BidsListProps } from './BidsList.types';
import { useStyles } from './BidsList.styles';
import { DesktopTable } from './desktopTable/DesktopTable';
import { MobileTable } from './mobileTable/MobileTable';

export const BidsList = ({ disabled, loading, error, bids, onPageChange, onAcceptBid, userData }: BidsListProps) => {
  const { formatMessage } = useLocale();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const classes = useStyles();
  const isRtl = theme.direction === 'rtl';
  const hasData = !error && !!bids && !!bids.data.length;
  const isVendor = userData.type === UserRole.ROLE_VENDOR;

  return (
    <Section
      minHeight={hasData ? undefined : '100%'}
      loading={loading}
      title={formatMessage({ id: getAppMessage('auction.details.buyer_bid_list.title') })}
    >
      {!loading && error && (
        <Box px={[2, 3]} flex="1" display="flex" alignItems="center" justifyContent="center">
          <Typography color="error">
            {formatMessage({ id: getAppMessage('exception.unknown.primary_text') })}
          </Typography>
        </Box>
      )}
      {!error && !!bids && (
        <>
          {!!bids.data.length ? (
            <>
              {matches ? (
                <DesktopTable
                  disabled={loading || disabled}
                  isRtl={isRtl}
                  activeRowClass={classes.activeRow}
                  onAcceptBid={onAcceptBid}
                  data={bids.data}
                  className={classes.table}
                  isVendor={isVendor}
                />
              ) : (
                <MobileTable
                  disabled={loading || disabled}
                  isRtl={isRtl}
                  activeRowClass={classes.activeRow}
                  onAcceptBid={onAcceptBid}
                  data={bids.data}
                  className={classes.table}
                  isVendor={isVendor}
                />
              )}
              <Box px={[2, 3]} mt={4}>
                <Pagination
                  currentPage={bids.pagination.current}
                  pagesCount={bids.pagination.pages}
                  onChange={onPageChange}
                />
              </Box>
            </>
          ) : (
            <Placeholder>
              <PlaceholderImage
                role="img"
                title={formatMessage({ id: getAppMessage('auction.details.buyer_bid_list.placeholder_image_title') })}
              />
              {userData.type === UserRole.ROLE_BUYER && (
                <Typography color="textPrimary">
                  {formatMessage({
                    id: getAppMessage('auction.details.buyer_bid_list.no_bids_buyer_info'),
                  })}
                </Typography>
              )}
              {[UserRole.ROLE_VENDOR, UserRole.ROLE_ADMIN].includes(userData.type) && (
                <Typography color="textPrimary">
                  {formatMessage({
                    id: getAppMessage('auction.details.buyer_bid_list.no_bids_vendor_info'),
                  })}
                </Typography>
              )}
            </Placeholder>
          )}
        </>
      )}
    </Section>
  );
};

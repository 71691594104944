import { useContext } from 'react';

import { QueryParamsDispatchContextType } from 'context/queryParams/queryParamsContext/QueryParamsContext.types';
import { QueryParamsDispatchContext } from 'context/queryParams/queryParamsContext/QueryParamsContextController';

export const useQueryParamsDispatch = (): QueryParamsDispatchContextType => {
  const context = useContext(QueryParamsDispatchContext);

  if (context === undefined) {
    throw new Error('useQueryParamsState is unavailable, make sure you are using QueryParamsContextController');
  }

  return context;
};

import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import dayjs from 'dayjs';

import { Status } from '../../../ui/status/Status';
import { Title } from '../../../ui/title/Title';
import { useLocale } from '../../../hooks/useLocale/useLocale';
import { Attribute } from '../attribute/Attribute';
import { Gallery } from '../../../ui/gallery/Gallery';
import { Input } from '../../../ui/input/Input';
import { ApiFormError, useApiForm } from '../../../hooks/useApiForm/useApiForm';
import { Button } from '../../../ui/button/Button';
import { UserRole, auctionStatusColors, auctionStatusColorDefault } from '../../../constants';
import { getAuctionStatusMessage } from '../../../helpers/getAuctionStatusMessage';
import { formatWeight } from '../../../helpers/formatWeight';
import { Dialog, DialogActions, DialogTitle } from '../../../ui/dialog/Dialog';
import { AuctionStatus } from '../../../api/actions/auction/auctionActions.types';
import { formatAmount } from '../../../helpers/formatAmount';
import { getAppMessage } from 'i18n/messages';

import { BasicInfoBidFields, BasicInfoProps } from './BasicInfo.types';
import { useStyles } from './BasicInfo.styles';

export const BasicInfo = ({
  auction,
  userData,
  dateTypes,
  dateGrades,
  units,
  disabled,
  onCloseAuction,
  onBid,
}: BasicInfoProps) => {
  const { formatMessage, formatMsg, locale } = useLocale();
  const [openCloseConfirmation, setOpenCloseConfirmation] = useState(false);
  const classes = useStyles();
  const { reset, register, formState, errors, onSubmit } = useApiForm(async (body: BasicInfoBidFields) => {
    if (userData.type !== UserRole.ROLE_BUYER || !onBid) return;
    const res = await onBid(Number(body.value) * 100, userData.bidInfo?.uuid);
    if (!res) return;
    if (res.error) return res.payload as ApiFormError<BasicInfoBidFields>;
  });
  const myBidPrice = userData.type === UserRole.ROLE_BUYER && userData.bidInfo?.myBidPrice;
  const auctionStatus = getAuctionStatusMessage(auction, userData.type);

  useEffect(() => {
    reset();
  }, [reset, myBidPrice]);

  const handleCancelCloseAuction = useCallback(() => setOpenCloseConfirmation(false), []);

  const handleCloseAuction = useCallback(() => {
    setOpenCloseConfirmation(false);
    onCloseAuction?.();
  }, [onCloseAuction]);
  return (
    <Paper elevation={0}>
      <Box px={[2, 3]} py={4}>
        <Grid container spacing={3}>
          <Grid item container direction="column" wrap="nowrap" xs={12} md={6}>
            <div className={classes.galleryWrapper}>
              <Gallery images={auction.images.map(({ main }) => main)} />
            </div>
          </Grid>
          <Grid item container direction="column" wrap="nowrap" xs={12} md={6}>
            <Box mb={1.5}>
              {Array.isArray(auctionStatus) ? (
                auctionStatus.map(status => (
                  <Status
                    color={auctionStatusColors[status] ?? auctionStatusColorDefault}
                    className={classes.status}
                    key={status}
                  >
                    {formatMessage({ id: getAppMessage(status) })}
                  </Status>
                ))
              ) : (
                <Status color={auctionStatusColors[auctionStatus] ?? auctionStatusColorDefault}>
                  {typeof auctionStatus === 'number'
                    ? formatMessage({ id: getAppMessage('auction.status.active') })
                    : formatMessage({ id: getAppMessage(auctionStatus) })}
                </Status>
              )}
            </Box>
            <Box mb={1.5}>
              <Title text={auction.name} component="h2" variant="h4" />
            </Box>
            {[AuctionStatus.new, AuctionStatus.accepted].includes(auction.status) && (
              <Box>
                <Typography className={classes.deadline}>
                  {formatMsg('auction.details.deadline_in', { remainingTime: dayjs(auction.endDate).toNow(true) })}
                </Typography>
              </Box>
            )}
            <Box my={6} className={classes.attributes}>
              <Attribute
                label={formatMsg('auction.details.attributes.weight')}
                value={formatWeight(
                  auction.weight,
                  locale,
                  units[auction.unit] ? formatMsg(units[auction.unit]) : auction.unit,
                )}
              />
              <Attribute
                label={formatMsg('auction.details.attributes.date_type')}
                value={dateTypes[auction.type] ? formatMsg(dateTypes[auction.type]) : auction.type}
              />
              <Attribute
                label={formatMsg('auction.details.attributes.date_grade')}
                value={dateGrades[auction.quality] ? formatMsg(dateGrades[auction.quality]) : auction.quality}
              />
              <Attribute label={formatMsg('auction.details.attributes.source')} value={auction.source} />
              <Attribute label={formatMsg('auction.details.attributes.location')} value={auction.location} />
              <Attribute
                className={classes.attributeWide}
                label={formatMsg('auction.details.attributes.description')}
                value={auction.description}
              />
            </Box>
            <div className={classes.actions}>
              {userData.type === UserRole.ROLE_BUYER && onBid && (
                <form onSubmit={onSubmit}>
                  <Grid container spacing={3}>
                    <Grid item>
                      <Input
                        style={{ width: 210 }}
                        name="value"
                        type="number"
                        label={formatMsg('auction.details.your_offer')}
                        error={!!errors.value?.message}
                        helperText={
                          errors.value?.message ||
                          formatMsg('auction.details.start_bidding_info', {
                            amount: formatAmount(userData.minBidAmount / 100, locale),
                          })
                        }
                        ref={register({
                          required: formatMsg('validation.common.required'),
                        })}
                      />
                    </Grid>
                    <Grid item>
                      <Box my={1}>
                        <Button
                          type="submit"
                          disabled={disabled || formState.isSubmitting}
                          text={
                            userData.bidInfo?.uuid
                              ? formatMsg('auction.details.rebid')
                              : formatMsg('auction.details.start_bidding')
                          }
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              )}
              {onCloseAuction && (
                <>
                  <Button
                    variant="text"
                    disabled={disabled}
                    onClick={() => setOpenCloseConfirmation(true)}
                    text={formatMsg('auction.details.close_auction')}
                  />
                  <Dialog open={openCloseConfirmation} onClose={handleCancelCloseAuction}>
                    <DialogTitle text={formatMsg('auction.details.close_auction_confirmation_title')} />
                    <DialogActions>
                      <Button
                        text={formatMsg('auction.details.close_auction')}
                        onClick={handleCloseAuction}
                        size="small"
                      />
                      <Button
                        text={formatMsg('common.cancel')}
                        variant="text"
                        onClick={handleCancelCloseAuction}
                        size="small"
                      />
                    </DialogActions>
                  </Dialog>
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

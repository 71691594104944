import { AuthAction } from '../auth.types';

export const setTokens: (accessToken: string, refreshToken: string) => AuthAction = (
  accessToken,
  refreshToken,
): AuthAction => ({
  type: 'set-tokens',
  accessToken,
  refreshToken,
});

export const clearTokens = (): AuthAction => ({ type: 'clear-tokens' });

import { makeStyles } from '@material-ui/core';

const outlineColor = 'rgba(0, 0, 0, 0.38)';
const outlineFocusedSpacing = 0.5;

export const useStyles = makeStyles(theme => ({
  dropzone: {
    minHeight: 190,
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      minHeight: 0,
      height: 190,
    },
    position: 'relative',
    cursor: 'pointer',
    outline: `1px solid ${outlineColor}`,
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.87)',
    opacity: 0.6,
    '&:focus': {
      outline: `${theme.spacing(outlineFocusedSpacing)}px solid ${outlineColor}`,
    },
  },
  dropzoneActive: {
    outline: `2px solid ${theme.palette.success.main}`,
    backgroundColor: theme.palette.success.light,
  },
  dropzoneBusy: {
    '& $content': {
      visibility: 'hidden',
    },
    '& $loadingOverlay': {
      visibility: 'visible',
    },
  },
  content: {
    visibility: 'visible',
  },
  loadingOverlay: {
    visibility: 'hidden',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    color: theme.palette.success.main,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

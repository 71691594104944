import React from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';

import { useStyles } from './LoaderOverlay.styles';

export const LoaderOverlay = ({ open, zIndex }: { open: boolean; zIndex?: number }) => {
  const classes = useStyles();
  return (
    <Backdrop open={open} classes={{ root: classes.root }} style={{ zIndex }}>
      <CircularProgress size={64} />
    </Backdrop>
  );
};

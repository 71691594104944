import React from 'react';
import { Box, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Paper, Select } from '@material-ui/core';
import clsx from 'clsx';
import { Controller } from 'react-hook-form';

import { useApiForm } from '../../../hooks/useApiForm/useApiForm';
import { CreateAuctionHeader } from '../createAuctionHeader/CreateAuctionHeader';
import { useLocale } from '../../../hooks/useLocale/useLocale';
import { Alert } from '../../../ui/alert/Alert';
import { FormDivider } from '../../../ui/formDivider/FormDivider';
import { Button } from '../../../ui/button/Button';
import { Checkbox } from '../../../ui/checkbox/Checkbox';
import { BankAccountInfoPayload } from '../../../api/actions/user/userActions.types';
import { Input } from '../../../ui/input/Input';
import { getSelectMenuProps } from '../../../helpers/getSelectMenuProps';
import { getIbanValidationOptions } from '../../../validation/iban';
import { getAppMessage } from 'i18n/messages';
import { formRequirements } from '../../../constants';

import { useStyles } from './BankAccountInfo.styles';
import { BankAccountInfoProps } from './BankAccountInfo.types';

export const BankAccountInfo = ({ onClose, onSubmit, banks }: BankAccountInfoProps) => {
  const { formatMsg, formatMessage } = useLocale();
  const classes = useStyles();
  const form = useApiForm<BankAccountInfoPayload>(payload => onSubmit(payload), {
    defaultValues: { bankAcknowledge: false, bankBeneficientName: '', bankIban: '', bankId: '' },
  });
  const isConfirmed = form.watch('confirmInformation');

  return (
    <>
      <CreateAuctionHeader onClose={onClose} title={formatMsg('auction.bank_info.title')} />
      <Box mt={3}>
        <Alert severity="warning">{formatMsg('auction.bank_info.warning')}</Alert>
      </Box>
      <Box mt={2}>
        <form onSubmit={form.onSubmit} noValidate>
          <FormDivider text={formatMsg('auction.bank_info.step_1')} />
          <Grid container spacing={2}>
            {form.formError && (
              <Grid item xs={12}>
                <FormHelperText error>{form.formError}</FormHelperText>
              </Grid>
            )}
            <Grid item xs={12}>
              <Input
                type="text"
                name="bankBeneficientName"
                label={formatMsg('auction.bank_info.labels.beneficient_name')}
                error={!!form.errors.bankBeneficientName}
                helperText={form.errors.bankBeneficientName?.message}
                ref={form.register({
                  required: formatMsg('validation.common.required'),
                  minLength: {
                    value: formRequirements.bankNameMin,
                    message: formatMessage({ id: getAppMessage('validation.user.bankBeneficientName.length_min') }),
                  },
                  maxLength: {
                    value: formRequirements.bankNameMax,
                    message: formatMessage({ id: getAppMessage('validation.user.bankBeneficientName.length_max') }),
                  },
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                type="text"
                name="bankIban"
                label={formatMsg('auction.bank_info.labels.iban')}
                error={!!form.errors.bankIban}
                helperText={form.errors.bankIban?.message}
                ref={form.register(getIbanValidationOptions({ formatMessage }))}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth error={!!form.errors.bankId}>
                <InputLabel id="bank">{formatMsg('auction.bank_info.labels.bank')}</InputLabel>
                <Controller
                  control={form.control}
                  rules={{ required: formatMsg('validation.common.required') }}
                  name="bankId"
                  as={Select}
                  displayEmpty
                  labelId="bank"
                  id="bank"
                  color="secondary"
                  label={formatMsg('auction.bank_info.labels.bank')}
                  MenuProps={getSelectMenuProps()}
                >
                  {Object.entries(banks).map(([value, name]) => (
                    <MenuItem key={value} value={value}>
                      {name}
                    </MenuItem>
                  ))}
                </Controller>
                {form.errors.bankId?.message && <FormHelperText>{form.errors.bankId?.message}</FormHelperText>}
              </FormControl>
            </Grid>
          </Grid>
          <FormDivider text={formatMsg('auction.bank_info.step_2')} />
          <Paper elevation={0} className={clsx(classes.paper, isConfirmed ? classes.paperSuccess : classes.paperGrey)}>
            <Checkbox
              name="bankAcknowledge"
              label={formatMsg('auction.bank_info.confirm_information')}
              error={!!form.errors.bankAcknowledge}
              helperText={form.errors.bankAcknowledge?.message}
              ref={form.register({ required: formatMsg('validation.common.required') })}
              color="primary"
            />
          </Paper>
          <Button disabled={form.formState.isSubmitting} type="submit" text={formatMsg('auction.bank_info.submit')} />
        </form>
      </Box>
    </>
  );
};

import { QueryResponse, convertActionToBase64 } from 'react-fetching-library';

import { Action } from 'api/types';
import { CacheStorage } from 'context/client/clientContextController/cache/localStorageCache.types';

const CACHE_KEY = 'requests_cache';
const CACHE_TIME = 24 * 60 * 60 * 1000;

const getCacheData = (): CacheStorage => {
  const serializedData = localStorage.getItem(CACHE_KEY);
  let data = null;

  try {
    data = serializedData ? JSON.parse(serializedData) : {};
  } catch (e) {
    data = {};
  }

  return data;
};

const setCacheData = (data: CacheStorage) => {
  localStorage.setItem(CACHE_KEY, JSON.stringify(data));
};

const findInCache = (key: string) => {
  return getCacheData()[key];
};

const saveToCache = (key: string, response: QueryResponse) => {
  const data = getCacheData();
  data[key] = { ...response, timestamp: Date.now() };
  setCacheData(data);
};

const deleteFromCache = (key: string) => {
  const data = getCacheData();
  delete data[key];
  setCacheData(data);
};

export const localStorageCache = {
  add: ({ signal, ...action }: Action, response: QueryResponse) => {
    if (action.method === 'GET' && action.config?.cacheResponse) {
      saveToCache(convertActionToBase64(action), response);
    }
  },
  remove: ({ signal, ...action }: Action) => {
    deleteFromCache(convertActionToBase64(action));
  },
  get: ({ signal, ...action }: Action) => {
    const key = convertActionToBase64(action);
    const response = findInCache(key);

    if (response?.timestamp && response.timestamp + CACHE_TIME > new Date().getTime()) {
      return response;
    } else {
      deleteFromCache(key);
      return undefined;
    }
  },
  getItems: () => {
    return getCacheData();
  },
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  setItems: (items: { [key: string]: any }) => {
    setCacheData(items);
  },
};

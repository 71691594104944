/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useIntl } from 'react-intl';

import { getAppMessage } from '../../../i18n/messages';
import { Input } from '../../input/Input';
import { useInputConstraints } from '../../../hooks/useInputConstraints/useInputConstraints';

import { NationalIdProps } from './NationalId.types';

/* Allow any number of digits. */
const predicate = (value: string) => new RegExp('^[0-9]*$').test(value);

export const NationalId = React.forwardRef<HTMLInputElement, NationalIdProps>(({ name, label, error, margin }, ref) => {
  const { formatMessage } = useIntl();
  const inputProps = useInputConstraints(predicate);

  return (
    <Input
      type="text"
      ref={ref}
      name={name}
      label={label || formatMessage({ id: getAppMessage('common.labels.national_id') })}
      error={!!error}
      helperText={error}
      margin={margin}
      inputProps={inputProps}
    />
  );
});

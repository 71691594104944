import { makeStyles, createStyles } from '@material-ui/core';

export const useInputStyles = makeStyles(() =>
  createStyles({
    root: {
      borderRadius: 0,
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: 500,
    },
    primaryInput: {
      borderWidth: '1px !important',
    },
    primaryInputLabel: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: 500,
      '&.Mui-focused': {
        color: 'rgba(0, 0, 0, 0.87)',
      },
    },
  }),
);

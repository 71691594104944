import dayjs from 'dayjs';

import {
  AuctionPaymentFlow,
  AuctionResponse,
  AuctionPaymentStatus,
  AuctionStatus,
  AuctionShippingStatus,
} from '../actions/auction/auctionActions.types';

export const mockAuction: AuctionResponse = {
  source: 'Region name',
  location: 'City name',
  name: 'Longer dates auction name',
  status: AuctionStatus.new,
  weight: 15000,
  unit: 'g',
  delivery: false,
  pickup: true,
  vendorId: '1',
  paymentStatus: AuctionPaymentStatus.pending,
  shippingStatus: AuctionShippingStatus.none,
  feeStatus: AuctionPaymentStatus.none,
  startDate: dayjs(new Date())
    .add(12, 'day')
    .toISOString(),
  endDate: dayjs(new Date())
    .add(24, 'day')
    .toISOString(),
  bidPrice: 60000,
  priceMin: 60000,
  type: 'Example type',
  quality: 'Example grade',
  images: [
    'https://media.gettyimages.com/photos/streets-in-illuminated-cityscape-riyadh-saudi-arabia-picture-id1146340650?s=1024x1024',
    'https://media.gettyimages.com/photos/skyscrapers-in-city-against-sky-during-sunset-picture-id1157933304?s=1024x1024',
    'https://media.gettyimages.com/photos/elevated-view-of-modern-city-center-riyadh-saudi-arabia-picture-id981764234?s=1024x1024',
  ].map(main => ({ main })),
  description: 'Example info',
  acknowledge: false,
  createdAt: dayjs(new Date())
    .add(-4, 'day')
    .toISOString(),
  uuid: '6f8d9081-d106-4b65-aa52-a2e8288d3c67',
  paymentFlow: AuctionPaymentFlow.none,
  mainImage:
    'https://media.gettyimages.com/photos/elevated-view-of-modern-city-center-riyadh-saudi-arabia-picture-id981764234?s=1024x1024',
};

/* eslint-disable @typescript-eslint/camelcase */
import { Server, Response as MockResponse } from 'miragejs';
import jwtEncode from 'jwt-encode';

import { LoginPayload } from '../actions/auth/authActions.types';

import { mockAuction } from './auction';

const mockUserUuid = '1c5dcfd9-2680-4ade-a1fa-d8159344b8a9';
const mockJwtSecret = 'secret';
const mockPassword = 'password';
const mockSmsPassword = '666666';
const mockBuyer = { phone: '0512312312', fullName: '123123123', nationalId: 'NO-SMS-CHECKING' };
const mockVendor = { phone: '0512312313', saudiDatesMark: 'NO-SMS-CHECKING' };
type Buyer = typeof mockBuyer;

function getTokenResponse() {
  const now = new Date();
  const exp = (() => {
    const date = new Date();
    date.setHours(date.getHours() + 1);
    return date;
  })();

  return new MockResponse(
    200,
    {},
    {
      token: jwtEncode(
        {
          iat: Math.round(now.getTime() / 1000),
          exp: Math.round(exp.getTime() / 1000),
          roles: ['ROLE_USER'],
          username: '0512312312_buyer',
        },
        mockJwtSecret,
      ),
      refresh_token: 'refresh_token',
    },
  );
}

export const mockServer = () => {
  new Server({
    routes() {
      this.urlPrefix = process.env.REACT_APP_API_URL;

      // Auction details
      this.get('/v1/auctions/:uuid', (schema, request) => {
        const uuid: string = request.params.uuid;

        if (uuid === mockAuction.uuid) return new MockResponse(200, {}, mockAuction);

        return new MockResponse(404, {});
      });

      // Buyer registration step 1
      this.post('/v1/register/buyer/verify', (schema, request) => {
        const payload: Buyer = JSON.parse(request.requestBody);

        const checks: Record<keyof Buyer, boolean> = {
          fullName: payload.fullName === mockBuyer.fullName,
          phone: payload.phone === mockBuyer.phone,
          nationalId: payload.nationalId === mockBuyer.nationalId,
        };

        if (!Object.values(checks).some(check => !check))
          return new MockResponse(
            201,
            {},
            JSON.stringify({
              uuid: mockUserUuid,
            }),
          );

        const errors: Partial<Record<keyof Buyer | 'error', string>> = {};
        if (!checks.phone) errors.phone = 'exception.buyer.not_unique_field.phone';
        if (!checks.fullName) errors.fullName = 'exception.buyer.not_unique_field.user_name';
        if (!checks.nationalId) errors.nationalId = 'exception.buyer.not_unique_field.national_id';

        return new MockResponse(400, {}, errors);
      });

      // Buyer registration step 2
      this.put('/v1/register/sms-code', (schema, request) => {
        const payload: { uuid: string; smsPassword: string } = JSON.parse(request.requestBody);

        if (payload.uuid === mockUserUuid && payload.smsPassword === mockSmsPassword) return new MockResponse(202);

        return new MockResponse(
          400,
          {},
          {
            error: 'exception.unknown.primary_text',
          },
        );
      });

      // Buyer registration step 3
      this.post('/v1/register/buyer', (schema, request) => {
        const payload: {
          phone: string;
          fullName: string;
          plainPassword: string;
          nationalId: string;
        } = JSON.parse(request.requestBody);

        if (payload.nationalId === mockBuyer.nationalId) return new MockResponse(201);

        return new MockResponse(
          400,
          {},
          {
            error: 'exception.unknown.primary_text',
          },
        );
      });

      // Login
      this.post('/login', (schema, request) => {
        const payload: LoginPayload = JSON.parse(request.requestBody);

        if (payload.password === mockPassword) return getTokenResponse();

        return new MockResponse(401, {}, { code: 401, message: `Bad mock request, use "${mockPassword}"` });
      });

      // Token refresh
      this.post('/token/refresh', () => getTokenResponse());

      this.post('/login-help/newpassword', () => {
        return new MockResponse(200);
      });

      this.post('/login-help/securitycode', () => {
        return new MockResponse(
          200,
          {},
          {
            uuid: mockUserUuid,
          },
        );
      });

      // Password reset step 1
      this.post('/login-help/phone', (schema, request) => {
        const payload: { phone: string } = JSON.parse(request.requestBody);

        if (payload.phone === mockBuyer.phone) return new MockResponse(200);

        return new MockResponse(401, {}, { code: 401, message: `Bad mock request, use "${mockBuyer.phone}"` });
      });

      this.passthrough();

      // Vendor registration step 1
      this.post('/v1/register/vendor/verify', (schema, request) => {
        const payload: { phone: string; saudiDatesMark: string } = JSON.parse(request.requestBody);

        if (payload.phone === mockVendor.phone && payload.saudiDatesMark === mockVendor.saudiDatesMark)
          return new MockResponse(
            201,
            {},
            JSON.stringify({
              uuid: mockUserUuid,
            }),
          );

        return new MockResponse(
          401,
          {},
          { code: 401, message: `Bad mock request, use "${mockVendor.phone}" and "${mockVendor.saudiDatesMark}"` },
        );
      });

      // Vendor registration step 2
      this.put('/v1/register/sms-code', (schema, request) => {
        const payload: { uuid: string; smsPassword: string } = JSON.parse(request.requestBody);

        if (payload.uuid === mockUserUuid && payload.smsPassword === mockSmsPassword) return new MockResponse(202);

        return new MockResponse(
          400,
          {},
          {
            error: 'exception.unknown.primary_text',
          },
        );
      });

      // Vendor registration step 3
      this.post('/v1/register/vendor', (schema, request) => {
        const payload: {
          phone: string;
          plainPassword: string;
          saudiDatesMark: string;
        } = JSON.parse(request.requestBody);

        if (payload.saudiDatesMark === mockVendor.saudiDatesMark) return new MockResponse(201);

        return new MockResponse(
          400,
          {},
          {
            error: 'exception.unknown.primary_text',
          },
        );
      });

      // auctions list
      this.get('/auctions', () => {
        return new MockResponse(
          200,
          {},
          JSON.stringify({
            auctions: [
              {
                uuid: '1',
                name: 'Auction name',
                mainImage: 'auction.png',
                datesType: 'Type 1',
                location: 'Riyadh',
                receivingMethod: 'Delivery and pickup',
                weight: 500,
                priceMin: 400,
                status: 'Pending fee',
              },
              {
                uuid: '2',
                name: 'Auction name',
                mainImage: 'auction.png',
                datesType: 'Type 1',
                location: 'Riyadh',
                receivingMethod: 'Delivery and pickup',
                weight: 500,
                priceMin: 400,
                status: 'Active',
              },
              {
                uuid: '3',
                name: 'Auction name',
                mainImage: 'auction.png',
                datesType: 'Type 1',
                location: 'Riyadh',
                receivingMethod: 'Delivery and pickup',
                weight: 500,
                priceMin: 400,
                status: 'Active',
              },
              {
                uuid: '4',
                name: 'Auction name',
                mainImage: 'auction.png',
                datesType: 'Type 1',
                location: 'Riyadh',
                receivingMethod: 'Delivery and pickup',
                weight: 500,
                priceMin: 400,
                status: 'Active',
              },
              {
                uuid: '5',
                name: 'Auction name',
                mainImage: 'auction.png',
                datesType: 'Type 1',
                location: 'Riyadh',
                receivingMethod: 'Delivery and pickup',
                weight: 500,
                priceMin: 400,
                status: 'Active',
              },
            ],
          }),
        );
      });

      // cities list
      this.get('/v1/common/cities', () => {
        return new MockResponse(200, {}, JSON.stringify(['City 1', 'City 2', 'City 3']));
      });
    },
  });
};

import React, { useCallback, useEffect } from 'react';
import { PropsWithChildren, useState } from 'react';
import { useClient } from 'react-fetching-library';
import * as Sentry from '@sentry/browser';

import { UserDataContext, UserDataRefreshContext } from '../userDataContext/UserDataContext';
import { useAuthState } from '../../../hooks/useAuthState/useAuthState';
import { getMe } from '../../../api/actions/user/userActions';
import { useAuthDispatch } from '../../../hooks/useAuthDispatch/useAuthDispatch';
import { clearTokens } from '../../auth/authActionCreators/authActionCreators';
import { getVendor } from '../../../api/actions/vendor/vendorActions';
import { UserDataContextType } from '../userDataContext/UserDataContext.types';

import { SentryUserData } from './UserDataContextController.types';

export const UserDataContextController = ({ children }: PropsWithChildren<{}>) => {
  const [userData, setUserData] = useState<UserDataContextType | null>(null);
  const { isAuthorized, user } = useAuthState();
  const client = useClient();
  const dispatch = useAuthDispatch();

  const getUserData = useCallback(() => {
    const cancellation = { cancel: false };

    if (!isAuthorized) {
      setUserData(null);
      return;
    }

    const f = async () => {
      const meRes = await client.query(getMe());
      if (cancellation.cancel) return;
      if (!meRes.error && meRes.payload) {
        const userData: UserDataContextType = { ...meRes.payload, vendorBankAccountInfo: null };
        if (meRes.payload.vendorUuid) {
          const vendorRes = await client.query(getVendor(meRes.payload.vendorUuid));
          if (cancellation.cancel) return;
          if (!vendorRes.error && vendorRes.payload) {
            const { bankBeneficientName, bankIban, bankId } = vendorRes.payload;
            if (typeof bankBeneficientName === 'string' && typeof bankIban === 'string' && typeof bankId === 'number') {
              userData.vendorBankAccountInfo = {
                bankBeneficientName,
                bankIban,
                bankId,
              };
            }
          }
        }
        setUserData(userData);
      } else {
        setUserData(null);
        dispatch(clearTokens());
      }
    };

    f();

    return () => {
      cancellation.cancel = true;
    };
  }, [client, dispatch, isAuthorized]);

  useEffect(getUserData, [getUserData]);

  useEffect(() => {
    if (process.env.REACT_APP_SENTRY_DSN) {
      Sentry.configureScope(function(scope) {
        let sentryUser: SentryUserData | null = null;
        if (user) {
          sentryUser = { id: user.username, roles: user.roles };

          if (userData) {
            const { userUuid, buyerUuid, vendorUuid } = userData;
            sentryUser = { ...sentryUser, userUuid, buyerUuid, vendorUuid };
          }
        }
        scope.setUser(sentryUser);
      });
    }
  }, [userData, user]);

  return (
    <UserDataContext.Provider value={userData}>
      <UserDataRefreshContext.Provider value={getUserData}>{children}</UserDataRefreshContext.Provider>
    </UserDataContext.Provider>
  );
};

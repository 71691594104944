import { makeStyles } from '@material-ui/core';

export const useHeroStyles = makeStyles(theme => ({
  root: {
    zIndex: 2,
    position: 'relative',
    marginBottom: '10px',
    padding: '180px 0 0',
    backgroundColor: theme.palette.secondary.light,
    [theme.breakpoints.up('sm')]: {
      marginBottom: '75px',
      padding: '200px 0 0',
    },
    [theme.breakpoints.up('md')]: {
      padding: '215px 0 0',
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: '95px',
      padding: '225px 0 0',
    },
    [theme.breakpoints.up('xl')]: {
      marginBottom: '115px',
      padding: '240px 0 0',
    },
    '&::before': {
      content: "''",
      zIndex: -1,
      position: 'absolute',
      top: 0,
      right: theme.direction === 'ltr' ? 0 : 'auto',
      left: theme.direction === 'rtl' ? 0 : 'auto',
      transform: `translate(${theme.direction === 'rtl' ? '-' : ''}53%, -48%)`,
      width: 'calc(1165px + 445 * ((100vw - 320px) / 1600))',
      height: 'calc(1165px + 445 * ((100vw - 320px) / 1600))',
      backgroundColor: theme.palette.secondary.dark,
      borderRadius: '100%',
      [theme.breakpoints.up('sm')]: {
        transform: `translate(${theme.direction === 'rtl' ? '-' : ''}38%, -40%)`,
      },
      [theme.breakpoints.up('md')]: {
        transform: `translate(${theme.direction === 'rtl' ? '-' : ''}38%, -46%)`,
      },
      [theme.breakpoints.up('xl')]: {
        transform: `translate(${theme.direction === 'rtl' ? '-' : ''}38%, -40%)`,
      },
    },
  },
  img: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      position: 'absolute',
      top: '50%',
      right: theme.direction === 'rtl' ? 0 : 'auto',
      left: theme.direction === 'ltr' ? 0 : 'auto',
      width: 'calc(220px + 740 * ((100vw - 320px) / 1600))',
      transform: `translateX(calc(-95px - 20 * ((100vw - 320px) / 1600))) translateY(-43%)`,
    },
    [theme.breakpoints.up('lg')]: {
      width: 'calc(220px + 740 * ((100vw - 320px) / 1600))',
      transform: `translateX(calc(-110px + 100 * ((100vw - 320px) / 1600))) translateY(-30%)`,
    },
    [theme.breakpoints.up('xl')]: {
      width: '970px',
      transform: `translateX(calc(-300px + 200 * ((100vw - 320px) / 1600))) translateY(-30%)`,
    },
    [`${theme.breakpoints.up('md')} and (-ms-high-contrast: none), (-ms-high-contrast: active)`]: {
      transform: `translateX(${theme.direction === 'rtl' ? '' : '-'}130px) translateY(-43%)`,
    },
    [`${theme.breakpoints.up('lg')} and (-ms-high-contrast: none), (-ms-high-contrast: active)`]: {
      transform: `translateX(${theme.direction === 'rtl' ? '' : '-'}80px) translateY(-30%)`,
    },
    [`${theme.breakpoints.up('xl')} and (-ms-high-contrast: none), (-ms-high-contrast: active)`]: {
      transform: `translateX(${theme.direction === 'rtl' ? '' : '-'}120px) translateY(-30%)`,
    },
  },
  text: {
    position: 'relative',
    textAlign: theme.direction === 'rtl' ? 'left' : 'right',
  },
  title: {
    marginBottom: '45px',
    maxWidth: '510px',
    fontWeight: 800,
    fontSize: '40px',
    lineHeight: '120%',
    color: theme.palette.primary.main,
    marginLeft: theme.direction === 'ltr' ? 'auto' : 0,
    [theme.breakpoints.up('sm')]: {
      fontSize: '44px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '54px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '64px',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '72px',
    },
  },
  desc: {
    marginBottom: '40px',
    maxWidth: '445px',
    fontSize: '18px',
    lineHeight: '28px',
    letterSpacing: '0.5px',
    color: '#0C2714',
    marginLeft: theme.direction === 'ltr' ? 'auto' : 0,
    [theme.breakpoints.up('md')]: {
      marginBottom: '55px',
    },
  },
  link: {
    order: 5,
    display: 'block',
    width: '100%',
    minWidth: '210px',
    textDecoration: 'none',
    [theme.breakpoints.up('sm')]: {
      order: 4,
      width: '70%',
      marginLeft: theme.direction === 'ltr' ? 'auto' : 0,
    },
    [theme.breakpoints.up('md')]: {
      display: 'inline-block',
      width: 'auto',
    },
    '&:last-of-type': {
      marginTop: '24px',
      [theme.breakpoints.up('md')]: {
        marginTop: 0,
        marginLeft: '24px',
      },
    },
  },
  btn: {
    width: '100%',
    padding: '18px 30px',
    fontSize: '20px',
    fontWeight: 'bold',
  },
}));

import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import { FileProps } from './File.types';
import { useStyles } from './File.styles';

export const File = ({ name, action }: FileProps) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" justify="space-between">
      <Grid item xs={12} md="auto">
        <Typography className={classes.name} variant="body1">
          {name}
        </Typography>
      </Grid>
      {action && (
        <Grid item xs={12} md="auto">
          <Box className={classes.action}>{action}</Box>
        </Grid>
      )}
    </Grid>
  );
};

import React, { useState, useCallback } from 'react';
import { useClient } from 'react-fetching-library';

import { ImageInput } from 'ui/imageInput/ImageInput';
import { ImageInputValue, UploadedFileDescriptor } from 'ui/imageInput/ImageInput.types';
import { AuctionPartExtendedProps } from '../CreateAuction.types';
import { addImageAction } from 'api/actions/auction/auctionActions';

export const AddImages = ({ register, setValue }: AuctionPartExtendedProps) => {
  const [images, setImages] = useState<ImageInputValue>([[], null]);
  const client = useClient();

  register({ name: 'images' });
  register({ name: 'mainImage' });

  const handleChange = useCallback(
    (imageInputValue: ImageInputValue) => {
      const [descriptors, mainImageId] = imageInputValue;
      const uploadedDescriptorIds = descriptors
        .filter((d): d is UploadedFileDescriptor => d.type === 'uploaded')
        .map(d => d.id);
      let nextMainImageId;
      if (mainImageId === null) {
        nextMainImageId = descriptors[0]?.type === 'uploaded' ? descriptors[0].id : null;
      } else {
        nextMainImageId = uploadedDescriptorIds.find(id => id === mainImageId) ?? uploadedDescriptorIds[0] ?? null;
      }
      if (nextMainImageId !== null) {
        setValue('images', uploadedDescriptorIds);
        setValue('mainImage', nextMainImageId);
      } else {
        setValue('images', []);
        setValue('mainImage', null);
      }
      setImages([descriptors, nextMainImageId]);
    },
    [setValue],
  );

  const uploader = async (file: File) => {
    const { error, payload } = await client.query(addImageAction(file));

    if (!error && payload) {
      setValue('mainImage', payload.uuid);
      return payload.uuid;
    }
    return;
  };

  return <ImageInput value={images} onChange={handleChange} uploader={uploader} />;
};

import { Action } from 'react-fetching-library';

import { VendorResponse } from './vendorActions.types';

export function getVendor(uuid: string): Action<VendorResponse> {
  return {
    method: 'GET',
    endpoint: `/v1/vendors/${uuid}`,
  };
}

import React from 'react';
import { Table as MuiTable, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';

import { useStyles } from './Table.styles';
import { TableProps } from './Table.types';

export const Table = ({ rows }: TableProps) => {
  const classes = useStyles();

  return (
    <MuiTable className={classes.root} size="small">
      <TableBody>
        {rows.map(row => (
          <TableRow key={row.key}>
            <TableCell>
              <Typography variant="body1" color="textSecondary">
                <strong>{row.key}</strong>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body1" color="textSecondary">
                <strong>{row.value}</strong>
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </MuiTable>
  );
};

import React from 'react';
import { Grid, Box } from '@material-ui/core';
import clsx from 'clsx';

import { ReactComponent as WaveImg } from 'assets/images/wave.svg';
import { ReactComponent as WaveMobileImg } from 'assets/images/wave-mobile.svg';
import { useHomeStyles } from 'app/home/Home.styles';

import { Features } from './features/Features';
import { Pages } from './pages/Pages';
import { GetToKnow } from './getToKnow/GetToKnow';
import { useStyles } from './AuctionFeatures.styles';

export const AuctionFeatures = () => {
  const classes = useStyles();
  const homeClasses = useHomeStyles();

  return (
    <>
      <WaveImg className={classes.wave} />
      <WaveMobileImg className={clsx(classes.wave, classes.waveMobile)} />
      <Box className={clsx(homeClasses.grid, classes.auctionFeaturesContainer)}>
        <Features />
        <Grid container spacing={4} className={classes.getToKnowWrapper}>
          <Grid item xs={12} md={5}>
            <GetToKnow />
          </Grid>
          <Grid item xs={12} md={7} className={classes.pagesWrapper}>
            <Pages />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

import React, { useEffect, Fragment } from 'react';
import { useLocation } from 'react-router-dom';

import { scrollTop } from 'helpers/scrollTop';

import { ScrollToTopProps } from './ScrollToTop.types';

export const ScrollToTop = ({ children }: ScrollToTopProps) => {
  const { pathname } = useLocation();

  useEffect(() => {
    scrollTop();
  }, [pathname]);

  return <Fragment>{children}</Fragment>;
};

import React, { useCallback, useEffect, useRef, useState } from 'react';
import jwtDecode from 'jwt-decode';
import * as Sentry from '@sentry/browser';

import { authStorage } from '../authStorage/AuthStorage';
import { AuthDispatchContext, AuthStateContext } from 'context/auth/authContext/AuthContext';
import { AuthStateContextType } from '../authContext/AuthContext.types';
import { AuthAction } from '../auth.types';
import { UserRole } from '../../../constants';

import { AuthContextControllerProps } from './AuthContextController.types';

function decodeAccessToken(accessToken: string): { exp: number; username: string; roles: UserRole[] } | undefined {
  try {
    return jwtDecode<{ exp: number; username: string; roles: UserRole[] }>(accessToken);
  } catch {
    return undefined;
  }
}

function getStateFromAuthStorage(): AuthStateContextType {
  if (authStorage.accessToken && authStorage.refreshToken) {
    const decodedToken = decodeAccessToken(authStorage.accessToken);
    const user = decodedToken ? { username: decodedToken.username, roles: decodedToken.roles } : undefined;

    if (user) {
      return {
        accessToken: authStorage.accessToken,
        refreshToken: authStorage.refreshToken,
        isAuthorized: true,
        user,
      };
    }
  }

  return {
    accessToken: null,
    refreshToken: null,
    isAuthorized: false,
    user: undefined,
  };
}

export const AuthContextController = ({ children }: AuthContextControllerProps) => {
  const [state, setState] = useState<AuthStateContextType>(getStateFromAuthStorage());
  const timeoutId = useRef<number | undefined>();

  useEffect(() => {
    if (authStorage.webStorage !== localStorage) return;

    const handler = (e: StorageEvent) => {
      clearTimeout(timeoutId.current);
      const authKeys: string[] = ['accessToken', 'refreshToken'];
      if (e.key && authKeys.includes(e.key)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        timeoutId.current = setTimeout(() => setState(getStateFromAuthStorage()));
      }
    };

    window.addEventListener('storage', handler);

    return () => {
      clearTimeout(timeoutId.current);
      window.removeEventListener('storage', handler);
    };
  }, [state]);

  const handleDispatch = useCallback((action: AuthAction) => {
    switch (action.type) {
      case 'clear-tokens': {
        if (process.env.REACT_APP_SENTRY_DSN) {
          Sentry.addBreadcrumb({
            category: 'auth',
            message: 'clear-tokens',
            level: Sentry.Severity.Info,
          });
        }
        authStorage.accessToken = null;
        authStorage.refreshToken = null;
        break;
      }
      case 'set-tokens': {
        if (process.env.REACT_APP_SENTRY_DSN) {
          const tokenExp = decodeAccessToken(action.accessToken)?.exp;
          Sentry.addBreadcrumb({
            category: 'auth',
            message: 'set-tokens',
            level: Sentry.Severity.Info,
            data: {
              exp: tokenExp !== undefined ? new Date(tokenExp * 1000) : undefined,
            },
          });
        }
        authStorage.accessToken = action.accessToken;
        authStorage.refreshToken = action.refreshToken;
        break;
      }
    }
    setState(getStateFromAuthStorage());
  }, []);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={handleDispatch}>{children}</AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

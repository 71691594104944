import React from 'react';
import { Grid } from '@material-ui/core';

import { useStyles } from '../BuyerProfileDetails.styles';
import { getAppMessage } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { BuyerProfileDetailsInfoProps } from '../BuyerProfileDetails.types';
import { BuyerField } from '../BuyerProfileDetails.enum';
import { WhatsappLink } from 'ui/whatsappLink/WhatsappLink';

export const BuyerProfileDetailsInfo = ({ userData, visible }: BuyerProfileDetailsInfoProps) => {
  const { formatMessage } = useLocale();
  const classes = useStyles();

  const empty = '-';

  return (
    <Grid container>
      <Grid item xs={12}>
        <p className={classes.label}>{formatMessage({ id: getAppMessage('common.labels.national_id') })}</p>
        <p className={classes.field}>{userData[BuyerField.nationalId]}</p>
      </Grid>
      <Grid item xs={12} md={6}>
        <p className={classes.label}>{formatMessage({ id: getAppMessage('common.labels.city') })}</p>
        <p className={classes.field}>{userData[BuyerField.city]?.length ? userData[BuyerField.city] : empty}</p>
      </Grid>
      <Grid item xs={12} md={6}>
        <p className={classes.label}>{formatMessage({ id: getAppMessage('common.labels.full_name') })}</p>
        <p className={classes.field}>{userData[BuyerField.fullName]?.length ? userData[BuyerField.fullName] : empty}</p>
      </Grid>
      {visible && (
        <>
          <Grid item xs={12} md={6}>
            <p className={classes.label}>{formatMessage({ id: getAppMessage('common.labels.phone_number') })}</p>
            <p className={classes.field}>{userData[BuyerField.phone]?.length ? userData[BuyerField.phone] : empty}</p>
          </Grid>
          <Grid item xs={12} md={6}>
            <p className={classes.label}>{formatMessage({ id: getAppMessage('common.labels.whats_app_link') })}</p>
            <WhatsappLink phone={userData[BuyerField.phone]} className={classes.field} />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export enum AuctionStatus {
  new = 'new',
  accepted = 'accepted',
  declined = 'declined',
  closed = 'closed',
  awarded = 'awarded',
  ended = 'ended',
}

export enum AuctionPaymentStatus {
  none = 'none',
  pending = 'pending',
  sended = 'sended',
  rejected = 'rejected',
  completed = 'completed',
}

export enum AuctionShippingStatus {
  none = 'none',
  pending = 'pending',
  received = 'received',
}

export enum AuctionPaymentFlow {
  none = 'none',
  waitingForBuyerPaymentConfirmation = 'waiting_for_buyer_payment_confirmation',
  waitingForVendorPaymentConfirmation = 'waiting_for_vendor_payment_confirmation',
  waitingForVendorFeePaymentConfirmation = 'waiting_for_vendor_fee_payment_confirmation',
  waitingForAdminFeePaymentConfirmation = 'waiting_for_admin_fee_payment_confirmation',
  paymentCompleted = 'payment_completed',
  buyerGetShipment = 'buyer_get_shipment',
}

export type AuctionResponse = {
  uuid: string;
  name: string;
  status: AuctionStatus;
  weight: number;
  unit: string;
  location: string;
  delivery: boolean;
  pickup: boolean;
  startDate: string;
  endDate: string;
  bidPrice?: number;
  bidsCount?: number;
  feeValue?: number;
  buyerFee?: string;
  vendorFee?: string;
  priceMin: number;
  type: string;
  quality: string;
  images: {
    main: string;
  }[];
  source: string;
  description: string;
  acknowledge: boolean;
  vendorId: string;
  createdAt: string;
  paymentStatus: AuctionPaymentStatus;
  shippingStatus: AuctionShippingStatus;
  feeStatus: AuctionPaymentStatus;
  paymentFlow: AuctionPaymentFlow;
  mainImage: string | null;
};

export type CreateAuctionPayload = {
  name: string;
  weight: number;
  unit: string;
  delivery: boolean;
  pickUp: boolean;
  priceMin: number;
  type: string;
  quality: string;
  source: string;
  description: string;
  acknowledge: boolean;
  startDate: string;
  endDate: string;
  location: string;
  mainImage: string | null;
  images: string[];
};

/* eslint-disable */
import React, {useMemo} from 'react';
import { Alert as MuiAlert } from '@material-ui/lab';
import { useStyles } from './Alert.styles';
import clsx from 'clsx';
import { AlertProps } from './Alert.types';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

export const Alert = ({ severity, children }: AlertProps) => {
  const classes = useStyles();
  const icon = useMemo(() => {
    switch (severity) {
      case "success":
        return<CheckCircleOutlinedIcon className={clsx(classes.alertIcon, classes.alertSuccessIcon)} />;
      case "warning":
        return <InfoOutlinedIcon className={clsx(classes.alertIcon, classes.alertWarningIcon)} />;
      case "error":
        return <CancelOutlinedIcon className={clsx(classes.alertIcon, classes.alertErrorIcon)} />
      default:
        return null;
    }
  },[severity])

  return <MuiAlert severity={severity} className={classes.root} icon={icon}>{children}</MuiAlert>;
};

import React, { useMemo } from 'react';
import { ClientContextProvider, createClient } from 'react-fetching-library';
import { useSnackbar } from 'notistack';

import { localStorageCache } from 'context/client/clientContextController/cache/localStorageCache';
import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { requestAuthInterceptor } from 'api/interceptors/requestAuthInterceptor/requestAuthInterceptor';
import { requestHostInterceptor } from 'api/interceptors/requestHostInterceptor/requestHostInterceptor';
import { useAuthDispatch } from '../../../hooks/useAuthDispatch/useAuthDispatch';
import { responseRefreshTokenInterceptor } from '../../../api/interceptors/responseRefreshTokenInterceptor/responseRefreshTokenInterceptor';
import { responseErrorInterceptor } from '../../../api/interceptors/responseErrorInterceptor/responseErrorInterceptor';
import { useLocale } from '../../../hooks/useLocale/useLocale';

import { ClientProviderProps } from './ClientContextController.types';

export const ClientContextController = ({ children }: ClientProviderProps) => {
  const { accessToken, refreshToken } = useAuthState();
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useLocale();
  const dispatch = useAuthDispatch();

  const baseUrl = String(process.env.REACT_APP_API_URL);

  const client = useMemo(() => {
    return createClient({
      requestInterceptors: [requestHostInterceptor(baseUrl), requestAuthInterceptor(accessToken)],
      responseInterceptors: [
        responseRefreshTokenInterceptor(refreshToken ?? '', dispatch),
        responseErrorInterceptor(enqueueSnackbar, formatMessage),
      ],
      cacheProvider: localStorageCache,
    });
  }, [accessToken, baseUrl, dispatch, enqueueSnackbar, formatMessage, refreshToken]);

  return <ClientContextProvider client={client}>{children}</ClientContextProvider>;
};

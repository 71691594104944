import React from 'react';
import { Grid, Paper } from '@material-ui/core';

import { AuctionPartProps, AcknowledgementsProps } from '../CreateAuction.types';
import { useLocale } from 'hooks/useLocale/useLocale';
import { getAppMessage } from 'i18n/messages';
import { Checkbox } from 'ui/checkbox/Checkbox';
import { useStyles } from '../CreateAuction.styles';
import { Link } from 'ui/link/Link';
import { AppRoute } from 'routing/AppRoute.enum';

export const Acknowledgements = ({ register, errors, backgroundColor }: AuctionPartProps & AcknowledgementsProps) => {
  const { formatMessage } = useLocale();
  const classes = useStyles();

  return (
    <Paper elevation={0} className={classes.paper} style={{ backgroundColor: backgroundColor }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Checkbox
            name="confirmInformation"
            label={formatMessage({ id: getAppMessage('auction.create_auction.confirm_information') })}
            error={Boolean(errors.confirmInformation)}
            helperText={errors.confirmInformation?.message}
            ref={register({ required: formatMessage({ id: getAppMessage('validation.common.required') }) })}
            color="primary"
          />
        </Grid>
        <Grid item xs={12}>
          <Checkbox
            name="agreeToPay"
            label={formatMessage(
              { id: getAppMessage('auction.create_auction.agree_pay') },
              {
                termsAndConditionsLink: (
                  <Link
                    href={AppRoute.terms}
                    text={formatMessage({ id: getAppMessage('login_and_registration.terms_and_conditions_link') })}
                    variant="inherit"
                  />
                ),
                privacyPolicyLink: (
                  <Link
                    href={AppRoute.privacy}
                    text={formatMessage({ id: getAppMessage('login_and_registration.privacy_policy_link') })}
                    variant="inherit"
                  />
                ),
              },
            )}
            error={Boolean(errors.agreeToPay)}
            helperText={errors.agreeToPay?.message}
            ref={register({ required: formatMessage({ id: getAppMessage('validation.common.required') }) })}
            color="primary"
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

import React, { useRef, useEffect } from 'react';
import { FormHelperText } from '@material-ui/core';

import { LoginLayout } from 'ui/loginLayout/LoginLayout';
import { Title } from 'ui/title/Title';
import { Button } from 'ui/button/Button';
import { useLocale } from 'hooks/useLocale/useLocale';
import { getAppMessage } from 'i18n/messages';
import { useLoginStepStyles } from 'app/login/Login.styles';
import { useApiForm } from '../../hooks/useApiForm/useApiForm';
import { getPhoneValidationOptions } from '../../validation/phone';
import { Phone } from '../../ui/fields/phone/Phone';
import { phonePrefix, sdmPhoneNumber } from '../../constants';
import { useScreenWidth } from 'hooks/useScreenWidth/useScreenWidth';
import { useTitleDispatch } from '../../hooks/useTitleDispatch/useTitleDispatch';
import { ExternalLink } from 'ui/link/externalLink/ExternalLink';

import { LoginHelpFormFields, LoginHelpProps } from './LoginHelp.types';

export const LoginHelp = ({ onSubmit }: LoginHelpProps) => {
  const classes = useLoginStepStyles();
  const { isTabletDesktop } = useScreenWidth();
  const form = useApiForm<LoginHelpFormFields>(onSubmit, { defaultValues: { phone: phonePrefix } });
  const { formatMessage, formatMsg } = useLocale();
  const setTitle = useTitleDispatch();

  useEffect(() => {
    setTitle([formatMsg('login_and_registration.login_help.title')]);
  }, [formatMsg, setTitle]);

  // First form field ref for focus management purpose
  const firstFieldRef = useRef<HTMLInputElement | null>(null);

  // Focus on first field on tablet, desktop devices
  useEffect(() => {
    const firstField = firstFieldRef.current;
    if (firstField && isTabletDesktop) {
      firstField.focus();
    }
  }, [isTabletDesktop]);

  return (
    <LoginLayout>
      <Title
        component="h1"
        variant="h5"
        text={formatMessage({ id: getAppMessage('login_and_registration.login_help.title') })}
      />
      <p className={classes.description}>
        {formatMessage({ id: getAppMessage('login_and_registration.login_help.description') })}
      </p>
      <form className={classes.form} onSubmit={form.onSubmit}>
        {form.formError && <FormHelperText error>{form.formError}</FormHelperText>}
        <Phone
          name="phone"
          autoFocus={isTabletDesktop}
          error={form.errors.phone?.message}
          ref={element => {
            form.register(element, getPhoneValidationOptions({ formatMessage }));
            firstFieldRef.current = element;
          }}
          margin="normal"
        />
        <Button
          type="submit"
          disabled={form.formState.isSubmitting}
          className={classes.button}
          text={formatMessage({ id: getAppMessage('login_and_registration.login_help.send') })}
        />
      </form>
      <p className={classes.help}>
        {formatMessage({ id: getAppMessage('login_and_registration.login_help.help') })}{' '}
        <ExternalLink href={`tel:+${sdmPhoneNumber}`}>
          {formatMessage({ id: getAppMessage('login_and_registration.login_help.contact') })}
        </ExternalLink>
      </p>
    </LoginLayout>
  );
};

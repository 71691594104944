import { useContext } from 'react';

import { CommonDataContextType } from 'context/commonData/commonDataContext/CommonDataContext.types';
import { CommonDataContext } from 'context/commonData/commonDataContext/CommonDataContext';

export const useCommonData: () => CommonDataContextType = () => {
  const context = useContext(CommonDataContext);

  if (context === undefined) {
    throw new Error('useCommonData must be used within a CommonDataContextController');
  }

  return context;
};

import { Action } from 'react-fetching-library';

import { PasswordPayload, HelpPhonePayload, SecurityCodePayload } from './passwordActions.types';

export function newPasswordAction(values: PasswordPayload): Action {
  return {
    method: 'POST',
    endpoint: '/v1/recovery-password',
    body: values,
    config: {
      skipAuthorization: true,
    },
  };
}

export function loginHelpPhoneAction(values: HelpPhonePayload): Action {
  return {
    method: 'POST',
    endpoint: '/v1/recovery-password/verify',
    body: values,
    config: {
      skipAuthorization: true,
    },
  };
}

export function loginHelpSecurityCodeAction(values: SecurityCodePayload): Action {
  return {
    method: 'PUT',
    endpoint: '/v1/register/sms-code',
    body: values,
    config: {
      skipAuthorization: true,
    },
  };
}

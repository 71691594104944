import React from 'react';
import { Typography } from '@material-ui/core';

import { TitleProps } from './Title.types';

export const Title = ({ text, variant, component, align = 'inherit', color = 'initial', className }: TitleProps) => {
  return (
    <Typography variant={variant} component={component} align={align} color={color} className={className}>
      {text}
    </Typography>
  );
};

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box, Typography } from '@material-ui/core';

import { UserRole } from '../../../../constants';
import { useLocale } from 'hooks/useLocale/useLocale';
import { getAppMessage } from 'i18n/messages';
import { Placeholder } from 'app/auctionDetails/placeholder/Placeholder';
import { ReactComponent as PlaceholderImage } from 'assets/images/finger-pointing-up.svg';
import { AuctionTile } from 'ui/auctionTile/AuctionTile';
import { Pagination } from 'ui/pagination/Pagination';
import { getAuctionStatusMessage } from 'helpers/getAuctionStatusMessage';

import { useStyles } from './CurrentBids.styles';
import { CurrentBidsListProps } from './CurrentBidsList.types';

export const CurrentBidsList = ({ error, auctions, onPageChange }: CurrentBidsListProps) => {
  const { formatMessage } = useLocale();
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {!error && !!auctions && (
        <>
          {!!auctions.data.length ? (
            <>
              <Box className={classes.list}>
                {auctions.data.map(auction => (
                  <AuctionTile
                    key={auction.uuid}
                    {...auction}
                    status={getAuctionStatusMessage(auction, UserRole.ROLE_BUYER)}
                    buyerProfileVersion
                  />
                ))}
              </Box>

              <Box px={[2, 3]} mt={4}>
                <Pagination
                  currentPage={auctions.pagination.current}
                  pagesCount={auctions.pagination.pages}
                  onChange={onPageChange}
                />
              </Box>
            </>
          ) : (
            <Placeholder>
              <PlaceholderImage
                role="img"
                title={formatMessage({ id: getAppMessage('user_profile.buyer.no_bids_info') })}
              />
              <Typography color="textPrimary">
                {formatMessage({
                  id: getAppMessage('user_profile.buyer.no_bids_info'),
                })}
              </Typography>
            </Placeholder>
          )}
        </>
      )}
    </Box>
  );
};

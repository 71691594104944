import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  auctionFeaturesContainer: {
    overflow: 'hidden',
  },
  title: {
    fontSize: '2rem',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    fontWeight: 700,
    lineHeight: 1.25,
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(10),
      fontSize: '3rem',
    },
  },
  description: {
    lineHeight: 1.56,
    fontSize: '1.125rem',
    fontWeight: 400,
    color: '#0C2714',
    marginBottom: theme.spacing(6),
  },
  getToKnowWrapper: {
    paddingBottom: theme.spacing(4),
  },
  connect: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-start',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: theme.spacing(1),
      paddingLeft: 0,
    },
  },
  connectText: {
    fontSize: '1.25rem',
  },
  subtitle: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    textAlign: 'center',
    opacity: 0.5,
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(1),
    },
  },
  phone: {
    color: theme.palette.primary.main,
    display: 'block',
    textDecoration: 'none',
    textAlign: 'center',
    fontWeight: 'bold',
    letterSpacing: '0.75px',
    [theme.breakpoints.up('md')]: {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
  },
  pagesWrapper: {
    position: 'relative',
    '&::before': {
      content: "''",
      zIndex: -1,
      position: 'absolute',
      top: 0,
      left: '-25vw',
      width: '150vw',
      height: '150vw',
      backgroundColor: theme.palette.secondary.dark,
      borderRadius: '100%',
      [theme.breakpoints.up('md')]: {
        width: '65vw',
        height: '65vw',
        left: 0,
        top: '20vw',
      },
      [theme.breakpoints.up('lg')]: {
        width: '70vw',
        height: '70vw',
        top: '10vw',
      },
      [theme.breakpoints.up('xl')]: {
        width: '75vw',
        height: '75vw',
        top: '2vw',
        left: '-5vw',
      },
    },
  },
  nav: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      bottom: theme.spacing(2),
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    [theme.breakpoints.up('lg')]: {
      bottom: theme.spacing(3),
    },
    [theme.breakpoints.up('xl')]: {
      justifyContent: 'space-evenly',
    },
  },
  navLink: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    padding: theme.spacing(2),
    fontSize: '1rem',
    lineHeight: 2.25,
    '&:hover': {
      textDecoration: 'underline',
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0.5),
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.125rem',
    },
  },
  wave: {
    backgroundColor: 'white',
    width: '100%',
    height: 'auto',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
    '& path': {
      fill: '#FAF5EE',
    },
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      height: 'calc(13px + 72 * ((100vw - 320px) / 1600))',
    },
  },
  waveMobile: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

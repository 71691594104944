import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  label: {
    fontSize: 10,
    textTransform: 'uppercase',
    color: theme.palette.grey[600],
    fontWeight: 'bold',
    lineHeight: 1.6,
  },
  itemTable: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    '& > tbody > tr > td': {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      borderBottom: 'none',
      height: 62,
    },
  },
}));

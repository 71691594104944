import React, { useState, ChangeEvent } from 'react';
import { Box } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { Dialog, DialogActions, DialogTitle } from 'ui/dialog/Dialog';
import { useLocale } from 'hooks/useLocale/useLocale';
import { getAppMessage } from 'i18n/messages';
import { Button } from 'ui/button/Button';
import { CompleteProfileDataProps, BuyerProfileDetailsFormFields } from '../BuyerProfileDetails.types';
import { useStyles } from '../BuyerProfileDetails.styles';
import { Input } from 'ui/input/Input';
import { useApiForm } from 'hooks/useApiForm/useApiForm';
import { BuyerField } from '../BuyerProfileDetails.enum';
import { getIdFromCityName } from 'helpers/extractCity';

export const CompleteProfileData = ({ cities, onSubmit, userData, open }: CompleteProfileDataProps) => {
  const { formatMessage, locale } = useLocale();
  const classes = useStyles();
  const { errors, register, setValue, onSubmit: handleSubmitCallback, clearError } = useApiForm<
    BuyerProfileDetailsFormFields
  >(onSubmit, {
    defaultValues: {
      [BuyerField.fullName]: userData[BuyerField.fullName],
    },
  });

  register({ name: BuyerField.city }, { required: formatMessage({ id: getAppMessage('validation.common.required') }) });
  register({ name: BuyerField.cityId });
  register({ name: BuyerField.fullName });

  const handleCityChange = (e: ChangeEvent<{}>, value: string | null) => {
    if (!value) {
      setValue(BuyerField.city, '');
      setValue(BuyerField.cityId, 0);
    } else if (value) {
      setValue(BuyerField.city, value);
      setValue(BuyerField.cityId, getIdFromCityName(value, cities));
      clearError();
    }
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const [dialogOpen, setDialogOpen] = useState(open);

  return (
    <Dialog open={dialogOpen} onClose={handleClose}>
      <Box m={4} mb={6}>
        <DialogTitle text={formatMessage({ id: getAppMessage('user_profile.buyer.complete_profile') })} />
      </Box>
      <Box mb={1}>
        <p>{formatMessage({ id: getAppMessage('user_profile.buyer.please_fill') })}</p>
      </Box>
      <form onSubmit={handleSubmitCallback}>
        <Autocomplete
          options={cities.map(({ nameEn, nameAr }) => (locale === 'en' ? nameEn : nameAr))}
          classes={{
            paper: classes.autocomplete,
            option: classes.selectItem,
          }}
          onChange={handleCityChange}
          renderInput={params => (
            <Input
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              label={formatMessage({ id: getAppMessage('common.labels.city') })}
              error={Boolean(errors.city)}
              helperText={errors.city?.message}
            />
          )}
        />
        <DialogActions>
          <Button size="small" type="submit" text={formatMessage({ id: getAppMessage('common.save') })} />
        </DialogActions>
      </form>
    </Dialog>
  );
};

import ar from 'dayjs/locale/ar-sa';
import en from 'dayjs/locale/en';

export enum DebugOption {
  noSmsChecking = 'debug:noSmsChecking',
  authStorage = 'debug:authStorage',
  direction = 'debug:direction',
  locale = 'debug:locale',
}

export enum UserRole {
  ROLE_BUYER = 'ROLE_BUYER',
  ROLE_VENDOR = 'ROLE_VENDOR',
  ROLE_ADMIN = 'ROLE_ADMIN',
}

export const appTitle = document.title;
export const deliveryReminderDelay = 4000; // ms
export const qsApiOptions = { addQueryPrefix: true, skipNulls: true };
export const maxFullNameLength = 30;
export const isTouchDevice = 'ontouchstart' in window;
export const phonePrefix = '05';
export const noSmsChecking = !!localStorage.getItem(DebugOption.noSmsChecking);
export const documentMimeTypes = ['application/pdf', 'image/*'];

export const currencyCode = 'SAR';

export const datePickerDateFormat = 'YYYY-MM-DD';

export const dayJsLocales = {
  ar,
  en,
};

export const formRequirements = {
  nameMaxLength: 60,
  weightMin: 1,
  priceMin: 1,
  onlyNumbers: /^[0-9]+$/,
  bankNameMin: 5,
  bankNameMax: 60,
};

export const auctionStartDateOffsetDays = 0;

export const auctionDurationDaysLimit = 4;

export const sdmPhoneNumber = '966114076597';
export const saudiArabiaCountryCode = '966';

export const externalLinks = {
  sdm: 'https://www.saudidatesmark.com/',
  takamol: 'https://takamolholding.com/',
  ncpd: 'https://ncpd.org.sa/',
};

export const prodUrl = 'https://dates-emazad.sa/';

// Auction status background colors
export enum StatusColors {
  green = '#4e8b43',
  yellow = '#fac571',
  red = '#ff0000',
  grey = '#c4c4c4',
  white = '#ffffff',
}

// Auction status text colors
export enum StatusTextColors {
  white = '#ffffff',
  black = '#000000',
  inherit = 'inherit',
}

export const auctionStatusColors: { [key: string]: StatusColors } = {
  'auction.status.active': StatusColors.green,
  'auction.status.payment_received': StatusColors.green,
  'auction.status.payment_pending': StatusColors.yellow,
  'auction.status.shipment_confirmation_pending': StatusColors.yellow,
  'auction.status.fee_payment_required': StatusColors.yellow,
  'auction.status.fee_payment_pending': StatusColors.yellow,
  'auction.status.fee_payment_received': StatusColors.green,
  'auction.status.fee_payment_confirmed': StatusColors.green,
  'auction.status.inactive': StatusColors.yellow,
  'auction.status.declined': StatusColors.red,
  'auction.status.ended': StatusColors.grey,
};

export const auctionStatusTextColors: { [key: string]: StatusTextColors } = {
  [StatusColors.green]: StatusTextColors.white,
  [StatusColors.yellow]: StatusTextColors.inherit,
  [StatusColors.red]: StatusTextColors.white,
  [StatusColors.grey]: StatusTextColors.black,
  [StatusColors.white]: StatusTextColors.inherit,
};

export const auctionStatusColorDefault = StatusColors.white;

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  auctionsSelect: {
    color: theme.palette.primary.main,
    '&::before, &::after': {
      content: 'none',
    },
  },
  auctionsSortingSelect: {
    flexFlow: theme.direction === 'rtl' ? 'row' : 'row-reverse',
    '& svg': {
      transform: 'scale(-1, 1)',
    },
    '& .MuiSelect-root': {
      padding: theme.direction === 'rtl' ? '6px 6px 7px 0' : '6px 0 7px 6px',
    },
  },
  filtersButton: {
    width: 48,
    height: 48,
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
    },
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)',
  },
}));

import React from 'react';
import { useParams } from 'react-router-dom';

import { Navigation } from 'ui/navigation/Navigation';
import { BuyerProfileDetailsContainer } from 'app/buyerProfileDetails/BuyerProfileDetailsContainer';

export const BuyerProfile = () => {
  const { uuid } = useParams<{ uuid: string }>();
  return (
    <>
      <Navigation />
      <BuyerProfileDetailsContainer uuid={uuid} />
    </>
  );
};

import React, { createContext, useCallback, useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router';
import qs from 'qs';

import { createQueryString } from 'helpers/createQueryString';
import { queryParamsReducer } from '../queryParamsReducer/queryParamsReducer';
import { QueryAction } from '../queryParamsReducer/queryParamsReducer.types';

import {
  QueryParamsDispatchContextType,
  QueryParamsStateContextValueType,
  SortingDirection,
} from './QueryParamsContext.types';
import { QueryParamsContextControllerProps } from './QueryParamsContextController.types';

export const QueryParamsStateContext = createContext<QueryParamsStateContextValueType | undefined>(undefined);
export const QueryParamsDispatchContext = createContext<QueryParamsDispatchContextType | undefined>(undefined);

const defaults: QueryParamsStateContextValueType = {
  page: '1',
  sort: {
    createdAt: SortingDirection.desc,
  },
};

const getStateFromSearch = (search: string): QueryParamsStateContextValueType => {
  const parsedQs = qs.parse(search, { ignoreQueryPrefix: true }) as QueryParamsStateContextValueType;
  return { ...defaults, ...parsedQs };
};

export const QueryParamsContextController = ({ children }: QueryParamsContextControllerProps) => {
  const history = useHistory();
  const [state, setState] = useState(getStateFromSearch(history.location.search));

  useLayoutEffect(() => {
    setState(getStateFromSearch(history.location.search));
  }, [history.location.search]);

  const handleDispatch = useCallback(
    (action: QueryAction) => {
      const nextState = queryParamsReducer(state, action);
      history.replace({ search: createQueryString(nextState) });
    },
    [history, state],
  );

  return (
    <QueryParamsStateContext.Provider value={state}>
      <QueryParamsDispatchContext.Provider value={handleDispatch}>{children}</QueryParamsDispatchContext.Provider>
    </QueryParamsStateContext.Provider>
  );
};

import React, { useState } from 'react';
import { Box, Paper } from '@material-ui/core';

import { Title } from 'ui/title/Title';
import { useLocale } from 'hooks/useLocale/useLocale';
import { getAppMessage } from 'i18n/messages';
import { Tabs } from 'ui/tabs/Tabs';

import { WonAuctionsContainer } from './wonAuctions/WonAuctionsContainer';
import { useStyles } from './ShoppingDetails.styles';
import { CurrentBidsListContainer } from './currentBidsList/CurrentBidsListContainer';

export const ShoppingDetails = () => {
  const classes = useStyles();
  const { formatMessage } = useLocale();
  const [activeTab, setActiveTab] = useState<'wonAuctions' | 'currentlyBidding'>('wonAuctions');

  return (
    <Paper elevation={0} className={classes.root}>
      <Title
        text={formatMessage({ id: getAppMessage('user_profile.buyer.shopping_details') })}
        variant="h4"
        component="h2"
        className={classes.title}
      />
      <Tabs
        className={classes.tabsNav}
        value={activeTab}
        onChange={(e, value) => {
          setActiveTab(value);
        }}
        labels={{
          wonAuctions: formatMessage({ id: getAppMessage('user_profile.buyer.won_auctions') }),
          currentlyBidding: formatMessage({ id: getAppMessage('user_profile.buyer.currently_bidding') }),
        }}
      />
      <Box hidden={activeTab !== 'wonAuctions'}>
        <WonAuctionsContainer />
      </Box>
      <Box hidden={activeTab !== 'currentlyBidding'}>
        <CurrentBidsListContainer />
      </Box>
    </Paper>
  );
};

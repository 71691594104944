import { QueryParamsStateContextValueType } from '../queryParamsContext/QueryParamsContext.types';

import { QueryAction } from './queryParamsReducer.types';

export const SET_AUCTION_STATUS_PARAM = 'query/set-auction-status-param';
export const SET_ORDER_PARAM = 'query/set-order-by-param';
export const SET_PAGE_PARAM = 'query/set-page-param';
export const SET_FILTERS_PARAM = 'query/set-filters-param';
export const UPDATE_QUERY_PARAMS = 'query/update-query-params';

export const queryParamsReducer: (
  state: QueryParamsStateContextValueType,
  action: QueryAction,
) => QueryParamsStateContextValueType = (state, action) => {
  const behaviours: Record<
    string,
    (state: QueryParamsStateContextValueType, action: QueryAction) => QueryParamsStateContextValueType
  > = {
    [SET_PAGE_PARAM]: (state, { page }) => ({
      ...state,
      page: page || '1',
    }),
    [SET_AUCTION_STATUS_PARAM]: (state, { auctionStatus }) => ({
      ...state,
      auctionStatus: auctionStatus,
      page: '1',
    }),
    [SET_ORDER_PARAM]: (state, { sort }) => ({
      ...state,
      sort: sort,
      page: '1',
    }),
    [SET_FILTERS_PARAM]: (state, { filter }) => ({
      ...state,
      filter: filter,
      page: '1',
    }),
    [UPDATE_QUERY_PARAMS]: state => ({
      ...state,
    }),
  };

  if (!behaviours[action.type]) {
    throw new Error(`Unhandled action type: ${action.type}`);
  }

  return behaviours[action.type](state, action);
};

export const scrollTop = () => {
  const position = document.body.scrollTop || document.documentElement.scrollTop;

  if (position) {
    window.scrollBy(0, -Math.max(1, Math.floor(position / 10)));
    const scrollAnimation = requestAnimationFrame(scrollTop);

    window.addEventListener('wheel', function stopAnimation() {
      cancelAnimationFrame(scrollAnimation);
      window.removeEventListener('wheel', stopAnimation);
    });

    window.addEventListener('keydown', function stopAnimation(key) {
      const stopScrollKeys = [33, 34, 32, 38, 40, 36, 35];

      if (stopScrollKeys.includes(key.which)) {
        cancelAnimationFrame(scrollAnimation);
        window.removeEventListener('keydown', stopAnimation);
      }
    });
  }
};

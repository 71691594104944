import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';

import { FormDivider } from 'ui/formDivider/FormDivider';
import { Button } from 'ui/button/Button';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useApiForm } from 'hooks/useApiForm/useApiForm';
import { getAppMessage } from 'i18n/messages';
import { Alert } from 'ui/alert/Alert';
import { useCommonData } from 'hooks/useCommonData/useCommonData';

import { CreateAuctionProps, CreateAuctionFormFields, BackgroundColor } from './CreateAuction.types';
import { useStyles } from './CreateAuction.styles';
import { BasicInformation } from './basicInformation/BasicInformation';
import { AuctionSetup } from './auctionSetup/AuctionSetup';
import { DeliveryMethod } from './deliveryMethod/DeliveryMethod';
import { Acknowledgements } from './acknowledgements/Acknowledgements';
import { AddImages } from './addImages/AddImages';
import { Location } from './location/Location';
import { CreateAuctionHeader } from './createAuctionHeader/CreateAuctionHeader';

export const CreateAuction = ({ onClose, onSubmit, vendorData }: CreateAuctionProps) => {
  const { formatMessage } = useLocale();
  const { dateTypesError, unitsError, cities: citiesList, citiesLoading, citiesError } = useCommonData();
  const cities = { cities: citiesList, citiesLoading, citiesError };

  const {
    register,
    onSubmit: handleSubmitCallback,
    errors,
    watch,
    setValue,
    triggerValidation,
    formState,
  } = useApiForm<CreateAuctionFormFields>(onSubmit, {
    defaultValues: {
      unit: 'g',
      startDate: '',
      endDate: '',
      images: [],
      mainImage: null,
    },
  });

  const classes = useStyles();

  const [backgroundColor, setBackgroundColor] = useState<BackgroundColor>('#FCFCFC');
  const [wasCheckedAlready, setWasCheckedAlready] = useState(false);
  const [fetchingDataError, setFetchingDataError] = useState(false);

  const watchConfirmInfo = watch('confirmInformation');
  const watchAgreeToPay = watch('agreeToPay');
  const watchDelivery = watch('delivery');
  const watchPickup = watch('pickUp');
  const watchLocation = watch('location');
  const watchType = watch('type');
  const watchQuality = watch('quality');

  register({ name: 'startDate' });
  register({ name: 'endDate' });

  const validateDeliveryChecked = () => {
    return watchPickup || watchDelivery || formatMessage({ id: getAppMessage('validation.common.at_least_one') });
  };

  useEffect(() => {
    if (watchConfirmInfo && watchAgreeToPay) {
      setBackgroundColor('#EBF0ED');
    } else {
      setBackgroundColor('#FCFCFC');
    }
  }, [watchConfirmInfo, watchAgreeToPay]);

  useEffect(() => {
    if (watchDelivery || watchPickup || wasCheckedAlready) {
      triggerValidation(['delivery', 'pickUp']);
      setWasCheckedAlready(true);
    }
  }, [watchDelivery, watchPickup, triggerValidation, wasCheckedAlready, setWasCheckedAlready]);

  useEffect(() => {
    if (watchType) {
      triggerValidation('type');
    }
  }, [triggerValidation, watchType]);

  useEffect(() => {
    if (watchQuality) {
      triggerValidation('quality');
    }
  }, [triggerValidation, watchQuality]);

  useEffect(() => {
    if (watchLocation === 'default') {
      triggerValidation('location');
    }
  }, [watchLocation, triggerValidation]);

  useEffect(() => {
    if (dateTypesError || unitsError || citiesError) {
      setFetchingDataError(true);
    }
  }, [citiesError, dateTypesError, unitsError]);

  return (
    <>
      <CreateAuctionHeader
        onClose={onClose}
        title={formatMessage({ id: getAppMessage('auction.create_auction.title') })}
      />
      {fetchingDataError && (
        <Box mt={3}>
          <Alert severity="error">{formatMessage({ id: getAppMessage('common.errors.fetching_data_error') })}</Alert>
        </Box>
      )}
      <form onSubmit={handleSubmitCallback} noValidate>
        <FormDivider
          text={formatMessage({ id: getAppMessage('auction.create_auction.step_1') })}
          className={classes.firstDivider}
        />
        <BasicInformation
          watch={watch}
          triggerValidation={triggerValidation}
          register={register}
          errors={errors}
          setValue={setValue}
        />
        <FormDivider text={formatMessage({ id: getAppMessage('auction.create_auction.step_2') })} />
        <Location
          watch={watch}
          triggerValidation={triggerValidation}
          register={register}
          errors={errors}
          setValue={setValue}
          cities={cities}
          vendorData={vendorData}
        />
        <FormDivider text={formatMessage({ id: getAppMessage('auction.create_auction.step_3') })} />
        <AddImages
          watch={watch}
          triggerValidation={triggerValidation}
          register={register}
          errors={errors}
          setValue={setValue}
        />
        <FormDivider text={formatMessage({ id: getAppMessage('auction.create_auction.step_4') })} />
        <AuctionSetup
          watch={watch}
          triggerValidation={triggerValidation}
          register={register}
          setValue={setValue}
          errors={errors}
        />
        <FormDivider text={formatMessage({ id: getAppMessage('auction.create_auction.step_5') })} />
        <DeliveryMethod register={register} errors={errors} validate={validateDeliveryChecked} />
        <FormDivider text={formatMessage({ id: getAppMessage('auction.create_auction.step_6') })} />
        <Acknowledgements register={register} errors={errors} backgroundColor={backgroundColor} />
        <Button
          type="submit"
          disabled={formState.isSubmitting}
          text={formatMessage({ id: getAppMessage('auction.create_auction.submit') })}
        />
      </form>
    </>
  );
};

import { makeStyles, createStyles } from '@material-ui/core';

export const useModalStyles = makeStyles(() =>
  createStyles({
    backdrop: {
      backgroundColor: '#1C562E !important',
      opacity: 0.9,
    },
  }),
);

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { Status } from '../../../../ui/status/Status';
import { getAppMessage } from '../../../../i18n/messages';
import { Button } from '../../../../ui/button/Button';
import { useLocale } from '../../../../hooks/useLocale/useLocale';
import { formatAmount } from '../../../../helpers/formatAmount';
import { Confirmation } from '../../confirmation/Confirmation';
import { useCommonData } from '../../../../hooks/useCommonData/useCommonData';
import { getNameFromCityId } from '../../../../helpers/extractCity';
import { Link } from 'ui/link/Link';
import { urlBuilders, AppRoute } from 'routing/AppRoute.enum';

import { useStyles } from './MobileTable.styles';
import { MobileTableProps } from './MobileTable.types';

export const MobileTable = ({
  disabled,
  isRtl,
  activeRowClass,
  onAcceptBid,
  data,
  className,
  isVendor,
}: MobileTableProps) => {
  const { formatMessage, locale } = useLocale();
  const { cities } = useCommonData();
  const classes = useStyles();

  return (
    <Table className={className}>
      <TableBody>
        {data.map(bid => (
          <TableRow key={bid.bidId} className={clsx(bid.accepted && activeRowClass)}>
            <TableCell padding="none">
              <Table className={classes.itemTable}>
                <TableBody>
                  <TableRow>
                    <TableCell align={isRtl ? 'left' : 'right'}>
                      <Box mb={0.5} className={classes.label}>
                        {formatMessage({ id: 'auction.details.buyer_bid_list.bid_amount' })}
                      </Box>
                      <Typography variant="body1">
                        <strong>{formatAmount(Number(bid.value) / 100, locale)}</strong>
                      </Typography>
                    </TableCell>
                    <TableCell align={isRtl ? 'left' : 'right'}>
                      <Box mb={0.5} className={classes.label}>
                        {formatMessage({ id: 'auction.details.buyer_bid_list.user_name' })}
                      </Box>
                      {isVendor ? (
                        <Link
                          text={bid.fullName}
                          href={urlBuilders[AppRoute.buyerProfile](bid.buyerId)}
                          underline="hover"
                          variant="inherit"
                        />
                      ) : (
                        <Typography color="primary" variant="body2">
                          {bid.fullName}
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align={isRtl ? 'left' : 'right'}>
                      <Box mb={0.5} className={classes.label}>
                        {formatMessage({ id: 'auction.details.buyer_bid_list.city' })}
                      </Box>
                      <Typography variant="body2">
                        {(bid.cityId && getNameFromCityId(bid.cityId, cities, locale)) || '-'}
                      </Typography>
                    </TableCell>
                    <TableCell align={isRtl ? 'left' : 'right'}>
                      {bid.accepted ? (
                        <Status>
                          {formatMessage({ id: getAppMessage('auction.details.buyer_bid_list.accepted') })}
                        </Status>
                      ) : (
                        onAcceptBid && (
                          <Box ml={-2}>
                            <Confirmation
                              popupId={bid.bidId}
                              titleMsg={'auction.details.buyer_bid_list.accept_bid_confirmation_title'}
                              bodyMsg={'auction.details.buyer_bid_list.accept_bid_confirmation_body'}
                              confirmationMsg={'auction.details.buyer_bid_list.accept_bid'}
                              onConfirm={() => onAcceptBid(bid.bidId)}
                            >
                              {confirmationProps => (
                                <Button
                                  disabled={disabled}
                                  variant="text"
                                  text={formatMessage({
                                    id: getAppMessage('auction.details.buyer_bid_list.accept_bid'),
                                  })}
                                  {...confirmationProps}
                                />
                              )}
                            </Confirmation>
                          </Box>
                        )
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useStepsSliderStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '10px 0 120px',
      [theme.breakpoints.up('md')]: {
        padding: '10px 0 135px',
      },
      [theme.breakpoints.up('xl')]: {
        padding: '10px 122px 135px',
      },
    },
  }),
);

import React, { useEffect } from 'react';
import { FormHelperText, Link as TextButton } from '@material-ui/core';

import { useLocale } from 'hooks/useLocale/useLocale';
import { getAppMessage } from 'i18n/messages';
import { LoginLayout } from 'ui/loginLayout/LoginLayout';
import { Title } from 'ui/title/Title';
import { Button } from 'ui/button/Button';
import { useLoginStepStyles } from 'app/login/Login.styles';
import { useApiForm } from '../../../hooks/useApiForm/useApiForm';
import { getSmsPasswordValidationOptions } from '../../../validation/smsPassword';
import { useTitleDispatch } from '../../../hooks/useTitleDispatch/useTitleDispatch';
import { SecurityCode as SecurityCodeField } from '../../../ui/fields/securityCode/SecurityCode';

import { SecurityCodeProps, SecurityCodeFields } from './SecurityCode.types';

export const SecurityCode = ({ onSubmit, onResend, resendingSecurityCode }: SecurityCodeProps) => {
  const classes = useLoginStepStyles();
  const { formatMessage, formatMsg } = useLocale();
  const form = useApiForm<SecurityCodeFields>(onSubmit);
  const setTitle = useTitleDispatch();

  useEffect(() => {
    setTitle([formatMsg('login_and_registration.security_code.title')]);
  }, [formatMsg, setTitle]);

  return (
    <LoginLayout>
      <Title
        component="h1"
        variant="h5"
        text={formatMessage({ id: getAppMessage('login_and_registration.security_code.title') })}
      />
      <p className={classes.description}>
        {formatMessage({ id: getAppMessage('login_and_registration.security_code.description') })}
      </p>
      <form className={classes.form} onSubmit={form.onSubmit}>
        {form.formError && <FormHelperText error>{form.formError}</FormHelperText>}
        <SecurityCodeField
          name="smsPassword"
          control={form.control}
          rules={getSmsPasswordValidationOptions({ formatMessage })}
          label={formatMessage({ id: getAppMessage('common.labels.security_code') })}
          error={form.errors.smsPassword?.message}
          margin="normal"
        />
        <Button
          className={classes.button}
          type="submit"
          disabled={form.formState.isSubmitting || resendingSecurityCode}
          text={formatMessage({ id: getAppMessage('login_and_registration.security_code.submit') })}
        />
      </form>
      <p className={classes.help}>
        {formatMessage({ id: getAppMessage('login_and_registration.security_code.help') })}{' '}
        <TextButton
          variant="body1"
          underline="always"
          component="button"
          classes={{ root: classes.textButton }}
          onClick={onResend}
        >
          {formatMessage({ id: getAppMessage('login_and_registration.security_code.resend') })}
        </TextButton>
      </p>
    </LoginLayout>
  );
};

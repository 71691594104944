import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useSelectStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: '100%',
    },
    primarySelectStyles: {
      paddingRight: '12px',
      borderRadius: 0,
      fontWeight: 500,
      borderWidth: '1px !important',
      '& .MuiSelect-select': {
        padding: theme.direction === 'rtl' ? '18.5px 12px 18.5px 8px' : '18.5px 8px 18.5px 12px',
        backgroundColor: 'transparent',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: '1px !important',
      },
    },
    primarySelectLabel: {
      color: '#000',
      '&.Mui-focused': {
        color: '#000',
      },
    },
    primarySelectLabelFilled: {
      color: '#000',
    },
    primarySelectOptionSelected: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
    },
  }),
);

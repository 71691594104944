import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  deadline: {
    fontSize: theme.extras.typography.body0.fontSize,
  },
  attributes: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      '-ms-grid-columns': '1fr 1fr',
      '-ms-grid-rows': '1fr 1fr 1fr',

      '& > div': {
        '&:nth-of-type(1)': {
          '-ms-grid-column': 1,
          '-ms-grid-row': 1,
          margin: `0 ${theme.spacing(2)}px ${theme.spacing(4)}px 0`,
        },
        '&:nth-of-type(2)': {
          '-ms-grid-column': 2,
          '-ms-grid-row': 1,
          margin: `0 0 ${theme.spacing(4)}px 0`,
        },
        '&:nth-of-type(3)': {
          '-ms-grid-column': 1,
          '-ms-grid-row': 2,
          margin: `0 ${theme.spacing(2)}px ${theme.spacing(4)}px 0`,
        },
        '&:nth-of-type(4)': {
          '-ms-grid-column': 2,
          '-ms-grid-row': 2,
          margin: `0 0 ${theme.spacing(4)}px 0`,
        },
        '&:nth-of-type(5)': {
          '-ms-grid-row': 3,
          '-ms-grid-row-span': 1,
          '-ms-grid-column': 1,
          '-ms-grid-column-span': 2,
          marginBottom: `${theme.spacing(4)}px`,
        },
      },
    },
  },
  actions: {
    textAlign: theme.direction === 'rtl' ? 'left' : 'right',
  },
  galleryColumn: {
    flexGrow: 1,
    overflow: 'hidden',
    maxWidth: '100%',
  },
  galleryWrapper: {
    [theme.breakpoints.up('lg')]: {
      marginRight: theme.spacing(3),
    },
  },
  attributeWide: {
    gridColumn: '1 / span 2',
  },
  status: {
    marginLeft: theme.direction === 'rtl' ? theme.spacing(1) : 'inherit',
    marginRight: theme.direction === 'rtl' ? 'inherit' : theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
    },
  },
}));

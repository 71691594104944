/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import EventIcon from '@material-ui/icons/Event';
import { DatePicker as MuiDatePicker } from '@material-ui/pickers';

import { datePickerDateFormat } from '../../constants';

import { DatePickerProps } from './DatePicker.types';

export const DatePicker = (props: DatePickerProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <MuiDatePicker
      {...props}
      autoOk
      disableToolbar
      variant="inline"
      color="secondary"
      inputVariant="outlined"
      format={datePickerDateFormat}
      PopoverProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
      onOpen={() => {
        setIsOpen(true);
      }}
      onClose={() => {
        setIsOpen(false);
      }}
      emptyLabel={isOpen ? ' ' : ''}
      InputProps={{
        endAdornment: <EventIcon />,
      }}
    />
  );
};

import { makeStyles } from '@material-ui/core';

const textColor = '#0C2714';

export const useTextImageSectionStyles = makeStyles(theme => ({
  root: {
    zIndex: 2,
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingTop: '90px',
    paddingBottom: '50px',
    display: 'flex',
    backgroundColor: 'transparent',
    [theme.breakpoints.up('md')]: {
      flexFlow: theme.direction === 'rtl' ? 'row-reverse' : 'row',
      paddingTop: '130px',
    },
    [theme.breakpoints.up('lg')]: {
      alignItems: 'flex-start',
      paddingTop: '150px',
    },
    [theme.breakpoints.up('xl')]: {
      paddingTop: '170px',
      paddingBottom: '10px',
    },
  },
  rootReversed: {
    justifyContent: 'flex-start',
    flexFlow: theme.direction === 'rtl' ? 'row' : 'row-reverse',
  },
  imgDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      transform: 'translate(calc(45px + 45 * ((100vw - 320px) / 1600)), calc(-25px - 20 * ((100vw - 320px) / 1600)))',
      width: 'calc(260px + 470 * ((100vw - 320px) / 1600))',
    },
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      height: 'auto',
      minWidth: 'calc(260px + 470 * ((100vw - 320px) / 1600))',
    },
    [`${theme.breakpoints.up('md')} and (-ms-high-contrast: none), (-ms-high-contrast: active)`]: {
      transform: `translate(${theme.direction === 'rtl' ? '-' : ''}20px, -50px)`,
    },
    [`${theme.breakpoints.up('lg')} and (-ms-high-contrast: none), (-ms-high-contrast: active)`]: {
      transform: `translate(${theme.direction === 'rtl' ? '-' : ''}50px, -50px)`,
    },
    [`${theme.breakpoints.up('xl')} and (-ms-high-contrast: none), (-ms-high-contrast: active)`]: {
      transform: `translate(${theme.direction === 'rtl' ? '-' : ''}70px, -55px)`,
    },
  },
  imgDesktopReversed: {
    [theme.breakpoints.up('md')]: {
      transform: 'translate(calc(0px + 35 * ((100vw - 320px) / 1600)), calc(-25px - 20 * ((100vw - 320px) / 1600)))',
    },
    [theme.breakpoints.up('lg')]: {
      transform:
        'translate(calc(-200px + 350 * ((100vw - 320px) / 1600)), calc(-25px - 20 * ((100vw - 320px) / 1600)))',
    },
    [`${theme.breakpoints.up('md')} and (-ms-high-contrast: none), (-ms-high-contrast: active)`]: {
      transform: `translate(${theme.direction === 'rtl' ? '' : '-'}20px, -50px)`,
    },
    [`${theme.breakpoints.up('lg')} and (-ms-high-contrast: none), (-ms-high-contrast: active)`]: {
      transform: `translate(${theme.direction === 'rtl' ? '-' : '-'}20px, -50px)`,
    },
    [`${theme.breakpoints.up('xl')} and (-ms-high-contrast: none), (-ms-high-contrast: active)`]: {
      transform: `translate(${theme.direction === 'rtl' ? '-' : ''}130px, -55px)`,
    },
  },
  imgMobile: {
    order: 3,
    display: 'block',
    width: '138%',
    transform: `translateX(${theme.direction === 'rtl' ? '-' : ''}14.5%)`,
    [theme.breakpoints.up('sm')]: {
      width: '470px',
      transform: `translateX(${theme.direction === 'rtl' ? '-' : ''}60px)`,
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      height: '100%',
    },
  },
  content: {
    flexFlow: 'column',
    alignItems: theme.direction === 'rtl' ? 'flex-start' : 'flex-end',
    display: 'flex',
    width: '100%',
    textAlign: theme.direction === 'rtl' ? 'left' : 'right',
    [theme.breakpoints.up('md')]: {
      minWidth: '330px',
      transform: `translateX(${theme.direction === 'rtl' ? '-' : ''}70px)`,
    },
    [theme.breakpoints.up('lg')]: {
      transform: 'none',
      marginLeft: theme.direction === 'rtl' ? 'calc(-40px + 140 * ((100vw - 320px) / 1600))' : 0,
      marginRight: theme.direction === 'ltr' ? 'calc(-40px + 140 * ((100vw - 320px) / 1600))' : 0,
    },
    [`${theme.breakpoints.between('md', 'lg')} and (-ms-high-contrast: none), (-ms-high-contrast: active)`]: {
      transform: `translateX(${theme.direction === 'rtl' ? '' : '-'}50px)`,
    },
  },
  contentReversed: {
    [theme.breakpoints.up('md')]: {
      minWidth: '330px',
      transform: 'none',
    },
    [theme.breakpoints.up('lg')]: {
      transform: 'none',
      marginRight: theme.direction === 'rtl' ? 'calc(-40px + 140 * ((100vw - 320px) / 1600))' : 0,
      marginLeft: theme.direction === 'ltr' ? 'calc(-40px + 140 * ((100vw - 320px) / 1600))' : 0,
    },
  },
  smallTitle: {
    order: 1,
    fontSize: '16px',
    lineHeight: '28px',
    fontWeight: 'bold',
    color: textColor,
  },
  title: {
    order: 2,
    maxWidth: '360px',
    width: '100%',
    fontSize: '32px',
    lineHeight: '40px',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    [theme.breakpoints.up('md')]: {
      marginBottom: '35px',
      maxWidth: '500px',
      fontSize: '38px',
      lineHeight: '54px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '50px',
      lineHeight: '62px',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '56px',
      lineHeight: '72px',
    },
  },
  description: {
    order: 4,
    maxWidth: '420px',
    width: '100%',
    marginTop: '-25px',
    marginBottom: '25px',
    fontSize: '18px',
    lineHeight: '28px',
    letterSpacing: '0.5px',
    color: textColor,
    [theme.breakpoints.up('sm')]: {
      order: 3,
      marginBottom: '35px',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: '45px',
    },
  },
  links: {
    order: 5,
    display: 'block',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      order: 4,
    },
    [theme.breakpoints.up('lg')]: {
      flexFlow: theme.direction === 'rtl' ? 'row' : 'row-reverse',
      justifyContent: 'flex-start',
      alignItems: 'center',
      display: 'flex',
    },
  },
  link: {
    display: 'block',
    width: '100%',
    minWidth: '210px',
    textDecoration: 'none',
    [theme.breakpoints.up('sm')]: {
      width: '70%',
      marginLeft: theme.direction === 'ltr' ? 'auto' : 0,
    },
    [theme.breakpoints.up('md')]: {
      display: 'inline-block',
      width: 'auto',
    },
  },
  linkMargin: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.direction === 'ltr' ? '32px' : 0,
      marginRight: theme.direction === 'rtl' ? '32px' : 0,
    },
  },
  btn: {
    width: '100%',
    padding: '18px 30px',
    fontSize: '20px',
    fontWeight: 'bold',
  },
  additionalLink: {
    display: 'block',
    marginTop: '16px',
    width: '100%',
    fontSize: '18px',
    lineHeight: '28px',
    color: theme.palette.primary.main,
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      width: '70%',
      marginLeft: theme.direction === 'ltr' ? 'auto' : 0,
      marginRight: theme.direction === 'rtl' ? 'auto' : 0,
      textAlign: theme.direction === 'rtl' ? 'left' : 'right',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'inline-block',
      marginTop: 0,
      width: 'auto',
    },
  },
}));

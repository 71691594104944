import React, { useEffect } from 'react';
import { Paper, Box, CircularProgress } from '@material-ui/core';

import { useLocale } from 'hooks/useLocale/useLocale';
import { Button } from 'ui/button/Button';
import { Title } from 'ui/title/Title';
import { Alert } from 'ui/alert/Alert';
import { getAppMessage } from 'i18n/messages';
import { getNameFromCityId } from 'helpers/extractCity';
import { useTitleDispatch } from '../../hooks/useTitleDispatch/useTitleDispatch';
import { useCommonData } from 'hooks/useCommonData/useCommonData';

import { BuyerProfileDetailsProps } from './BuyerProfileDetails.types';
import { useStyles } from './BuyerProfileDetails.styles';
import { BuyerProfileDetailsForm } from './BuyerProfileDetailsForm/BuyerProfileDetailsForm';
import { BuyerProfileDetailsInfo } from './BuyerProfileDetailsInfo/BuyerProfileDetailsInfo';
import { BuyerField } from './BuyerProfileDetails.enum';

export const BuyerProfileDetails = ({
  onSubmit,
  toggleEdit,
  editable,
  visible,
  error,
  loading,
  payload,
  edit,
}: BuyerProfileDetailsProps) => {
  const { formatMessage, formatMsg, locale } = useLocale();
  const classes = useStyles();
  const setTitle = useTitleDispatch();
  const { cities, citiesError, citiesLoading } = useCommonData();

  useEffect(() => {
    setTitle([formatMsg('page_title.buyer_profile'), payload?.fullName]);
  }, [formatMsg, setTitle, payload]);

  if (loading || citiesLoading) {
    return (
      <Paper elevation={0}>
        <Box p={3} display="flex" justifyContent="center">
          <CircularProgress size={32} />
        </Box>
      </Paper>
    );
  }

  if (error || !payload || citiesError || !cities.length) {
    return <Alert severity="error">{formatMessage({ id: getAppMessage('common.errors.fetching_data_error') })}</Alert>;
  }

  const userData = {
    [BuyerField.nationalId]: payload.nationalId,
    [BuyerField.fullName]: payload.fullName,
    [BuyerField.phone]: payload.phone,
    [BuyerField.city]: getNameFromCityId(payload.cityId, cities, locale),
    [BuyerField.cityId]: payload.cityId ? payload.cityId : 0,
  };

  return (
    <Paper elevation={0}>
      <Box p={3}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
          <Title
            text={formatMessage({ id: getAppMessage('user_profile.buyer.profile_details') })}
            variant="h5"
            component="h3"
            className={classes.title}
          />
          {editable && !edit && (
            <Button onClick={toggleEdit} text={formatMessage({ id: getAppMessage('common.edit') })} variant="text" />
          )}
        </Box>
        {!edit && <BuyerProfileDetailsInfo userData={userData} visible={visible} />}
        {edit && (
          <BuyerProfileDetailsForm onSubmit={onSubmit} onClose={toggleEdit} userData={userData} cities={cities} />
        )}
      </Box>
    </Paper>
  );
};

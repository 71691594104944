import React, { useRef, useEffect } from 'react';
import { Box, FormHelperText } from '@material-ui/core';

import { LoginLayout } from '../../ui/loginLayout/LoginLayout';
import { Title } from 'ui/title/Title';
import { Button } from 'ui/button/Button';
import { Tabs } from 'ui/tabs/Tabs';
import { Link } from '../../ui/link/Link';
import { AppRoute, urlBuilders } from '../../routing/AppRoute.enum';
import { getAppMessage } from '../../i18n/messages';
import { useApiForm } from '../../hooks/useApiForm/useApiForm';
import { getPasswordValidationOptions } from '../../validation/password';
import { getPhoneValidationOptions } from '../../validation/phone';
import { Phone } from '../../ui/fields/phone/Phone';
import { Password } from '../../ui/fields/password/Password';
import { phonePrefix } from '../../constants';
import { useScreenWidth } from 'hooks/useScreenWidth/useScreenWidth';
import { useTitleDispatch } from '../../hooks/useTitleDispatch/useTitleDispatch';
import { useLocale } from '../../hooks/useLocale/useLocale';

import { LoginFormFields, LoginProps } from './Login.types';
import { useStyles } from './Login.styles';

export const Login = ({ onSubmit }: LoginProps) => {
  const classes = useStyles();
  const { isTabletDesktop } = useScreenWidth();
  const { formatMessage, formatMsg } = useLocale();
  const form = useApiForm<LoginFormFields>(onSubmit, { defaultValues: { phone: phonePrefix, type: 'vendor' } });
  const setTitle = useTitleDispatch();

  useEffect(() => {
    setTitle([formatMsg('login_and_registration.log_in')]);
  }, [formatMsg, setTitle]);

  const type = form.watch('type');

  form.register({ name: 'type' }, { required: true });

  // First form field ref for focus management purpose
  const firstFieldRef = useRef<HTMLInputElement | null>(null);

  // Focus on first field on tab change
  useEffect(() => {
    const firstField = firstFieldRef.current;
    if (firstField && isTabletDesktop) {
      firstField.focus();
    }
  }, [isTabletDesktop, type]);

  return (
    <LoginLayout>
      <Box mb={4}>
        <Title
          text={formatMessage({ id: getAppMessage('login_and_registration.log_in') })}
          component="h2"
          variant="h5"
        />
      </Box>
      <form className={classes.form} onSubmit={form.onSubmit}>
        <Box mb={2}>
          <Tabs
            value={type}
            onChange={(e, value) => form.setValue('type', value)}
            className={classes.tabs}
            labels={{
              vendor: formatMessage({ id: getAppMessage('login_and_registration.tabs.want_to_sell') }),
              buyer: formatMessage({ id: getAppMessage('login_and_registration.tabs.want_to_buy') }),
            }}
          />
        </Box>
        {form.formError && <FormHelperText error>{form.formError}</FormHelperText>}
        <Phone
          name="phone"
          autoFocus={isTabletDesktop}
          error={form.errors.phone?.message}
          ref={element => {
            form.register(element, getPhoneValidationOptions({ formatMessage }));
            firstFieldRef.current = element;
          }}
          margin="normal"
        />
        <Password
          name="password"
          error={form.errors.password?.message}
          ref={form.register(getPasswordValidationOptions({ formatMessage }))}
          margin="normal"
        />
        <Box py={1}>
          <Link
            variant="caption"
            underline="none"
            text={formatMessage({ id: getAppMessage('login_and_registration.forgot_password') })}
            href={urlBuilders[AppRoute.loginHelp](type)}
          />
        </Box>
        <Button
          type="submit"
          className={classes.button}
          disabled={form.formState.isSubmitting}
          text={formatMessage({ id: getAppMessage('login_and_registration.log_in') })}
        />
        <Box className={classes.help}>
          {formatMessage({ id: getAppMessage('login_and_registration.no_account') })}{' '}
          <Link
            text={formatMessage({ id: getAppMessage('login_and_registration.sign_up') })}
            href={AppRoute.register}
            variant="inherit"
          />
        </Box>
      </form>
    </LoginLayout>
  );
};

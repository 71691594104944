import React from 'react';
import { useIntl } from 'react-intl';

import { getAppMessage } from '../../../i18n/messages';
import { Input } from '../../input/Input';
import { maxFullNameLength } from '../../../constants';
import { useInputConstraints } from '../../../hooks/useInputConstraints/useInputConstraints';

import { FullNameProps } from './FullName.types';

/* Allow up to maxFullNameLength of non-digit characters. */
const predicate = (value: string) => new RegExp(`^\\D{0,${maxFullNameLength}}$`).test(value);

export const FullName = React.forwardRef<HTMLInputElement, FullNameProps>(
  ({ name, label, autoFocus, error, margin }, ref) => {
    const { formatMessage } = useIntl();
    const inputProps = useInputConstraints(predicate);

    return (
      <Input
        name={name}
        ref={ref}
        type="text"
        autoFocus={autoFocus}
        label={label || formatMessage({ id: getAppMessage('common.labels.full_name') })}
        error={!!error}
        helperText={error}
        margin={margin}
        inputProps={inputProps}
      />
    );
  },
);

import React, { PropsWithChildren, useCallback } from 'react';
import { Box, Dialog as MuiDialog, Grid, IconButton, Typography, useTheme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { DialogProps } from './Dialog.types';

export const DialogTitle = ({ text }: { text: string }) => {
  return (
    <Box textAlign="center">
      <Typography variant="h6" component="h2">
        <strong>{text}</strong>
      </Typography>
    </Box>
  );
};

export const DialogActions = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Box mt={8}>
      <Grid container spacing={3}>
        {React.Children.map(children, child => (
          <Grid item>{child}</Grid>
        ))}
      </Grid>
    </Box>
  );
};

export const Dialog = ({ open, onClose, disabled, children }: DialogProps) => {
  const theme = useTheme();
  const handleClose = useCallback(() => !disabled && onClose(), [disabled, onClose]);

  return (
    <MuiDialog open={open} onClose={handleClose} maxWidth="md">
      <Box p={[2, 3, 6]}>
        <Box
          display={['none', 'none', 'block']}
          position="absolute"
          left={theme.spacing(1.5)}
          top={theme.spacing(1.5)}
          right={theme.spacing(1.5)}
          textAlign="end"
        >
          <IconButton onClick={handleClose} size="small" disabled={disabled}>
            <CloseIcon />
          </IconButton>
        </Box>

        {children}
      </Box>
    </MuiDialog>
  );
};

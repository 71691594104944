import { IntlFormatters } from 'react-intl';
import { ValidationOptions } from 'react-hook-form';

import { getAppMessage } from '../i18n/messages';

export function getSmsPasswordValidationOptions({
  formatMessage,
}: {
  formatMessage: IntlFormatters['formatMessage'];
}): ValidationOptions {
  return { required: formatMessage({ id: getAppMessage('validation.common.required') }) };
}

import { useEffect } from 'react';

import { clearTokens } from 'context/auth/authActionCreators/authActionCreators';
import { useAuthDispatch } from 'hooks/useAuthDispatch/useAuthDispatch';

export const LogoutContainer = () => {
  const dispatch = useAuthDispatch();

  useEffect(() => {
    dispatch(clearTokens());
  }, [dispatch]);

  return null;
};

import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme =>
  createStyles({
    imageWrapper: {
      justifyContent: 'center',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-start',
      },
    },
    image: {
      display: 'block',
      maxWidth: '80%',
      maxHeight: theme.spacing(6),
      margin: '0 auto',
      alignSelf: 'center',
      [theme.breakpoints.up('sm')]: {
        maxHeight: theme.spacing(8),
        maxWidth: '65%',
      },
      [theme.breakpoints.up('md')]: {
        maxHeight: '100%',
        margin: 0,
      },
    },
    text: {
      fontSize: '1.125rem',
      lineHeight: 1.56,
      letterSpacing: '0.5px',
      color: '#0C2714',
      [theme.breakpoints.up('md')]: {
        margin: 0,
      },
    },
  }),
);

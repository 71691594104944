import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';

import { UserRole } from '../../constants';
import { AuctionPaymentFlow, AuctionStatus } from '../../api/actions/auction/auctionActions.types';
import { useTitleDispatch } from '../../hooks/useTitleDispatch/useTitleDispatch';
import { useLocale } from '../../hooks/useLocale/useLocale';

import { AuctionDetailsProps } from './AuctionDetails.types';
import { BidsListContainer } from './bidsList/BidsListContainer';
import { Payments } from './payments/Payments';
import { Fee } from './fee/Fee';
import { MyBids } from './myBids/MyBids';
import { VendorInfo } from './vendorInfo/VendorInfo';
import { Admin } from './admin/Admin';
import { BasicInfo } from './basicInfo/BasicInfo';
import { DeliveryConfirmation } from './deliveryConfirmation/DeliveryConfirmation';

const spacing = 3;

export const AuctionDetails = ({
  disabled,
  auction,
  userData,
  dateTypes,
  dateGrades,
  units,
  onCloseAuction,
  onLeaveBidding,
  onFeeConfirm,
  onPaymentConfirm,
  onConfirmDelivery,
  onBid,
  onAcceptBid,
  onPaymentConfirmationUpload,
  onFeeConfirmationUpload,
  onPaymentRemove,
  onFeeRemove,
  onPaymentReject,
  onFeeReject,
  bidsListRefreshKey,
}: AuctionDetailsProps) => {
  const { formatMsg } = useLocale();
  const setTitle = useTitleDispatch();

  useEffect(() => {
    setTitle([formatMsg('page_title.auction_details'), auction.name]);
  }, [auction.name, formatMsg, setTitle]);

  return (
    <>
      <Grid container spacing={spacing} direction="column" wrap="nowrap">
        {userData.type === UserRole.ROLE_BUYER &&
          auction.status === AuctionStatus.awarded &&
          auction.paymentFlow === AuctionPaymentFlow.paymentCompleted &&
          userData.bidInfo?.accepted && (
            <Grid item>
              <DeliveryConfirmation disabled={disabled} onConfirmDelivery={onConfirmDelivery} />
            </Grid>
          )}
        <Grid item>
          <BasicInfo
            disabled={disabled}
            auction={auction}
            userData={userData}
            dateTypes={dateTypes}
            dateGrades={dateGrades}
            units={units}
            onCloseAuction={onCloseAuction}
            onBid={onBid}
          />
        </Grid>
        <Grid item>
          <Grid container spacing={spacing}>
            <Grid item xs={12} lg={6}>
              <Grid container spacing={spacing} direction="column" wrap="nowrap">
                {userData.type === UserRole.ROLE_BUYER && (
                  <Grid item>
                    <MyBids
                      disabled={disabled}
                      auction={auction}
                      buyerData={userData}
                      onPaymentConfirmationUpload={onPaymentConfirmationUpload}
                      onPaymentConfirmationRemove={onPaymentRemove}
                      onLeaveBidding={onLeaveBidding}
                    />
                  </Grid>
                )}
                {userData.type === UserRole.ROLE_VENDOR && (
                  <Grid item>
                    <Payments
                      disabled={disabled}
                      auction={auction}
                      onConfirmPayment={onPaymentConfirm}
                      onRejectPayment={onPaymentReject}
                    />
                  </Grid>
                )}
                {userData.type === UserRole.ROLE_BUYER && userData.vendor && (
                  <Grid item>
                    <VendorInfo vendor={userData.vendor} vendorId={auction.vendorId} />
                  </Grid>
                )}
                {userData.type === UserRole.ROLE_VENDOR && (
                  <Grid item>
                    <Fee
                      disabled={disabled}
                      auction={auction}
                      onConfirmationUpload={onFeeConfirmationUpload}
                      onConfirmationRemove={onFeeRemove}
                    />
                  </Grid>
                )}
                {userData.type === UserRole.ROLE_ADMIN && (
                  <Grid item>
                    <Admin
                      disabled={disabled}
                      auction={auction}
                      onConfirmFee={onFeeConfirm}
                      onRejectFee={onFeeReject}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
              <BidsListContainer
                refreshKey={bidsListRefreshKey}
                disabled={disabled}
                auctionUuid={auction.uuid}
                userData={userData}
                onAcceptBid={onAcceptBid}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

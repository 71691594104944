import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { LoginContainer } from 'app/login/LoginContainer';
import { LogoutContainer } from 'app/logout/LogoutContainer';
import { RegisterContainer } from 'app/registration/RegisterContainer';
import { LoginHelpContainer } from '../app/loginHelp/LoginHelpContainer';
import { AuctionDetailsContainer } from '../app/auctionDetails/AuctionDetailsContainer';
import { AuctionsContainer } from 'app/auctionsList/AuctionsContainer';
import { HomeContainer } from 'app/home/HomeContainer';
import { FAQ } from 'app/faq/FAQ';
import { VendorProfile } from '../app/vendorProfile/VendorProfile';
import { BuyerProfile } from 'app/buyerProfile/BuyerProfile';
import { PrivacyPolicy } from 'app/privacyPolicy/PrivacyPolicy';
import { TermsAndConditions } from 'app/termsAndConditions/TermsAndConditions';
import { ShortLink } from '../app/shortLink/ShortLink';

import { AppRoute } from './AppRoute.enum';
import { AuthorizedRoute } from './AuthorizedRoute';

export const AppRoutes = () => {
  return (
    <Switch>
      <Route path={AppRoute.home} exact component={HomeContainer} />
      <Route path={AppRoute.faq} exact component={FAQ} />
      <Route path={AppRoute.privacy} exact component={PrivacyPolicy} />
      <Route path={AppRoute.terms} exact component={TermsAndConditions} />
      <Route path={AppRoute.register} component={RegisterContainer} />
      <Route path={AppRoute.login} component={LoginContainer} />
      <Route path={AppRoute.loginHelp} component={LoginHelpContainer} />
      <AuthorizedRoute noReturn path={AppRoute.logout} component={LogoutContainer} />
      <AuthorizedRoute path={AppRoute.vendorProfile} component={VendorProfile} />
      <AuthorizedRoute path={AppRoute.buyerProfile} component={BuyerProfile} />
      <AuthorizedRoute path={AppRoute.auctionDetails} component={AuctionDetailsContainer} />
      <AuthorizedRoute path={AppRoute.auctionsList} component={AuctionsContainer} />
      <AuthorizedRoute path={AppRoute.shortLink} component={ShortLink} />

      <Redirect to={AppRoute.home} />
    </Switch>
  );
};

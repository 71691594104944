import React from 'react';
import { Link } from '@material-ui/core';

import { ExternalLinkProps } from './ExternalLink.types';

export const ExternalLink = ({
  children,
  text,
  href,
  target,
  underline = 'always',
  variant = 'body1',
  color,
  className,
}: ExternalLinkProps) => (
  <Link color={color} href={href} target={target} underline={underline} variant={variant} className={className}>
    {text || children}
  </Link>
);

import React from 'react';
import { Box } from '@material-ui/core';
import clsx from 'clsx';

import { Hero } from 'ui/hero/Hero';
import { Navigation } from 'ui/navigation/Navigation';
import { StepsSlider } from 'ui/stepsSlider/StepsSlider';
import { TextWithNumber } from 'ui/textWithNumber/TextWithNumber';
import { TextImageSection } from 'ui/textImageSection/TextImageSection';
import { Footer } from 'ui/footer/Footer';
import { AppRoute } from 'routing/AppRoute.enum';
import HomeImg1 from 'assets/images/home-img-1.png';
import HomeImg2 from 'assets/images/home-img-2.png';
import { ReactComponent as WaveImg } from 'assets/images/wave.svg';
import { ReactComponent as WaveMobileImg } from 'assets/images/wave-mobile.svg';
import { useLocale } from 'hooks/useLocale/useLocale';
import { getAppMessage } from 'i18n/messages';
import { externalLinks } from '../../constants';

import { useHomeStyles } from './Home.styles';

export const HomeContainer = () => {
  const { formatMessage } = useLocale();
  const classes = useHomeStyles();
  const SellersStepsSlider = [
    <TextWithNumber
      number="01"
      title={formatMessage({ id: getAppMessage('home.sellers_steps_slider.slide_1.title') })}
      description={formatMessage({ id: getAppMessage('home.sellers_steps_slider.slide_1.description') })}
      fixedTitleHeight
    />,
    <TextWithNumber
      number="02"
      title={formatMessage({ id: getAppMessage('home.sellers_steps_slider.slide_2.title') })}
      description={formatMessage({ id: getAppMessage('home.sellers_steps_slider.slide_2.description') })}
      fixedTitleHeight
    />,
    <TextWithNumber
      number="03"
      title={formatMessage({ id: getAppMessage('home.sellers_steps_slider.slide_3.title') })}
      description={formatMessage({ id: getAppMessage('home.sellers_steps_slider.slide_3.description') })}
      fixedTitleHeight
    />,
    <TextWithNumber
      number="04"
      title={formatMessage({ id: getAppMessage('home.sellers_steps_slider.slide_4.title') })}
      description={formatMessage({ id: getAppMessage('home.sellers_steps_slider.slide_4.description') })}
      fixedTitleHeight
    />,
  ];
  const BuyersStepsSlider = [
    <TextWithNumber
      number="01"
      title={formatMessage({ id: getAppMessage('home.buyers_steps_slider.slide_1.title') })}
      description={formatMessage({ id: getAppMessage('home.buyers_steps_slider.slide_1.description') })}
      fixedTitleHeight
    />,
    <TextWithNumber
      number="02"
      title={formatMessage({ id: getAppMessage('home.buyers_steps_slider.slide_2.title') })}
      description={formatMessage({ id: getAppMessage('home.buyers_steps_slider.slide_2.description') })}
      fixedTitleHeight
    />,
    <TextWithNumber
      number="03"
      title={formatMessage({ id: getAppMessage('home.buyers_steps_slider.slide_3.title') })}
      description={formatMessage({ id: getAppMessage('home.buyers_steps_slider.slide_3.description') })}
      fixedTitleHeight
    />,
    <TextWithNumber
      number="04"
      title={formatMessage({ id: getAppMessage('home.buyers_steps_slider.slide_4.title') })}
      description={formatMessage({ id: getAppMessage('home.buyers_steps_slider.slide_4.description') })}
      fixedTitleHeight
    />,
  ];

  return (
    <>
      <Navigation variant="overlay" />
      <Box className={classes.root}>
        <Box className={classes.grid}>
          <Hero />
          <TextImageSection
            image={HomeImg1}
            imageAlt={formatMessage({ id: getAppMessage('home.for_sellers.img_alt') })}
            smallTitle={formatMessage({ id: getAppMessage('home.for_sellers.small_title') })}
            title={formatMessage({ id: getAppMessage('home.for_sellers.title') })}
            description={formatMessage({ id: getAppMessage('home.for_sellers.description') })}
            btnText={formatMessage({ id: getAppMessage('home.for_sellers.start_selling') })}
            link={AppRoute.login}
            additionalLink={{
              link: externalLinks.sdm,
              text: formatMessage({ id: getAppMessage('home.for_sellers.get_a_mark') }),
            }}
          />
        </Box>
        <StepsSlider slides={SellersStepsSlider} />
        <Box className={classes.lightBackground}>
          <WaveImg className={classes.wave} />
          <WaveMobileImg className={clsx(classes.wave, classes.waveMobile)} />
          <Box className={classes.grid}>
            <TextImageSection
              image={HomeImg2}
              imageAlt={formatMessage({ id: getAppMessage('home.for_buyers.img_alt') })}
              smallTitle={formatMessage({ id: getAppMessage('home.for_buyers.small_title') })}
              title={formatMessage({ id: getAppMessage('home.for_buyers.title') })}
              description={formatMessage({ id: getAppMessage('home.for_buyers.description') })}
              btnText={formatMessage({ id: getAppMessage('home.for_buyers.explore_auctions') })}
              link={AppRoute.auctionsList}
              reversed
            />
          </Box>
          <StepsSlider slides={BuyersStepsSlider} />
        </Box>
        <Footer />
      </Box>
    </>
  );
};
